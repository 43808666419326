import { Component, OnInit } from '@angular/core';
import { ServiceMangeService } from 'src/app/services/service-mange.service';
import { MatDialog } from '@angular/material/dialog';
import { AddMicroServiceComponent } from '../add-micro-service/add-micro-service.component';
import { MainService } from 'src/app/services/main.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-all-services',
  templateUrl: './all-services.component.html',
  styleUrls: ['./all-services.component.css'],
})
export class AllServicesComponent implements OnInit {
  showSpinnerForMainServices: boolean = true;
  showSpinnerForSubServices: boolean = false;
  displayedColumns: string[] = [
    'serviceImage',
    'serviceName',
    'serviceCost',
    'action',
  ];

  page: number = 1;
  limit: number = 7;
  totalCount: number = 10;
  loading: boolean = false;

  showServiceList: boolean = true;
  mobileView = window.matchMedia('(max-width: 767px)');

  array = new Array(9);
  list_services: any;
  microServiceList: any = [];
  ServiceName: any = '';
  serivceId: any;
  serviceItem: any;
  add_serviceStatus = true;
  pipe = new DatePipe('en-US');
  scheduleForm: FormGroup | any;
  dates_array: any = [];
  disabledApp = false;
  color: ThemePalette = 'primary';
  constructor(
    private rest: ServiceMangeService,
    public dialog: MatDialog,
    private main: MainService
  ) {}

  ngOnInit() {
    const date1 = new Date('2023-08-09');
    const date2 = new Date('2023-08-30');
    this.scheduleForm = new FormGroup({
      duration: new FormControl('', [Validators.required]),
      capacity: new FormControl('', [Validators.required]),
      valid_from: new FormControl('', [Validators.required]),
      valid_to: new FormControl('', [Validators.required]),
    });
    // let allDates = this.getDatesBetween(date1, date2);
    this.all_services();
  }
  getDatesBetween(startDate: any, endDate: any, capacity: any, duration: any) {
    let endTime = this.pipe.transform(new Date(endDate), 'HH:mm:ss');
    let startTime = this.pipe.transform(new Date(startDate), 'HH:mm:ss');

    const currentDate = new Date(startDate.getTime());
    while (currentDate <= endDate) {
      let date = this.pipe.transform(new Date(currentDate), 'yyyy-MM-dd');
      this.dates_array.push({
        valid_from: `${date} ${startTime}`,
        valid_to: `${date} ${endTime}`,
        duration: duration,
        capacity: capacity,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.addScheduleService();
  }

  all_services() {
    this.rest.allServices().subscribe((res: any) => {
      this.showSpinnerForMainServices = false;
      this.list_services = res;
      this.list_services?.forEach((element: any) => {
        element.status = false;
        if (element.image == '') {
          element.image = 'assets/images/serivces/1.png';
        }
      });
      this.serviceDetails(res[0]);
      this.serviceItem = res[0];
      this.ServiceName = res[0].name;
      this.serivceId = res[0].id;
      this.list_services[0].status = true;
    });
  }

  serviceObject: any;

  serviceDetails(item: any) {
    this.showSpinnerForSubServices = true;
    this.serviceObject = item;
    this.list_services.forEach((element: any) => {
      if (element.id == item.id) {
        element.status = true;
      } else {
        element.status = false;
      }
    });
    this.serviceItem = item;
    this.ServiceName = item.name;
    this.serivceId = item.id;
    this.rest
      .microService(this.page, this.limit, item?.id)
      .subscribe((res: any) => {
        this.showSpinnerForSubServices = false;
        if (res?.error_reason) {
          this.microServiceList = [];
        } else {
          this.microServiceList = res?.results;
          this.totalCount = res?.total_count;
          this.microServiceList?.forEach((element: any) => {
            // if (element.image == '') {
            //   element.image = 'assets/images/avatar.png';
            // }

            if (!element.cost) {
              element.cost = 0;
            }
          });
        }
      });
  }

  changePage(e: PageEvent) {
    this.showSpinnerForSubServices = true;
    this.page = e.pageIndex + 1;
    this.all_services();
  }

  addMicroService() {
    const dialogRef = this.dialog.open(AddMicroServiceComponent, {
      width: '400px',
      data: { name: this.ServiceName },
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        res.type_id = this.serivceId;

        this.rest
          .addMicro_service(res)
          .then((response) => response.text())
          .then((result: any) => {
            let res = JSON.parse(result);
            if (res.message == 'Service Name Exists!') {
              this.main.errorToast('Micro Service Name Exists!');
            } else {
              this.main.SuccessToast('Micro service added successfully');
              this.serviceDetails(this.serviceItem);
            }
          })
          .catch();
      }
    });
  }

  addService() {
    this.add_serviceStatus = false;
    (this.serviceName = ''), (this.serviceImage = '');
    // const dialogRef = this.dialog.open(AddServiceComponent ,{
    //   width : "400px",
    // });

    // dialogRef.afterClosed().subscribe((res:any) => {
    //   if(res){
    //     this.rest.add_service(res).then(response => response.text())
    //     .then(result => console.log(result))
    //     .catch(error => {
    //       this.main.errorToast("Ops something wrong happen!")
    //     });
    //   }
    // })
  }

  back() {
    if (this.mobileView.matches) {
      this.showServiceList = true;
    }

    this.add_serviceStatus = true;
    this.schedules_status = false;
    this.edit_serviceStatus = false;
    this.scheduleEdit = false;
  }

  // add service
  serviceName = '';
  serviceImage = '';
  schedules_status = false;
  uploadServiceImage(e: any) {
    this.serviceImage = e.target.files[0];
  }

  service_Id: any;
  next() {
    let data = {
      name: this.serviceName,
      image: this.serviceImage,
    };
    this.rest
      .add_service(data)
      .then((response) => response.text())
      .then((result) => {
        this.service_Id = result;
        this.schedules_status = true;

        let data2 = {
          id: result,
          disabled: this.disabledApp,
        };
        this.rest.disableServices(data2).subscribe((res: any) => {});
      })
      .catch((error) => {
        this.main.errorToast('Ops something wrong happen!');
      });
  }

  addServices() {
    // this.back()
    let data = this.scheduleForm.value;
    data.valid_from = new Date(data.valid_from);
    data.valid_to = new Date(data.valid_to);

    this.getDatesBetween(
      data.valid_from,
      data.valid_to,
      data.capacity,
      data.duration
    );
  }

  addScheduleService() {
    let data = {
      service_id: this.service_Id,
      schedules: this.dates_array,
    };
    if (this.edit_serviceStatus == true) {
      this.rest.add_Service_Schedules(data).subscribe((res: any) => {
        this.main.SuccessToast('service Edit successfully');
        this.ngOnInit();
        this.back();
      });
    } else {
      this.rest.add_Service_Schedules(data).subscribe((res: any) => {
        this.main.SuccessToast('service added successfully');
        this.ngOnInit();
        this.back();
      });
    }
  }

  // delete service

  deleteService(value?: any) {
    if (value) {
    } else {
      value = this.serviceObject;
    }
  }

  // edit service

  edit_serviceStatus = false;
  serviceNameEdit = '';
  serviceImageEdit = '';
  serviceId = '';
  imageUploaded = '';
  serviceCost = '';
  editMicroService(item?: any) {
    if (item) {
    } else {
      item = this.serviceObject;
    }
    this.edit_serviceStatus = true;
    this.add_serviceStatus = false;
    this.serviceNameEdit = item.name;
    this.serviceImageEdit = item.image;
    this.serviceId = item.id;
    if (item.cost) {
      this.serviceCost = item.cost;
    } else {
      this.serviceCost = '';
    }
  }

  uploadServiceImageEdit(e: any) {
    this.imageUploaded = e.target.files[0];
  }
  deleteImage() {
    this.serviceImageEdit = '';
  }

  scheduleEdit = false;
  EditService() {
    this.service_Id = this.serviceId;
    let data: any;
    if (this.imageUploaded != '') {
      data = {
        name: this.serviceNameEdit,
        image: this.imageUploaded,
        service_id: this.serviceId,
      };
    } else {
      data = {
        name: this.serviceNameEdit,
        service_id: this.serviceId,
      };
    }

    if (this.serviceCost != '') {
      data.cost = this.serviceCost;
    }

    this.rest
      .edit_service(data)
      .then((response) => response.text())
      .then((result: any) => {
        let res = JSON.parse(result);
        if (res.message == 'Service Name Exists!') {
          this.main.errorToast(' Service Name Exists!');
        } else {
          //
          if (this.serviceCost != '') {
            this.main.SuccessToast('service edit successfully');
            this.all_services();
          } else {
            this.scheduleEdit = true;
            let data = {
              id: this.serviceId,
              disabled: this.disabledApp,
            };
            this.rest.disableServices(data).subscribe((res: any) => {});
          }
          //
        }
      })
      .catch();
  }

  backToServices() {
    this.showServiceList = true;
  }

  showServiceMobileView() {
    if (this.mobileView.matches) {
      this.showServiceList = false;
    }
  }
}
