<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center">
    <h3>Gate Monitor</h3>
    <i
      title="Full screen"
      class="fa-solid fa-expand"
      (click)="fullScreen()"
    ></i>
  </div>

  <div
    class="mb-4 d-flex justify-content-between align-items-center flex-wrap"
    *ngIf="!hideFilters"
  >
    <div class="me-2 selectFilter">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose Gate</mat-label>
        <mat-select multiple>
          <mat-option
            value="{{ item.name }}"
            *ngFor="let item of gatesArray"
            (click)="selectGate(item)"
          >
            <strong>{{ item.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="selectFilter">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose Gate Type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select unit type"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            *ngFor="let item of gateTypesArr"
            [value]="item"
            (click)="selectGateType(item)"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="inputButton">
      <button class="btn me-2" (click)="inputFilter()">Input filter</button>
      <button
        *ngIf="formFilterBoolean"
        class="btn resetButton"
        (click)="resetInputFilter()"
      >
        Reset
      </button>
    </div>
  </div>

  <section class="tableUser">
    <div class="row mainRow">
      <div
        class="col-md-6 p-2 dataHolder"
        ngClass="{{
          gateData?.message.includes('Valid') ? 'greenMainRow' : 'redMainRow'
        }}"
        [@fadeInOut]
        *ngFor="let gateData of copyDataComesFromSocket"
      >
        <div class="rowData">
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col-md-2 d-flex flex-wrap imagesDivHolder">
              <ngx-avatar
                *ngIf="!gateData?.image"
                class="mb-2"
                size="70"
                name="{{ gateData?.name }}"
              ></ngx-avatar>

              <img
                *ngIf="gateData?.image"
                [src]="baseUrlForImages + '/images/logs' + gateData?.image"
                class="imageClick userImage"
                alt=""
                (click)="
                  openPhoto(
                    photoDialog,
                    baseUrlForImages + '/images/logs' + gateData?.image
                  )
                "
              />

              <img
                *ngIf="gateData?.globalscene"
                [src]="
                  baseUrlForImages + '/images/logs' + gateData?.globalscene
                "
                class="imageClick"
                alt=""
                (click)="
                  openPhoto(
                    photoDialog,
                    baseUrlForImages + '/images/logs' + gateData?.globalscene
                  )
                "
              />

              <img
                *ngIf="gateData?.smallscene"
                [src]="baseUrlForImages + '/images/logs' + gateData?.smallscene"
                class="imageClick"
                alt=""
                (click)="
                  openPhoto(
                    photoDialog,
                    baseUrlForImages + '/images/logs' + gateData?.smallscene
                  )
                "
              />
            </div>

            <div class="col-md-8 userDetails">
              <ul>
                <li><strong>Name:</strong> {{ gateData?.name | titlecase }}</li>
                <li>
                  <strong>Controller ID:</strong> {{ gateData?.controller_id }}
                </li>
                <li><strong>Message:</strong> {{ gateData?.message }}</li>
                <li>
                  <strong>Date and time:</strong>
                  [ {{ gateData?.timestamp | date }} ] [
                  {{ gateData?.timestamp | date : "shortTime" : "en-US" }} ]
                </li>
                <li>
                  <strong>Access type:</strong> {{ gateData?.access_type }}
                </li>
                <li *ngIf="gateData?.client_type">
                  <strong>Client type:</strong>
                  {{ gateData?.client_type | titlecase }}
                </li>
                <li><strong>Unit name:</strong> {{ gateData?.unit_name }}</li>

                <li *ngIf="gateData?.plate_number">
                  <strong>Plate number:</strong> {{ gateData?.plate_number }}
                </li>
                <li *ngIf="gateData?.registered_to">
                  <strong>Registered to:</strong> {{ gateData?.registered_to }}
                </li>
              </ul>
            </div>

            <div class="col-md-2 d-flex justify-content-end align-items-start">
              <div class="directionHolder">
                <img
                  *ngIf="gateData.direction == 'IN'"
                  src="../../../../assets/images/goGreen.png"
                />
                <img
                  *ngIf="gateData.direction == 'OUT'"
                  src="../../../../assets/images/goRed.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <strong
      *ngIf="!copyDataComesFromSocket.length"
      class="d-flex justify-content-center mt-5"
      >Select <span class="me-2 ms-2" style="color: red">Gate</span> or
      <span class="me-2 ms-2" style="color: red">Gate Type</span> to see the
      data, and wait for the car coming.</strong
    >
  </section>
</section>

<ng-template #photoDialog let-data let-ref="dialogRef">
  <section class="photoHolder">
    <img [src]="photo" class="imageDivInHolder" />
  </section>
</ng-template>
