<section class="main">
  <h2 class="m-0">Unpaid Invoices</h2>
  <small>You should pay the invoice to proceed the printing</small>
  <hr />

  <div>
    <section class="tableUnit table-responsive mt-3">
      <ul>
        <li>
          <strong>Purchase Name :</strong>
          {{ upPaidInvoice.purchase_name | titlecase }}
        </li>
        <li><strong>Total amount :</strong> {{ upPaidInvoice.ammount }} LE</li>
        <li *ngIf="upPaidInvoice.payment_user">
          <strong>Paid by :</strong> {{ upPaidInvoice.payment_user }}
        </li>
        <li>
          <strong>Issued by :</strong> {{ upPaidInvoice.user.name | titlecase }}
        </li>
        <li><strong>Created at :</strong> {{ upPaidInvoice.created_at }}</li>
        <li><strong>Updated at :</strong> {{ upPaidInvoice.updated_on }}</li>
      </ul>

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Amount</th>
            <th scope="col">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of upPaidInvoice.items; let i = index">
            <td>
              {{ item?.name | titlecase }}
            </td>
            <td>
              {{ item?.amount }}
            </td>
            <td>{{ item?.price }} LE</td>
          </tr>
        </tbody>
      </table>

      <button
        [disabled]="!checkForPayInvoicePermission()"
        *ngIf="!upPaidInvoice?.paid"
        class="btn d-flex m-auto"
        (click)="payInvoice(upPaidInvoice.id)"
      >
        Pay Invoice
      </button>
    </section>
  </div>
</section>
