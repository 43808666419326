import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { UnitsService } from 'src/app/services/units.service';
import { QrInvtationComponent } from '../qr-invtation/qr-invtation.component';
import { MainService } from 'src/app/services/main.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.css'],
})
export class QrCodesComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';

  showSpinner: boolean = true;
  @ViewChild('dateInput') dateInput: any;

  unitId: any;
  unitName: any;
  visitDate: any = new Date();
  formattedDate: any;
  pipe = new DatePipe('en-US');
  qrCodesArray: any;

  constructor(
    private activeRouter: ActivatedRoute,
    public dialog: MatDialog,
    private unitService: UnitsService,
    private mainService: MainService,
    private location: Location
  ) {
    this.unitId = this.activeRouter.snapshot.paramMap.get('id');
    this.unitName = this.activeRouter.snapshot.paramMap.get('name');
    this.formatDate();
  }

  ngOnInit() {
    this.showSpinner = true;
    this.getUnitInvitations();
  }

  getUnitInvitations() {
    this.unitService
      .getUnitInvitations(this.unitId, this.formattedDate)
      .subscribe((data: any) => {
        this.qrCodesArray = data;
        this.value = data?.qr_code;
        this.showSpinner = false;
      });
  }

  openQRCodeCard(qrCardData: any) {
    const dialogRef = this.dialog.open(QrInvtationComponent, {
      width: '320px',
      data: qrCardData,
    });
  }

  formatDate() {
    const dateObj = new Date(this.visitDate);
    this.formattedDate = this.pipe.transform(dateObj, 'yyyy-M-d');
  }

  getValue(event: MatDatepickerInputEvent<Date>) {
    this.showSpinner = true;
    this.visitDate = event.value;
    this.formatDate();
    this.getUnitInvitations();
  }

  qrCode: string = '';
  openCancelQR(qrCode: any) {
    this.qrCode = qrCode;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  cancelQR() {
    this.unitService.cancelQRInvitation(this.qrCode).subscribe((data: any) => {
      this.mainService.SuccessToast('Qr Code cancelled');
      this.showSpinner = true;
      this.getUnitInvitations();
    });
  }

  goBack(): void {
    this.location.back();
  }
}
