import { Component, OnInit } from '@angular/core';
import { GAccessService } from 'src/app/services/g-accsess.service';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MainComponent } from '../main/main.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class AddMemberComponent implements OnInit {
  chooseType: any = 'choose Type';
  chooseCategory: any = 'choose Category';
  dateFrom: any = '';
  dateTo: any = '';
  name: any = '';
  categoryId: any = '';
  cat_arr: any;
  pipe = new DatePipe('en-US');
  submitted = false;
  constructor(
    private rest: GAccessService,
    public dialogRef: MatDialogRef<MainComponent>
  ) {}
  ngOnInit() {
    // this.getType()
  }

  getType(item: any) {
    this.rest.getStaffTypes(item).subscribe((res: any) => {
      this.cat_arr = res.children;
      this.chooseType = item;
    });
  }
  getCat(item: any) {
    this.chooseCategory = item.name;
    this.categoryId = item.id;
  }
  addMember(form: any) {
    this.submitted = true;
    if (form.valid && this.categoryId != '') {
      let data = {
        name: this.name,
        valid_from: this.pipe.transform(this.dateFrom, 'yyyy-MM-dd'),
        valid_to: this.pipe.transform(this.dateTo, 'yyyy-MM-dd'),
        unit_relation_id: this.categoryId,
      };

      this.dialogRef.close(data);
    }
  }
}
