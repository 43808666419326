<section class="main">
    <h1>
        Import Client
    </h1>
    <hr>
    <div>
        <label>Unit name</label>
        <input type="search" (input)="searchUnit()" [(ngModel)]="unitName" class="form-control">
    </div>
    <div class="searchResult" *ngIf="units_arr.length != 0">
        <ul>
            <li (click)="getUnitItem(item)" *ngFor="let item of units_arr">
                {{item.name}}
            </li>
        </ul>
    </div>

    <section class="mt-2" *ngIf="unit_clients.length != 0">
        <mat-form-field appearance="outline">
            <mat-label>All Clients</mat-label>
            <mat-select multiple [(value)]="selectedClients">
                <mat-option (onSelectionChange)="ClientsChange($event)" *ngFor="let item of unit_clients" [value]="item">
                    {{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <div *ngIf="unit_clients.length != 0">
        <button class="btn" (click)="import()">import</button>
    </div>
</section>
