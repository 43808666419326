import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from '../pages/pages.component';
import { SideBarComponent } from '../main-component/side-bar/side-bar.component';
import { HeaderComponent } from '../main-component/header/header.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { UnitsModule } from './units/units.module';
import { MainDashboardModule } from './main-dashboard/main-dashboard.module';
import { VillagesModule } from './villages/villages.module';
import { GenralAccessModule } from './genral-access/genral-access.module';
import { OperatorsModule } from './operators/operators.module';
import { RolesModule } from './roles/roles.module';
import { GatesModule } from './gates/gates.module';
import { ClientsModule } from './clients/clients.module';
import { MangeServiceModule } from './mange-service/mange-service.module';
import { AllLogsModule } from './all-logs/all-logs.module';
import { ServiceRequestModule } from './service-request/service-request.module';
import { UtiltyModule } from './utilty/utilty.module';
import { FamilyModule } from './family/family.module';
import { AvatarModule } from 'ngx-avatar';
import { ConfigurationsModule } from './configuration/configurations.module';
import { PromoCodesModule } from './promo-codes/promo-codes.module';

@NgModule({
  declarations: [PagesComponent, SideBarComponent, HeaderComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatMenuModule,
    UnitsModule,
    VillagesModule,
    MainDashboardModule,
    GenralAccessModule,
    OperatorsModule,
    RolesModule,
    GatesModule,
    ConfigurationsModule,
    ClientsModule,
    PromoCodesModule,
    MangeServiceModule,
    AllLogsModule,
    ServiceRequestModule,
    UtiltyModule,
    FamilyModule,
    AvatarModule,
  ],
})
export class PagesModule {}
