import { Component, OnInit, TemplateRef } from '@angular/core';
import { GAccessService } from 'src/app/services/g-accsess.service';
import { UnitsService } from 'src/app/services/units.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GatesService } from 'src/app/services/gates.service';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../../environments/environments';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-access-list',
  templateUrl: './access-list.component.html',
  styleUrls: ['./access-list.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ transform: 'translateY(0)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateY(100%)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(100%)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class AccessListComponent implements OnInit {
  photo: string = '';

  showSpinner: boolean = true;
  filter_form: FormGroup | any;
  current_page = 1;
  pagesLength = 1;
  pageNumber = 0;
  pageLimit = 100;

  all_gates: any;
  all_types: any;
  pipe = new DatePipe('en-US');
  access_logs: any = [];
  filterDataForm: any;

  constructor(
    private rest: GAccessService,
    private unitService: UnitsService,
    private formBuilder: FormBuilder,
    private gateService: GatesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initFormFilet();
    this.retrieveAccessLog();
    this.retrieve_gates();
    this.retrieve_types();
  }

  initFormFilet() {
    this.filter_form = this.formBuilder.group({
      name: [''],
      access_type: [''],
      message: [''],
      controller_id: [''],
      direction: [''],
      plate_number: [''],
      unit_name: [''],
      registered_to: [''],
      valid_to: [''],
      valid_from: [''],
      charOne: [''],
      charTow: [''],
      charThree: [''],
      number: [''],
    });
  }
  retrieveAccessLog(value?: any) {
    let data: any;
    if (value) {
      delete value.number;
      delete value.charThree;
      delete value.charTow;
      delete value.charOne;
      Object.keys(value).forEach((key) => {
        if (value[key] === '' || value[key] === null) {
          delete value[key];
        }
      });
      value.page = this.current_page;
      value.page_limit = this.pageLimit;
      data = value;
    } else {
      data = {
        page: this.current_page,
        page_limit: this.pageLimit,
      };
    }

    this.rest.retrieveLogs(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.access_logs = res.access_logs;
      this.access_logs.forEach((element: any) => {
        if (element.globalscene) {
          element.globalscene = `${environment.baseUrl}/images/logs${element.globalscene}`;
        }
        if (element.smallscene) {
          element.smallscene = `${environment.baseUrl}/images/logs${element.smallscene}`;
        }
        if (element.image) {
          element.image = `${environment.baseUrl}/images/logs${element.image}`;
        }
      });
      this.pagesLength = res.total_number;
    });
  }

  statusBtn = true;
  change_layout(value: any) {
    this.statusBtn = !this.statusBtn;
  }

  retrieve_gates() {
    this.gateService.retrieveGates().subscribe((res: any) => {
      this.all_gates = res;
    });
  }

  retrieve_types() {
    this.unitService.cardType().subscribe((res: any) => {
      this.all_types = res.children;
    });
  }

  // filterData() {
  //   let data = this.filter_form.value;
  //   data.number = Intl.NumberFormat('ar-SA', {}).format(data.number);
  //   data.number = data.number.replace('٬', '');
  //   if (data.charThree) {
  //     data.plate_number =
  //       data.charThree + ' ' + data.charTow + ' ' + data.charOne + data.number;
  //   }
  //   data.valid_from = this.pipe.transform(data.valid_from, 'yyyy-MM-dd');
  //   data.valid_to = this.pipe.transform(data.valid_to, 'yyyy-MM-dd');
  //   this.retrieveAccessLog(data);
  // }

  filterData() {
    this.showSpinner = true;
    // let data = this.filter_form.value;
    this.current_page = 1;
    this.pagesLength = 1;
    this.pageNumber = 0;
    this.pageLimit = 100;

    this.filterDataForm = this.filter_form.value;
    this.filterDataForm.number = Intl.NumberFormat('ar-SA', {}).format(
      this.filterDataForm.number
    );
    this.filterDataForm.number = this.filterDataForm.number.replace('٠', '');
    // if (this.filterDataForm.charThree) {
    //   this.filterDataForm.plate_number =
    //     this.filterDataForm.charThree +
    //     ' ' +
    //     this.filterDataForm.charTow +
    //     ' ' +
    //     this.filterDataForm.charOne +
    //     this.filterDataForm.number;
    // }

    this.filterDataForm.plate_number =
      this.filterDataForm.charThree +
      (this.filterDataForm.charTow ? ' ' + this.filterDataForm.charTow : '') +
      (this.filterDataForm.charOne ? ' ' + this.filterDataForm.charOne : '') +
      this.filterDataForm.number;

    this.filterDataForm.valid_from = this.pipe.transform(
      this.filterDataForm.valid_from,
      'yyyy-MM-dd'
    );
    this.filterDataForm.valid_to = this.pipe.transform(
      this.filterDataForm.valid_to,
      'yyyy-MM-dd'
    );
    this.retrieveAccessLog(this.filterDataForm);
  }

  exportData() {
    let data = this.filter_form.value;
    data.number = Intl.NumberFormat('ar-SA', {}).format(data.number);
    data.number = data.number.replace('٬', '');
    if (data.charThree) {
      data.plate_number =
        data.charThree + ' ' + data.charTow + ' ' + data.charOne + data.number;
    }
    data.valid_from = this.pipe.transform(data.valid_from, 'yyyy-MM-dd');
    data.valid_to = this.pipe.transform(data.valid_to, 'yyyy-MM-dd');

    delete data.number;
    delete data.charThree;
    delete data.charTow;
    delete data.charOne;
    Object.keys(data).forEach((key) => {
      if (data[key] === '' || data[key] === null) {
        delete data[key];
      }
    });
    data.page = this.current_page;
    data.page_limit = this.pagesLength;

    this.rest
      .exportLogos(data)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export_file.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  resetForm() {
    this.filter_form.reset();
    this.retrieveAccessLog();
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.pageNumber = e.pageIndex;
    this.current_page = e.pageIndex + 1;
    this.pageLimit = e.pageSize;
    this.retrieveAccessLog(this.filterDataForm);
  }

  filter_status: any = true;

  toggleFilter() {
    this.filter_status = !this.filter_status;
  }

  openPhoto(dialog: TemplateRef<any>, photo: any) {
    this.photo = photo;
    this.dialog.open(dialog);
  }
}
