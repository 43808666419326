import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MainService } from '../services/main.service';

@Injectable({
  providedIn: 'root',
})
// export class AuthGuard implements CanActivate {
//   constructor(private rest : AuthService , private router: Router){}
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//       if (!this.rest.getLoginStatus()) {
//         this.router.navigate(['login']);
//       }
//       return true
//   }

// }
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private service: MainService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.getLoginStatus()) {
      this.router.navigate(['login']);
      return false;
    }

    // Check if token is expired
    const token: any = localStorage.getItem('acc_token');
    if (this.authService.isTokenExpired(token)) {
      localStorage.clear();
      this.router.navigate(['login']);
      this.service.infoToast('Access token is expired');
      return false;
    }

    return true;
  }
}
