import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GAccessService } from 'src/app/services/g-accsess.service';
import { AddUserAccessConfigurationComponent } from '../add-user-access-configuration/add-user-access-configuration.component';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { EditUserAccessConfigurationComponent } from '../edit-user-access-configuration/edit-user-access-configuration.component';
import { MainService } from 'src/app/services/main.service';
import { PageEvent } from '@angular/material/paginator';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-user-access-configuration',
  templateUrl: './user-access-configuration.component.html',
  styleUrls: ['./user-access-configuration.component.css'],
})
export class UserAccessConfigurationComponent implements OnInit {
  page = 1;
  limit = 10;
  totalCount = 0;
  userTypes: any;
  userTypeId: any;
  accessTypes: any;
  accessTypeId: any;
  configurationId: any;
  showSpinner: boolean = true;
  allUserAccessConfiguration: any;

  constructor(
    private readonly configuration: ConfigurationsService,
    private readonly GAccessService: GAccessService,
    private unitService: UnitsService,
    private mainService: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getAllUserAccessConfigurations();
    this.getUserTypes();
    this.getAccessTypes();
  }

  getAllUserAccessConfigurations() {
    this.configuration
      .getAllUsersAccessConfiguration(
        this.page,
        this.limit,
        this.userTypeId,
        this.accessTypeId
      )
      .subscribe((data: any) => {
        this.showSpinner = false;
        this.allUserAccessConfiguration = data?.results;
        this.totalCount = data?.total_count;
      });
  }

  getUserTypes() {
    this.GAccessService.getTypes().subscribe((res: any) => {
      this.userTypes = res?.children;
    });
  }

  getAccessTypes() {
    this.unitService.cardType().subscribe((res: any) => {
      this.accessTypes = res?.children;
    });
  }

  selectUserType(userTypeId: any) {
    this.showSpinner = true;
    this.userTypeId = userTypeId;
    this.getAllUserAccessConfigurations();
  }

  selectAllUsersType() {
    this.userTypeId = null;
    this.showSpinner = true;
    this.getAllUserAccessConfigurations();
  }

  selectAccessType(accessTypeId: any) {
    this.showSpinner = true;
    this.accessTypeId = accessTypeId;
    this.getAllUserAccessConfigurations();
  }

  selectAllAccessType() {
    this.accessTypeId = null;
    this.showSpinner = true;
    this.getAllUserAccessConfigurations();
  }

  addNewUserAccessConfiguration() {
    const dialogRef = this.dialog.open(AddUserAccessConfigurationComponent, {
      width: '90%',
      maxHeight: '800px',
      maxWidth: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.configuration.addUserAccessConfiguration(res).subscribe({
          next: (response: any) => {
            if (response.status) {
              this.showSpinner = true;
              this.getAllUserAccessConfigurations();
              this.mainService.SuccessToast(
                'User Access Config Added Successfully'
              );
            } else {
              this.mainService.errorToast(response?.message);
            }
          },
          error: (err) => {
            this.mainService.errorToast('Something went wrong');
          },
        });
      }
    });
  }

  editUserAccessConfiguration(item: any) {
    const dialogRef = this.dialog.open(EditUserAccessConfigurationComponent, {
      width: '90%',
      maxHeight: '800px',
      maxWidth: '450px',
      data: {
        userAccessConfiguration: item,
      },
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.configuration.editUserAccessConfiguration(res).subscribe({
          next: (response: any) => {
            if (response.status) {
              this.showSpinner = true;
              this.getAllUserAccessConfigurations();
              this.mainService.SuccessToast(
                'User Access Config Updated Successfully'
              );
            } else {
              this.mainService.errorToast(response?.message);
            }
          },
          error: (err) => {
            this.mainService.errorToast('Something went wrong');
          },
        });
      }
    });
  }

  openDeleteConfiguration(item: any) {
    this.configurationId = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  deleteUserAccessConfiguration() {
    this.configuration
      .deleteUserAccessConfiguration(this.configurationId)
      .subscribe((res: any) => {
        if (res.status) {
          this.mainService.SuccessToast(res.message);
          this.showSpinner = true;
          this.getAllUserAccessConfigurations();
        } else {
          this.mainService.errorToast(res.message);
        }
      });
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.page = e?.pageIndex + 1;
    this.limit = e?.pageSize;
    this.getAllUserAccessConfigurations();
  }
}
