<section class="main">
  <h1>Delete RFID</h1>
  <hr />

  <div>
    <p>Are you sure you want to Delete {{ VehicleNumber }}</p>
  </div>

  <div class="mt-3">
    <button (click)="delete()" class="btn remove">Remove</button>
    <button mat-dialog-close class="btn">Cancel</button>
  </div>
</section>
