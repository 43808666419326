import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GatesService } from 'src/app/services/gates.service';
import { MainService } from 'src/app/services/main.service';
import { GateMonitorComponent } from '../gate-monitor/gate-monitor.component';

@Component({
  selector: 'app-gate-monitor-input-filter',
  templateUrl: './gate-monitor-input-filter.component.html',
  styleUrls: ['./gate-monitor-input-filter.component.css'],
})
export class GateMonitorInputFilterComponent implements OnInit {
  inputFilterForm: FormGroup | any;
  gatesArray: any;
  gateTypesArr: any;
  gateDirection: any = ['In', 'Out'];

  constructor(
    public dialogRef: MatDialogRef<GateMonitorComponent>,
    private gatesService: GatesService,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.getInputFilterForm();
    this.retrieveGates();
    this.gatesType();
  }

  getInputFilterForm() {
    this.inputFilterForm = new FormGroup({
      unit_name: new FormControl(null),
      name: new FormControl(null),
      message: new FormControl(null),
      registered_to: new FormControl(null),
      vehiclePlateNumbers: new FormControl(null),
      vehiclePlateLetter1: new FormControl(null, [Validators.maxLength(1)]),
      vehiclePlateLetter2: new FormControl(null, [Validators.maxLength(1)]),
      vehiclePlateLetter3: new FormControl(null, [Validators.maxLength(1)]),
      gate: new FormControl(null),
      gateType: new FormControl(null),
      direction: new FormControl(null),
      from: new FormControl(null),
      to: new FormControl(null),
    });
  }

  retrieveGates() {
    this.gatesService.retrieveGates().subscribe((res: any) => {
      this.gatesArray = res;
    });
  }

  gatesType() {
    this.mainService.gateByType().subscribe((res: any) => {
      this.gateTypesArr = res?.children;
    });
  }

  applyInputFilter() {
    this.dialogRef.close(this.inputFilterForm.value);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.inputFilterForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }

  isAnyFormControlDirty(): boolean {
    return Object.keys(this.inputFilterForm.controls).some(
      (controlName) => this.inputFilterForm.get(controlName).dirty
    );
  }
}
