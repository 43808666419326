import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private http: HttpClient) {}

  retrieveOperators() {
    return this.http.get(`${environment.baseUrl}/role/all`);
  }

  retrievePermission() {
    return this.http.get(`${environment.baseUrl}/permission/`);
  }

  addRole(data: any) {
    return this.http.post(`${environment.baseUrl}/role/`, data);
  }
  addPermissionForRole(data: any) {
    return this.http.put(`${environment.baseUrl}/role/addPermission`, data);
  }

  retrieveRoles() {
    return this.http.get(`${environment.baseUrl}/role/all`);
  }

  roleDetails(id: any) {
    return this.http.get(`${environment.baseUrl}/role/?role_id=${id}`);
  }

  editRole(data: any) {
    return this.http.put(`${environment.baseUrl}/role/`, data);
  }

  deleteRole(data: any) {
    return this.http.delete(`${environment.baseUrl}/role/urv`, data);
  }

  changePw(data: any) {
    return this.http.post(`${environment.baseUrl}/auth/forgot`, data);
  }
}
