import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUtilitiesComponent } from '../all-utiltes/all-utiltes.component';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.css'],
})
export class AddSectionComponent implements OnInit {
  addForm: FormGroup | any;
  status_edit = false;

  constructor(
    public dialogRef: MatDialogRef<AddUtilitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.data) {
      this.status_edit = true;
      this.addForm.patchValue(this.data);
    } else {
      this.status_edit = false;
    }
  }

  initForm() {
    this.addForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
    });
  }

  addSection() {
    this.dialogRef.close(this.addForm.value);
  }

  editSection() {
    let data = this.addForm.value;
    data.section_id = this.data.id;
    this.dialogRef.close(data);
  }
}
