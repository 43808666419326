import { Component, OnInit, Inject } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { UnitDetailsComponent } from '../unit-details/unit-details.component';
import { MainService } from 'src/app/services/main.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { UnitUnpaidInvoicesComponent } from '../unit-unpaid-invoices/unit-unpaid-invoices.component';
import { RegisterAccessComponent } from '../register-access/register-access.component';
import { UnpaidInvoicesComponent } from '../unpaid-invoices/unpaid-invoices.component';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css'],
})
export class AddCardComponent implements OnInit {
  showSpinnerForRFID: boolean = false;
  showInvoicePaymentHint: boolean = false;
  invoicePaymentHint: string = 'Invoice must be paid.';
  cardType_array: any = [];
  cardId: any = '';
  cardType: any;
  cards_arr: any = [];
  RfidForm: FormGroup | any;
  access_Type: any;
  disableCardId: boolean = true;
  unitId: any;
  unitUsers: any;
  clientId: any;
  pipe = new DatePipe('en-US');

  allOtherInvoices: any;
  unPaidOtherInvoices: any[] | any = [];
  todayDate: any;

  invoicesIdsToBeDelivered: any[] = [];
  constructor(
    private rest: UnitsService,
    private mainService: MainService,
    private formBuilder: FormBuilder,
    private clientService: ClientsService,
    public dialogRef: MatDialogRef<UnitDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.cardsList(this.data?.id);
    this.getCardType();
    this.initRfidForm();
    this.checkTodayDate();
    this.unitId = this.data?.unitId;
    this.clientId = this.data?.id;
    this.unitUsers = this.data?.unitUsers;
  }

  checkTodayDate() {
    const currentDate = new Date();
    this.todayDate = this.pipe.transform(currentDate, 'yyyy-MM-dd');
  }

  initRfidForm() {
    this.RfidForm = this.formBuilder.group({
      front: ['', Validators.required],
      front_source: [''],
      back_source: [''],
      back: ['', Validators.required],
      charOne: ['', Validators.required],
      charTow: ['', Validators.required],
      charThree: ['', Validators.required],
      number: ['', Validators.required],
      serial_number: ['', [Validators.required]],
      card_id: ['', [Validators.required]],
    });
  }

  getCardType() {
    this.rest.cardType().subscribe((res: any) => {
      res.children.forEach((element: any) => {
        if (element.name == 'RFID') {
          this.access_Type = element.id;
        }
        if (
          element.name?.includes('Mifare') ||
          element.name?.includes('mifare')
        ) {
          this.cardType_array.push(element);
        } else if (
          element.name?.includes('Proximity') ||
          element.name?.includes('proximity')
        ) {
          this.cardType_array.push(element);
          this.cardType = element.id;
        }
      });
      // this.cardType_array = res.children[0].children
      // this.cardType_array.push(res.children[res.children.length - 1])
      // this.cardType = res.children[res.children.length - 1].id
    });
  }

  getAllInvoices() {
    return this.rest.unit_invoice(this.unitId).pipe(
      tap((res: any) => {
        this.allOtherInvoices = res?.others;
        const hasDetailsInvoices = this.allOtherInvoices.some(
          (invoice: any) => 'details' in invoice
        );
        if (hasDetailsInvoices) {
          this.unPaidOtherInvoices = this.allOtherInvoices.filter(
            (item: any) => {
              const details = item?.details || '';
              const numbers = details.match(/\d+/g)?.map(Number) || [];

              if (
                details.includes('RFID') &&
                numbers.includes(this.clientId) &&
                item?.updated_on?.includes(this.todayDate)
              ) {
                return true;
              }

              // this.unPaidOtherInvoices = null;
              return false;
            }
          );
          // this.unPaidOtherInvoices =
          //   filteredInvoices.length > 0 ? filteredInvoices : [];
        } else {
          // this.unPaidOtherInvoices = null;
          this.openRegisterAccessDialog();
        }
      })
    );
  }

  radioChange(e: any) {
    this.cardType = e.target.value.trim();
    if (this.cardType === 'RFID') {
      this.showSpinnerForRFID = true;
      this.showInvoicePaymentHint = false;

      this.getAllInvoices().subscribe({
        next: (response: any) => {
          if (this.unPaidOtherInvoices.length > 0) {
            const unPaidOtherInvoices = this.unPaidOtherInvoices.filter(
              (invoice: any) => !invoice.paid
            );

            const paidOtherInvoices = this.unPaidOtherInvoices.filter(
              (invoice: any) => invoice.paid
            );

            if (
              this.unPaidOtherInvoices.some((invoice: any) => !invoice?.paid)
            ) {
              this.showSpinnerForRFID = true;

              const dialogRef = this.dialog.open(UnitUnpaidInvoicesComponent, {
                width: '700px',
                maxHeight: '700px',
                data: {
                  unPaidOtherInvoices: unPaidOtherInvoices,
                  unitId: this.unitId,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    this.showSpinnerForRFID = false;
                  } else {
                    this.invoicePaymentHint = 'Invoice must be paid.';
                    this.showInvoicePaymentHint = true;
                  }
                });
            } else if (
              paidOtherInvoices.some(
                (invoice: any) => invoice?.paid && !invoice?.delivered
              )
            ) {

              this.invoicesIdsToBeDelivered = paidOtherInvoices.map(
                (invoice: any) => invoice.id
              );

              this.showSpinnerForRFID = false;
            } else if (
              paidOtherInvoices.some(
                (invoice: any) => invoice?.paid && invoice?.delivered
              )
            ) {
              this.openRegisterAccessDialog();
            }
          }
          if (this.unPaidOtherInvoices.length == 0) {
            this.openRegisterAccessDialog();
          }
        },
        error: (err) => {
          if (err?.status == 406) {
            this.openRegisterAccessDialog();
          }
        },
      });
    } else {
      this.showInvoicePaymentHint = false;
    }
    this.disableCardId = false;
  }

  invoiceDelivery(invoice: any) {
    this.clientService.deliverInvoice(invoice).subscribe((res: any) => {
      if (res.status) {
        this.mainService.SuccessToast('Invoice delivered');
      } else {
        this.mainService.errorToast(res?.message);
      }
    });
  }

  openRegisterAccessDialog() {
    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitId: this.unitId,
        RFID_data: {
          RFIDFromAddCard: true,
          accessTypeId: this.access_Type,
          clientId: this.clientId,
        },
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.rest.registerAccessService(result).subscribe((res: any) => {
          if (res?.status == false) {
            if (res?.message == 'Maximum RFID Cards Reached') {
              this.mainService.errorToast(res.message);
              this.dialog.closeAll();
            } else {
              this.showSpinnerForRFID = false;
            }
          } else {
            if (res.paid) {
              this.showSpinnerForRFID = false;
            } else {
              const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                width: '650px',
                data: {
                  result: res,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    this.showSpinnerForRFID = false;
                  } else {
                    this.invoicePaymentHint =
                      'You should pay the invoice to complete creating RFID';

                    this.showInvoicePaymentHint = true;
                  }
                });
            }
          }
        });
      } else {
        this.invoicePaymentHint = 'You should register first';
        this.showInvoicePaymentHint = true;
      }
    });
  }

  addCard() {
    if (this.cardType == 'RFID') {
      this.addRFId();
    } else {
      this.cardType = +this.cardType;
      let data = {
        type_id: this.cardType,
        card_id: this.cardId,
      };
      this.dialogRef.close(data);
    }
  }

  cardsList(id: any) {
    this.rest.cardsList(id).subscribe((res: any) => {
      this.cards_arr = res;
    });
  }

  deleteCard(value: any) {
    this.rest.deleteCard(value.id).subscribe((res: any) => {
      this.mainService.SuccessToast('Card Deleted');
      this.cardsList(this.data.id);
    });
  }

  onFileChange(e: any, name: any) {
    const file = e.target.files[0];
    if (name == 'front') {
      this.RfidForm.patchValue({
        front_source: file,
      });
    } else {
      this.RfidForm.patchValue({
        back_source: file,
      });
    }
  }

  addRFId() {
    let RfidData = this.RfidForm.value;
    RfidData.number = Intl.NumberFormat('ar-SA', {}).format(RfidData.number);
    RfidData.number = RfidData.number.replace('٬', '');
    let data = {
      front: RfidData.front_source,
      back: RfidData.back_source,
      type_id: this.access_Type,
      card_id: RfidData.card_id,
      license_plate:
        RfidData.charThree +
        ' ' +
        RfidData.charTow +
        ' ' +
        RfidData.charOne +
        RfidData.number,
      serial_number: RfidData.serial_number,
    };
    this.dialogRef.close({
      data: data,
      invoicesIdsToBeDelivered: this.invoicesIdsToBeDelivered,
    });
  }
}
