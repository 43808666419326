<section class="main">
  <div class="">
    <h3>Manage clients</h3>
    <div class="col-md-12 mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Search by client</mat-label>
        <input matInput type="text" (input)="clientSearch($event)" />
      </mat-form-field>
    </div>

    <div class="filter d-flex justify-content-between align-items-center">
      <!-- <div
        class="inputSearch d-flex justify-content-between align-items-center"
      >
        <input
          type="search"
          (input)="clientSearch($event)"
          class="form-control"
          placeholder="Search clients"
        />
        <a (click)="clientLogs()">Clients log</a>
      </div> -->
      <!-- <div class="dropDowen">
        <div class="dropdown mb-0 ">
          <button
            class="btn  dropdown-toggle text-truncate"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            All Clients
            <img src="assets/images/mangeunit/arrow.png" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" style="cursor: pointer">one</a>
            </li>
          </ul>
        </div>
      </div> -->
    </div>

    <app-print-confirmation-modal
      #printModal
      (okClick)="onModalOk()"
      (cancelClick)="onModalCancel()"
      (backgroundClick)="onModalBackground()"
    ></app-print-confirmation-modal>

    <div id="cardPrint">
      <div class="cards print" *ngFor="let item of dataPrint">
        <div class="front">
          <div class="background-image">
            <img [src]="item.frontImage" />
          </div>

          <ul class="clientData">
            <li>
              <p style="margin: 0; padding: 0" class="clientName">
                {{ item.name | uppercase }}
              </p>
            </li>
            <li>
              <p style="margin: 0; padding: 0" *ngIf="item.unitName != ''">
                <strong>Unit:</strong> {{ item.unitName }}
              </p>
            </li>
            <li>
              <p style="margin: 0; padding: 0" *ngIf="item.villageName != ''">
                <strong>Zone:</strong> {{ item.villageName }}
              </p>
            </li>
            <li *ngIf="item.userType == 'Tenant' || item.userType == 'tenant'">
              <p style="margin: 0; padding: 0">
                <strong
                  >[ {{ item?.validFrom }} ] : [ {{ item?.validTo }} ]</strong
                >
              </p>
            </li>
          </ul>

          <div class="centered-image">
            <img class="clientImage" [src]="item.image" alt="Avatar" />
            <!-- <div class="containerr">
              <p class="clientName">{{ item.name | uppercase }}</p>
              <p *ngIf="item.unitName != ''">
                <strong>Unit:</strong> {{ item.unitName }}
              </p>
              <p *ngIf="item.villageName != ''">
                <strong>Zone:</strong> {{ item.villageName }}
              </p>
            </div> -->
          </div>
        </div>
        <div class="back">
          <div>
            <img [src]="item.image1" />
          </div>
        </div>
      </div>
    </div>

    <mat-spinner diameter="50" *ngIf="showSpinner" class="mb-4"></mat-spinner>

    <div class="row">
      <div class="table">
        <table
          mat-table
          [dataSource]="all_clients"
          matSort
          matSortDirection="desc"
          matSortDisableClear
          *ngIf="!showSpinner"
        >
          <ng-container matColumnDef="userImage">
            <th mat-header-cell *matHeaderCellDef>
              <strong>Image</strong>
            </th>

            <td mat-cell *matCellDef="let row" class="imageColumn">
              <img
                *ngIf="row?.photo"
                [src]="row?.photo?.changingThisBreaksApplicationSecurity"
                class="serviceImage"
              />

              <div class="ngxAvatar" *ngIf="!row?.photo">
                <ngx-avatar
                  size="50"
                  name="{{ row?.name }}"
                  initialsSize="2"
                  cornerRadius="11"
                ></ngx-avatar>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              <strong>Full name</strong>
            </th>
            <td mat-cell *matCellDef="let row">
              <small class="userName" (click)="viewClient(row)">
                {{ row?.name | titlecase }}
              </small>
              <img
                *ngIf="row.verified == true"
                src="assets/images/mangeunit/circle_check.png"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="userUnitDetails">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              <strong>Unit details</strong>
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="userUnitDetails">
                <span class="hint">{{ row?.type?.name }}</span>

                <span *ngIf="row?.verified == true" class="hint">Verified</span>
                <span *ngIf="row?.activated == true" class="hint"
                  >Activated</span
                >
                <span
                  (click)="goToUnit(unit?.name)"
                  style="cursor: pointer"
                  *ngFor="let unit of row?.unit"
                  class="hint"
                  >UN:{{ unit?.name }}</span
                >
                <span *ngIf="row?.phone_number" class="hint">{{
                  row?.phone_number
                }}</span>
                <span *ngIf="row?.email" class="hint">{{ row?.email }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              <strong>Action</strong>
            </th>
            <td mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                class="moreDetailsButton"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button (click)="editClient(row)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  *ngIf="row?.cancelled === false"
                  (click)="openBlockClient('block', row)"
                  mat-menu-item
                >
                  <mat-icon>block</mat-icon>
                  <span>Block</span>
                </button>

                <button
                  *ngIf="row?.cancelled === true"
                  (click)="openBlockClient('unblock', row)"
                  mat-menu-item
                >
                  <mat-icon>check_circle_outline</mat-icon>
                  <span>Active</span>
                </button>

                <button (click)="openDeleteClient(row)" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>

                <button (click)="generateInvitation(row)" mat-menu-item>
                  <mat-icon>email</mat-icon>
                  <span>Invitations</span>
                </button>

                <button (click)="blockUnitGates(row?.id)" mat-menu-item>
                  <mat-icon>meeting_room</mat-icon>
                  <span>Gate access</span>
                </button>

                <button (click)="addCard(row)" mat-menu-item>
                  <mat-icon>bookmark_added</mat-icon>
                  <span>Activate</span>
                </button>

                <button (click)="addNote('client', row?.id)" mat-menu-item>
                  <mat-icon
                    [matBadgeHidden]="row?.notes?.length > 0 ? false : true"
                    [matBadge]="row?.notes?.length"
                    matBadgeColor="warn"
                    >note_add</mat-icon
                  >
                  <span>Add/Show Note</span>
                </button>

                <button
                  *ngIf="!row.printed"
                  (click)="print_card_check_invoices(row)"
                  mat-menu-item
                >
                  <mat-icon>print</mat-icon>
                  <span>Print</span>
                </button>

                <button
                  *ngIf="row.printed"
                  (click)="print_card_again(row)"
                  mat-menu-item
                >
                  <mat-icon class="printAgain">print</mat-icon>
                  <span>Print Again</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div *ngIf="all_clients?.length === 0 && !showSpinner">
        <p>Sorry, no clients to display!</p>
      </div>

      <mat-paginator
        [pageSize]="limit"
        [length]="totalCount"
        [pageIndex]="page - 1"
        (page)="handlePageEvent($event)"
      ></mat-paginator>
    </div>

    <button
      id="printButton"
      class="cardPrint"
      [useExistingCss]="true"
      printSectionId="cardPrint"
      ngxPrint
    >
      print
    </button>

    <!-- cards -->
    <!-- <div class="mt-5">
      <div class="row">
        <div class="col-md-4 col-12" *ngFor="let item of all_clients">
          <div
            class="clientsCard"
            [ngClass]="{
              clientsCardWhenBlock: item.cancelled === true
            }"
          >
            <div class="row info">
              <div class="col-4">
                <img
                  *ngIf="item.photo"
                  class="user img-fluid"
                  [src]="item.photo.changingThisBreaksApplicationSecurity"
                />
                <img
                  *ngIf="!item.photo"
                  class="user img-fluid"
                  src="assets/images/avatar.png"
                />
              </div>
              <div class="col-7 p-0 ownerInfo">
                <p
                  class="mb-2"
                  style="cursor: pointer"
                  (click)="viewClient(item)"
                >
                  <img
                    *ngIf="item.verified == true"
                    src="assets/images/mangeunit/circle_check.png"
                  />
                  {{ item.name }}
                </p>
                <div>
                  <span class="hint">{{ item.type.name }}</span>

                  <span *ngIf="item.verified == true" class="hint"
                    >Verified</span
                  >
                  <span *ngIf="item.activated == true" class="hint"
                    >Activated</span
                  >
                  <span
                    (click)="goToUnit(unit.name)"
                    style="cursor: pointer"
                    *ngFor="let unit of item.unit"
                    class="hint"
                    >UN:{{ unit.name }}</span
                  >
                  <span *ngIf="item.phone_number" class="hint">{{
                    item.phone_number
                  }}</span>
                  <span *ngIf="item.email" class="hint">{{ item.email }}</span>
                </div>
              </div>
              <div class="col-1 dots">
                <mat-menu #menu="matMenu">
                  <p (click)="editClient(item)" class="menuItem" mat-menu-item>
                    Edit
                  </p>
                  <p
                    *ngIf="item.cancelled === false"
                    (click)="openBlockClient('block', item)"
                    class="menuItem"
                    mat-menu-item
                  >
                    Block
                  </p>
                  <p
                    *ngIf="item.cancelled === true"
                    (click)="openBlockClient('unblock', item)"
                    class="menuItem"
                    mat-menu-item
                  >
                    Active
                  </p>

                  <p
                    (click)="openDeleteClient(item)"
                    class="menuItem"
                    mat-menu-item
                  >
                    Delete
                  </p>
                </mat-menu>
                <img
                  [matMenuTriggerFor]="menu"
                  src="assets/images/mangeunit/dots.png"
                />
              </div>
            </div>

            <div
              class="mt-4 icons d-flex align-items-center justify-content-between"
            >
              <img
                (click)="generateInvitation(item)"
                matTooltip="Invitations "
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                src="assets/images/mangeunit/Group 162.png"
              />

              <img
                (click)="blockUnitGates(item.id)"
                mat-raised-button
                matTooltip="Gate access"
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                class="icon2"
                src="assets/images/mangeunit/Group 136.png"
              />

              <img
                (click)="addCard(item)"
                mat-raised-button
                matTooltip="Activate"
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                class="icon3"
                src="assets/images/mangeunit/Group 160.png"
              />

              <div class="notes">
                <p *ngIf="item.notes.length != 0">
                  <span>{{ item.notes.length }}</span>
                </p>
                <img
                  (click)="addNote('client', item.id)"
                  mat-raised-button
                  matTooltip="Add/Show Note"
                  matTooltipClass="example-tooltip-uppercase"
                  [matTooltipPosition]="'above'"
                  src="assets/images/mangeunit/Group 170.png"
                />
              </div>

              <img
                *ngIf="!item.printed"
                (click)="print_card(item)"
                mat-raised-button
                matTooltip="Print"
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                src="assets/images/mangeunit/5.png"
              />

              <img
                *ngIf="item.printed"
                (click)="print_card(item)"
                mat-raised-button
                matTooltip="Print Again"
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                src="assets/images/mangeunit/greenPrint.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div *ngIf="all_clients.length != 0">
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div> -->
</section>

<!-- blocked Modal -->

<button
  id="btn4"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal4"
>
  Launch demo modal
</button>

<div
  class="modal fade"
  id="exampleModal4"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">
          {{ blockClientTitle }} client
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to {{ blockClientTitle }} the client?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="blockClient()"
          data-bs-dismiss="modal"
        >
          {{ blockClientTitle }} Client
        </button>
      </div>
    </div>
  </div>
</div>

<!-- deleted model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete client</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">Are you sure you want to delete the client?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteClient()"
          data-bs-dismiss="modal"
        >
          Delete client
        </button>
      </div>
    </div>
  </div>
</div>
