import { Component, OnInit } from '@angular/core';
import { VillageService } from 'src/app/services/village.service';
import { ThemePalette } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainGatesComponent } from '../main-gates/main-gates.component';
import { MatDialogRef } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-add-village-config',
  templateUrl: './add-village-config.component.html',
  styleUrls: ['./add-village-config.component.css'],
})
export class AddVillageConfigComponent implements OnInit {
  showSpinner: boolean = true;
  villageList: any;
  color: ThemePalette = 'primary';
  checked = false;
  villageForm: FormGroup | any;
  qrTypesArray: any;

  constructor(
    private villageSer: VillageService,
    private unitService: UnitsService,
    public dialogRef: MatDialogRef<MainGatesComponent>
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.allVillage();
    this.allQrTypes();
    this.initForm();
  }

  initForm() {
    this.villageForm = new FormGroup({
      villages: new FormControl('', [Validators.required]),
      excluded: new FormControl(''),
      rented_unit: new FormControl(false),
      kids_access: new FormControl(false),
    });
  }

  allVillage() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.showSpinner = false;
      this.villageList = res?.results;
    });
  }

  allQrTypes() {
    this.unitService.QrTypes().subscribe((res: any) => {
      this.qrTypesArray = res?.children;
    });
  }

  addVillageConfig() {
    let data = this.villageForm?.value;
    this.dialogRef.close(data);
  }
}
