<section class="main">
  <mat-tab-group>
    <mat-tab label="Add Note">
      <!-- <div class="mt-3">
        <label>Action</label>
        <input [(ngModel)]="action" class="form-control" type="text" />
      </div> -->

      <div class="mt-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Action</mat-label>
          <input matInput [(ngModel)]="action" type="text" />
        </mat-form-field>
      </div>

      <!-- <div class="mt-3">
        <label>Any Notes ?</label>
        <textarea [(ngModel)]="notes" class="form-control"></textarea>
      </div> -->

      <div>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Any Notes ?</mat-label>
          <textarea
            rows="3"
            matInput
            [(ngModel)]="notes"
            type="text"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <button class="btn add" (click)="addNote()">Add it</button>
        <button mat-dialog-close class="btn ms-3">Cancel</button>
      </div>
    </mat-tab>

    <mat-tab label="Show Note">
      <h3 class="mt-3">Notes :</h3>
      <hr />
      <div
        class="cards mb-3 d-flex justify-content-between align-items-center"
        *ngFor="let item of note_array"
      >
        <div>
          <p>Name : {{ item.user.username }}</p>
          <p>Action : {{ item.action }}</p>
          <p>Note : {{ item.note }}</p>
          <p>Time : {{ item.timestamp }}</p>
        </div>
        <div>
          <img (click)="deleteNote(item)" src="assets/images/delete.png" />
        </div>
      </div>
      <div *ngIf="note_array == null">
        <p>No Notes Found</p>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
