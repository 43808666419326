import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-pages',
  template: `<app-header></app-header>
    <div class="row">
      <div class="col-md-2 " *ngIf="mainService?.booleanValue">
        <app-side-bar></app-side-bar>
      </div>
      <div
        class="col-md-10 col-12"
        [ngClass]="mainService.booleanValue ? 'col-md-10' : 'col-md-12'"
      >
        <router-outlet></router-outlet>
      </div>
    </div>`,
})
export class PagesComponent implements OnInit {
  constructor(public mainService: MainService) {}
  ngOnInit() {}
}
