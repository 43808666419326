<section class="main">
  <section class="sup_main">
    <h1>Family Requests</h1>
    <hr />

    <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

    <section *ngIf="!showSpinner" class="tableUnit">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 20%">Name</th>
            <th>Units</th>
            <th>Phone</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of clients">
            <td
              style="cursor: pointer"
              (click)="user_details(item)"
              class="d-flex align-items-center"
            >
              <p class="text-truncate mb-0 d-flex align-items-center">
                <ng-container>
                  <!-- <img
                    class="avatar"
                    (error)="item.images[0].image = 'assets/images/user.png'"
                    [src]="item.images[0].image"
                  /> -->

                  <img
                    *ngIf="item?.images?.length != 0"
                    src="{{ item?.images[0]?.image }}"
                    class="me-2 clientImage"
                  />

                  <ngx-avatar
                    *ngIf="item?.images?.length == 0"
                    class="me-2"
                    size="50"
                    name="{{ item?.name }}"
                    initialsSize="2"
                    cornerRadius="11"
                  ></ngx-avatar>
                </ng-container>
                <!-- <ng-container *ngIf="item.images.length == 0">
                  <img class="avatar" src="assets/images/user.png" />
                </ng-container> -->
              </p>
              {{ item?.name | titlecase }}
            </td>
            <td style="cursor: pointer">
              <ng-container *ngIf="item?.unit.length != 0">
                <p (click)="goToUnit(item?.unit[0]?.name)" class="mb-0 mt-2">
                  {{ item?.unit[0]?.name }}
                </p>
              </ng-container>
            </td>
            <td>
              <p class="mb-0 mt-2">{{ item?.phone_number }}</p>
            </td>

            <td class="text-center">
              <img
                style="width: 5px"
                class="mt-2"
                src="assets/images/mangeunit/dots.png"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div>
      <mat-paginator
        (page)="handlePageEvent($event)"
        [pageIndex]="pageIndex"
        [length]="totalCount"
        [pageSize]="page_size"
        [pageSizeOptions]="[10, 25, 100, 150, 200]"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </section>
</section>
