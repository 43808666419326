import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VillageService } from 'src/app/services/village.service';
import { AddVillageConfigComponent } from '../../gates/add-village-config/add-village-config.component';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-village-configuration',
  templateUrl: './village-configuration.component.html',
  styleUrls: ['./village-configuration.component.css'],
})
export class VillageConfigurationComponent implements OnInit {
  villages_arr_table: any = [];
  village_list: any;
  selectedVillage: any = 'all';
  selected_qr_village: any;

  showSpinnerForVillages: boolean = true;

  constructor(
    private villageSer: VillageService,
    public dialog: MatDialog,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.retrieve_all_villages();
  }

  // village config

  retrieve_all_villages() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.village_list = res?.results;
      // this.selectedVillage = res[0];
      // this.selected_user_village = res[0];
      this.selected_qr_village = res?.results[0];
      res?.results.forEach((element: any) => {
        this.retrieveVillagesConfig(element);
      });
      // this.users_type();
      // this.qr_type();
    });
  }

  retrieveVillagesConfig(item: any) {
    this.villageSer.retrieveVillageConfig(item.id).subscribe((res: any) => {
      this.showSpinnerForVillages = false;
      res.name = item.name;
      this.villages_arr_table.push(res);
    });
  }

  addVillageConfig() {
    const dialogRef = this.dialog.open(AddVillageConfigComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.excluded == '') {
        delete res.excluded;
      }
      if (res) {
        this.villageSer.addVillageConfig(res).subscribe((res: any) => {
          this.mainService.SuccessToast('Config added successfully');
          this.villages_arr_table = [];

          this.retrieve_all_villages();
        });
      }
    });
  }

  villageChange(e: any) {
    this.villages_arr_table = [];
    if (e.value == 'all') {
      this.village_list.forEach((element: any) => {
        this.retrieveVillagesConfig(element);
      });
    } else {
      this.retrieveVillagesConfig(e.value);
    }
  }

  // end village
}
