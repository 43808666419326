<section class="main m-0 mb-1">
  <h3 style="text-align: center" class="mb-4">Edit user configuration</h3>

  <mat-spinner diameter="20" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <div *ngIf="!showSpinner">
    <strong>Project name<br /> </strong>

    <div class="span">
      <strong>{{ project }}</strong>
    </div>

    <strong>Type name<br /> </strong>

    <div class="span">
      <strong>{{ type }}</strong>
    </div>
  </div>

  <div *ngIf="!showSpinner">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="row mt-3 d-flex">
        <div *ngIf="frontImageEdit" class="image-container">
          <img [src]="frontImageUrl" class="image" />
          <button class="remove-button" (click)="removeFrontImage()">
            Remove
          </button>
        </div>

        <input
          type="file"
          id="frontImage"
          hidden
          (change)="uploadFrontImage($event)"
          #fileInput2
        />

        <label
          for="frontImage"
          class="uploadImage"
          title="Upload Front Image"
          *ngIf="!frontImageEdit"
        >
          <div>
            <img
              class="icon"
              src="../../../../assets/images/upload.png"
              alt=""
            />
            <br />
            <strong>Upload Front Image</strong>
          </div></label
        >
      </div>

      <div class="row mt-3 d-flex">
        <div *ngIf="backImageEdit" class="image-container">
          <img [src]="backImageUrl" class="image" />
          <button class="remove-button" (click)="removeBackImage()">
            Remove
          </button>
        </div>

        <input
          type="file"
          id="backImage"
          hidden
          (change)="uploadBackImage($event)"
          #fileInput2
        />

        <label
          for="backImage"
          class="uploadImage"
          title="Upload unit photos"
          *ngIf="!backImageEdit"
        >
          <div>
            <img
              class="icon"
              src="../../../../assets/images/upload.png"
              alt=""
            />
            <br />
            <strong>Upload Back Image</strong>
          </div></label
        >
      </div>
    </div>
  </div>

  <div *ngIf="!showSpinner" class="mt-3">
    <div class="d-flex justify-content-around">
      <button class="btn" (click)="saveEditUserConfiguration()">Save</button>

      <button class="btn close" (click)="close()">Cancel</button>
    </div>
  </div>
</section>
