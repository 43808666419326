import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllServicesComponent } from './all-services/all-services.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { AddMicroServiceComponent } from './add-micro-service/add-micro-service.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EditServiceComponent } from './edit-service/edit-service.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AvatarModule } from 'ngx-avatar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    AllServicesComponent,
    AddServiceComponent,
    AddMicroServiceComponent,
    EditServiceComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    AvatarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class MangeServiceModule {}
