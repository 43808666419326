<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center">
    <h3>User access configuration</h3>
  </div>

  <div class="mb-4 d-flex justify-content-between align-items-center flex-wrap">
    <div class="filters d-flex justify-content-between align-items-center">
      <mat-form-field appearance="outline" class="w-100 me-2">
        <mat-label>User type</mat-label>
        <mat-select>
          <mat-option value="" (click)="selectAllUsersType()">
            <strong>All</strong>
          </mat-option>

          <mat-option
            value="{{ item?.id }}"
            *ngFor="let item of userTypes"
            (click)="selectUserType(item?.id)"
          >
            <strong>{{ item.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Access type</mat-label>
        <mat-select>
          <mat-option value="" (click)="selectAllAccessType()">
            <strong>All</strong>
          </mat-option>

          <mat-option
            value="{{ item?.id }}"
            *ngFor="let item of accessTypes"
            (click)="selectAccessType(item?.id)"
          >
            <strong>{{ item.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inputButton">
      <button class="btn addNewImage" (click)="addNewUserAccessConfiguration()">
        Add new configuration
      </button>
    </div>
  </div>

  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinner" class="tableUnit table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Village</th>
          <th scope="col">User Type</th>
          <th scope="col">Access Type</th>
          <th scope="col">Max. First Cards</th>
          <th scope="col">Max. Extra Cards</th>
          <th scope="col">First Issue Fees</th>
          <th scope="col">Extra Card Fees</th>
          <th scope="col">Reissuance Fees</th>
          <th scope="col">Insured</th>
          <th scope="col">Insurance Fees</th>
          <th scope="col">Min. Stay Price</th>
          <th scope="col">Cards / Bed Room</th>
          <th scope="col">Expiry Date</th>
          <th scope="col">Min. Stay</th>
          <th scope="col" class="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="configurationRow"
          *ngFor="let item of allUserAccessConfiguration; let i = index"
        >
          <td>
            <span>{{ item?.village | titlecase }}</span>
          </td>
          <td>
            <span>{{ item?.user_type | titlecase }}</span>
          </td>
          <td>
            <span>{{ item?.access_type | titlecase }}</span>
          </td>
          <td>
            <span>{{ item?.maximum_first_cards }}</span>
          </td>
          <td>
            <span>{{ item?.maximum_extra_cards }}</span>
          </td>
          <td>
            <span>{{ item?.first_issue_fees }} <strong>LE</strong></span>
          </td>
          <td>
            <span>{{ item?.extra_cards_fees }} <strong>LE</strong></span>
          </td>
          <td>
            <span>{{ item?.reissuance_fees }} <strong>LE</strong></span>
          </td>
          <td>
            <span>{{ item?.insured }}</span>
          </td>
          <td>
            <span>{{ item?.insurance_fees }} <strong>LE</strong></span>
          </td>
          <td>
            <span>{{ item?.minimum_stay_price }} <strong>LE</strong></span>
          </td>
          <td>
            <span>{{ item?.cards_per_bedroom }}</span>
          </td>
          <td>
            <span>{{ item?.expiry_date | date }}</span>
          </td>
          <td>
            <span>{{ item?.minimum_stay }}</span>
          </td>

          <td class="actionColumn">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="moreDetailsButton"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editUserAccessConfiguration(item)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button mat-menu-item (click)="openDeleteConfiguration(item)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
    <h3
      class="d-flex align-items-center justify-content-center"
      *ngIf="
        allUserAccessConfiguration.length == null ||
        !allUserAccessConfiguration.length
      "
    >
      No Data Found
    </h3>
  </section>

  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>

<!-- deleted model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete configuration</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete this user access configuration?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteUserAccessConfiguration()"
          data-bs-dismiss="modal"
        >
          Delete configuration
        </button>
      </div>
    </div>
  </div>
</div>
