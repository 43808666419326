<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center">
    <h3>Promo codes</h3>
    <div class="inputButton">
      <button class="btn addNewImage me-2" (click)="addNewPromoCode()">
        Add promo code
      </button>
    </div>
  </div>

  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinner" class="tableUnit table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Promo code</th>
          <th scope="col">Percentage</th>
          <th scope="col">Limit</th>
          <th scope="col">No. of invoices</th>
          <th scope="col" class="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="configurationRow"
          *ngFor="let item of allPromoCodes; let i = index"
        >
          <td>
            <strong>{{ item.promo_code | titlecase }}</strong>
          </td>
          <td>
            <strong>{{ item.percentage }} %</strong>
          </td>

          <td>
            <strong>{{ item.limit }} LE</strong>
          </td>

          <td>
            <strong>{{ item.Invoices?.length }}</strong>
          </td>

          <td class="actionColumn">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="moreDetailsButton"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editPromoCode(item)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button mat-menu-item (click)="openDeletePromoCode(item)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 *ngIf="allPromoCodes == null">No Data Found</h3>
  </section>

  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>

<!-- deleted model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete promo code</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete the promo code?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deletePromoCode()"
          data-bs-dismiss="modal"
        >
          Delete promo code
        </button>
      </div>
    </div>
  </div>
</div>
