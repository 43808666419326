import { Component, OnInit } from '@angular/core';
import { ServiceMangeService } from 'src/app/services/service-mange.service';
import { AddUtilityComponent } from '../add-utilty/add-utilty.component';
import { MatDialog } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { AddSectionComponent } from '../add-section/add-section.component';
import { AddInoviceComponent } from '../add-inovice/add-inovice.component';

@Component({
  selector: 'app-all-utiltes',
  templateUrl: './all-utiltes.component.html',
  styleUrls: ['./all-utiltes.component.css'],
})
export class AddUtilitiesComponent implements OnInit {
  showSpinner: boolean = true;
  allUtility: any = [];
  utilityDetails: any = {
    unit_measure: '',
    sections: [],
  };
  constructor(
    private rest: ServiceMangeService,
    private mainService: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.getData();
  }

  getData() {
    this.rest.retrieve_all_utilities().subscribe((res: any) => {
      this.showSpinner = false;
      this.allUtility = res;
      this.allUtility.forEach((element: any) => {
        element.status = false;
      });

      this.utilityDetails = res[0];
      this.allUtility[0].status = true;
    });
  }

  utilityDetailsFunction(item: any) {
    this.allUtility.forEach((element: any) => {
      if (element.id == item.id) {
        element.status = true;
      } else {
        element.status = false;
      }
    });
    this.utilityDetails = item;
  }

  addUtility(item?: any) {
    let data;
    if (item) {
      data = item;
    }
    const dialogRef = this.dialog.open(AddUtilityComponent, {
      width: '450px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (!item) {
          if (result.status == true) {
            this.rest
              .disabledUtlity(result.utility_id)
              .subscribe((res: any) => {});
          }
          this.rest
            .addUtitlyImage(result)
            .then((response) => response.text())
            .then((res: any) => {
              this.mainService.SuccessToast('Utility Added');
              this.getData();
            });
        } else {
          if (result.status) {
            let disabledData = {
              id: result.utility_id,
              disabled: result.status,
            };
            this.rest.disabledUtlity(disabledData).subscribe((res: any) => {});
          }
          this.rest.editUtility(result).subscribe((res: any) => {
            this.mainService.SuccessToast('Utility Edited');
            this.getData();
          });
        }
      }
    });
  }

  addSection(value: any, status: any) {
    let data;
    if (status == 'edit') {
      data = value;
    }
    const dialogRef = this.dialog.open(AddSectionComponent, {
      width: '450px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (status == 'edit') {
          this.rest.editSection(result).subscribe((res: any) => {
            this.mainService.SuccessToast('Section Edited');
            this.getData();
          });
        } else {
          result.utility_id = value.id;
          this.rest.addSection(result).subscribe((res: any) => {
            this.mainService.SuccessToast('Section added');
            this.getData();
          });
        }
      }
    });
  }

  addInvoice() {
    const dialogRef = this.dialog.open(AddInoviceComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.utility_id = this.utilityDetails.id;
        this.rest.addInvoice(result).subscribe(
          (res: any) => {
            this.mainService.SuccessToast('Utility Invoice Added');
          },
          (err: any) => {
            this.mainService.errorToast(err.error.message);
          }
        );
      }
    });
  }
}
