import { Component, OnInit, Inject } from '@angular/core';
import { RolesService } from 'src/app/services/roles.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RolesMainComponent } from '../roles-main/roles-main.component';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css'],
})
export class EditRoleComponent implements OnInit {
  showSpinner: boolean = true;
  showSpinnerForPermission: boolean = true;

  RoleName: string = '';
  submitted = false;
  PermissionsName = 'Choose Permission';
  all_permissions: any;
  original_permissions: any;
  permissions_choice: any = [];
  permissions_arr: any = [];

  constructor(
    private rest: RolesService,
    public dialogRef: MatDialogRef<RolesMainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.getPermissions();
  }

  getData(id: any) {
    let permiosn: any = [];
    this.rest.roleDetails(id).subscribe((res: any) => {
      this.RoleName = res?.name;
      this.permissions_choice = res?.rolePermissions;

      res.rolePermissions.forEach((element: any) => {
        this.all_permissions.forEach((item: any) => {
          if (element?.name == item?.name) {
            permiosn.push(item);
          }
        });
      });
      this.permissions_arr = permiosn;
      // this.permissions_arr = ['Retrieve operator']
    });
  }

  getPermissions() {
    this.rest.retrievePermission().subscribe((res: any) => {
      this.all_permissions = res;
      this.original_permissions = res;
      this.getData(this.data?.id);
      this.showSpinner = false;
      this.showSpinnerForPermission = false;
    });
  }

  getPerItem(item: any) {
    for (let i = 0; i < this.permissions_choice?.length; i++) {
      if (item.name == this.permissions_choice[i]?.name) {
        this.permissions_choice?.splice(i, 1);
      }
    }
    this.permissions_choice?.push(item);
  }

  addRole() {
    this.submitted = true;
    if (this.RoleName != '' && this.permissions_choice?.length != 0) {
      let data = {
        name: this.RoleName,
        permiosn: this.permissions_arr,
        roleId: this.data?.id,
      };
      this.dialogRef.close(data);
    }
  }

  getInput(event: any) {
    this.RoleName = event?.target?.value;
  }

  searchForPermission(value: any) {
    if (!value) {
      this.all_permissions = this.original_permissions;
    } else {
      this.all_permissions = this.original_permissions.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
}
