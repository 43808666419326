import { Component } from '@angular/core';

@Component({
  selector: 'app-clients-log',
  templateUrl: './clients-log.component.html',
  styleUrls: ['./clients-log.component.css']
})
export class ClientsLogComponent {

}
