import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';
@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private http: HttpClient) {}

  allClients(page: any, limit: any, clientName: any) {
    return this.http.get(
      `${environment.baseUrl}/client/all/?page=${page}&limit=${limit}&client_name=${clientName}`
    );
  }

  searchClient(name: any, pageIndex: any, pageLimit: any) {
    return this.http.get(
      `${environment.baseUrl}/client/search/?client_name=${name}&page=${pageIndex}&limit=${pageLimit}`
    );
  }
  getTypes() {
    return this.http.get(`${environment.baseUrl}/types/?name=owner`);
  }

  retrieveClient(id: any) {
    return this.http.get(`${environment.baseUrl}/client/?client_id=${id}`);
  }

  editClient(data: any) {
    return this.http.put(`${environment.baseUrl}/client/`, data);
  }

  printCardForClient(id: any, printed: boolean) {
    return this.http.put(
      `${environment.baseUrl}/client/printCard/?client_id=${id}&printed=${printed}`,
      ''
    );
  }

  deliverInvoice(id: any) {
    return this.http.put(
      `${environment.baseUrl}/invoices/deliver?id=${id}`,
      {}
    );
  }

  all_unverifiedClient(current: any, size: any) {
    return this.http.get(
      `${environment.baseUrl}/client/unverified?page=${current}&limit=${size}`
    );
  }

  verifyClient(id: any) {
    return this.http.put(
      `${environment.baseUrl}/client/verify/?client_id=${id}`,
      ''
    );
  }

  activateClient(id: any) {
    return this.http.put(
      `${environment.baseUrl}/client/activate/?client_id=${id}`,
      ''
    );
  }
}
