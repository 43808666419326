<section class="main">
  <div class="supmain" *ngIf="!showSpinner">
    <h3>
      <span>
        <img src="assets/images/mangeunit/back.png" />
      </span>
      <span>{{ villageName }}</span>
    </h3>
  </div>

  <mat-spinner *ngIf="showSpinner" diameter="20"></mat-spinner>

  <hr />

  <div>
    <h3>Units Village</h3>

    <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

    <section *ngIf="!showSpinner" class="tableUnit table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Unit name</th>
            <th scope="col">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of unitsArray">
            <td style="cursor: pointer" (click)="unitDetails(item.name)">
              {{ item.name }}
            </td>
            <td>
              <span>{{ item.type.name }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</section>
