import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { MainService } from 'src/app/services/main.service';
import { ClientsService } from 'src/app/services/clients.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewRfidComponent } from '../view-rfid/view-rfid.component';
import { DeletRfidComponent } from '../delet-rfid/delet-rfid.component';
import { EditRFIDComponent } from '../edit-rfid/edit-rfid.component';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class EditClientComponent implements OnInit {
  birthDateControlName: string = 'birth_date';
  defaultDate = moment();
  clientRelation: any;

  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.Egypt];
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode = true;

  validForm = true;
  unitId: any;
  clientId: any;
  clientForm: FormGroup | any;
  RfidForm: FormGroup | any;
  types_arr: any;
  relationArray: any;
  typeName: any = 'Relation type';
  typeId: any = '';
  submitted = false;
  userPhotoPath: any = '';
  pipe = new DatePipe('en-US');
  userEditPhoto = false;
  url: any;
  documentImage: any = [];
  userDocEditPhoto = false;
  documentsFiles: any = [];
  array_unit: any = [];
  unitNameSearch = '';
  selectedUnitName = '';
  unitRelation = '';
  access_Type: any;
  card_id: any;
  unitSearch_arr: any = [];
  rfid_list: any = [];
  clients_image: any;
  showConfirmEditMessageAfterAddingUnit: boolean = false;

  newlyAddedIndices: number[] = [];
  showIdentityTypeInput: boolean = true;
  identityType: string = '';
  identityTypes: any[] = [
    {
      name: 'National Id',
      key: 'nationalId',
    },
    {
      name: 'Passport',
      key: 'passport',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    private mainService: MainService,
    private clientService: ClientsService,
    private location: Location,
    private rest: UnitsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.unitId = this.activeRouter.snapshot.params['id'];
    this.clientId = this.activeRouter.snapshot.params['id1'];
    this.initForm();
    this.unitRelationType();
    this.getUnitRelation();
    this.retrieveClient();
    this.initRfidForm();
    this.accessType();
  }

  searchUnit(e: any) {
    this.rest.searchUnit(this.unitNameSearch).subscribe((res: any) => {
      if (res) {
        this.unitSearch_arr = res.results;
      } else {
        this.unitSearch_arr = [];
      }
    });
  }

  searchForUnit(searchKey: any) {
    this.unitNameSearch = searchKey;
    this.rest.searchUnit(this.unitNameSearch).subscribe((res: any) => {
      if (res) {
        this.unitSearch_arr = res.results;
      } else {
        this.unitSearch_arr = [];
      }
    });
  }

  selectUnit(unit: any) {
    this.unitIdChoose = unit?.id;
    this.selectedUnitName = unit?.name;
  }

  unitIdChoose: any;

  getUnit(value: any) {
    this.unitNameSearch = value.name;
    this.unitIdChoose = value.id;
    this.unitSearch_arr = [];
  }

  relationTypeValue(relationType: any) {
    this.unitRelation = relationType;
  }

  addUnit() {
    if (this.selectedUnitName != '' && this.unitRelation != '') {
      let obj = {
        name: this.selectedUnitName,
        relation: this.unitRelation,
        id: this.unitIdChoose,
      };
      this.array_unit.push(obj);
      this.newlyAddedIndices.push(this.array_unit.length - 1);

      this.showConfirmEditMessageAfterAddingUnit = true;
    }
  }

  isNewlyAdded(index: number): boolean {
    return this.newlyAddedIndices.includes(index);
  }
  identitySelected: any;
  nationalId: any = '';
  passportId: any = '';
  retrieveClient() {
    this.rfid_list = [];
    this.clientService.retrieveClient(this.clientId).subscribe((res: any) => {
      res.cards.forEach((element: any) => {
        if (element.RFID) {
          this.rfid_list = element.RFID;
        }
      });
      this.clients_image = res.images;
      // this.clients_image.forEach((element: any) => {
      //   element.image = this._sanitizer.bypassSecurityTrustUrl(
      //     `data:image/png;base64,` + element.image
      //   );
      // });
      this.array_unit = res.unit;
      this.typeName = res.type.name;
      this.typeId = res.type.id;
      this.clientForm.patchValue(res);
      this.clientForm.patchValue({
        type: res?.type?.id,
      });

      this.clientRelation = localStorage.getItem('clientRelation');

      if (this.is14DigitNumber(res.national_id)) {
        this.identityType = 'National Id';
        this.identitySelected = 'nationalId';
        this.nationalId = res.national_id;
      } else {
        this.identityType = 'Passport';
        this.identitySelected = 'passport';
        this.passportId = res.national_id;
      }
    });
  }

  is14DigitNumber(input: string): boolean {
    const digitRegex = /^\d{14}$/;
    return digitRegex.test(input);
  }

  get ControlForm() {
    return this.clientForm.controls;
  }

  initForm() {
    this.clientForm = this.formBuilder.group({
      name: ['', Validators.required],
      valid_from: ['', Validators.required],
      valid_to: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      birth_date: [this.defaultDate, Validators.required],
      gender: ['', Validators.required],
      phone_number: ['', Validators.required],
      verified: [''],
      ext_id: [''],
      activated: [''],
      national_id: ['', [Validators.required, this.conditionalValidator()]],
      type: ['', Validators.required],
      arabic_name: ['', Validators.required],
    });
  }
  initRfidForm() {
    this.RfidForm = this.formBuilder.group({
      front: ['', Validators.required],
      front_source: [''],
      back_source: [''],
      back: ['', Validators.required],
      charOne: ['', Validators.required],
      charTow: ['', Validators.required],
      charThree: ['', Validators.required],
      number: ['', Validators.required],
      // license_plate: ['', Validators.required],
      serial_number: ['', [Validators.required]],
      card_id: ['', [Validators.required]],
    });
  }

  selectIdentityType(value: any) {
    this.showIdentityTypeInput = true;

    if (value == 'nationalId') {
      this.identityType = 'National Id';
      this.clientForm.get('national_id').patchValue(this.nationalId);
    } else if (value == 'passport') {
      this.identityType = 'Passport';
      this.clientForm.get('national_id').patchValue(this.passportId);
    }
  }

  unitRelationType() {
    this.rest.unitRelation().subscribe((res: any) => {
      this.types_arr = res.children;
    });
  }

  getUnitRelation() {
    this.rest.unitRelations().subscribe((res: any) => {
      this.relationArray = res.children;
    });
  }

  accessType() {
    this.rest.cardType().subscribe((res: any) => {
      res.children.forEach((element: any) => {
        if (element.name == 'RFID') {
          this.access_Type = element.id;
        }
      });
    });
  }

  getRelationTypeId(value: any) {
    this.typeName = value.name;
    this.typeId = value.id;
  }
  back() {
    this.location.back();
  }

  editClient() {
    let unitArr: any = [];
    let data = this.clientForm.value;
    data.id = this.clientId;
    data.unit_relation_id = this.typeId;
    this.array_unit.forEach((element: any) => {
      unitArr.push({
        unit_id: element.id,
        relation: element.relation,
      });
    });
    data.units = unitArr;
    data.birth_date = this.pipe.transform(data.birth_date, 'yyyy-MM-dd');
    data.valid_from = this.pipe.transform(data.valid_from, 'yyyy-MM-dd');
    data.valid_to = this.pipe.transform(data.valid_to, 'yyyy-MM-dd');
    data.phone_number = this.clientForm.get('phone_number').value.e164Number;

    this.clientService.editClient(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Edit Client Success');
      this.back();
    });
  }

  deleteUnit(index: any) {
    this.array_unit.splice(index, 1);
  }

  addRFId() {
    let RfidData = this.RfidForm.value;
    RfidData.number = Intl.NumberFormat('ar-SA', {}).format(RfidData.number);
    RfidData.number = RfidData.number.replace('٬', '');
    let data = {
      front: RfidData.front_source,
      back: RfidData.back_source,
      card_id: RfidData.card_id,
      client_id: this.clientId,
      license_plate:
        RfidData.charThree +
        ' ' +
        RfidData.charTow +
        ' ' +
        RfidData.charOne +
        RfidData.number,
      type_id: this.access_Type,
      unit_id: this.unitId,
      serial_number: RfidData.serial_number,
    };

    this.rest
      .addRFId(data)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        if (res.status == true) {
          this.mainService.SuccessToast('RFID Added');
          this.retrieveClient();
        } else {
          this.mainService.errorToast('Opss something wrong happen!');
        }
      })
      .catch((error) => {
        this.mainService.errorToast('Opss something wrong happen!');
      });
  }

  onFileChange(e: any, name: any) {
    const file = e.target.files[0];
    if (name == 'front') {
      this.RfidForm.patchValue({
        front_source: file,
      });
    } else {
      this.RfidForm.patchValue({
        back_source: file,
      });
    }
  }

  openViewRFID(data: any) {
    this.rest.retrieveRFId(data.id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(ViewRfidComponent, {
        width: '400px',
        data: { item: res },
      });
    });
  }

  deleteRFId(item: any) {
    const dialogRef = this.dialog.open(DeletRfidComponent, {
      width: '400px',
      data: { item: item },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.rest.deleteRFId(item.id).subscribe((res: any) => {
          this.mainService.SuccessToast('RFID Deleted');
          this.retrieveClient();
        });
      }
    });
  }

  editRFID(item: any) {
    this.rest.retrieveRFId(item.id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(EditRFIDComponent, {
        width: '500px',
        data: { item: res },
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.rest
            .editRFId(result)
            .then((response) => response.text())
            .then((resu) => {
              let res = JSON.parse(resu);
              if (res.status == true) {
                this.mainService.SuccessToast('RFID Edit success');
                this.retrieveClient();
              } else {
                this.mainService.errorToast('Opss something wrong happen!');
              }
            })
            .catch((error) => {
              this.mainService.errorToast('Opss something wrong happen!');
            });
        }
      });
    });
  }

  // images
  errorDocumentPhoto = false;
  uploadDocumentClient(event: any) {
    this.userDocEditPhoto = true;
    let files: any = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (this.typeDocName == 'personal_photo') {
        this.documentsFiles.push({
          type: this.typeDocName,
          path: files[i],
        });
      } else {
        this.documentsFiles.push({
          type: this.typeDocName + i,
          path: files[i],
        });
      }

      this.convertFile(files[i]);
    }
  }

  convertFile(file: any) {
    if (file.type == 'application/pdf') {
      if (this.typeDocName == 'Other') {
        this.typeDocName = this.typeInputName;
      }
      this.documentImage.push({
        url: file.name,
        file: true,
        fileName: file.name,
        name: this.typeDocName,
      });
    } else {
      if (this.typeDocName == 'Other') {
        this.typeDocName = this.typeInputName;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.documentImage.push({
          url: reader.result,
          file: false,
          fileName: file.name,
          name: this.typeDocName,
        });
      };
    }
    this.errorDocumentPhoto = false;
  }

  removeDocImage(item: any) {
    for (let i = 0; i < this.documentImage.length; i++) {
      if (this.documentImage[i].fileName == item.fileName) {
        this.documentImage.splice(i, 1);
      }
    }
    for (let i = 0; i < this.documentsFiles.length; i++) {
      if (this.documentsFiles[i].path.name == item.fileName) {
        this.documentsFiles.splice(i, 1);
      }
    }
    if (this.documentsFiles.length == 0) {
      this.userDocEditPhoto = false;
      this.errorDocumentPhoto = true;
    }
  }

  typeDocName: any = 'personal_photo';
  typeInputName: any = '';
  status_input_type_name = false;
  typeChoose(e: any) {
    if (e == 'Other') {
      this.status_input_type_name = true;
    } else {
      this.status_input_type_name = false;
      this.typeDocName = e;
    }
  }

  showInputFile = false;
  addImage() {
    this.showInputFile = true;
  }

  createImage() {
    this.rest
      .addClientImage(this.clientId, this.documentsFiles)
      .then((response: any) => response.text())
      .then((result: any) => {
        this.mainService.SuccessToast('Image updated');
        // this.back();
        this.documentsFiles = [];
        this.documentImage = [];
        this.showInputFile = false;
        this.status_input_type_name = false;
        this.typeDocName = 'personal_photo';
        this.retrieveClient();
      })
      .catch((error: any) => {});
  }

  deleteImage(id: any) {
    this.rest.deleteClientImage(id).subscribe((res: any) => {
      this.retrieveClient();
      this.mainService.SuccessToast('Image Deleted');
    });
  }

  inputChange(e: any) {
    this.typeDocName = e.target.value;
  }

  conditionalValidator() {
    return (control: FormControl) => {
      if (this.identityType == 'National Id') {
        return this.egyptianNationalIdValidator(control);
      }
      return null;
    };
  }

  egyptianNationalIdValidator(
    control: FormControl
  ): { [key: string]: any } | null {
    const nationalId = control.value;
    const regex = /^[0-9]{14}$/;

    if (nationalId && !regex.test(nationalId)) {
      return { invalidNationalId: true };
    }
    return null;
  }

  //Form Validator
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.clientForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
  //Form Validator
}
