<section class="main">
  <h2>Add new operator</h2>
  <form [formGroup]="registerForm">
    <div class="row">
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Operator name</mat-label>
          <input
            matInput
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && ControlForm.name.errors }"
            class="form-control"
            placeholder="Type something"
          />
        </mat-form-field>

        <!-- <label>Operator name</label>
        <input
          formControlName="name"
          [ngClass]="{ 'is-invalid': submitted && ControlForm.name.errors }"
          class="form-control"
          placeholder="Type something"
        /> -->
      </div>
    </div>
    <div class="row">
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>User name</mat-label>
          <input
            matInput
            formControlName="username"
            [ngClass]="{
              'is-invalid': submitted && ControlForm.username.errors
            }"
            class="form-control"
            placeholder="Type something"
          />
        </mat-form-field>

        <!-- <label>User name</label>
        <input
          formControlName="username"
          [ngClass]="{ 'is-invalid': submitted && ControlForm.username.errors }"
          class="form-control"
          placeholder="Type something"
        /> -->
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <label>
        <input type="radio" value="true" formControlName="web_login" />
        <span>Web login</span>
      </label>
      <label>
        <input type="radio" value="false" formControlName="web_login" />
        <span>Non web login</span>
      </label>

      <div class="waring" *ngIf="submitted && ControlForm.web_login.errors">
        status is required
      </div>
    </div>

    <div class="row mt-3">
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && ControlForm.email.errors }"
            class="form-control"
            placeholder="Type something"
          />
        </mat-form-field>

        <!-- <label>Email</label>
        <input
          formControlName="email"
          [ngClass]="{ 'is-invalid': submitted && ControlForm.email.errors }"
          class="form-control"
          placeholder="Type something"
        /> -->
      </div>
    </div>
    <div class="row">
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Phone number</mat-label>
          <input
            matInput
            formControlName="phone_number"
            [ngClass]="{
              'is-invalid': submitted && ControlForm.phone_number.errors
            }"
            class="form-control"
            type="text"
            placeholder="Type something"
          />
        </mat-form-field>

        <!-- <label>Phone number</label>
        <input
          formControlName="phone_number"
          [ngClass]="{
            'is-invalid': submitted && ControlForm.phone_number.errors
          }"
          class="form-control"
          type="text"
          placeholder="Type something"
        /> -->
      </div>
    </div>
    <div class="row">
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            [ngClass]="{
              'is-invalid': submitted && ControlForm.password.errors
            }"
            class="form-control"
            placeholder="Type something"
          />
        </mat-form-field>

        <!-- <label>Password</label>
        <input
          type="password"
          formControlName="password"
          [ngClass]="{ 'is-invalid': submitted && ControlForm.password.errors }"
          class="form-control"
          placeholder="Type something"
        /> -->
      </div>
    </div>
  </form>

  <div *ngIf="savedRoles_array.length != 0">
    <div class="permission mt-2" *ngFor="let item of savedRoles_array">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Village / Projects</h4>
        <img style="margin-top: -13px" src="assets/images/edit.png" />
      </div>
      <p class="subTitle">{{ item.village }}</p>

      <div class="roles">
        <p>Roles</p>
        <div>
          <span class="hint" *ngFor="let role of item.roles">
            {{ role.name }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="permission mt-2 p-4" *ngIf="cardPermission">
    <div class="d-flex align-items-center justify-content-around">
      <div>
        <input
          class="me-1"
          [checked]="status_radio"
          (change)="changeRadio($event)"
          type="radio"
          id="1"
          name="age"
          value="1"
        />
        <label for="1">Villages</label>
      </div>
      <div>
        <input
          (change)="changeRadio($event)"
          class="me-1"
          type="radio"
          id="2"
          name="age"
          value="2"
        />
        <label for="2">Project</label>
      </div>
    </div>

    <div *ngIf="status_radio">
      <div class="mt-2">
        <mat-form-field appearance="outline">
          <mat-label>Villages name</mat-label>
          <mat-select multiple name="name" [(ngModel)]="villages_list">
            <mat-option *ngFor="let item of villages_arr" [value]="item">
              {{ item?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select roles</mat-label>
          <mat-select multiple name="name" [(ngModel)]="rols_list">
            <mat-option *ngFor="let item of roles_arr" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!status_radio">
      <div class="mt-2">
        <mat-form-field appearance="outline">
          <mat-label>Projects name</mat-label>
          <mat-select multiple name="name" [(ngModel)]="list_projects">
            <mat-option *ngFor="let item of project_list" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select roles</mat-label>
          <mat-select multiple name="name" [(ngModel)]="rols_list">
            <mat-option *ngFor="let item of roles_arr" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-4 cardFooter">
      <span style="cursor: pointer" (click)="saveCard()">Save</span>
      <span style="cursor: pointer" (click)="cardPermission = !cardPermission"
        >cancel</span
      >
    </div>
  </div>
  <button class="btn addPermission" (click)="addPermission()">
    Add new role
  </button>

  <div class="f-b mt-4">
    <button class="btn cancel">Cancel</button>
    <button class="btn add" (click)="addOperators()">Add operator</button>
  </div>
</section>
