<section class="main">
  <section class="sup_main">
    <div class="live-gate">
      <div class="mb-4 d-flex justify-content-between align-items-center">
        <h4>Gates dashboard</h4>
      </div>
      <div class="row align-items-center">
        <div class="col-12" *ngFor="let item of selectedGate">
          <div class="text-start">
            <div class="d-flex justify-content-between align-items-center">
              <h2>{{ item.name }}</h2>
              <p (click)="moreGate(item.id)" class="gate_details">
                Gate Details
              </p>
            </div>

            <ngx-charts-pie-grid
              (select)="getChart($event, item.statisticsLiveGates)"
              [designatedTotal]="item.show"
              [view]="view"
              [scheme]="colorScheme"
              [results]="item.statisticsLiveGates"
            >
            </ngx-charts-pie-grid>
            <div class="infoCard" *ngFor="let chart of item.statisticsLiveGates">
              <h4 class="pt-2" *ngIf="chart.status == true">
                {{ item.name }} - {{ chart.name }}
              </h4>
              <ngx-charts-pie-grid
                *ngIf="chart.status == true"
                [view]="viewTwo"
                [scheme]="colorScheme"
                [results]="chart.option"
              >
              </ngx-charts-pie-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
