import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MangeUnitsComponent } from './mange-units/mange-units.component';
import { UnitDetailsComponent } from './unit-details/unit-details.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { AddClientComponent } from './add-client/add-client.component';
import { AddUnitComponent } from './addunit/addunit.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddquotaComponent } from './addquota/addquota.component';
import { MatMenuModule } from '@angular/material/menu';
import { UnitBlockComponent } from './unit-block/unit-block.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AddNoteComponent } from './add-note/add-note.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ImportClientComponent } from './import-client/import-client.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { InvtaionComponent } from './invtaion/invtaion.component';
import { AddCardComponent } from './add-card/add-card.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxPrintModule } from 'ngx-print';
import { ViewRfidComponent } from './view-rfid/view-rfid.component';
import { DeletRfidComponent } from './delet-rfid/delet-rfid.component';
import { EditRFIDComponent } from './edit-rfid/edit-rfid.component';
import { ViewClientComponent } from './view-client/view-client.component';
import { QrInvtationComponent } from './qr-invtation/qr-invtation.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { LogsClientComponent } from './logs-client/logs-client.component';
import { UnitInvoiceComponent } from './unit-inovice/unit-inovice.component';
import { CompleteUnitDetailsComponent } from './complete-unit-details/complete-unit-details.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AvatarModule } from 'ngx-avatar';
import { MatIconModule } from '@angular/material/icon';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environments';
import { GoogleMapComponent } from './google-map/google-map.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditUnitDetailsComponent } from './edit-unit-details/edit-unit-details.component';
import { QrCodesComponent } from './qr-codes/qr-codes.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { MatExpansionModule } from '@angular/material/expansion';
import { RegisterAccessComponent } from './register-access/register-access.component';
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { UnpaidInvoicesComponent } from './unpaid-invoices/unpaid-invoices.component';
import { UnitRentalsComponent } from './unit-rentals/unit-rentals.component';
import { UnpaidInvoicePrintAllComponent } from './unpaid-invoice-print-all/unpaid-invoice-print-all.component';
import { UnitUnpaidInvoicesComponent } from './unit-unpaid-invoices/unit-unpaid-invoices.component';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
  declarations: [
    MangeUnitsComponent,
    UnitDetailsComponent,
    CompleteUnitDetailsComponent,
    AddClientComponent,
    AddUnitComponent,
    EditUnitDetailsComponent,
    AddquotaComponent,
    UnitBlockComponent,
    AddNoteComponent,
    ImportClientComponent,
    InvtaionComponent,
    AddCardComponent,
    EditClientComponent,
    ViewRfidComponent,
    DeletRfidComponent,
    EditRFIDComponent,
    ViewClientComponent,
    QrInvtationComponent,
    LogsClientComponent,
    UnitInvoiceComponent,
    QrCodesComponent,
    GoogleMapComponent,
    RegisterAccessComponent,
    PayInvoiceComponent,
    UnpaidInvoicesComponent,
    UnitRentalsComponent,
    UnpaidInvoicePrintAllComponent,
    UnitUnpaidInvoicesComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    CdkScrollable,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    NgxPrintModule,
    NgxQRCodeModule,
    AvatarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    IntlInputPhoneModule,
    NgxIntlTelInputModule,
    MatExpansionModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
      // libraries: ['places'],
      // language: 'ar',
    }),
  ],
  exports: [GoogleMapComponent],
})
export class UnitsModule {}
