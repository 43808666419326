import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditClientComponent } from '../edit-client/edit-client.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-rfid',
  templateUrl: './view-rfid.component.html',
  styleUrls: ['./view-rfid.component.css'],
})
export class ViewRfidComponent implements OnInit {
  VehicleNumber: any;
  Card_ID: any;
  serialNum: any;
  frontImage: any;
  backImage: any;
  constructor(
    public dialogRef: MatDialogRef<EditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.VehicleNumber = this.data.item.license_plate;
    this.Card_ID = this.data.item.card_id;
    this.serialNum = this.data.item.serial_number;
    this.frontImage = this.data.item.front;
    this.frontImage = this._sanitizer.bypassSecurityTrustUrl(
      `data:image/png;base64,` + this.frontImage
    );
    this.backImage = this.data.item.back;
    this.backImage = this._sanitizer.bypassSecurityTrustUrl(
      `data:image/png;base64,` + this.backImage
    );
  }
}
