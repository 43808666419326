<section class="main">
  <div class="heading d-flex justify-content-between align-items-center">
    <h1>Roles</h1>
    <div>
      <button class="btn" (click)="addRole()">Add new role</button>
    </div>
  </div>

  <!-- roles -->

  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section
    *ngIf="!showSpinner"
    class="mt-5 d-flex align-items-center roleSection"
  >
    <div *ngFor="let item of role_arr">
      <div class="roleItem d-flex align-items-center">
        <p>{{ item.name }}</p>
        <img (click)="editRole(item)" src="assets/images/edit1.png" />
      </div>
    </div>
  </section>
</section>
