<section class="main">
  <h3>Add new Village</h3>

  <label>Select Project</label>
  <!-- <div class="dropdown mb-0">
    <button
      class="btn dropdown-toggle"
      type="button"
      id="dropdownMenuButton2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ ProjectName }}
      <img src="assets/images/mangeunit/arrow.png" />
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
      <li *ngFor="let item of project_arr">
        <a class="dropdown-item" (click)="getProjectData(item)">
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
  <span *ngIf="statusProject" class="errorMessage">Project is required</span> -->

  <div class="col-md-12">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Select Project</mat-label>
      <mat-select
        fullWidth
        placeholder="Select Project"
        class="d-felx justify-content-center align-items-center"
      >
        <mat-option
          (click)="getProjectData(item)"
          *ngFor="let item of project_arr"
          [value]="item?.id"
        >
          <strong>
            {{ item?.name | titlecase }}
          </strong>
        </mat-option>
      </mat-select>
      <span *ngIf="statusProject" class="errorMessage"
        >Project is required</span
      >
    </mat-form-field>
  </div>

  <form (ngSubmit)="f1.form.valid && onSubmit(f1)" #f1="ngForm" novalidate>
    <div class="row">
      <!-- <div class="col">
        <div class="form-group">
          <label>Village name</label>
          <input
            required
            name="name"
            [(ngModel)]="villageName"
            placeholder="EX. Home"
            #nameVillage="ngModel"
            type="text"
            class="form-control"
          />

          <div
            *ngIf="nameVillage.errors && (nameVillage.touched || f1.submitted)"
          >
            <div class="errorMessage" *ngIf="nameVillage.errors?.['required']">
              <span class="errorMessage">Village name is required</span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-md-12 mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Village name</mat-label>
          <input
            #nameVillage="ngModel"
            [(ngModel)]="villageName"
            matInput
            type="text"
            required
            name="name"
            class="form-control"
          />
        </mat-form-field>

        <div
          *ngIf="nameVillage.errors && (nameVillage.touched || f1.submitted)"
        >
          <div class="errorMessage" *ngIf="nameVillage.errors?.['required']">
            <span class="errorMessage">Village name is required</span>
          </div>
        </div>
      </div>
    </div>

    <div class="addUnits d-flex justify-content-end">
      <div>
        <button mat-dialog-close class="btn me-2">Cancel</button>
        <button class="btn addUnit">Add Village</button>
      </div>
    </div>
  </form>
</section>
