import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { io } from 'socket.io-client';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environments';

@Component({
  selector: 'app-live-gate-type',
  templateUrl: './live-gate-type.component.html',
  styleUrls: ['./live-gate-type.component.css'],
})
export class LiveGateTypeComponent implements OnInit {
  socketInit: any;
  selectedGate: any = [];
  gats_arr: any;
  view: any = [1000, 200];
  viewTwo: any = [1000, 300];
  statisticsLiveGates: any = [
    {
      name: 'Owners',
      value: 0,
    },
    {
      name: 'Guest',
      value: 0,
    },
    {
      name: 'Tenant',
      value: 0,
    },
    {
      name: 'Household',
      value: 0,
    },
    {
      name: 'Staff',
      value: 0,
    },
    {
      name: 'VIP',
      value: 0,
    },
  ];
  colorScheme: any = {
    domain: ['#3734E5', '#82BD69', '#a11726', '#17a19f', '#F9BD3B', '#c70c6f'],
  };
  isDoughnut = true;
  constructor(private rest: MainService, private route: Router) {}
  socketConfig: any;
  ngOnInit() {
    this.socketConfig = io(`${environment.baseSocket}`);
    this.socketConfig.on('gate_type_access', (data: any) => {
      this.selectedGate.forEach((element: any) => {
        if (element.id == data.gate_id) {
          this.liveData(data, element);
          localStorage.setItem(
            'DataOfGateType',
            JSON.stringify(this.selectedGate)
          );
        }
      });
    });
    this.allGats();
  }

  onActivate(event: any, item: any): void {
    item.forEach((element: any) => {
      if (element.name == event.value.name) {
        element.status = !element.status;
      } else {
        element.status = false;
      }
    });
  }
  onDeactivate(event: any, item: any): void {
    item.forEach((element: any) => {
      if (element.name == event.value.name) {
        element.status = !element.status;
      } else {
        element.status = false;
      }
    });
  }

  allGats() {
    if (localStorage.getItem('DataOfGateType')) {
      this.selectedGate = [];
      this.selectedGate = localStorage.getItem('DataOfGateType');
      this.selectedGate = JSON.parse(this.selectedGate);
      this.selectedGate.forEach((element: any) => {
        this.connectWithSocket(element);
      });
    } else {
      this.rest.gateByType().subscribe((res: any) => {
        res.children.forEach((element: any) => {
          element.statisticsLiveGates = this.statisticsLiveGates;
          element.show = 1;
          this.selectedGate.push(element);
          this.connectWithSocket(element);
        });
      });
    }
  }

  gateChange(event: any) {
    if (event.isUserInput) {
      if (event.source.selected == true) {
        this.connectWithSocket(event.source.value);
      }
    }
  }

  arrayOfChoices: any = [];
  elementStatus = false;
  getChart(event: any, item: any) {
    item.forEach((element: any) => {
      if (element.name == event.name) {
        element.status = !element.status;
      } else {
        element.status = false;
      }
    });
  }

  connectWithSocket(Element?: any) {
    this.socketConfig.emit('type_state', Element.id);
  }

  liveData(data: any, element: any) {
    element.statisticsLiveGates = [];
    element.show = 0;
    let unitRelation = data['Unit Relations'];
    let staffRelation = data.Staff;
    unitRelation.forEach((el: any) => {
      if (el.Guest) {
        this.guestCalc(el.Guest, element);
      } else if (el.Owner) {
        this.ownersCalc(el.Owner, element);
      } else if (el.Tenant) {
        this.tenantCalc(el.Tenant, element);
      } else if (el['Main Owner']) {
        this.MainOwnerCalc(el['Main Owner'], element);
      } else if (el.Household) {
        this.houseHoldCalc(el.Household, element);
      }
    });
    this.vipCalc(data.VIP, element);
    this.staffCalc(staffRelation, element);
  }

  // main owners statistics
  total_main_owner = 0;
  MainOwnerCalc(data: any, element: any) {
    let result: any = [];
    let totalOwners: any = 0;
    data?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      totalOwners = totalOwners + Object.values(el)[0];
      result.push(element);
    });
    this.total_main_owner = totalOwners;
    // element.statisticsLiveGates.unshift({
    //   name: 'Main Owner',
    //   value: totalOwners,
    //   option: result,
    //   status: false
    // });
  }

  // owners statistics
  ownersCalc(data: any, element: any) {
    let result: any = [];
    let totalOwners: any = 0;
    data?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      totalOwners = totalOwners + Object.values(el)[0];
      result.push(element);
    });
    element.statisticsLiveGates.unshift({
      name: 'Owner',
      value: totalOwners + this.total_main_owner,
      option: result,
      status: false,
    });
  }

  // guest statistics

  guestCalc(data: any, element: any) {
    let result: any = [];
    let totalGuest: any = 0;
    data?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      totalGuest = totalGuest + Object.values(el)[0];
      result.push(element);
    });

    element.statisticsLiveGates.push({
      name: 'Guest',
      value: totalGuest,
      option: result,
      status: false,
    });
    // element.statisticsLiveGates.push(

    // )
  }

  // tenant statistics
  tenantCalc(data: any, element: any) {
    let result: any = [];
    let totalTenant: any = 0;
    data?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      totalTenant = totalTenant + Object.values(el)[0];
      result.push(element);
    });

    element.statisticsLiveGates.push({
      name: 'Tenant',
      value: totalTenant,
      option: result,
      status: false,
    });
  }

  //house statistics
  houseHoldCalc(data: any, element: any) {
    let result: any = [];
    let totalHousehold: any = 0;
    data?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      totalHousehold = totalHousehold + Object.values(el)[0];
      result.push(element);
    });

    element.statisticsLiveGates.push({
      name: 'Household',
      value: totalHousehold,
      option: result,
      status: false,
    });
  }

  // staff statistics
  staffCalc(data: any, element: any) {
    let result: any = [];
    let totalStaff: any = 0;

    data.forEach((element: any) => {
      // let element
      element[Object.keys(element)[0]]?.map((el: any) => {
        // element = { "key": Object.keys(el)[0], "value": Object.values(el)[0] }
        totalStaff = totalStaff + Object.values(el)[0];
      });
      // result.push(element)
    });

    let keyName = Object.keys(data[0])[0];
    data[0][keyName]?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      result.push(element);
    });

    element.statisticsLiveGates.push({
      name: 'Staff',
      value: totalStaff,
      option: result,
      status: false,
    });
  }

  vipCalc(data: any, element: any) {
    let result: any = [];
    let totalVip: any = 0;

    data.forEach((element: any) => {
      element[Object.keys(element)[0]]?.map((el: any) => {
        let element;
        // element = { "key": Object.keys(el)[0], "value": Object.values(el)[0] }
        totalVip = totalVip + Object.values(el)[0];
        // result.push(element)
      });
    });
    let keyName = Object.keys(data[0])[0];
    data[0][keyName]?.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      result.push(element);
    });

    element.statisticsLiveGates.push({
      name: 'VIP',
      value: totalVip,
      option: result,
      status: false,
    });
  }

  moreGate(id: any) {
    this.route.navigate(['pages/gate_types', id]);
  }
}
