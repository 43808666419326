import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { PromoCodeListComponent } from '../promo-code-list/promo-code-list.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-promo-code',
  templateUrl: './add-promo-code.component.html',
  styleUrls: ['./add-promo-code.component.css'],
})
export class AddPromoCodeComponent implements OnInit {
  addNewPromoCodeForm: FormGroup | any;

  constructor(
    public dialogRef: MatDialogRef<PromoCodeListComponent>,
    private readonly promoCodeService: PromoCodeService,
    private main: MainService
  ) {}

  ngOnInit() {
    this.getAddPromoCodeForm();
  }

  getAddPromoCodeForm() {
    this.addNewPromoCodeForm = new FormGroup({
      promo_code: new FormControl(null, [Validators.required]),
      percentage: new FormControl(null, [Validators.required]),
      limit: new FormControl(null, [Validators.required]),
    });
  }

  addPromoCode() {
    if (this.addNewPromoCodeForm.invalid) {
      this.addNewPromoCodeForm.markAllAsTouched();
      return;
    }

    const { promo_code, percentage, limit } = this.addNewPromoCodeForm.value;

    const promoCode = {
      promo_code,
      percentage,
      limit,
    };

    this.promoCodeService.addPromoCodeService(promoCode).subscribe(
      (res: any) => {
        this.main.SuccessToast('Promo code added successfully');
        this.dialogRef.close(res);
      },
      (err: any) => {
        this.main.errorToast('Something wrong');
      }
    );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addNewPromoCodeForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
}
