<!-- <section class="main">
  <h2>Unpaid Invoices</h2>
  <small>You should pay the invoice to proceed the printing</small>
  <hr />

  <div>
    <section class="tableUnit table-responsive mt-3">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Invoice type</th>
            <th scope="col">Paid by</th>
            <th scope="col">Issued by</th>
            <th scope="col">Amount</th>
            <th scope="col">Payment type</th>
            <th scope="col">Created at</th>
            <th scope="col">Updated on</th>
            <th scope="col">Payment status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of unPaidInvoices; let i = index">
            <td>
              {{ item?.invoce_type?.name | titlecase }}
            </td>
            <td>
              {{ item?.payment_user?.name | titlecase }}
            </td>
            <td>
              {{ item?.user?.name | titlecase }}
            </td>
            <td>
              {{ item?.ammount }} <strong *ngIf="item?.ammount">LE</strong>
            </td>
            <td>
              {{ item?.payment_type?.name | titlecase }}
            </td>
            <td>
              {{ item?.created_at }}
            </td>
            <td>
              {{ item?.updated_on }}
            </td>

            <td>
              <button
                [disabled]="!checkForPayInvoicePermission()"
                *ngIf="!item?.paid"
                class="btn"
                (click)="payInvoice(item?.id)"
              >
                Pay Invoice
              </button>

              <span *ngIf="item?.paid">The invoice is already paid</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</section> -->

<section class="main">
  <h2 class="m-0">Unpaid Invoices</h2>
  <small>You should pay the invoice to proceed the printing</small>
  <hr />

  <div>
    <section class="tableUnit table-responsive mt-3">
      <ul>
        <li>
          <strong>Purchase Name :</strong>
          {{ upPaidInvoice?.purchase_name | titlecase }}
        </li>
        <li *ngIf="upPaidInvoice?.price">
          <strong>Price :</strong>
          {{ upPaidInvoice?.price }} LE
        </li>
        <li>
          <strong>Total amount :</strong>
          <strong
            [ngClass]="promoCodeVerifiedSuccessfully ? 'promoCodeHint' : ''"
          >
            {{ upPaidInvoice?.ammount }} LE
          </strong>
        </li>
        <li *ngIf="upPaidInvoice?.payment_user">
          <strong>Paid by :</strong>
          {{ upPaidInvoice?.payment_user?.name | titlecase }}
        </li>
        <li>
          <strong>Issued by :</strong>
          {{ upPaidInvoice?.user.name | titlecase }}
        </li>
        <li><strong>Created at :</strong> {{ upPaidInvoice?.created_at }}</li>
        <li><strong>Updated at :</strong> {{ upPaidInvoice?.updated_on }}</li>
      </ul>

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Amount</th>
            <th scope="col">Price</th>
            <th scope="col">Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of upPaidInvoice.items; let i = index">
            <td>
              {{ item?.name | titlecase }}
            </td>
            <td>
              {{ item?.amount }}
            </td>
            <td>{{ item?.price }} LE</td>
            <td>{{ item?.total_price }} LE</td>
          </tr>
        </tbody>
      </table>

      <mat-form-field
        appearance="outline"
        class="w-100"
        *ngIf="!promoCodeVerifiedSuccessfully"
      >
        <mat-label>Promo code</mat-label>
        <input matInput type="text" [(ngModel)]="promoCode" />
      </mat-form-field>

      <button
        [disabled]="promoCode == undefined || promoCode === ''"
        class="btn verifyButton"
        (click)="verifyPromoCode()"
        *ngIf="!promoCodeVerifiedSuccessfully"
      >
        Verify
      </button>
      <hr *ngIf="!promoCodeVerifiedSuccessfully" />

      <strong *ngIf="promoCodeVerifiedSuccessfully" class="promoCodeHint"
        >Promo code verified</strong
      >

      <div
        class="d-flex justify-content-center"
        [ngClass]="promoCodeVerifiedSuccessfully ? 'mt-2' : ''"
      >
        <button
          [disabled]="!checkForPayInvoicePermission()"
          *ngIf="!upPaidInvoice?.paid"
          class="btn d-flex me-2"
          (click)="payInvoice(upPaidInvoice.id)"
        >
          Pay Invoice
        </button>

        <button
          class="btn"
          mat-dialog-close
          style="background-color: rgb(209, 209, 209) !important"
        >
          Cancel
        </button>
      </div>
    </section>
  </div>
</section>
