<section class="main">
  <div
    class="heading d-flex justify-content-between align-items-center flex-wrap"
  >
    <h1>General access</h1>
    <div>
      <button class="btn" (click)="addCategory()">Create new category</button>
      <button class="btn add" (click)="addMember()">Add new member</button>
    </div>
  </div>
  <!-- <div class="edit_view mb-4 mt-2">
    <button class="btn activeBtn">View/Edit</button>
  </div> -->

  <!-- <div class="d-flex justify-content-between flex-wrap mb-4 mt-2">
    <div class="d-flex align-items-center flex-wrap">
      <div class="me-2 filtersInputs">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search by client</mat-label>
          <input (input)="searchClients($event)" matInput type="text" />
        </mat-form-field>
      </div>

      <div class="filtersInputs">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>All Categories</mat-label>
          <mat-select
            fullWidth
            placeholder="Select category"
            class="d-felx justify-content-center align-items-center"
          >
            <mat-option
              *ngFor="let item of global_category"
              [value]="item"
              (click)="filter_staff(item)"
            >
              <strong>
                {{ item | titlecase }}
              </strong>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="filtersInputs">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Staff</mat-label>
        <mat-select
          fullWidth
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option value="staff" (click)="getParent('Staff')">
            <strong>Staff</strong>
          </mat-option>

          <mat-option value="vip" (click)="getParent('VIP')">
            <strong>VIP</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->

  <!-- NEW WORK -->
  <div class="d-flex justify-content-between flex-wrap mb-4 mt-2">
    <div class="filtersInputs">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Search by client</mat-label>
        <input (input)="searchClients($event)" matInput type="text" />
      </mat-form-field>
    </div>

    <div class="d-flex align-items-center flex-wrap">
      <div class="me-2 filtersInputs">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Choose User Type</mat-label>
          <mat-select>
            <mat-option
              *ngFor="let userType of userTypesArray"
              [value]="userType.id"
              (click)="selectUserType(userType)"
            >
              <strong>{{ userType.name | titlecase }}</strong>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filtersInputs" [formGroup]="categoryForm">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Choose Category</mat-label>
          <mat-select formControlName="category">
            <mat-option
              *ngFor="let item of types_arr"
              [value]="item.id"
              (click)="filterByCategory(item)"
            >
              <strong>{{ item.name | titlecase }}</strong>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button
        *ngIf="showResetFilterButton"
        class="btn resetButton ms-2"
        (click)="resetFilter()"
      >
        Reset Filter
      </button>
    </div>
  </div>
  <!-- NEW WORK -->

  <div id="cardPrint">
    <div class="cards print" *ngFor="let item of dataPrint">
      <div class="front">
        <div class="background-image">
          <img [src]="item.frontImage" />
        </div>

        <ul class="clientData">
          <li>
            <p style="margin: 0; padding: 0" class="clientName">
              {{ item.name | uppercase }}
            </p>
          </li>
          <li>
            <p style="margin: 0; padding: 0" *ngIf="item.unitName != ''">
              <strong>Unit:</strong> {{ item.unitName }}
            </p>
          </li>
          <li>
            <p style="margin: 0; padding: 0" *ngIf="item.villageName != ''">
              <strong>Zone:</strong> {{ item.villageName }}
            </p>
          </li>
        </ul>

        <div class="centered-image">
          <img class="clientImage" [src]="item.image" alt="Avatar" />
          <!-- <div class="containerr">
            <p class="clientName">{{ item.name | uppercase }}</p>
            <p *ngIf="item.unitName != ''">
              <strong>Unit:</strong> {{ item.unitName }}
            </p>
            <p *ngIf="item.villageName != ''">
              <strong>Zone:</strong> {{ item.villageName }}
            </p>
          </div> -->
        </div>
      </div>
      <div class="back">
        <div>
          <img [src]="item.image1" />
        </div>
      </div>
    </div>
  </div>

  <button
    id="printButton"
    class="cardPrint"
    [useExistingCss]="true"
    printSectionId="cardPrint"
    ngxPrint
  >
    print
  </button>

  <!-- cards -->
  <section class="cards_content">
    <mat-spinner diameter="50" *ngIf="showSpinner" class="mb-4"></mat-spinner>
    <div class="row" *ngIf="!showSpinner">
      <div class="col-md-4 col-12" *ngFor="let item of global_array">
        <div
          class="clientsCard"
          [ngClass]="{
            clientsCardWhenBlock: item.cancelled === true
          }"
        >
          <div class="row info">
            <div class="col-4">
              <ngx-avatar
                size="80"
                name="{{ item?.name }}"
                cornerRadius="5"
                [round]="false"
              ></ngx-avatar>
            </div>
            <div class="col-7 p-0 ownerInfo">
              <p class="mb-2 text-truncate">
                <img
                  *ngIf="item.verified"
                  src="assets/images/mangeunit/circle_check.png"
                />
                {{ item.name }}
              </p>
              <div>
                <span class="hint" *ngIf="item.verified"> Authenticated </span>
                <span class="hint">
                  {{ item.type.name }}
                </span>
                <span class="hint" *ngIf="item.activated == true">
                  Active
                </span>
                <span class="hint" *ngIf="item.activated == false">
                  unActive
                </span>
              </div>
            </div>
            <div class="col-1 dots">
              <mat-menu #menu="matMenu">
                <p (click)="editClient(item.id)" class="menuItem" mat-menu-item>
                  Edit
                </p>
                <p
                  *ngIf="item.cancelled === false"
                  (click)="openBlockClient('block', item)"
                  class="menuItem"
                  mat-menu-item
                >
                  Block
                </p>
                <p
                  *ngIf="item.cancelled === true"
                  (click)="openBlockClient('unblock', item)"
                  class="menuItem"
                  mat-menu-item
                >
                  Active
                </p>
                <p
                  (click)="openDeleteClient(item)"
                  class="menuItem"
                  mat-menu-item
                >
                  Delete
                </p>
              </mat-menu>
              <img
                [matMenuTriggerFor]="menu"
                src="assets/images/mangeunit/dots.png"
              />
            </div>
          </div>

          <div
            class="mt-4 icons d-flex align-items-center justify-content-between"
          >
            <!-- <img  matTooltip="Invitations " matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'" src="assets/images/mangeunit/Group 162.png" /> -->

            <img
              mat-raised-button
              matTooltip="Gate access"
              (click)="blockUnitGates(item.id)"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              class="icon2"
              src="assets/images/mangeunit/Group 136.png"
            />

            <img
              mat-raised-button
              matTooltip="Activate"
              (click)="addCard(item)"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              class="icon3"
              src="assets/images/mangeunit/Group 160.png"
            />

            <div class="notes d-flex">
              <p class="me-2" *ngIf="item.notes.length != 0">
                <span>{{ item.notes.length }}</span>
              </p>
              <img
                mat-raised-button
                matTooltip="Add/Show Note"
                (click)="addNote('client', item.id)"
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                src="assets/images/mangeunit/Group 170.png"
              />
            </div>

            <img
              mat-raised-button
              matTooltip="Print"
              (click)="print_card(item)"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              src="assets/images/mangeunit/5.png"
            />
          </div>
        </div>
      </div>
    </div>

    <p *ngIf="message_display" class="d-flex justify-content-center">
      <strong>No data Found</strong>
    </p>
  </section>

  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
    >
    </mat-paginator>
  </div>
</section>

<!-- model blocked -->

<button
  id="btn4"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal4"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal4"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">
          {{ blockClientTitle }} client
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to {{ blockClientTitle }} the client?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="blockClient()"
          data-bs-dismiss="modal"
        >
          {{ blockClientTitle }} Client
        </button>
      </div>
    </div>
  </div>
</div>

<!-- delete model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete client</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">Are you sure you want to delete the client?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteClient()"
          data-bs-dismiss="modal"
        >
          Delete client
        </button>
      </div>
    </div>
  </div>
</div>
