<section class="main">
  <h3 class="d-flex justify-content-center">Create new category</h3>

  <!-- <label>Select type</label>
  <div class="dropdown mb-0">
    <button
      class="btn dropdown-toggle"
      type="button"
      id="dropdownMenuButton2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ typename }}
      <img src="assets/images/mangeunit/arrow.png" />
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
      <li *ngFor="let item of typeArray">
        <a class="dropdown-item" (click)="getTypeInfo(item)">
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div> -->

  <div class="row mt-4">
    <div class="col-md-12 mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select Type"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            (click)="getTypeInfo(item)"
            *ngFor="let item of typeArray"
            [value]="item?.id"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>

          <mat-option [disabled]="true" *ngIf="!typeArray?.length">
            <strong>No villages found in this project</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- <span *ngIf="statusProject" class="errorMessage">Project is required</span>   -->

  <form>
    <!-- <div class="row mt-3">
      <div class="col">
        <div class="form-group">
          <label>Category name</label>
          <input
            required
            name="name"
            [ngClass]="{ 'is-invalid': submitted && categoryName == '' }"
            [(ngModel)]="categoryName"
            placeholder="EX. Home"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-12 mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Category name</mat-label>
          <input
            matInput
            required
            name="name"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && categoryName == '' }"
            placeholder="EX. Home"
            [(ngModel)]="categoryName"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="addUnits d-flex justify-content-end">
      <div>
        <button mat-dialog-close class="btn me-2">Cancel</button>
        <button (click)="submit()" class="btn addUnit">Add category</button>
      </div>
    </div>
  </form>
</section>
