<section class="main">
    <div class="">
        <h3>
            Manage clients
        </h3>

        <div class="filter d-flex justify-content-between align-items-center">
            <div class="inputSearch d-flex justify-content-between align-items-center">
                <input type="search" class="form-control" placeholder="Search clients">
                <a>Clients log</a>
            </div>
            <div>
               <button class="btn filterBtn">
                    Filters
               </button>
            </div>
        </div>

        <div class="d-flex align-items-center mt-3">
            <div class="dropDowen">
                <div class="dropdown mb-0 ">
                    <button class="btn  dropdown-toggle text-truncate" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        All Clients
                        <img src="assets/images/mangeunit/arrow.png">
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a class="dropdown-item" style="cursor: pointer">one</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="dropDowen">
                    <div class="dropdown mb-0 ">
                        <button class="btn  dropdown-toggle text-truncate" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            All Clients
                            <img src="assets/images/mangeunit/arrow.png">
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a class="dropdown-item" style="cursor: pointer">one</a>
                            </li>
                        </ul>
                    </div>
                </div>
        </div>

        <!-- table -->

        <section class=" mt-5 tableUnit table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Client name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Other info</th>
                        <th scope="col" class="text-end">Quick actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class=" d-flex align-items-center">
                            <label style="cursor: pointer">AHMED</label>
                        </td>
                        <td>
                            <span>Owner</span>
                            <span>Tenant</span>
                        </td>
                        <td>
                            <span>Authenticated</span>
                            <span>Active</span>
                        </td>
                        <td class="text-end icons">
                            <img src="assets/images/mangeunit/8.png">
                            <img src="assets/images/mangeunit/Group 136.png">
                            <img src="assets/images/mangeunit/Group 160.png">
                            <img src="assets/images/mangeunit/6.png">
                            <img src="assets/images/mangeunit/7.png">
                            <img class="dots" [matMenuTriggerFor]="menu" src="assets/images/mangeunit/dots.png">
                            <mat-menu #menu="matMenu">
                                <p class="menuItem" mat-menu-item>Edit</p>
                                <p class="menuItem" mat-menu-item>Merge</p>
                                <p class="menuItem" mat-menu-item>Block</p>
                                <p class="menuItem" mat-menu-item>Delete</p>
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>
</section>