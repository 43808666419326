import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsService {
  constructor(private http: HttpClient) {}

  retrieveConfigurations(page: number, limit: number, projectId?: any) {
    if (projectId == undefined || !projectId) {
      return this.http.get(
        `${environment.baseUrl}/images/project_all_cards/?page=${page}&limit=${limit}`
      );
    } else {
      return this.http.get(
        `${environment.baseUrl}/images/project_all_cards/?page=${page}&limit=${limit}&project_id=${projectId}`
      );
    }
  }

  addConfiguration(configurations: any) {
    return this.http.post(
      `${environment.baseUrl}/images/cards/`,
      configurations
    );
  }

  editConfiguration(configurations: any) {
    return this.http.put(
      `${environment.baseUrl}/images/cards/`,
      configurations
    );
  }

  deleteConfiguration(configurationId: any) {
    return this.http.delete(
      `${environment.baseUrl}/images/cards/?id=${configurationId}`
    );
  }

  //USER ACCESS CONFIGURATION

  getAllUsersAccessConfiguration(
    page: number,
    limit: number,
    userTypeId?: any,
    accessTypeId?: any
  ) {
    let url = `${environment.baseUrl}/cards/configuration?page=${page}&limit=${limit}`;

    if (userTypeId !== undefined && userTypeId) {
      url += `&user_type_id=${userTypeId}`;
    }

    if (accessTypeId !== undefined && accessTypeId) {
      url += `&access_type_id=${accessTypeId}`;
    }

    return this.http.get(url);
  }

  addUserAccessConfiguration(userAccessConfigurationData: any) {
    return this.http.post(
      `${environment.baseUrl}/cards/configuration`,
      userAccessConfigurationData
    );
  }

  editUserAccessConfiguration(userAccessConfigurationData: any) {
    return this.http.put(
      `${environment.baseUrl}/cards/configuration`,
      userAccessConfigurationData
    );
  }

  deleteUserAccessConfiguration(userAccessConfigurationId: any) {
    return this.http.delete(
      `${environment.baseUrl}/cards/configuration?id=${userAccessConfigurationId}`
    );
  }
}
