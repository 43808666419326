<section class="main m-4 mb-1">
  <h3 style="text-align: center" class="mb-4">Pay Invoice</h3>

  <div *ngIf="showPromoCodeInput">
    <strong
      >Total amount :
      <span [ngClass]="promoCodeVerifiedSuccessfully ? 'promoCodeHint' : ''"
        >{{ invoiceAmount }} LE</span
      >
    </strong>

    <div class="row mt-2">
      <mat-form-field
        appearance="outline"
        class="col-md-8"
        *ngIf="!promoCodeVerifiedSuccessfully"
      >
        <mat-label>Promo code</mat-label>
        <input matInput type="text" [(ngModel)]="promoCode" />
      </mat-form-field>
      <button
        [disabled]="promoCode == undefined || promoCode === ''"
        class="btn pay verifyButton col-md-4"
        (click)="verifyPromoCode()"
        *ngIf="!promoCodeVerifiedSuccessfully"
      >
        Verify
      </button>
    </div>

    <strong *ngIf="promoCodeVerifiedSuccessfully" class="promoCodeHint"
      >Promo code verified</strong
    >
  </div>

  <div
    class="row"
    [formGroup]="payInvoiceForm"
    [ngClass]="promoCodeVerifiedSuccessfully ? 'mt-2' : ''"
  >
    <div class="col-md-12 mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Payment type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select payment type"
          formControlName="paymentType"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option *ngFor="let item of paymentTypes" [value]="item?.id">
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>

          <mat-option [disabled]="true" *ngIf="!paymentTypes?.length">
            <strong>No payment types found</strong>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="isControlHasError('paymentType', 'required')">
          Payment type is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="addUnits d-flex justify-content-center">
    <div>
      <button
        class="btn pay"
        (click)="pay()"
        [disabled]="payInvoiceForm.invalid"
      >
        Pay Invoice
      </button>
    </div>
  </div>
</section>
