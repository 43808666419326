import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditClientComponent } from '../edit-client/edit-client.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-rfid',
  templateUrl: './edit-rfid.component.html',
  styleUrls: ['./edit-rfid.component.css'],
})
export class EditRFIDComponent implements OnInit {
  VehicleNumber: any;
  Card_ID: any;
  serialNum: any;
  frontImage: any;
  backImage: any;
  newFront: any = '';
  newBack: any = '';
  constructor(
    public dialogRef: MatDialogRef<EditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.VehicleNumber = this.data.item.license_plate;
    this.Card_ID = this.data.item.card_id;
    this.serialNum = this.data.item.serial_number;
    this.frontImage = this.data.item.front;
    this.frontImage = this._sanitizer.bypassSecurityTrustUrl(
      `data:image/png;base64,` + this.frontImage
    );
    this.backImage = this.data.item.back;
    this.backImage = this._sanitizer.bypassSecurityTrustUrl(
      `data:image/png;base64,` + this.backImage
    );
  }

  onFileChange(e: any, name: any) {
    const file = e.target.files[0];
    if (name == 'front') {
      this.newFront = file;
    } else {
      this.newBack = file;
    }
  }

  editRFId() {
    let data: any = {
      card_id: this.Card_ID,
      id: this.data.item.id,
      license_plate: this.VehicleNumber,
      type_id: this.data.item.type_id,
      serial_number: this.serialNum,
      client_id: this.data.item.client_id,
      unit_id: this.data.item.unit_id,
    };

    if (this.newFront != '') {
      data.front = this.newFront;
    }
    if (this.newBack != '') {
      data.back = this.newBack;
    }


    this.dialogRef.close(data);
  }
}
