import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OperatorsMainComponent } from '../opreators-main/opreators-main.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  oldPw = '';
  newPw = '';
  constructor(public dialogRef: MatDialogRef<OperatorsMainComponent>) {}

  ngOnInit() {}

  changePw() {
    let change_pw_obj = {
      new_password: this.newPw,
      old_password: this.oldPw,
    };

    this.dialogRef.close(change_pw_obj);
  }
}
