<section class="main">
  <h3>Add new role</h3>

  <form>
    <div class="row mt-3">
      <div class="col">
        <div class="form-group">
          <label>Role name</label>
          <input
            required
            name="name"
            [ngClass]="{ 'is-invalid': submitted && RoleName == '' }"
            [(ngModel)]="RoleName"
            placeholder="EX. Home"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="mt-3">
      <p class="mb-0">Permissions</p>
      <mat-form-field>
        <mat-label>Permissions</mat-label>
        <mat-select multiple [(ngModel)]="permissions_arr" name="name">
          <mat-option *ngFor="let item of all_permissions" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <label class="mt-3">Permissions</label>
        <div class="dropdown mb-0 ">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                aria-expanded="false">
                {{PermissionsName}}
                <img src="assets/images/mangeunit/arrow.png">
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <li *ngFor="let item of all_permissions">
                    <a class="dropdown-item" (click)="getPerItem(item)">
                        {{item.name}}
                    </a>
                </li>
            </ul>
        </div> -->
    <div class="perChoise mb-1" *ngIf="permissions_arr.length != 0">
      <span *ngFor="let item of permissions_arr">
        {{ item.name }}
      </span>
    </div>

    <div class="addUnits d-flex justify-content-end mt-5 mb-2">
      <div>
        <button class="btn me-2" mat-dialog-close>Cancel</button>
        <button class="btn addUnit" (click)="addRole()">Save</button>
      </div>
    </div>
  </form>
</section>
