<section class="main">
  <h2>Add Village Config</h2>
  <hr />

  <mat-spinner diameter="30" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <form *ngIf="!showSpinner" [formGroup]="villageForm">
    <mat-form-field appearance="outline">
      <mat-label>Choose Village</mat-label>
      <mat-select multiple formControlName="villages">
        <mat-option *ngFor="let item of villageList" [value]="item.id">
          {{ item?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Excluded Qr Types</mat-label>
      <mat-select multiple formControlName="excluded">
        <mat-option *ngFor="let item of qrTypesArray" [value]="item.name">
          {{ item?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <mat-slide-toggle formControlName="kids_access" [color]="color">
        Kids access
      </mat-slide-toggle>
    </div>

    <div class="mt-3">
      <mat-slide-toggle formControlName="rented_unit" [color]="color">
        Rented unit
      </mat-slide-toggle>
    </div>
  </form>

  <div class="footer mt-4" *ngIf="!showSpinner">
    <button
      class="btn addGate"
      [disabled]="villageForm?.invalid"
      (click)="addVillageConfig()"
    >
      Add Config
    </button>
    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
