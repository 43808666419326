import { Component, OnInit } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { MatDialog } from '@angular/material/dialog';
import { AddUnitComponent } from '../addunit/addunit.component';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { UnitBlockComponent } from '../unit-block/unit-block.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-mange-units',
  templateUrl: './mange-units.component.html',
  styleUrls: ['./mange-units.component.css'],
})
export class MangeUnitsComponent implements OnInit {
  showSpinner: boolean = true;
  unitName: any = '';
  units_arr: any = [];
  multiUnits = false;
  checkBoxStatus = false;
  typesArr: any = [];
  typeName = 'Choose Type';
  page = 1;
  pageIndex = 0;
  limit = 9;
  unitSearchKey: string | any = '';
  panelOpenState = false;

  totalCount = 0;
  constructor(
    private rest: UnitsService,
    private route: Router,
    private main: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.allUnits();
    this.getTypes();
  }

  allUnits() {
    this.rest
      .allUnitsPagination(this.page, this.limit, this.unitSearchKey)
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.units_arr = res?.results;
        this.totalCount = res?.total_count;
      });
  }

  // searchUnit() {
  //   if (this.unitName == '') {
  //     this.allUnits();
  //   } else {
  //     this.checkBoxStatus = false;
  //     this.typeName = 'Choose Type';
  //     this.rest.searchUnit(this.unitName).subscribe((res: any) => {
  //       this.units_arr = res;
  //     });
  //   }
  // }

  searchUnit(unitSearchEvent: any) {
    this.page = 1;
    this.limit = 9;
    this.unitSearchKey = unitSearchEvent?.target?.value;
    this.allUnits();
  }

  searchByType(type: any) {
    this.units_arr = [];
    this.typeName = type;
    this.rest.allUnits(this.unitName, type).subscribe((res: any) => {
      if (res != null) {
        res.forEach((element: any) => {
          this.units_arr.push({
            name: element,
            type: type,
          });
        });
      } else {
        this.units_arr = res;
      }
    });
  }

  unitDetails(name: string) {
    this.route.navigate(['pages/unit_details', name]);
  }

  getTypes() {
    this.rest.getTypes().subscribe((res: any) => {
      this.typesArr = res.children;
    });
  }

  add_unit() {
    const dialogRef = this.dialog.open(AddUnitComponent, {
      width: '90%',
      maxWidth: '450px',
    });

    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if (result) {
    //     this.rest.addUnit(result).subscribe(
    //       (res: any) => {
    //         this.main.SuccessToast('Unit Added Successfully');
    //       },
    //       (err: any) => {
    //         this.main.errorToast('Something wrong');
    //       }
    //     );
    //   }
    // });
  }

  blockUnitGates() {
    const dialogRef = this.dialog.open(UnitBlockComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let ids: any = [];
        this.unit_blocked.forEach((element: any) => {
          ids.push(element.id);
        });

        let data = {
          gates: result,
          units: ids,
        };

        this.rest.blockUnitForGate(data).subscribe((res: any) => {
          this.main.SuccessToast('units blocked');
          // this.searchUnit();
        });
      }
    });
  }

  unit_blocked: any = [];
  unit_blocked_btn_status = false;
  inputCheckBox(value: any, item: any, index: any) {
    this.unit_blocked_btn_status = true;
    if (this.unit_blocked.includes(item)) {
      this.unit_blocked.splice(index, 1);
    } else {
      this.unit_blocked.push(item);
    }
    if (this.unit_blocked.length != 0) {
      this.unit_blocked_btn_status = true;
    } else {
      this.unit_blocked_btn_status = false;
    }
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    // this.pageIndex = e?.pageIndex;
    this.page = e?.pageIndex + 1;
    this.limit = e?.pageSize;
    this.allUnits();
  }

  editUnit(unitDetails?: any) {
    this.route.navigate(['pages/edit-unit-details', unitDetails?.id]);
    localStorage.setItem('extId', unitDetails?.ext_id);
  }
}
