import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let authReq = request;
    if (authReq.url.includes('login')) {
    } else {
      authReq = this.addTokenHeader(authReq);
    }
    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error.status === 401) {
          // localStorage.clear()
          // location.reload()
        }
        return throwError(error);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>) {
    const token = localStorage.getItem('acc_token');
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
    return request.clone({ headers });
    // return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }
}
