<section class="main">
    <div>
        <label>Old Password</label>
        <input [(ngModel)]="oldPw"  type="text" class="form-control">
    </div>
    <div class="mt-3">
        <label>New Password</label>
        <input [(ngModel)]="newPw" type="text" class="form-control">
    </div>

    <button class="btn btn-primary mt-3" (click)="changePw()">Save</button>
</section>