<section class="main">
    <section class="sup_main">
        <h1 class="titleHead">{{service_obj.servName}}</h1>
        <hr>
        <div class="d-flex  align-items-center sumary">
            <img class="me-3" style="width:60px ; height: 60px;" src="{{service_obj.servImage}}">
            <div>
                <p class="mb-2 ms-1 title">{{service_obj.servName}}</p>
                <div class="d-flex align-items-center">
                    <span class="me-4 d-flex  align-items-center">
                        <img class="me-1" src="assets/images/serivces/profile.png">
                        <p class="client_name">{{service_obj.name }}</p>
                    </span>

                    <span class="me-4 d-flex  align-items-center">
                        <img class="me-1" src="assets/images/serivces/house.png">
                        <p class="client_name">{{service_obj.unit}}</p>
                    </span>

                    <!-- <span *ngIf="service_obj.status == 'Active'" class="statusActive d-flex  align-items-center">
                        <p>{{service_obj.status}}</p>
                    </span>
                    <span *ngIf="service_obj.status == 'Pending'" class="statusPending d-flex  align-items-center">
                        <p>{{service_obj.status}}</p>
                    </span> -->
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{service_obj.status}}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li (click)="changeStatus('Pending')"><a class="dropdown-item">Pending</a></li>
                            <li (click)="changeStatus('Active')"><a class="dropdown-item">Active</a></li>
                            <li (click)="changeStatus('Closed')"><a class="dropdown-item">Closed</a></li>
                            <li (click)="changeStatus('Complete')"><a class="dropdown-item">Complete</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-6 mb-2">
                <div class="form-group">
                    <label>Service name</label>
                    <input disabled value="{{service_obj.servName}}" class="form-control" type="text">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Service category</label>
                    <input disabled value="{{service_obj.servName}}" class="form-control" type="text">
                </div>
            </div>
            <div class="col-6 mb-2">
                <div class="form-group">
                    <label>Number of rooms</label>
                    <input disabled value="{{service_obj.quantity}}" class="form-control" type="text">
                </div>
            </div>
            <div class="col-6">
                <!-- <div class="form-group">
                    <label>Rating</label>
                    <input class="form-control" type="text">
                </div> -->
            </div>
            <div class="col-6 mb-2">
                <div class="form-group">
                    <label>Date</label>
                    <input disabled value="{{service_obj.date}}" class="form-control" type="date">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Time</label>
                    <input disabled value="{{service_obj.time}}" class="form-control" type="time">
                </div>
            </div>
            <div class="col-6 mb-2">
                <div class="form-group">
                    <label>Description</label>
                    <textarea disabled value="{{service_obj.description}}" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="mt-2" *ngIf="service_obj.images">
            <h2>Photo & Video</h2>
            <div class="row photos">
                <div class="col-2" *ngFor="let item of service_obj.images ">
                    <img src="{{item.image}}" class="img-fluid">
                </div>
            </div>
        </div>

        <div class="mt-2">
            <h2>Voice</h2>
            <audio controls>
                <source src="horse.ogg" type="audio/ogg">
                <source src="horse.mp3" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </div>
    </section>
</section>