import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients.service';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
})
export class UserInfoComponent implements OnInit {
  user: any;
  constructor(
    private clientService: ClientsService,
    private route: Router,
    private mainSer: MainService
  ) {}

  ngOnInit() {
    this.user = localStorage.getItem('user_family');
    this.user = JSON.parse(this.user);
  }

  activeClient() {
    this.clientService.verifyClient(this.user.id).subscribe((res: any) => {
      this.mainSer.SuccessToast('Client Verified');
      this.route.navigate(['pages/family_requests']);
    });
  }

  reject() {
    this.route.navigate(['pages/family_requests']);
  }
}
