<section class="main m-0 mb-1">
  <h3 style="text-align: center" class="mb-4">Edit Promo code</h3>

  <div class="row" [formGroup]="editNewPromoCodeForm">
    <div class="col-md-12 mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Promo code</mat-label>
        <input matInput type="text" formControlName="promo_code" />
        <!-- <mat-error *ngIf="isControlHasError('promo_code', 'required')">
          Promo code is required
        </mat-error> -->
      </mat-form-field>
    </div>

    <div class="col-md-12 mb-2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Percentage</mat-label>
        <input matInput type="number" formControlName="percentage" />
        <!-- <mat-error *ngIf="isControlHasError('percentage', 'required')">
          Percentage is required
        </mat-error> -->

        <mat-hint>This field is in percentage (%) </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Limit</mat-label>
        <input matInput type="number" formControlName="limit" />
        <!-- <mat-error *ngIf="isControlHasError('limit', 'required')">
          Limit is required
        </mat-error> -->
        <mat-hint>The limit is in Egyptian pound (LE)</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3">
    <button class="btn addPromoCode" (click)="editPromoCode()">Save</button>
  </div>
</section>
