import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainGatesComponent } from './main-gates/main-gates.component';
import { AddGateComponent } from './add-gate/add-gate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddVillageConfigComponent } from './add-village-config/add-village-config.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddUserConfigComponent } from './add-user-config/add-user-config.component';
import { AddQrConfigComponent } from './add-qr-config/add-qr-config.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddQrTypeComponent } from './add-qr-type/add-qr-type.component';
import { MatInputModule } from '@angular/material/input';
import { AddUserTypeComponent } from './add-user-type/add-user-type.component';
import { SpaceToUnderscorePipe } from 'src/app/shared/pipes/spaceToUnderscore.pipe';

@NgModule({
  declarations: [
    MainGatesComponent,
    AddGateComponent,
    AddVillageConfigComponent,
    AddUserConfigComponent,
    AddQrConfigComponent,
    AddQrTypeComponent,
    AddUserTypeComponent,
    SpaceToUnderscorePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
})
export class GatesModule {}
