<section class="main">
  <h2>Unit Unpaid Invoices</h2>
  <small>You should pay the invoice to proceed the printing</small>
  <hr />

  <div>
    <section class="tableUnit table-responsive mt-3">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Amount</th>
            <th scope="col">Created at</th>
            <th scope="col">Updated on</th>
            <th scope="col">Payment status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of unPaidOtherInvoices; let i = index">
            <td>
              {{ item?.purchase_name }}
            </td>
            <td>{{ item?.ammount }} <strong>LE</strong></td>
            <td>
              {{ item?.created_at }}
            </td>
            <td>
              {{ item?.updated_on }}
            </td>

            <td>
              <button
                [disabled]="!checkForPayInvoicePermission()"
                *ngIf="!item?.paid"
                class="btn"
                (click)="payInvoice(item)"
              >
                Pay Invoice
              </button>

              <span *ngIf="item?.paid">The invoice is already paid</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</section>
