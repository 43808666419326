import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';
@Injectable({
  providedIn: 'root',
})
export class OperatorsService {
  constructor(private http: HttpClient) {}

  retrieveOperators() {
    return this.http.get(`${environment.baseUrl}/auth/operators`);
  }

  addOperators(data: any) {
    return this.http.post(`${environment.baseUrl}/auth/register`, data);
  }

  editOperators(data: any) {
    return this.http.post(`${environment.baseUrl}/auth/edit`, data);
  }

  addRoleForUser(data: any) {
    return this.http.post(`${environment.baseUrl}/role/urv`, data);
  }
  addRoleForUserAndProject(data: any) {
    return this.http.post(`${environment.baseUrl}/role/padmin`, data);
  }

  retrieveLogs(data: any) {
    return this.http.post(`${environment.baseUrl}/cards/operators/`, data);
  }
  exportLogs(data: any) {
    return this.http.post(
      `${environment.baseUrl}/cards/operators_exports/`,
      data
    );
  }
}
