<!-- access log -->
<section class="main">
  <div
    class="mb-3 d-flex align-items-center justify-content-between bntFilters titles"
  >
    <h1>Operators Log</h1>
    <button class="btn btn1" (click)="exportData()">Export</button>
  </div>
  <section class="tableSection">
    <div class="filters">
      <form [formGroup]="filter_form">
        <div class="row">
          <!-- <div class="col-4">
            <label>Operator Name</label>
            <input
              formControlName="operator_name"
              type="text"
              class="form-control"
            />
          </div> -->

          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Operator name</mat-label>
              <input matInput type="text" formControlName="operator_name" />
            </mat-form-field>
          </div>

          <!--
          <div class="col-4">
            <label>Operator Email</label>
            <input formControlName="email" type="text" class="form-control" />
          </div> -->

          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Operator Email</mat-label>
              <input matInput type="text" formControlName="email" />
            </mat-form-field>
          </div>

          <!--
          <div class="col-4">
            <label>Unit Name</label>
            <input type="text" class="form-control" />
          </div> -->

          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Unit Name</mat-label>
              <input matInput type="text" />
            </mat-form-field>
          </div>

          <!--
          <div class="col-4 mt-2">
            <label>Client Name</label>
            <input type="text" class="form-control" />
          </div> -->

          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Client Name</mat-label>
              <input matInput type="text" />
            </mat-form-field>
          </div>

          <!-- <div class="col-12 col-md-4 mt-2">
            <label>From</label>
            <div class="d-flex align-items-center datePicker">
              <input
                formControlName="valid_from"
                class="form-control"
                placeholder="Valid Form"
                [matDatepicker]="pickerFrom"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="pickerFrom"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </div>
          </div> -->

          <div class="col-12 col-md-4">
            <div class="d-flex align-items-center datePicker">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>From</mat-label>
                <input
                  [formControlName]="validFromControlName"
                  matInput
                  [matDatepicker]="pickerFrom"
                />

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="pickerFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <!-- <div class="col-12 col-md-4 mt-2">
            <label>To</label>
            <div class="d-flex align-items-center datePicker">
              <input
                formControlName="valid_to"
                class="form-control"
                placeholder="Valid To"
                [matDatepicker]="pickerTo"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="pickerTo"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </div>
          </div> -->

          <div class="col-12 col-md-4">
            <div class="d-flex align-items-center datePicker">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>To</mat-label>
                <input
                  [formControlName]="validToControlName"
                  matInput
                  [matDatepicker]="pickerTo"
                />

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="pickerTo"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="mt-3 bntFilters">
        <button class="btn btn1" (click)="filterData()">Filter</button>
        <button class="btn btn2" (click)="resetForm()">Reset</button>
      </div>
    </div>

    <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

    <div *ngIf="!showSpinner" class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th *ngFor="let key of tableHeaderArray">
              {{ key | replaceUnderscore | titlecase }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of operators_logs">
            <td *ngFor="let key of tableHeaderArray">
              {{ item[key] }}
            </td>
          </tr>
        </tbody>
        <p *ngIf="operators_logs.length == 0">No Data Found</p>
      </table>
    </div>
  </section>
  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="pageNumber"
      [length]="pagesLength"
      [pageSize]="pageLimit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>
