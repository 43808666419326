import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { ClientsService } from 'src/app/services/clients.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewRfidComponent } from '../view-rfid/view-rfid.component';
@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.css'],
})
export class ViewClientComponent implements OnInit {
  validForm = true;
  unitId: any;
  clientId: any;
  clientForm: FormGroup | any;
  RfidForm: FormGroup | any;
  types_arr: any;
  typeName: any = 'Relation type';
  typeId: any = '';
  submitted = false;
  userPhotoPath: any = '';
  pipe = new DatePipe('en-US');
  userEditPhoto = false;
  url: any;
  documentImage: any = [];
  userDocEditPhoto = false;
  documentsFiles: any = [];
  array_unit: any = [];
  unitNameSearch = '';
  unitRelation = '';
  access_Type: any;
  card_id: any;
  unitSearch_arr: any = [];
  rfid_list: any = [];
  clients_image: any;
  constructor(
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    private route: Router,
    private clientService: ClientsService,
    private location: Location,
    private rest: UnitsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.unitId = this.activeRouter.snapshot.params['id'];
    this.clientId = this.activeRouter.snapshot.params['id1'];
    this.initForm();
    this.unitRelationType();
    this.retrieveClient();
    this.accessType();
  }

  retrieveClient() {
    this.rfid_list = [];
    this.clientService.retrieveClient(this.clientId).subscribe((res: any) => {
      res.cards.forEach((element: any) => {
        if (element.RFID) {
          this.rfid_list = element.RFID;
        }
      });
      this.clients_image = res.images;
      // this.clients_image.forEach((element:any) => {
      //   element.image = this._sanitizer.bypassSecurityTrustUrl(
      //     `data:image/png;base64,` + element.image
      //   );
      // });
      this.array_unit = res.unit;
      this.clientForm.patchValue(res);
    });
  }
  EditClient() {
    this.route.navigate(['pages/edit_client', this.unitId, this.clientId]);
  }
  get ControlForm() {
    return this.clientForm.controls;
  }

  initForm() {
    this.clientForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, Validators.required],
      valid_from: [{ value: '', disabled: true }, Validators.required],
      valid_to: [{ value: '', disabled: true }, Validators.required],
      email: [
        { value: '', disabled: true },
        [Validators.required, Validators.email],
      ],
      birth_date: [{ value: '', disabled: true }, Validators.required],
      gender: ['', Validators.required],
      phone_number: [{ value: '', disabled: true }, Validators.required],
      verified: [''],
      activated: [''],
      national_id: [{ value: '', disabled: true }, Validators.required],
      arabic_name: [{ value: '', disabled: true }, Validators.required],
    });
  }

  unitRelationType() {
    this.rest.unitRelation().subscribe((res: any) => {
      this.types_arr = res.children;
    });
  }

  accessType() {
    this.rest.cardType().subscribe((res: any) => {
      res.children.forEach((element: any) => {
        if (element.name == 'RFID') {
          this.access_Type = element.id;
        }
      });
    });
  }

  getRelationTypeId(value: any) {
    this.typeName = value.name;
    this.typeId = value.id;
  }
  back() {
    this.location.back();
  }

  openViewRFID(data: any) {
    this.rest.retrieveRFId(data.id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(ViewRfidComponent, {
        width: '400px',
        data: { item: res },
      });
    });
  }
}
