<section class="main m-0 mb-1">
  <h3 style="text-align: center" class="mb-4">{{ project | titlecase }}</h3>

  <mat-spinner diameter="20" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <div>
    <strong>Project name<br /> </strong>

    <div class="span">
      <strong>{{ project }}</strong>
    </div>

    <strong>Type name<br /> </strong>

    <div class="span">
      <strong>{{ type }}</strong>
    </div>
  </div>

  <div class="divImageHolder">
    <div style="width: 120px" *ngIf="frontPhoto">
      <strong>Front Image</strong>
      <img
        [src]="frontPhoto"
        class="configurationImage"
        (click)="openPhoto(photoDialog, frontPhoto)"
      />
    </div>

    <div style="width: 120px" *ngIf="backPhoto">
      <strong>Back Image</strong>
      <img
        [src]="backPhoto"
        class="configurationImage"
        (click)="openPhoto(photoDialog, backPhoto)"
      />
    </div>
  </div>

  <div *ngIf="!showSpinner" class="mt-3">
    <div class="d-flex justify-content-around">
      <button class="btn" (click)="editConfiguration()">Edit</button>

      <button class="btn close" (click)="close()">Cancel</button>
    </div>
  </div>
</section>

<ng-template #photoDialog let-data let-ref="dialogRef">
  <section class="photoHolder">
    <img [src]="photo" class="imageDivInHolder" />
  </section>
</ng-template>
