import { Component, OnInit } from '@angular/core';
import { VillageService } from 'src/app/services/village.service';
import { MainService } from 'src/app/services/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MainGatesComponent } from '../main-gates/main-gates.component';

@Component({
  selector: 'app-add-gate',
  templateUrl: './add-gate.component.html',
  styleUrls: ['./add-gate.component.css'],
})
export class AddGateComponent implements OnInit {
  village_list: any;
  gates_list: any;
  gate_form: FormGroup | any;
  constructor(
    private villageSer: VillageService,
    public dialogRef: MatDialogRef<MainGatesComponent>,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.allVillage();
    this.allGate_type();
    this.initForm();
  }

  initForm() {
    this.gate_form = new FormGroup({
      village_id: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      type_id: new FormControl('', [Validators.required]),
      anpr_ip: new FormControl('', [Validators.required]),
      cctv_ip: new FormControl('', [Validators.required]),
      face_rec_ip: new FormControl('', [Validators.required]),
    });
  }

  allVillage() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.village_list = res?.results;
    });
  }

  allGate_type() {
    this.mainService.gateByType().subscribe((res: any) => {
      this.gates_list = res.children;
    });
  }

  addGate() {
    let data = this.gate_form.value;
    this.dialogRef.close(data);
  }
}
