<section class="main">
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <h3>Village configuration</h3>
    <div class="addGate">
      <div class="me-2">
        <mat-form-field appearance="outline">
          <mat-label>Choose Village</mat-label>
          <mat-select
            (selectionChange)="villageChange($event)"
            [(value)]="selectedVillage"
          >
            <mat-option value="all"> All </mat-option>
            <mat-option *ngFor="let item of village_list" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button class="btn" (click)="addVillageConfig()">
        Add Village config
      </button>
    </div>
  </div>

  <mat-spinner *ngIf="showSpinnerForVillages" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinnerForVillages" class="sup_main">
    <table class="mt-3 table table-striped sticky-header">
      <thead>
        <tr>
          <th scope="col">Village Name</th>
          <th scope="col">Kids access</th>
          <th scope="col">Rented unit</th>
          <th scope="col">Excluded</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of villages_arr_table">
          <td>{{ item?.name }}</td>
          <td>{{ item?.kids_access }}</td>
          <td>{{ item?.rented_unit }}</td>
          <td>
            <div class="mainDivExcluded">
              <div
                class="excluded"
                style="margin-bottom: 10px"
                *ngFor="let excluded of item?.excluded"
              >
                {{ excluded }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>

  <!--
  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div> -->
</section>
