<section class="main">
  <h3 class="d-flex justify-content-center">Add new member</h3>

  <!-- <div>
    <label>Select Type</label>
    <div class="dropdown mb-0">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ chooseType }}
        <img src="assets/images/mangeunit/arrow.png" />
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
        <li (click)="getType('staff')">
          <a class="dropdown-item"> Staff </a>
        </li>
        <li (click)="getType('vip')">
          <a class="dropdown-item"> VIP </a>
        </li>
      </ul>
    </div>

    <div class="waring" *ngIf="submitted && chooseType == 'choose Type'">
      Type is required
    </div>
  </div> -->

  <div class="row mt-4">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select Type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select type"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option value="Staff" (click)="getType('staff')">
            <strong>Staff</strong>
          </mat-option>

          <mat-option value="VIP" (click)="getType('vip')">
            <strong>VIP</strong>
          </mat-option>
        </mat-select>
        <div class="waring" *ngIf="submitted && chooseType == 'choose Type'">
          Type is required
        </div>
      </mat-form-field>
    </div>

    <div class="col-md-12" *ngIf="chooseType != 'choose Type'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select Category</mat-label>
        <mat-select
          fullWidth
          placeholder="Select category"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            (click)="getCat(item)"
            *ngFor="let item of cat_arr"
            [value]="item?.id"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
        <div
          class="waring"
          *ngIf="submitted && chooseCategory == 'choose Category'"
        >
          Category is required
        </div>
      </mat-form-field>
    </div>
  </div>

  <!-- <div *ngIf="chooseType != 'choose Type'">
    <label class="mt-3">Select Category</label>
    <div class="dropdown mb-0">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ chooseCategory }}
        <img src="assets/images/mangeunit/arrow.png" />
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
        <li *ngFor="let item of cat_arr">
          <a class="dropdown-item" (click)="getCat(item)">
            {{ item.name }}
          </a>
        </li>
      </ul>
    </div>
    <div
      class="waring"
      *ngIf="submitted && chooseCategory == 'choose Category'"
    >
      Category is required
    </div>
  </div> -->

  <form #form="ngForm">
    <div class="row">
      <!-- <div class="col-12">
        <div class="form-group">
          <label>Name</label>
          <input
            required
            name="name"
            [ngClass]="{ 'is-invalid': submitted && name == '' }"
            [(ngModel)]="name"
            name="name"
            placeholder="EX. Home"
            type="text"
            class="form-control"
          />
        </div>
      </div> -->

      <div class="col-md-12 mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Name</mat-label>
          <input
            matInput
            required
            name="name"
            [ngClass]="{ 'is-invalid': submitted && name == '' }"
            [(ngModel)]="name"
            type="text"
          />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12">
        <div class="d-flex align-items-center datePicker">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>From</mat-label>
            <input
              matInput
              name="dateFrom"
              [(ngModel)]="dateFrom"
              [matDatepicker]="pickerFrom"
              [ngClass]="{ 'is-invalid': submitted && dateFrom == '' }"
            />

            <mat-datepicker-toggle
              matIconSuffix
              [for]="pickerFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="col-12 mt-3">
        <label>From</label>
        <div class="mb-3 d-flex align-items-center datePicker">
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && dateFrom == '' }"
            [(ngModel)]="dateFrom"
            name="dateFrom"
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerFrom"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </div>
      </div> -->

      <div class="col-12 col-md-12">
        <div class="d-flex align-items-center datePicker">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>To</mat-label>
            <input
              matInput
              name="dateTo"
              [(ngModel)]="dateTo"
              [matDatepicker]="pickerTo"
              [ngClass]="{ 'is-invalid': submitted && dateTo == '' }"
            />

            <mat-datepicker-toggle
              matIconSuffix
              [for]="pickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="col-12">
        <label>To</label>
        <div class="mb-3 d-flex align-items-center datePicker">
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && dateTo == '' }"
            [(ngModel)]="dateTo"
            name="dateTo"
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerTo"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </div>
      </div> -->
    </div>

    <div class="addUnits d-flex justify-content-end">
      <div>
        <button mat-dialog-close class="btn me-2">Cancel</button>
        <button (click)="addMember(form)" class="btn addUnit">
          Add Member
        </button>
      </div>
    </div>
  </form>
</section>
