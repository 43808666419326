import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class VillageService {
  constructor(private http: HttpClient) {}

  // villages

  villageList(currentPage?: any, limit?: any) {
    return this.http.get(
      `${environment.baseUrl}/village/getvillages/?page=${currentPage}&limit=${limit}`
    );
  }

  addVillage(data: any) {
    return this.http.post(`${environment.baseUrl}/village/`, data);
  }

  villageDetails(id: any) {
    return this.http.get(
      `${environment.baseUrl}/village/getvillage/?village_id=${id}`
    );
  }

  searchVillage(name: string) {
    return this.http.get(
      `${environment.baseUrl}/village/?village_name=${name}`
    );
  }

  // projects

  projectList() {
    return this.http.get(`${environment.baseUrl}/projects/all`);
  }

  projectListWithSearch(projectName?: any) {
    return this.http.get(
      `${environment.baseUrl}/projects/?name=${projectName}`
    );
  }

  // retriveVillageConfig

  addVillageConfig(data: any) {
    return this.http.post(`${environment.baseUrl}/gates/village_config`, data);
  }

  retrieveVillageConfig(id: any) {
    return this.http.get(
      `${environment.baseUrl}/gates/village_config?village_id=${id}`
    );
  }
}
