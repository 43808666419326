import { Component, OnInit } from '@angular/core';
import { ChangePasswordComponent } from 'src/app/pages/operators/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { RolesService } from 'src/app/services/roles.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  userName: any;
  constructor(
    public dialog: MatDialog,
    private mainService: MainService,
    private rolesService: RolesService
  ) {}
  ngOnInit() {
    this.userName = localStorage.getItem('userName');
  }

  change_password() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.rolesService.changePw(result).subscribe(
          (res: any) => {
            this.mainService.SuccessToast('Password Successfully changed.');
          },
          (err: any) => {
            this.mainService.errorToast(err.error.message);
          }
        );
      }
    });
  }

  fullScreen() {
    this.mainService.booleanValue = !this.mainService.booleanValue;
  }

  logout() {
    localStorage.clear();
    location.reload();
  }
}
