<div
  class="modal fade show d-block"
  tabindex="-1"
  *ngIf="show"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="pt-md pb-md">
          <h4 class="fs-lg">Select your location on map</h4>
        </div>

        <agm-map
          [latitude]="latitude"
          [longitude]="longitude"
          [zoom]="zoom"
          (mapClick)="setLocation($event)"
        >
          <agm-marker
            [latitude]="latitude"
            [longitude]="longitude"
            [markerDraggable]="false"
            (dragEnd)="setLocation($event)"
          >
          </agm-marker>
        </agm-map>

        <div class="actions d-flex justify-content-end mt-2">
          <button class="btn me-2" (click)="save()">Save</button>
          <button class="btn" (click)="close()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
