import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddGateComponent } from '../../gates/add-gate/add-gate.component';
import { GatesService } from 'src/app/services/gates.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-gate-configuration',
  templateUrl: './gate-configuration.component.html',
  styleUrls: ['./gate-configuration.component.css'],
})
export class GateConfigurationComponent implements OnInit {
  showSpinnerForGates: boolean = true;
  gates_arr: any = [];

  constructor(
    public dialog: MatDialog,
    private rest: GatesService,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.retrieveGates();
  }

  // gates
  retrieveGates() {
    this.rest.retrieveGates().subscribe((res: any) => {
      this.showSpinnerForGates = false;
      this.gates_arr = res;
    });
  }
  addGate() {
    const dialogRef = this.dialog.open(AddGateComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.rest.addGate(res).subscribe((res: any) => {
          this.mainService.SuccessToast('Gate added successfully');
          this.retrieveGates();
        });
      }
    });
  }
  // end gates
}
