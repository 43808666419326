import { Component, OnInit } from '@angular/core';
import { VillageService } from 'src/app/services/village.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MangeVillageComponent } from '../mange-village/mange-village.component';

@Component({
  selector: 'app-add-village',
  templateUrl: './add-village.component.html',
  styleUrls: ['./add-village.component.css'],
})
export class AddVillageComponent implements OnInit {
  villageName = '';
  project_arr: any = [];
  projectId: any;
  statusProject = false;

  constructor(
    private rest: VillageService,
    public dialogRef: MatDialogRef<MangeVillageComponent>
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.rest.projectList().subscribe((res: any) => {
      this.project_arr = res;
    });
  }

  getProjectData(value: any) {
    // this.ProjectName = value?.name
    this.projectId = value?.id;
    this.statusProject = false;
  }

  onSubmit(value: any) {
    if (!this.projectId || this.projectId == undefined) {
      this.statusProject = true;
    } else {
      this.statusProject = false;
      let data = {
        name: this.villageName,
        project_id: this.projectId,
      };
      this.dialogRef.close(data);
    }
  }
}
