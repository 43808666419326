<header>
  <div class="main">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-felx align-items-center">
        <img class="logo" src="assets/images/logo.png" />

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="fullScreen()"
          title="Hide side menu"
        >
          <span>
            <img
              style="width: 35px; padding-left: 10px"
              src="assets/images/icon-menu.png"
            />
          </span>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <img
          class="icon icon-notifcation"
          src="assets/images/notifcation.png"
        />
        <img class="icon icon-email ms-3" src="assets/images/email.png" />
        <!-- <img class="icon icon-user ms-3" [matMenuTriggerFor]="menu" src="assets/images/user.png"> -->
        <ngx-avatar
          [matMenuTriggerFor]="menu"
          class="icon icon-user ms-3"
          size="50"
          name="{{ userName }}"
          initialsSize="2"
          cornerRadius="11"
        ></ngx-avatar>
      </div>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <button (click)="logout()" mat-menu-item>Log out</button>
    <button (click)="change_password()" mat-menu-item>Change password</button>
  </mat-menu>
</header>
