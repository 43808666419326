<section class="main">
  <h2>Add Qr Type</h2>
  <hr />

  <mat-spinner *ngIf="showSpinner" diameter="20"></mat-spinner>

  <form [formGroup]="typeForm" *ngIf="!showSpinner">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Type name</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error *ngIf="isControlHasError('name', 'required')">
        Type name is required
      </mat-error>
    </mat-form-field>
  </form>

  <div class="footer mt-4">
    <button
      class="btn addGate"
      [disabled]="typeForm.invalid || showSpinner"
      (click)="add_qr_type()"
    >
      Add Qr type
    </button>
    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
