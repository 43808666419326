import { Component, OnInit } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { AddUtilitiesComponent } from '../all-utiltes/all-utiltes.component';
export interface User {
  name: string;
}
@Component({
  selector: 'app-add-inovice',
  templateUrl: './add-inovice.component.html',
  styleUrls: ['./add-inovice.component.css'],
})
export class AddInoviceComponent implements OnInit {
  myControl = new FormControl<string | User>('');
  options: User[] = [{ name: 'Mary' }, { name: 'Shelley' }, { name: 'Igor' }];
  filteredOptions: Observable<User[]> | any;
  consumed: any;
  description: any;
  unit_id: any;
  constructor(
    private unitService: UnitsService,
    public dialogRef: MatDialogRef<AddUtilitiesComponent>
  ) {}

  ngOnInit() {
    // this.initData(this.options)
  }
  initData(parm: any) {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : parm.slice();
      })
    );
  }
  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  getData(e: any) {
    if (e.target.value.length > 2) {
      this.unitService.searchUnit(e.target.value).subscribe((res: any) => {
        this.options = res;
        this.initData(res);
      });
    }
  }
  getOption(data: any) {
    this.unit_id = data.id;
  }

  addInvoice() {
    let data = {
      description: this.description,
      consumed: this.consumed,
      unit_id: this.unit_id,
    };
    this.dialogRef.close(data);
  }
}
