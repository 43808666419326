import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '../auth/auth.guard';
import { MangeUnitsComponent } from './units/mange-units/mange-units.component';
import { UnitDetailsComponent } from './units/unit-details/unit-details.component';
import { AddClientComponent } from './units/add-client/add-client.component';
import { DashboardComponent } from './main-dashboard/dashbaord/dashbaord.component';
import { LiveGateTypeComponent } from './main-dashboard/live-gate-type/live-gate-type.component';
import { MangeVillageComponent } from './villages/mange-village/mange-village.component';
import { VillageDetailsComponent } from './villages/village-details/village-details.component';
import { MainComponent } from './genral-access/main/main.component';
import { OperatorsMainComponent } from './operators/opreators-main/opreators-main.component';
import { RolesMainComponent } from './roles/roles-main/roles-main.component';
import { MainGatesComponent } from './gates/main-gates/main-gates.component';
import { AllClientsComponent } from './clients/all-clients/all-clients.component';
import { ClientsLogComponent } from './clients/clients-log/clients-log.component';
import { ClientsPaymentComponent } from './clients/clients-payment/clients-payment.component';
import { AllServicesComponent } from './mange-service/all-services/all-services.component';
import { EditClientComponent } from './units/edit-client/edit-client.component';
import { ViewClientComponent } from './units/view-client/view-client.component';
import { MainLogsComponent } from './all-logs/main-logs/main-logs.component';
import { MainSerReqComponent } from './service-request/main-ser-req/main-ser-req.component';
import { ServiceDetailsComponent } from './service-request/serv-details/serv-details.component';
import { AddUtilitiesComponent } from './utilty/all-utiltes/all-utiltes.component';
import { MainFamilyComponent } from './family/main-family/main-family.component';
import { UserInfoComponent } from './family/user-info/user-info.component';
import { LogsClientComponent } from './units/logs-client/logs-client.component';
import { UnitInvoiceComponent } from './units/unit-inovice/unit-inovice.component';
import { CompleteUnitDetailsComponent } from './units/complete-unit-details/complete-unit-details.component';
import { EditUnitDetailsComponent } from './units/edit-unit-details/edit-unit-details.component';
import { QrCodesComponent } from './units/qr-codes/qr-codes.component';
import { SystemAnalyticsComponent } from './main-dashboard/system-analytics/system-analytics.component';
import { GateMonitorComponent } from './main-dashboard/gate-monitor/gate-monitor.component';
import { UserCardConfigurationComponent } from './configuration/user-card-configuration/user-card-configuration.component';
import { OperatorsListComponent } from './all-logs/oprreators-list/oprreators-list.component';
import { GateConfigurationComponent } from './configuration/gate-configuration/gate-configuration.component';
import { VillageConfigurationComponent } from './configuration/village-configuration/village-configuration.component';
import { UsersConfigurationComponent } from './configuration/users-configuration/users-configuration.component';
import { QrConfigurationComponent } from './configuration/qr-configuration/qr-configuration.component';
import { UserAccessConfigurationComponent } from './configuration/user-access-configuration/user-access-configuration.component';
import { UnitRentalsComponent } from './units/unit-rentals/unit-rentals.component';
import { PromoCodeListComponent } from './promo-codes/promo-code-list/promo-code-list.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    component: PagesComponent,
    children: [
      { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
      { path: 'Dashboard', component: LiveGateTypeComponent },
      { path: 'gate-monitor', component: GateMonitorComponent },
      { path: 'system_analytics', component: SystemAnalyticsComponent },
      { path: 'gate_types/:id', component: DashboardComponent },
      // { path: 'mange_units', component: MangeUnitsComponent },
      {
        path: 'mange_units',
        component: MangeUnitsComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./units/units.module').then((m) => m.UnitsModule),
      },
      {
        path: 'unit_details/:id',
        component: UnitDetailsComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./units/units.module').then((m) => m.UnitsModule),
      },
      {
        path: 'complete-unite-details/:id/:typeName',
        component: CompleteUnitDetailsComponent,
      },

      {
        path: 'edit-unit-details/:id',
        component: EditUnitDetailsComponent,
      },

      // { path: 'unit_details/:id', component: UnitDetailsComponent },
      { path: 'add_client/:id', component: AddClientComponent },
      { path: 'edit_client/:id/:id1', component: EditClientComponent },
      { path: 'view_client/:id/:id1', component: ViewClientComponent },
      { path: 'mange_village', component: MangeVillageComponent },
      { path: 'village_details/:id', component: VillageDetailsComponent },
      { path: 'general_access', component: MainComponent },
      { path: 'Operators', component: OperatorsMainComponent },
      { path: 'access_logs', component: MainLogsComponent },
      { path: 'operators_logs', component: OperatorsListComponent },
      { path: 'roles', component: RolesMainComponent },
      { path: 'mange_gate', component: MainGatesComponent },
      { path: 'promo-codes', component: PromoCodeListComponent },
      { path: 'clients_payment', component: ClientsPaymentComponent },
      { path: 'all_clients', component: AllClientsComponent },
      { path: 'clients_log', component: ClientsLogComponent },
      { path: 'all_services', component: AllServicesComponent },
      { path: 'service_request', component: MainSerReqComponent },
      { path: 'service_request_Details', component: ServiceDetailsComponent },
      { path: 'Utility', component: AddUtilitiesComponent },
      { path: 'family_requests', component: MainFamilyComponent },
      { path: 'family_user_info/:id', component: UserInfoComponent },
      { path: 'activity_logs/:id', component: LogsClientComponent },
      { path: 'unit_invoice/:id/:name', component: UnitInvoiceComponent },
      { path: 'unit_rentals/:id/:name/:villageId', component: UnitRentalsComponent },
      { path: 'qr-codes/:id/:name', component: QrCodesComponent },
      {
        path: 'user-card-configuration',
        component: UserCardConfigurationComponent,
      },
      {
        path: 'gate-configuration',
        component: GateConfigurationComponent,
      },
      {
        path: 'village-configuration',
        component: VillageConfigurationComponent,
      },
      {
        path: 'users-configuration',
        component: UsersConfigurationComponent,
      },
      {
        path: 'qr-configuration',
        component: QrConfigurationComponent,
      },

      {
        path: 'user-access-configuration',
        component: UserAccessConfigurationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
