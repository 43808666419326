import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { GAccessService } from 'src/app/services/g-accsess.service';
import { AddMemberComponent } from '../add-member/add-member.component';
import { UnitsService } from 'src/app/services/units.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UnitBlockComponent } from '../../units/unit-block/unit-block.component';
import { MainService } from 'src/app/services/main.service';
import { AddCardComponent } from '../../units/add-card/add-card.component';
import { ClientsService } from 'src/app/services/clients.service';
import { AddNoteComponent } from '../../units/add-note/add-note.component';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  showSpinner: boolean = true;
  pipe = new DatePipe('en-US');
  staff_category: any;
  staff_all: any = [];
  staff_array: any;
  selectParent = 'Staff';
  vip_category: any;
  vip_all: any = [];
  vip_array: any;
  global_category: any;
  global_array: any;
  message_display = false;
  All_categories: any = 'All categories';
  all_gates: any;
  all_types: any;
  filter_form: FormGroup | any;
  imageBase641: any;
  imageBase64: any;
  dataPrint: any = [];
  printingCardOrientation: any = environment.printingCardOrientation;

  cardObject = {
    name: '',
    unitName: '',
    validFrom: '',
    validTo: '',
    type: '',
    image: '',
    image1: 'assets/images/teamCard.jpg',
    frontImage: '',
    villageName: '',
    status: false,
  };

  page = 1;
  limit = 12;
  totalCount = 0;
  searchKeyForClientName: any;
  typeId: any;
  userTypesArray: any;
  types_arr: any;
  disableCategoryList: boolean = true;
  categoryForm: FormGroup | any;
  showResetFilterButton: boolean = false;

  configurationPage = 1;
  configurationLimit = 0;
  allConfiguration: any;
  frontPhoto: any;
  backPhoto: any;

  constructor(
    public dialog: MatDialog,
    private rest: GAccessService,
    private unitService: UnitsService,
    private formBuilder: FormBuilder,
    private mainService: MainService,
    private clientService: ClientsService,
    private route: Router,
    private readonly configuration: ConfigurationsService
  ) {}

  ngOnInit() {
    this.retrieveStaffOrVIP();
    this.getUserTypes();
    this.getCategoryForm();
    this.getAllConfigurations();
    this.showSpinner = true;

    // this.initFormFilet();
    // this.retrieve_staff()
    // this.http
    //   .get('/assets/images/fouka.jpg', { responseType: 'blob' })
    //   .subscribe((res: any) => {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       var base64data = reader.result;
    //       this.imageBase64 = base64data;
    //       // this.retrieveVip();
    //       this.retrieve_staff();
    //     };
    //     reader.readAsDataURL(res);
    //   });
    // this.http
    //   .get('/assets/images/foukaone.jpg', { responseType: 'blob' })
    //   .subscribe((res: any) => {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       var base64data = reader.result;
    //       this.imageBase641 = base64data;
    //     };
    //     reader.readAsDataURL(res);
    //   });
  }

  // initFormFilet() {
  //   this.filter_form = this.formBuilder.group({
  //     name: [''],
  //     access_type: [''],
  //     message: [''],
  //     controller_id: [''],
  //     direction: [''],
  //     plate_number: [''],
  //     unit_name: [''],
  //     registered_to: [''],
  //     valid_to: [''],
  //     valid_from: [''],
  //   });
  // }

  // getParent(value: any) {
  //   this.showSpinner = true;
  //   this.selectParent = value;
  //   if (value == 'Staff') {
  //     this.retrieve_staff();
  //   } else {
  //     this.retrieveVip();
  //   }
  // }

  getAllConfigurations() {
    this.configuration
      .retrieveConfigurations(this.page, this.limit)
      .subscribe((data: any) => {
        this.showSpinner = false;
        this.allConfiguration = data.results;
        this.allConfiguration.forEach((element: any) => {
          if (element.images?.length != 0) {
            element.images?.forEach((item: any) => {
              if (item.image_type == 'front') {
                element.frontPhoto = item.image;
              } else if (item.image_type == 'back') {
                element.backPhoto = item.image;
              }
            });
          }
        });
      });
  }

  addCategory() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: '90%',
      maxWidth: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.showSpinner = true;
        this.rest.addCategory(result).subscribe((res: any) => {
          this.retrieve_staff();
        });
      }
    });
  }

  retrieve_staff() {
    this.global_array = [];
    this.rest.retrieveStaff().subscribe((res: any) => {
      this.showSpinner = false;
      this.staff_category = Object.keys(res[0]);
      this.global_category = Object.keys(res[0]);
      this.staff_array = res[0];
      this.staff_category.forEach((element: any) => {
        this.global_array = [...this.global_array, ...res[0][element]];
        this.staff_all = [...this.staff_all, ...res[0][element]];
      });
      if (this.global_array.length == 0) {
        this.message_display = true;
      } else {
        this.message_display = false;
      }
    });
  }

  // filter_staff(item: any) {
  //   this.showSpinner = true;
  //   this.All_categories = item;
  //   if (this.selectParent == 'Staff') {
  //     this.showSpinner = false;
  //     this.global_array = this.staff_array[item];
  //     if (this.global_array.length == 0) {
  //       this.message_display = true;
  //     } else {
  //       this.message_display = false;
  //     }
  //   } else {
  //     this.showSpinner = false;
  //     this.global_array = this.vip_array[item];
  //     if (this.global_array.length == 0) {
  //       this.message_display = true;
  //     } else {
  //       this.message_display = false;
  //     }
  //   }
  // }

  // retrieve Vip

  retrieveVip() {
    this.global_array = [];
    this.rest.retrieveVip().subscribe((res: any) => {
      this.showSpinner = false;
      this.vip_category = Object.keys(res[0]);
      this.global_category = Object.keys(res[0]);
      this.vip_array = res[0];
      this.vip_category.forEach((element: any) => {
        this.global_array = [...this.global_array, ...res[0][element]];
        this.vip_all = [...this.vip_all, ...res[0][element]];
      });
      if (this.global_array.length == 0) {
        this.message_display = true;
      } else {
        this.message_display = false;
      }
    });
  }

  //NEW WORK

  getCategoryForm() {
    this.categoryForm = new FormGroup({
      category: new FormControl(null),
    });

    if (this.disableCategoryList) {
      this.categoryForm.get('category').disable();
    }
  }

  // GETTING MAIN DATA [ NEW REQUEST ]
  retrieveStaffOrVIP() {
    this.rest
      .retrieveStaffOrVIP(
        this.page,
        this.limit,
        this.searchKeyForClientName,
        this.typeId
      )
      .subscribe((data: any) => {
        this.global_array = data?.results;
        this.showSpinner = false;
        this.totalCount = data?.total_count;

        if (this.global_array.length == 0) {
          this.message_display = true;
        } else {
          this.message_display = false;
        }
      });
  }

  //GET USER TYPE [ STAFF or VIP ]
  getUserTypes() {
    this.unitService.userTypes().subscribe((res: any) => {
      this.userTypesArray = res?.children;
      this.userTypesArray = this.userTypesArray.filter((userType: any) => {
        return userType.name === 'Staff' || userType.name === 'VIP';
      });
    });
  }

  // OPEN THE CATEGORY DROPDOWN LIST
  selectUserType(userType: any) {
    this.types_arr = userType?.children;
    this.categoryForm.get('category').enable();
  }

  // FILTER THE ARRAY BY CATEGORY
  filterByCategory(category: any) {
    this.showSpinner = true;
    this.page = 1;
    this.typeId = category?.id;
    this.retrieveStaffOrVIP();
    this.showResetFilterButton = true;
  }

  searchClients(item: any) {
    this.showSpinner = true;
    this.searchKeyForClientName = item?.target?.value;
    this.retrieveStaffOrVIP();
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.page = e?.pageIndex + 1;
    this.limit = e?.pageSize;
    this.retrieveStaffOrVIP();
  }

  resetFilter() {
    this.showResetFilterButton = !this.showResetFilterButton;
    this.typeId = null;
    this.showSpinner = true;
    this.categoryForm.get('category').disable();
    this.retrieveStaffOrVIP();
  }

  addMember() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      width: '90%',
      maxWidth: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.showSpinner = true;
        this.rest.add_member(result).subscribe((res: any) => {
          this.retrieve_staff();
        });
      }
    });
  }

  blockUnitGates(id: any) {
    let unitOrClientID: any;
    let unitOrClientName: any;
    if (id != null) {
      unitOrClientID = id;
      unitOrClientName = 'client';
    } else {
      unitOrClientID = '';
      unitOrClientName = 'unit';
    }
    const dialogRef = this.dialog.open(UnitBlockComponent, {
      width: '400px',
      data: { id: unitOrClientID, name: unitOrClientName },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (id != null) {
          let data = {
            gates: result,
            clients: [id],
          };
          if (result.balckList.length != 0) {
            data.gates = result.balckList;
            this.unitService.blackListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in blackList Gates');
            });
          } else {
            data.gates = result.WhiteList;
            this.unitService.whiteListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in whiteList Gates');
            });
          }
        }
      }
    });
  }

  addCard(value: any) {
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: '450px',
      data: { id: value.id },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.client_id = value.id;
        if (result.license_plate) {
          this.unitService
            .addRFId(result)
            .then((response) => response.text())
            .then((result) => {
              let res = JSON.parse(result);
              if (res.status == true) {
                this.mainService.SuccessToast('RFID Added');
                this.activeClient(value.id);
              } else {
                this.mainService.errorToast('Ops something wrong happen!');
              }
            });
        } else {
          this.unitService.addCard(result).subscribe((res: any) => {
            this.activeClient(value.id);
            this.mainService.SuccessToast('Card Added');
          });
        }
      }
    });
  }
  activeClient(value: any) {
    let data = {
      id: value + '',
      activated: true,
    };
    this.clientService.editClient(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Client Activated');
    });
  }

  addNote(value: any, id: any) {
    let idOfClientOrUnit;
    idOfClientOrUnit = id;
    const dialogRef = this.dialog.open(AddNoteComponent, {
      width: '450px',
      data: { id: idOfClientOrUnit, name: value },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.client_id = id;
        (result.expiry_date = this.pipe.transform(new Date(), 'yyyy-MM-dd')),
          this.unitService.addNoteForClient(result).subscribe((res: any) => {
            this.mainService.SuccessToast('Note Added for the client');
          });
      }
    });
  }

  userTypeForPrinting: any;
  orientationPrinting: any;

  checkUserType(type: any) {
    for (const obj of this.printingCardOrientation) {
      if (obj.userType === type) {
        this.orientationPrinting = obj.orientation;
      }
    }
  }

  print_card(data: any) {
    this.userTypeForPrinting = data?.type?.name;
    this.checkUserType(this.userTypeForPrinting);

    this.dataPrint = [];
    this.cardObject.name = data.name;
    if (data.type.name == 'Main Owner') {
      this.cardObject.type = 'owner' + "'s" + ' ' + 'Card';
    } else {
      this.cardObject.type = data.type.name + "'s" + ' ' + 'Card';
    }

    this.cardObject.validFrom = data.valid_from;
    this.cardObject.validTo = data.valid_to;
    this.cardObject.villageName = data?.unit[0]?.village.name;

    // this.cardObject.unitName = this.unit_name;
    if (data.photo) {
      this.cardObject.image = data.photo;
    } else {
      this.cardObject.image = 'assets/images/avatar.png';
    }

    this.findPhotosByType(this.allConfiguration, data?.type?.name);
    this.cardObject.image1 = this.backPhoto;
    this.cardObject.frontImage = this.frontPhoto;

    // if (data.type.name == 'Main Owner' || data.type.name == 'Owner') {
    //   this.cardObject.image1 = this.imageBase641;
    //   this.cardObject.status = true;
    // } else {
    //   this.cardObject.image1 = this.imageBase64;
    //   this.cardObject.status = false;
    // }
    this.dataPrint.push(this.cardObject);

    setTimeout(() => {
      this.clientService
        .printCardForClient(data.id, true)
        .subscribe((res: any) => {
          this.mainService.SuccessToast('Printed success');
        });
      const button: any = document.getElementById('printButton');
      button.click();
      window.onafterprint = function () {};
    }, 500);
  }

  findPhotosByType(configurationArray: any, userType: any) {
    this.frontPhoto = null;
    this.backPhoto = null;

    for (const obj of configurationArray) {
      if (obj.type === userType) {
        this.frontPhoto = obj.frontPhoto;
        this.backPhoto = obj.backPhoto;
        break;
      }
    }
  }

  editClient(value: any) {
    this.route.navigate(['pages/edit_client', '', value]);
  }

  blockClientTitle = 'Block';
  clientIdDeleted: any;
  openBlockClient(title: any, item: any) {
    if (title == 'block') {
      this.blockClientTitle = 'Block';
    } else {
      this.blockClientTitle = 'Active';
    }
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn4');
    button.click();
  }
  blockClient() {
    let data = {
      id: this.clientIdDeleted,
      cancelled: true,
    };
    if (this.blockClientTitle == 'Block') {
      data.cancelled = true;
    } else {
      data.cancelled = false;
    }
    this.clientService.editClient(data).subscribe((res: any) => {
      if (this.blockClientTitle == 'Block') {
        this.mainService.SuccessToast('Client Blocked');
      } else {
        this.mainService.SuccessToast('Client Active');
      }
      this.retrieve_staff();
    });
  }

  openDeleteClient(item: any) {
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  deleteClient() {
    this.unitService
      .deleteClient(this.clientIdDeleted)
      .subscribe((res: any) => {
        this.mainService.SuccessToast('Client deleted');
        this.retrieve_staff();
      });
  }
}
