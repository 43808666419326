<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center">
    <h3>Manage units</h3>
    <div class="icon">
      <!-- <img (mouseenter)="multiUnits = !multiUnits" (click)="checkBoxStatus = !checkBoxStatus"
                (mouseleave)="multiUnits = !multiUnits" src="assets/images/mangeunit/1.png">
            <img class="ms-3" src="assets/images/mangeunit/4.png">
            <img class="ms-3" src="assets/images/mangeunit/2.png">
            <img class="ms-3" src="assets/images/mangeunit/3.png">
            <img class="ms-3" src="assets/images/mangeunit/5.png"> -->
      <button class="btn ms-3" (click)="add_unit()">Add Unit</button>
    </div>
  </div>
  <div *ngIf="multiUnits" class="mangeUnits">
    <p>Select more than one unit to take that action</p>
  </div>

  <div
    class="supmain mb-2 filter d-flex justify-content-between align-items-center"
  >
    <!-- <div class="form-group" class="searchInput">
      <input
        type="search"
        [(ngModel)]="unitName"
        (input)="searchUnit($event)"
        class="form-control"
        placeholder="Search unit"
      />
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Search by unit</mat-label>
        <input (input)="searchUnit($event)" matInput type="text" />
      </mat-form-field>
    </div>

    <!-- <div class="dropdown mb-0">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ typeName }}
        <img src="assets/images/mangeunit/arrow.png" />
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li *ngFor="let item of typesArr">
          <a class="dropdown-item" (click)="searchByType(item.name)">{{
            item.name
          }}</a>
        </li>
      </ul>
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Unit type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select unit type"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            *ngFor="let item of typesArr"
            [value]="item"
            (click)="searchByType(item?.name)"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <mat-accordion class="d-md-none mb-5" *ngIf="!showSpinner">
    <mat-expansion-panel
      *ngFor="let item of units_arr; let i = index"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-content-between">
          <strong>{{ item.name }}</strong>


          <small>{{ item.type | titlecase }}</small>


        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center clientData">
          <img
            *ngIf="item?.image?.image"
            src="{{ item?.image?.image }}"
            class="me-2 clientImage"
          />

          <ngx-avatar
            *ngIf="!item?.image?.image"
            class="me-2"
            size="50"
            name="{{ item.client_name }}"
            initialsSize="2"
            cornerRadius="11"
          ></ngx-avatar>

          <strong>{{ item.client_name }}</strong>
        </div>

        <div>
          <button class="btn ms-3" (click)="editUnit(item)">Edit Unit</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- table units -->

  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinner" class="tableUnit table-responsive d-none d-md-block">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Unit name</th>
          <th scope="col">Owner name</th>
          <th scope="col">Unit Type</th>
          <th scope="col" class="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of units_arr; let i = index"
          style="vertical-align: middle"
        >
          <td class="d-flex align-items-center">
            <input
              (change)="inputCheckBox($event, item, i)"
              id="{{ item.id }}"
              *ngIf="checkBoxStatus"
              class="me-1"
              type="checkbox"
            />
            <img
              src="../../../../assets/images/unitName.png"
              class="me-2 unitIcon"
            />
            <label
              class="nameWithOutCheckBox"
              *ngIf="!checkBoxStatus"
              (click)="unitDetails(item.name)"
              ><strong>{{ item.name }}</strong></label
            >
            <label
              for="{{ item.id }}"
              *ngIf="checkBoxStatus"
              style="cursor: pointer"
              ><strong>{{ item.name }}</strong></label
            >
          </td>
          <td>
            <div class="d-flex align-items-center">
              <img
                *ngIf="item?.image?.image"
                src="{{ item?.image?.image }}"
                class="me-2 clientImage"
              />

              <ngx-avatar
                *ngIf="!item?.image?.image"
                class="me-2"
                size="50"
                name="{{ item.client_name }}"
                initialsSize="2"
                cornerRadius="11"
              ></ngx-avatar>

              <strong>{{ item.client_name }}</strong>
            </div>
          </td>
          <td>
            <strong>{{ item.type | titlecase }}</strong>
          </td>
          <td class="text-end">
            <!-- <img
              *ngIf="!checkBoxStatus"
              style="cursor: pointer"
              src="assets/images/mangeunit/6.png"
            />
            <img
              *ngIf="!checkBoxStatus"
              style="cursor: pointer"
              class="ms-3"
              src="assets/images/mangeunit/7.png"
            /> -->

            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="moreDetailsButton"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editUnit(item)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <!-- <button mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button> -->
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 *ngIf="units_arr == null">No Data Found</h3>
  </section>

  <div *ngIf="unit_blocked_btn_status && checkBoxStatus" class="text-end">
    <button (click)="blockUnitGates()" class="btn blockUnit mt-2">
      Block Units
    </button>
  </div>

  <div class="paginator" *ngIf="unitName == ''">
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>
