<section class="main">
  <h3>Edit Service</h3>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label>Name</label>
        <input [(ngModel)]="serviceName" type="text" class="form-control" />
      </div>
      <div class="form-group mt-3" *ngIf="servicCost != ''">
        <label>Cost</label>
        <input [(ngModel)]="servicCost" type="text" class="form-control" />
      </div>
    </div>
  </div>
  <div class="mt-4">
    <p>Service image</p>
    <div *ngIf="serviceImage != ''" class="imageIcon">
      <span (click)="deleteImage()">
        <img src="assets/images/delete.png" />
      </span>
      <img src="{{ serviceImage }}" style="width: 100px" />
    </div>
    <label *ngIf="serviceImage == ''" for="images" class="drop-container">
      <input
        (change)="uploadServiceImage($event)"
        type="file"
        id="images"
        accept="image/*"
        required
      />
    </label>
  </div>

  <div class="mt-4">
    <button class="btn add" (click)="addService()">Edit Service</button>
    <button class="btn">Cancel</button>
  </div>
</section>
