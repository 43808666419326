import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserCardConfigurationComponent } from '../user-card-configuration/user-card-configuration.component';
import { EditConfigurationComponent } from '../edit-configuration/edit-configuration.component';

@Component({
  selector: 'app-configuration-details',
  templateUrl: './configuration-details.component.html',
  styleUrls: ['./configuration-details.component.css'],
})
export class ConfigurationDetailsComponent implements OnInit {
  showSpinner: boolean = false;
  photo: string = '';

  project: any;
  type: any;
  frontPhoto: any;
  backPhoto: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserCardConfigurationComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadMainData();
  }

  loadMainData() {
    this.project = this.data.configuration.project;
    this.type = this.data.configuration.type;
    this.backPhoto = this.data.configuration.backPhoto;
    this.frontPhoto = this.data.configuration.frontPhoto;
  }

  editConfiguration() {
    const dialogRef = this.dialog.open(EditConfigurationComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        configuration: this.data.configuration,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.status) {
        this.showSpinner = true;
        this.dialogRef.close();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  openPhoto(dialog: TemplateRef<any>, photo: any) {
    this.photo = photo;
    this.dialog.open(dialog);
  }
}
