import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesMainComponent } from './roles-main/roles-main.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { FormsModule } from '@angular/forms';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [RolesMainComponent, AddRoleComponent, EditRoleComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
  ],
})
export class RolesModule {}
