import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { AddPromoCodeComponent } from '../add-promo-code/add-promo-code.component';
import { EditPromoCodeComponent } from '../edit-promo-code/edit-promo-code.component';
import { MainService } from 'src/app/services/main.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-promo-code-list',
  templateUrl: './promo-code-list.component.html',
  styleUrls: ['./promo-code-list.component.css'],
})
export class PromoCodeListComponent implements OnInit {
  showSpinner: boolean = true;
  page = 1;
  limit = 10;
  totalCount = 0;
  promoCodeId: any;
  allPromoCodes: any;

  constructor(
    private readonly promoCodeService: PromoCodeService,
    public dialog: MatDialog,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.getAllPromoCodes();
  }

  getAllPromoCodes() {
    this.promoCodeService
      .getAllPromoCodeService(this.page, this.limit)
      .subscribe((res: any) => {
        this.allPromoCodes = res.results;
        this.showSpinner = false;
      });
  }

  addNewPromoCode() {
    const dialogRef = this.dialog.open(AddPromoCodeComponent, {
      width: '90%',
      maxWidth: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.status) {
        this.showSpinner = true;
        this.getAllPromoCodes();
      }
    });
  }

  editPromoCode(item: any) {
    const dialogRef = this.dialog.open(EditPromoCodeComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        promoCodeDetails: item,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.status) {
        this.showSpinner = true;
        this.getAllPromoCodes();
      }
    });
  }

  openDeletePromoCode(item: any) {
    this.promoCodeId = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  deletePromoCode() {
    this.promoCodeService
      .deletePromoCode(this.promoCodeId)
      .subscribe((res: any) => {
        if (res.status) {
          this.mainService.SuccessToast(res.message);
          this.showSpinner = true;
          this.getAllPromoCodes();
        } else {
          this.mainService.errorToast(res.message);
        }
      });
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.page = e?.pageIndex + 1;
    this.limit = e?.pageSize;
    this.getAllPromoCodes();
  }
}
