<section class="main">
  <h1>View RFID</h1>
  <hr />
  <div class="row">
    <div class="col-12">
      <label>Vehicle Number</label>
      <input
        disabled
        [(ngModel)]="VehicleNumber"
        type="text"
        class="form-control"
      />
    </div>
    <div class="col-6 mt-3">
      <label>Front Image</label>
      <img class="img-fluid" [src]="frontImage" />
    </div>
    <div class="col-6 mt-3">
      <label>Back Image</label>
      <img class="img-fluid" [src]="backImage" />
    </div>
    <div class="col-12 mt-3">
      <label>Serial number</label>
      <input
        disabled
        [(ngModel)]="serialNum"
        type="text"
        class="form-control"
      />
    </div>
    <div class="col-12 mt-3">
      <label>Card ID</label>
      <input disabled [(ngModel)]="Card_ID" type="text" class="form-control" />
    </div>

    <div class="mt-3">
      <button class="btn" mat-dialog-close>Cancel</button>
    </div>
  </div>
</section>
