<section class="main">
  <section id="capture" #screen>
    <div
      class="text-center mb-3"
      [ngClass]="
        qrCodeCardConfiguration.logo
          ? 'd-flex justify-content-between align-items-center '
          : ''
      "
    >
      <img
        class="logo"
        [src]="projectLogo"
        *ngIf="qrCodeCardConfiguration?.logo"
      />
      <!-- <img class="logo" [src]="projectLogo" /> -->

      <div>
        <h4 class="mb-0">Gate Pass</h4>
        <small class="">One Use Pass</small>
      </div>
    </div>
    <!-- <div class="mt-4 d-flex justify-content-between align-items-center">
      <div class="divOne">
        <h4 class="mb-1">Visitor</h4>
        <p>{{ clientName | titlecase }}</p>
      </div>
      <div class="divtow text-right">
        <h4 class="mb-1">Inviter</h4>
        <p>{{ clientInviter | titlecase }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="divOne">
        <h4 class="mb-1">Unit</h4>
        <p>{{ unitName | titlecase }}</p>
      </div>
      <div class="divtow text-right">
        <h4 class="mb-1">Date</h4>
        <p><strong>From:</strong> {{ dateFrom | date : "mediumDate" }}</p>
        <p><strong>To:</strong> {{ dateTo | date : "mediumDate" }}</p>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div class="divOne">
        <h4 class="mb-1">Phone no.</h4>
        <p>{{ phone_number }}</p>
      </div>
    </div> -->

    <table>
      <tr *ngIf="qrCodeCardConfiguration?.inviter">
        <th>Inviter</th>
        <td>{{ clientInviter | titlecase }}</td>
      </tr>
      <tr *ngIf="qrCodeCardConfiguration?.visitor">
        <th>Visitor</th>
        <td>{{ clientName | titlecase }}</td>
      </tr>
      <tr *ngIf="qrCodeCardConfiguration?.visitorPhone">
        <th>Visitor Phone</th>
        <td>{{ phone_number }}</td>
      </tr>
      <tr *ngIf="qrCodeCardConfiguration?.visitorEmail">
        <th>Visitor email</th>
        <td>{{ email }}</td>
      </tr>
      <tr *ngIf="qrCodeCardConfiguration?.unit">
        <th>Unit</th>
        <td>{{ unitName | titlecase }}</td>
      </tr>
      <tr *ngIf="qrCodeCardConfiguration?.peopleCount">
        <th>People count</th>
        <td>{{ peopleCount }}</td>
      </tr>
      <tr *ngIf="qrCodeCardConfiguration?.visitDate">
        <th>Visit date</th>
        <td>
          <p><strong>From:</strong> {{ dateFrom | date : "mediumDate" }}</p>
          <p><strong>To:</strong> {{ dateTo | date : "mediumDate" }}</p>
        </td>
      </tr>
    </table>

    <div class="text-center">
      <ngx-qrcode
        [elementType]="elementType"
        [errorCorrectionLevel]="correctionLevel"
        [value]="value"
        cssClass="bshadow"
        [width]="160"
      ></ngx-qrcode>
    </div>
  </section>
  <div
    class="text-center mt-3 d-flex align-items-center justify-content-center"
  >
    <button class="btn me-2" (click)="download()">Download</button>
    <button class="btn whatsAppButton" (click)="sendWhatsApp()">
      Send WhatsApp
    </button>
  </div>
  <div id="download" style="padding: 10px; display: none">
    <img #canvas />
    <a #downloadLink></a>
  </div>
</section>
