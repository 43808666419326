<!-- access log -->
<section class="main">
  <div
    class="mb-3 d-flex align-items-center justify-content-between bntFilters titles"
  >
    <h1>Access Log</h1>
    <button class="btn btn1" (click)="exportData()">Export</button>
  </div>
  <section class="tableSection">
    <div class="filters">
      <div class="d-flex">
        <img
          class="burgerIcon"
          (click)="toggleFilter()"
          src="assets/images/icon-menu.png"
        />
        <span class="hint">Tab to show/hide filtration inputs</span>
      </div>
      <div *ngIf="filter_status" [@fadeInOut]>
        <form [formGroup]="filter_form">
          <div class="row">
            <div class="col-md-3 col-12">
              <label>Unit Name</label>
              <input
                formControlName="unit_name"
                type="text"
                class="form-control"
              />
            </div>
            <div class="col-md-3 col-12">
              <label>Client Name</label>
              <input formControlName="name" type="text" class="form-control" />
            </div>
            <div class="col-md-3 col-12">
              <label>Message</label>
              <input
                formControlName="message"
                type="text"
                class="form-control"
              />
            </div>
            <div class="col-md-3 col-12">
              <label>Registered to</label>
              <input
                formControlName="registered_to"
                type="text"
                class="form-control"
              />
            </div>
            <div class="col-10 mt-2">
              <label>Vehicle Plate </label>
              <div class="row d-flex align-items-center">
                <div class="col-md-3 col-5">
                  <input
                    type="text"
                    formControlName="number"
                    class="form-control"
                  />
                </div>
                <div class="col-md-2 col-2">
                  <input
                    type="text"
                    formControlName="charOne"
                    class="form-control"
                  />
                </div>
                <div class="col-md-2 col-2">
                  <input
                    type="text"
                    formControlName="charTow"
                    class="form-control"
                  />
                </div>
                <div class="col-md-2 col-2">
                  <input
                    type="text"
                    formControlName="charThree"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12 mt-3">
              <mat-form-field>
                <mat-label>Select Gate</mat-label>
                <mat-select multiple formControlName="controller_id">
                  <mat-option
                    value="{{ item.name }}"
                    *ngFor="let item of all_gates"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4 col-12 mt-3">
              <mat-form-field>
                <mat-label>Select Type</mat-label>
                <mat-select multiple formControlName="access_type">
                  <mat-option
                    value="{{ item.name }}"
                    *ngFor="let item of all_types"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4 col-12 mt-3">
              <mat-form-field>
                <mat-label>Select Direction</mat-label>
                <mat-select formControlName="direction">
                  <mat-option value="IN"> IN </mat-option>
                  <mat-option value="OUT"> OUT </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
              <label>From</label>
              <div class="d-flex align-items-center datePicker">
                <input
                  formControlName="valid_from"
                  class="form-control"
                  placeholder="Valid Form"
                  [matDatepicker]="pickerFrom"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="pickerFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <label>To</label>
              <div class="d-flex align-items-center datePicker">
                <input
                  formControlName="valid_to"
                  class="form-control"
                  placeholder="Valid To"
                  [matDatepicker]="pickerTo"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="pickerTo"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
              </div>
            </div>
          </div>
        </form>
        <div class="mt-3 bntFilters">
          <button class="btn btn1" (click)="filterData()">Filter</button>
          <button class="btn btn2" (click)="resetForm()">Reset</button>
        </div>
      </div>
    </div>

    <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

    <section class="tableUser">
      <div class="row mainRow">
        <div
          class="col-md-6 p-2 dataHolder"
          ngClass="{{
            gateData?.message.includes('Valid') ? 'greenMainRow' : 'redMainRow'
          }}"
          [@fadeInOut]
          *ngFor="let gateData of access_logs"
        >
          <div class="rowData">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-md-2 d-flex flex-wrap imagesDivHolder">
                <ngx-avatar
                  *ngIf="!gateData?.image"
                  class="mb-2"
                  size="70"
                  name="{{ gateData?.name }}"
                ></ngx-avatar>

                <img
                  *ngIf="gateData?.image"
                  [src]="gateData?.image"
                  class="imageClick userImage"
                  alt=""
                  (click)="openPhoto(photoDialog, gateData?.image)"
                />

                <img
                  *ngIf="gateData?.globalscene"
                  [src]="gateData?.globalscene"
                  class="imageClick"
                  alt=""
                  (click)="openPhoto(photoDialog, gateData?.globalscene)"
                />

                <img
                  *ngIf="gateData?.smallscene"
                  [src]="gateData?.smallscene"
                  class="imageClick"
                  alt=""
                  (click)="openPhoto(photoDialog, gateData?.smallscene)"
                />
              </div>

              <div class="col-md-8 userDetails">
                <ul>
                  <li>
                    <strong>Name:</strong> {{ gateData?.name | titlecase }}
                  </li>
                  <li>
                    <strong>Controller ID:</strong>
                    {{ gateData?.controller_id }}
                  </li>
                  <li><strong>Message:</strong> {{ gateData?.message }}</li>
                  <li>
                    <strong>Date and time:</strong>
                    [ {{ gateData?.timestamp | date }} ] [
                    {{ gateData?.timestamp | date : "shortTime" : "en-US" }} ]
                  </li>
                  <li>
                    <strong>Access type:</strong> {{ gateData?.access_type }}
                  </li>
                  <li *ngIf="gateData?.client_type">
                    <strong>Client type:</strong>
                    {{ gateData?.client_type | titlecase }}
                  </li>
                  <li><strong>Unit name:</strong> {{ gateData?.unit_name }}</li>

                  <li *ngIf="gateData?.plate_number">
                    <strong>Plate number:</strong> {{ gateData?.plate_number }}
                  </li>
                  <li *ngIf="gateData?.registered_to">
                    <strong>Registered to:</strong>
                    {{ gateData?.registered_to }}
                  </li>
                </ul>
              </div>

              <div
                class="col-md-2 d-flex justify-content-end align-items-start"
              >
                <div class="directionHolder">
                  <img
                    *ngIf="gateData.direction == 'IN'"
                    src="../../../../assets/images/goGreen.png"
                  />
                  <img
                    *ngIf="gateData.direction == 'OUT'"
                    src="../../../../assets/images/goRed.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div *ngIf="!showSpinner" class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="headTh">Access type</th>
            <th class="headTh">Unit</th>
            <th class="headTh">Activated</th>
            <th class="headTh">Name</th>
            <th class="headTh">Plate number</th>
            <th class="headTh">Registered to</th>
            <th class="headTh">Controller id</th>
            <th class="headTh">Direction</th>
            <th class="headTh">Machine ip</th>
            <th class="headTh">Message</th>
            <th class="headTh">Time stamp</th>
            <th class="headTh" style="width: 7%">Image</th>
            <th class="headTh" style="width: 7%">Global scene</th>
            <th class="headTh" style="width: 7%">Small scene</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of access_logs">
            <td>{{ item?.access_type }}</td>
            <td>{{ item?.unit_name }}</td>
            <td>{{ item?.activated }}</td>
            <td>{{ item?.name }}</td>
            <td>{{ item?.plate_number }}</td>
            <td>{{ item?.registered_to }}</td>
            <td>{{ item?.controller_id }}</td>
            <td>{{ item?.direction }}</td>
            <td>{{ item?.machine_ip }}</td>
            <td>{{ item?.message }}</td>
            <td>{{ item?.timestamp }}</td>
            <td>
              <img
                *ngIf="item?.image"
                style="width: 100%; height: 75px"
                [src]="item?.image"
              />
            </td>
            <td>
              <img
                *ngIf="item?.globalscene"
                style="width: 100%; height: 75px"
                [src]="item?.globalscene"
              />
            </td>
            <td>
              <img
                *ngIf="item?.globalscene"
                style="width: 100%; height: 75px"
                [src]="item?.smallscene"
              />
            </td>
          </tr>
        </tbody>
        <p *ngIf="access_logs?.length == 0">No Data Found</p>
      </table>
    </div> -->
  </section>

  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="pageNumber"
      [length]="pagesLength"
      [pageSize]="pageLimit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>

<ng-template #photoDialog let-data let-ref="dialogRef">
  <section class="photoHolder">
    <img [src]="photo" class="imageDivInHolder" />
  </section>
</ng-template>
