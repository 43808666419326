<section class="main">
  <div
    class="supmain mb-3 d-flex justify-content-between align-items-center flex-wrap"
  >
    <h3>
      <span>
        <img
          src="assets/images/mangeunit/back.png"
          class="backButton"
          (click)="goBack()"
        />
      </span>
      {{ unit_name }}
    </h3>

    <app-print-confirmation-modal
      #printModal
      (okClick)="onModalOk()"
      (cancelClick)="onModalCancel()"
      (backgroundClick)="onModalBackground()"
    ></app-print-confirmation-modal>

    <div
      class="icon d-flex justify-content-between align-items-center flex-wrap"
    >
      <img
        mat-raised-button
        matTooltip="Gates access"
        (click)="blockUnitGates(null)"
        matTooltipClass="example-tooltip-uppercase"
        [matTooltipPosition]="'above'"
        src="assets/images/mangeunit/1.png"
      />

      <img
        class="ms-3"
        mat-raised-button
        matTooltip="Add/Show Note"
        matTooltipClass="example-tooltip-uppercase"
        [matTooltipPosition]="'above'"
        (click)="addNote('unit', null)"
        src="assets/images/mangeunit/Group 170.png"
      />

      <img
        class="ms-3"
        mat-raised-button
        matTooltip="Merge Clients"
        matTooltipClass="example-tooltip-uppercase"
        [matTooltipPosition]="'above'"
        (click)="openToMerge()"
        src="assets/images/mangeunit/4.png"
      />

      <img
        class="ms-3"
        mat-raised-button
        matTooltip="Transfier Client"
        matTooltipClass="example-tooltip-uppercase"
        (click)="transferUnitsOpen()"
        [matTooltipPosition]="'above'"
        src="assets/images/mangeunit/2.png"
      />

      <img
        class="ms-3"
        mat-raised-button
        matTooltip="Import Existing Client"
        matTooltipClass="example-tooltip-uppercase"
        (click)="importClient()"
        [matTooltipPosition]="'above'"
        src="assets/images/mangeunit/3.png"
      />

      <img
        class="ms-3"
        mat-raised-button
        (click)="print_card_check_invoices('all')"
        matTooltip="Print Card"
        matTooltipClass="example-tooltip-uppercase"
        [matTooltipPosition]="'above'"
        src="assets/images/mangeunit/5.png"
      />

      <div class="actionColoredButtons">
        <button class="btn ms-3" (click)="addClient()">Add new client</button>
        <button class="btn ms-1" (click)="activityLog()">Activity Log</button>
        <button class="btn ms-1" (click)="invoices()">Invoices</button>
        <button class="btn ms-1" (click)="rentals()">Rentals</button>
        <button class="btn ms-1" (click)="qrCodes()">QR Codes</button>
      </div>
    </div>
    <!-- Import Existing Client -->
  </div>

  <!-- <div class="row">
    <div class="col-6">
      <div class="">
        <label>Unit name</label>
        <input
          [(ngModel)]="unitNameSearch"
          (input)="getUnits($event)"
          placeholder="Find another Unit"
          type="text"
          class="form-control"
        />
      </div>
      <div class="listSearch" *ngIf="allunits.length != 0">
        <ul>
          <li (click)="goUnit(item)" *ngFor="let item of allunits">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="mt-3">
      <mat-form-field
        [ngClass]="{ 'w-50': !isMobile, 'w-100': isMobile }"
        appearance="outline"
      >
        <mat-label>Unit name</mat-label>
        <mat-select
          [(ngModel)]="allunits"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option class="custom-search-input">
            <ngx-mat-select-search
              placeholderLabel="Search for units"
              noEntriesFoundLabel="'No data found'"
              (keyup)="searchForUnits(unit?.value)"
              #unit
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-spinner
            diameter="20"
            *ngIf="showSpinnerForPermission"
            class="mt-2 mb-2"
          ></mat-spinner>

          <mat-option
            *ngFor="let item of allunits"
            [value]="item"
            (click)="goUnit(item)"
          >
            <span *ngIf="!showSpinnerForPermission">
              {{ item?.name }}
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="mergeStatus" class="mangeUnits">
    <p>Merge Clients</p>
  </div>
  <div *ngIf="transferStatus" class="transferUnits">
    <p>Transfer Unit</p>
  </div>

  <!-- alert payment -->
  <!-- <div class="paymentAlert">
        <p>
            some payments are due for this unit, send a reminder to unit owner to take action now!
            <span> Send reminder More details</span>
        </p>
    </div> -->
  <!--  -->

  <!-- quota -->

  <div class="Quota mt-4">
    <p class="title mb-0">Quota</p>
    <!-- <div class="d-flex align-items-center datePicker">
      <input
        [(ngModel)]="dateCurrent"
        (dateInput)="getDateToGetQuota($event)"
        placeholder="yyyy/mm/dd"
        [matDatepicker]="picker"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div> -->

    <div class="d-flex align-items-center datePicker mt-2">
      <mat-form-field appearance="outline">
        <input
          [(ngModel)]="dateCurrent"
          matInput
          (dateInput)="getDateToGetQuota($event)"
          [matDatepicker]="picker"
        />

        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngIf="showQuotaAvalible" class="d-flex quotaDetails flex-wrap">
      <p class="me-3">
        <span>Priority : </span>
        {{ Priority }}
      </p>
      <p class="me-3">
        <span>Quota : </span>
        {{ Quota }}
      </p>
      <p class="me-3">
        <span>Generated invitations:</span>
        {{ Generated }}
      </p>
      <p class="me-3">
        <span>Used invitations:</span>
        {{ Used }}
      </p>
      <p>
        <span>Remaining invitations:</span>
        {{ Remaining }}
      </p>
    </div>
    <p *ngIf="!showQuotaAvalible">No Data Found</p>

    <!-- <p class="addQuota" (click)="add_quota()">
      Add quota
      <img src="assets/images/mangeunit/plus.png" />
    </p> -->

    <div class="icon d-flex align-items-center justify-content-between">
      <button class="btn" (click)="add_quota()">Add Quota</button>
      <button class="btn" (click)="registerAccess()">Register Access</button>
    </div>
  </div>
  <!-- end quta -->

  <!-- clients -->
  <section class="mt-5">
    <div class="mb-3 d-flex align-items-center justify-content-between">
      <h3>Clients</h3>

      <mat-form-field appearance="outline">
        <mat-select fullWidth placeholder="Select user type">
          <mat-option (click)="getUserType('all')" value="All">
            <strong> All </strong>
          </mat-option>

          <mat-option
            (click)="getUserType(item)"
            *ngFor="let item of cleintsKeys"
            [value]="item"
          >
            <strong>
              {{ item | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <div class="dropdown mb-0">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ Filter_by }}
          <img src="assets/images/mangeunit/arrow.png" />
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li (click)="getUserType('all')"><a class="dropdown-item">All</a></li>
          <li *ngFor="let item of cleintsKeys" (click)="getUserType(item)">
            <a class="dropdown-item">{{ item }}</a>
          </li>
        </ul>
      </div> -->
    </div>

    <div
      *ngIf="mergeButtonStatus"
      class="mt-2 mb-4 d-flex justify-content-between align-items-center flex-wrap"
    >
      <div class="actionMergeHints">
        <p class="mergeHint">{{ hintTextForMerging }}</p>
        <strong>Be sure that their NAMES are the same.</strong>
      </div>
      <div class="actionMergeButtons">
        <button
          class="btn"
          (click)="this.mergeButtonStatus = !this.mergeButtonStatus"
        >
          Cancel
        </button>
        <button class="btn mergeBtn" (click)="clickToMerge()">
          Merge Client
        </button>
      </div>
    </div>

    <!-- card -->

    <!-- <div id="cardPrint">
      <div class="card_front">
        <img [src]="cardObject.image" style="width: 10%" />
        <div>
          <p>{{cardObject.name}}</p>
          <p>{{cardObject.unitName}}</p>
          <p>{{cardObject.type}}</p>
          <p>{{cardObject.validFrom}} </p>
          <p>{{cardObject.validFrom}} </p>
        </div>
      </div>
      <div class="card_back">
         <img src="../../../../assets/images/teamCard.jpg">
      </div>
    </div> -->
    <div id="cardPrint">
      <div class="cards print" *ngFor="let item of dataPrint">
        <div class="front">
          <div class="background-image">
            <img [src]="item?.frontImage" />
          </div>

          <ul class="clientData">
            <li>
              <p style="margin: 0; padding: 0" class="clientName">
                {{ item?.name | uppercase }}
              </p>
            </li>
            <li>
              <p style="margin: 0; padding: 0" *ngIf="item.unitName != ''">
                <strong>Unit:</strong> {{ item?.unitName }}
              </p>
            </li>
            <li>
              <p style="margin: 0; padding: 0" *ngIf="item.villageName != ''">
                <strong>Zone:</strong> {{ item?.villageName }}
              </p>
            </li>
            <li *ngIf="item.userType == 'Tenant' || item.userType == 'tenant'">
              <p style="margin: 0; padding: 0">
                <strong
                  >[ {{ item?.validFrom }} ] : [ {{ item?.validTo }} ]</strong
                >
              </p>
            </li>
          </ul>

          <div class="centered-image">
            <img
              class="clientImage"
              *ngIf="!item.userType.includes('Guest')"
              [src]="item?.image"
              alt="Avatar"
            />
            <!-- <div class="containerr">
              <p class="clientName">{{ item.name | uppercase }}</p>
              <p *ngIf="item.unitName != ''">
                <strong>Unit:</strong> {{ item.unitName }}
              </p>
              <p *ngIf="item.villageName != ''">
                <strong>Zone:</strong> {{ item.villageName }}
              </p>
            </div> -->
          </div>
        </div>
        <div class="back">
          <div>
            <img [src]="item?.image1" />
          </div>
        </div>
      </div>
    </div>

    <button
      id="printButton"
      class="cardPrint"
      [useExistingCss]="true"
      printSectionId="cardPrint"
      ngxPrint
    >
      print
    </button>

    <mat-spinner diameter="50" *ngIf="showSpinner" class="mb-4"></mat-spinner>

    <div class="row" *ngIf="!showSpinner">
      <div class="col-md-4 col-12" *ngFor="let item of unit_clients">
        <div
          class="clientsCard"
          [ngClass]="{
            cardWhenMerge: mergeButtonStatus === true,
            clientsCardWhenBlock: item.cancelled === true
          }"
        >
          <div class="row info">
            <div class="col-4">
              <!-- <img class="user img-fluid" [src]="item.image" /> -->
              <img
                *ngIf="item.photo"
                class="user img-fluid"
                [src]="item.photo.changingThisBreaksApplicationSecurity"
              />

              <ngx-avatar
                *ngIf="!item.photo"
                size="80"
                name="{{ item?.name }}"
                cornerRadius="5"
                [round]="false"
              ></ngx-avatar>

              <!-- <img
                *ngIf="!item.photo"
                class="user img-fluid"
                src="assets/images/avatar.png"
              /> -->
            </div>
            <div class="col-7 p-0 ownerInfo">
              <p
                class="mb-2"
                style="cursor: pointer"
                (click)="viewClient(item.id)"
              >
                <img
                  *ngIf="item.verified == true"
                  src="assets/images/mangeunit/circle_check.png"
                />
                {{ item.name | titlecase }}
              </p>
              <div>
                <span *ngIf="item.relation" class="hint">{{
                  item.relation
                }}</span>
                <span *ngIf="item.verified == true" class="hint">Verified</span>
                <span *ngIf="item.activated == true" class="hint"
                  >Activated</span
                >
                <span *ngIf="item.multiple_units == true" class="hint"
                  >Multiple units</span
                >
                <span *ngIf="item.phone_number" class="hint">{{
                  item.phone_number
                }}</span>
                <span *ngIf="item.email" class="hint">{{ item.email }}</span>
              </div>
            </div>
            <div class="col-1 dots">
              <mat-menu #menu="matMenu">
                <p (click)="editClient(item.id)" class="menuItem" mat-menu-item>
                  Edit
                </p>
                <!-- <p class="menuItem" mat-menu-item>Merge</p> -->
                <p
                  *ngIf="item.cancelled === false"
                  (click)="openBlockClient('block', item)"
                  class="menuItem"
                  mat-menu-item
                >
                  Block
                </p>
                <p
                  *ngIf="item.cancelled === true"
                  (click)="openBlockClient('unblock', item)"
                  class="menuItem"
                  mat-menu-item
                >
                  Active
                </p>
                <p
                  (click)="openDeleteClient(item)"
                  class="menuItem"
                  mat-menu-item
                >
                  Delete
                </p>
              </mat-menu>
              <img
                [matMenuTriggerFor]="menu"
                src="assets/images/mangeunit/dots.png"
              />
            </div>
          </div>

          <div
            class="mt-4 icons d-flex align-items-center justify-content-between"
          >
            <!-- <img *ngIf="item.activated"
              (click)="activeClient(item)"
              mat-raised-button
              matTooltip="Deactivated"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              src="assets/images/mangeunit/smile.png"
            />
            <img *ngIf="!item.activated"
            (click)="activeClient(item)"
            mat-raised-button
            matTooltip="Activated"
            matTooltipClass="example-tooltip-uppercase"
            [matTooltipPosition]="'above'"
            src="assets/images/mangeunit/sad.png"
          /> -->
            <img
              (click)="generateInvitation(item)"
              mat-raised-button
              matTooltip="Invitations "
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              src="assets/images/mangeunit/Group 162.png"
            />

            <img
              (click)="blockUnitGates(item.id)"
              class="icon2"
              mat-raised-button
              matTooltip="Gate access"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              src="assets/images/mangeunit/Group 136.png"
            />

            <img
              (click)="addCard(item)"
              mat-raised-button
              matTooltip="Activate"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              class="icon3"
              src="assets/images/mangeunit/Group 160.png"
            />

            <div class="notes">
              <p *ngIf="item.notes.length != 0">
                <span>{{ item.notes.length }}</span>
              </p>
              <img
                (click)="addNote('client', item.id)"
                mat-raised-button
                matTooltip="Add/Show Note"
                matTooltipClass="example-tooltip-uppercase"
                [matTooltipPosition]="'above'"
                src="assets/images/mangeunit/Group 170.png"
              />
            </div>

            <img
              *ngIf="!item.printed"
              (click)="print_card_check_invoices(item)"
              mat-raised-button
              matTooltip="Print"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              src="assets/images/mangeunit/5.png"
            />

            <img
              *ngIf="item.printed"
              (click)="print_card_check_invoices(item)"
              mat-raised-button
              matTooltip="Print Again"
              matTooltipClass="example-tooltip-uppercase"
              [matTooltipPosition]="'above'"
              src="assets/images/mangeunit/greenPrint.png"
            />
          </div>

          <div
            class="cardCover"
            [ngClass]="{ cardBorder: item.status === true }"
            (click)="getSelectedItem(item)"
            *ngIf="mergeButtonStatus"
          ></div>
        </div>
      </div>
    </div>

    <h3 *ngIf="unit_clients.length == 0 && !showSpinner">No Data Found</h3>

    <!-- <div
      *ngIf="mergeButtonStatus"
      class="mt-2 d-flex justify-content-between align-items-center"
    >
      <p>Select the clients you want to merge</p>
      <div>
        <button class="btn">Cancel</button>
        <button class="btn mergeBtn" (click)="clickToMerge()">
          Merge Client
        </button>
      </div>
    </div> -->
  </section>
</section>

<!-- Button trigger modal -->
<button
  id="btn"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Merge clients</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to merge the selected clients?
      </div>

      <mat-slide-toggle
        class="example-margin"
        [color]="color"
        [checked]="replaceExtensionID"
        [(ngModel)]="replaceExtensionID"
      >
        Replace Extension ID
      </mat-slide-toggle>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="mergeClients()"
          data-bs-dismiss="modal"
        >
          Merge clients
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<button
  id="btn2"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal2"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal2"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Transfer Unit</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <label class="mb-2">Search about new unit</label>
          <input
            type="search"
            (input)="getUnits($event)"
            [(ngModel)]="unitsTransferInput"
            class="form-control"
            placeholder="ex:abcde"
          />
          <ul class="unitList" *ngIf="showlistUnits">
            <li (click)="getUnit(item)" *ngFor="let item of allunits">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="transfer()"
          data-bs-dismiss="modal"
        >
          Transfer
        </button>
      </div>
    </div>
  </div>
</div>

<!-- delete model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete client</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">Are you sure you want to delete the client?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteClient()"
          data-bs-dismiss="modal"
        >
          Delete client
        </button>
      </div>
    </div>
  </div>
</div>

<!-- blocked -->

<button
  id="btn4"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal4"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal4"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">
          {{ blockClientTitle }} client
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to {{ blockClientTitle }} the client?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="blockClient()"
          data-bs-dismiss="modal"
        >
          {{ blockClientTitle }} Client
        </button>
      </div>
    </div>
  </div>
</div>
