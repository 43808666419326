<section class="main">
  <h2>Add Gate</h2>
  <hr />

  <div [formGroup]="gate_form">
    <!-- <div class="mb-3">
      <label>Gate Name</label>
      <input type="text" formControlName="name" class="form-control" />
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Gate name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Choose Village</mat-label>
      <mat-select formControlName="village_id">
        <mat-option *ngFor="let item of village_list" value="{{ item.id }}">
          <strong>{{ item.name }}</strong>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Choose Gate Type</mat-label>
      <mat-select formControlName="type_id">
        <mat-option *ngFor="let item of gates_list" value="{{ item.id }}"
          ><strong>{{ item.name }}</strong></mat-option
        >
      </mat-select>
    </mat-form-field>
    <!-- <div>
      <label>anpr ip</label>
      <input formControlName="anpr_ip" type="text" class="form-control" />
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>ANPR IP</mat-label>
        <input matInput type="text" formControlName="anpr_ip" />
      </mat-form-field>
    </div>

    <!-- <div class="mt-3">
      <label>Gate id</label>
      <input formControlName="cctv_ip" type="text" class="form-control" />
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Gate ID</mat-label>
        <input matInput type="text" formControlName="cctv_ip" />
      </mat-form-field>
    </div>

    <!--
    <div class="mt-3">
      <label>face recognition ip</label>
      <input formControlName="face_rec_ip" type="text" class="form-control" />
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Face Recognition IP</mat-label>
        <input matInput type="text" formControlName="face_rec_ip" />
      </mat-form-field>
    </div>
  </div>

  <div class="footer mt-4">
    <button
      class="btn addGate"
      [disabled]="gate_form.invalid"
      (click)="addGate()"
    >
      Add Gate
    </button>
    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
