<section class="main" *ngIf="!data.RFID_data">
  <h2>Register Access</h2>
  <hr />

  <div [formGroup]="registerAccessForm">
    <mat-form-field appearance="outline">
      <mat-label>Access Type</mat-label>
      <mat-select formControlName="access_type_id">
        <mat-option
          (click)="selectAccessType()"
          *ngFor="let item of accessTypes"
          [value]="item?.id"
        >
          <strong>{{ item?.name }}</strong>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="d-flex justify-content-between align-items-center mb-3">
      <mat-slide-toggle
        *ngIf="showRegisterClientsAndSelectUser && !showAccessTypeOnly"
        formControlName="register_clients"
        [(ngModel)]="checked"
        (change)="registerClientsChange($event.checked)"
        >Register clients</mat-slide-toggle
      >

      <mat-slide-toggle *ngIf="RFIDSelection" formControlName="re_issue"
        >Re-issue</mat-slide-toggle
      >
    </div>
    <mat-form-field
      appearance="outline"
      *ngIf="showUnitUsersList && !showAccessTypeOnly"
    >
      <mat-label>Select User</mat-label>
      <mat-select multiple="true" formControlName="clients">
        <mat-option *ngFor="let item of unitUsers" [value]="item?.id">
          <strong>{{ item?.name }}</strong>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!showUnitUsersList && !showAccessTypeOnly">
      <mat-form-field appearance="outline">
        <mat-label>User Types</mat-label>
        <mat-select formControlName="user_type_id">
          <mat-option
            *ngFor="let item of userTypes"
            [value]="item?.id"
            (click)="selectUserType(item?.name)"
            ><strong>{{ item?.name }}</strong></mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="!showUnitUsersList && !showAccessTypeOnly"
        appearance="outline"
        class="w-100"
      >
        <mat-label>Amount</mat-label>
        <input matInput type="number" formControlName="amount" />
      </mat-form-field>

      <div class="row" *ngIf="showDateRange">
        <div class="col-md-6 col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Date from</mat-label>
            <input
              placeholder="dd/mm/yyyy"
              [matDatepicker]="pickerFrom"
              matInput
              type="text"
              formControlName="valid_from"
            />

            <mat-datepicker-toggle
              matIconSuffix
              [for]="pickerFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Time from</mat-label>
            <input
              matInput
              class="time-picker"
              type="time"
              id="appt"
              name="appt"
              formControlName="time_from"
            />
          </mat-form-field>
        </div>

        <div class="col-md-6 col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Date to</mat-label>
            <input
              placeholder="dd/mm/yyyy"
              [matDatepicker]="pickerTo"
              matInput
              type="text"
              formControlName="valid_to"
            />

            <mat-datepicker-toggle
              matIconSuffix
              [for]="pickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Time to</mat-label>
            <input
              matInput
              class="time-picker"
              type="time"
              id="appt"
              name="appt"
              formControlName="time_to"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="footer mt-4">
    <button
      *ngIf="!showAccessTypeOnly"
      class="btn addGate"
      [disabled]="registerAccessForm.invalid"
      (click)="registerAccess()"
    >
      Register
    </button>

    <button
      *ngIf="showAccessTypeOnly"
      class="btn addGate"
      [disabled]="registerAccessForm.invalid"
      (click)="registerAccessForPrintingAgin()"
    >
      Register
    </button>

    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>

<section class="main" *ngIf="data.RFID_data">
  <h2>Register Access</h2>
  <hr />

  <div [formGroup]="registerAccessForm">
    <mat-slide-toggle formControlName="re_issue">Re-issue</mat-slide-toggle>
  </div>

  <div class="footer mt-4">
    <button class="btn addGate" (click)="registerAccessForRIFDFromAddCard()">Register</button>

    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
