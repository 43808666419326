<section class="main">
  <h3>Edit role</h3>

  <mat-spinner diameter="30" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <form *ngIf="!showSpinner">
    <div class="row mt-3">
      <div class="col">
        <div class="form-group">
          <label>Role name</label>
          <input
            required
            name="name"
            [ngClass]="{ 'is-invalid': submitted && RoleName == '' }"
            [(ngModel)]="RoleName"
            placeholder="EX. Home"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <!--
        <mat-form-field>
            <mat-select multiple placeholder="Toppings" [(ngModel)]="selectedObjects" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let el of toppingList" [value]="el.value">
                    {{el.label}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

    <div class="mt-3">
      <p class="mb-0">Permissions</p>
      <mat-form-field>
        <mat-label>Permissions</mat-label>
        <mat-select
          multiple
          [(ngModel)]="permissions_arr"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option class="custom-search-input">
            <ngx-mat-select-search
              placeholderLabel="Search for permission"
              noEntriesFoundLabel="'No data found'"
              (keyup)="searchForPermission(unit?.value)"
              #unit
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-spinner
            diameter="20"
            *ngIf="showSpinnerForPermission"
            class="mt-2 mb-2"
          ></mat-spinner>

          <mat-option *ngFor="let item of all_permissions" [value]="item">
            {{ item?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="perChoise" *ngIf="permissions_arr.length != 0">
      <p *ngFor="let item of permissions_arr">
        {{ item.name }}
      </p>
    </div>

    <div class="addUnits d-flex justify-content-end mt-5 mb-2">
      <div>
        <button mat-dialog-close class="btn me-2">Cancel</button>
        <button mat-button class="btn addUnit" (click)="addRole()">Save</button>
      </div>
    </div>
  </form>
</section>
