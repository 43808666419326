<section class="main">
  <!-- <h1>Logs</h1>
  <mat-tab-group>
    <mat-tab label="Access logs">
      <app-access-list></app-access-list>
    </mat-tab>
    <mat-tab label="Operators logs">
      <app-oprreators-list></app-oprreators-list>
    </mat-tab>
  </mat-tab-group> -->



      <app-access-list></app-access-list>

</section>
