<section class="main m-0 mb-1">
  <h3 style="text-align: center" class="mb-4">Input filter</h3>
  <div class="row" [formGroup]="inputFilterForm">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Unit name</mat-label>
        <input matInput type="text" formControlName="unit_name" />
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Client name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Message</mat-label>
        <input matInput type="text" formControlName="message" />
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Registered to</mat-label>
        <input matInput type="text" formControlName="registered_to" />
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 col-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Vehicle plate numbers</mat-label>
            <input matInput type="text" formControlName="vehiclePlateNumbers" />
          </mat-form-field>
        </div>

        <div class="col-md-2 col-4">
          <mat-form-field appearance="outline" class="w-100">
            <!-- <mat-label>Vehicle plate</mat-label> -->
            <input
              matInput
              type="text"
              formControlName="vehiclePlateLetter1"
              maxlength="1"
            />
          </mat-form-field>
        </div>
        <div class="col-md-2 col-4">
          <mat-form-field appearance="outline" class="w-100">
            <!-- <mat-label>Vehicle plate</mat-label> -->
            <input
              matInput
              type="text"
              formControlName="vehiclePlateLetter2"
              maxlength="1"
            />
          </mat-form-field>
        </div>
        <div class="col-md-2 col-4">
          <mat-form-field appearance="outline" class="w-100">
            <!-- <mat-label>Vehicle plate</mat-label> -->
            <input
              matInput
              type="text"
              formControlName="vehiclePlateLetter3"
              maxlength="1"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select gate</mat-label>
        <mat-select
          multiple
          fullWidth
          placeholder="Select gate"
          formControlName="gate"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option [value]="item?.name" *ngFor="let item of gatesArray">
            <strong>{{ item?.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select gate type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select gate type"
          formControlName="gateType"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option *ngFor="let item of gateTypesArr" [value]="item.id">
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select direction</mat-label>
        <mat-select
          fullWidth
          placeholder="Select direction"
          formControlName="direction"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option *ngFor="let item of gateDirection" [value]="item">
            <strong>
              {{ item | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>From</mat-label>
        <input
          matInput
          formControlName="from"
          class="form-control"
          [matDatepicker]="pickerFrom"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFrom"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>To</mat-label>
        <input
          matInput
          formControlName="to"
          class="form-control"
          [matDatepicker]="pickerTo"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTo"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="addUnits d-flex justify-content-center">
    <div>
      <button class="btn addUnit" (click)="applyInputFilter()">Apply</button>
    </div>
  </div>
</section>

<!-- [disabled]="inputFilterForm.invalid" -->
