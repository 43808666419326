import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients.service';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

interface clients {
  name: string;
  units: any;
  phone_number: string;
}

@Component({
  selector: 'app-main-family',
  templateUrl: './main-family.component.html',
  styleUrls: ['./main-family.component.css'],
})
export class MainFamilyComponent implements OnInit {
  showSpinner: boolean = true;
  current_page = 0;
  pageIndex = 0;
  page_size = 50;
  totalCount = 0;

  clients: any;
  constructor(private clientService: ClientsService, private route: Router) {}
  ngOnInit() {
    this.init_data();
  }

  init_data() {
    this.clientService
      .all_unverifiedClient(this.current_page, this.page_size)
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.clients = res?.results;
        this.totalCount = res?.total_count;
      });
  }
  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.pageIndex = e.pageIndex;
    this.current_page = e.pageIndex + 1;
    this.page_size = e.pageSize;
    this.init_data();
  }

  user_details(item: any) {
    localStorage.setItem('user_family', JSON.stringify(item));
    this.route.navigate(['pages/family_user_info', item.name]);
  }

  goToUnit(name: any) {
    this.route.navigate(['pages/unit_details', name]);
  }
}
