import { Component, OnInit } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { VillageService } from 'src/app/services/village.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MangeUnitsComponent } from '../mange-units/mange-units.component';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addunit',
  templateUrl: './addunit.component.html',
  styleUrls: ['./addunit.component.css'],
})
export class AddUnitComponent implements OnInit {
  showSpinnerForProjects: boolean = true;
  villageListDisable: boolean = true;

  typename: any = 'EX. Rental';
  unitTypeArray: any;
  projectsArray: any;
  villagesArray: any;
  buildingZoneArray: any;
  addUnitForm: FormGroup | any;
  projectNameSearchKey: any = '';

  constructor(
    private rest: UnitsService,
    public dialogRef: MatDialogRef<MangeUnitsComponent>,
    private apis: VillageService,
    private route: Router,
    private main: MainService
  ) {}

  ngOnInit() {
    this.getVillage();
    this.getProjects();
    this.getTypes();
    this.getAddUnitForm();
  }

  getAddUnitForm() {
    this.addUnitForm = new FormGroup({
      projectName: new FormControl(null, [Validators.required]),
      villageName: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      buildingZone: new FormControl({ value: null, disabled: true }),
      unitName: new FormControl(null, [Validators.required]),
      extensionID: new FormControl(null, [Validators.required]),
      unitType: new FormControl(null, [Validators.required]),
    });
  }

  getVillage() {
    this.apis.villageList().subscribe((res: any) => {
      this.villagesArray = res?.results;
    });
  }

  selectVillage(village: any) {
    this.buildingZoneArray = village?.buildings;
    this.addUnitForm.get('buildingZone').enable();
  }

  getProjects() {
    this.apis
      .projectListWithSearch(this.projectNameSearchKey)
      .subscribe((res: any) => {
        this.projectsArray = res;
        this.showSpinnerForProjects = false;
      });
  }

  searchForProject(searchKey: any) {
    this.showSpinnerForProjects = true;
    this.projectNameSearchKey = searchKey;
    this.getProjects();
  }

  selectProject(project: any) {
    this.villagesArray = project?.villages;
    this.addUnitForm.get('villageName').enable();
  }

  getTypes() {
    this.rest.getTypes().subscribe((res: any) => {
      this.unitTypeArray = res?.children;
    });
  }

  chooseType(value: any) {
    this.typename = value?.name;
  }

  addUnit() {
    if (this.addUnitForm.invalid) {
      this.addUnitForm.markAllAsTouched();
      return;
    }

    if (this.addUnitForm.get('buildingZone').value) {
      const { villageName, unitName, extensionID, unitType, buildingZone } =
        this.addUnitForm.value;

      const unit = {
        name: unitName,
        village_id: villageName,
        ext_id: extensionID,
        unit_type_id: unitType,
        building_id: buildingZone,
      };

      this.rest.addUnit(unit).subscribe(
        (res: any) => {
          this.main.SuccessToast('Unit Added Successfully');
          this.route.navigate([
            'pages/complete-unite-details',
            res,
            this.typename,
          ]);
          this.dialogRef.close();
        },
        (err: any) => {
          if (err?.error?.error_reason == 'Unit_400') {
            this.main.infoToast(err?.error?.message);
          } else {
            this.main.errorToast('Something wrong');
          }
        }
      );
    } else {
      const { villageName, unitName, extensionID, unitType } =
        this.addUnitForm.value;

      const unit = {
        name: unitName,
        village_id: villageName,
        ext_id: extensionID,
        unit_type_id: unitType,
      };

      this.rest.addUnit(unit).subscribe(
        (res: any) => {
          this.main.SuccessToast('Unit Added Successfully');
          this.route.navigate([
            'pages/complete-unite-details',
            res,
            this.typename,
          ]);
          this.dialogRef.close();
        },
        (err: any) => {
          if (err?.error?.error_reason == 'Unit_400') {
            this.main.infoToast(err?.error?.message);
          } else {
            this.main.errorToast('Something wrong');
          }
        }
      );
    }
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addUnitForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
}
