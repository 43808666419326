<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center supmain">
    <h3>Manage Villages</h3>
    <div class="icon">
      <!-- <img  src="assets/images/mangeunit/1.png">
            <img class="ms-3" src="assets/images/mangeunit/4.png">
            <img class="ms-3" src="assets/images/mangeunit/2.png">
            <img class="ms-3" src="assets/images/mangeunit/3.png">
            <img class="ms-3" src="assets/images/mangeunit/5.png"> -->
      <button class="btn ms-3" (click)="add_Village()">Add new Village</button>
    </div>
  </div>

  <div class="searchInput mb-3">
    <input
      type="search"
      [(ngModel)]="villageName"
      (input)="searchVillage()"
      class="form-control"
      placeholder="Search Village"
    />
  </div>
  <!-- table Village -->
  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinner" class="tableUnit table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Village name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of villages_arr">
          <td class="d-flex align-items-center">
            <p (click)="VillageDetails(item.id)" style="cursor: pointer">
              {{ item?.name }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 *ngIf="villages_arr?.length == 0">No Data Found</h3>
  </section>

  <div>
    <mat-paginator
      (page)="changePage($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="9"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>
