import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { UserAccessConfigurationComponent } from '../user-access-configuration/user-access-configuration.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { VillageService } from 'src/app/services/village.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-add-user-access-configuration',
  templateUrl: './add-user-access-configuration.component.html',
  styleUrls: ['./add-user-access-configuration.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class AddUserAccessConfigurationComponent implements OnInit {
  showSpinnerForProjects: boolean = true;
  projectNameSearchKey: any = '';
  projectsArray: any;

  types_arr: any;
  accessTypes: any;
  villages_arr: any;
  userTypesArray: any;
  showSpinner: boolean = true;
  pipe = new DatePipe('en-US');
  insured: boolean | null = null;
  disableTypesList: boolean = true;
  addUserAccessConfigurationForm: FormGroup | any;

  constructor(
    private unitService: UnitsService,
    private rest: VillageService,
    public dialogRef: MatDialogRef<UserAccessConfigurationComponent>
  ) {}

  ngOnInit() {
    this.initForm();
    this.getUserTypes();
    this.getAccessTypes();
    this.getProjects();
  }
  selectAllLabel = 'Select All';
  selectAllValue = 'all';

  initForm() {
    this.addUserAccessConfigurationForm = new FormGroup({
      userTypes: new FormControl(null),
      user_type_id: new FormControl(null, [Validators.required]),
      villages: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      access_type_id: new FormControl(null, [Validators.required]),
      maximum_first_cards: new FormControl(null, [Validators.required]),
      maximum_extra_cards: new FormControl(null, [Validators.required]),
      first_issue_fees: new FormControl(null, [Validators.required]),
      extra_cards_fees: new FormControl(null, [Validators.required]),
      reissuance_fees: new FormControl(null, [Validators.required]),
      insured: new FormControl(null, [Validators.required]),
      insurance_fees: new FormControl(null, [Validators.required]),
      minimum_stay_price: new FormControl(null, [Validators.required]),
      cards_per_bedroom: new FormControl(null, [Validators.required]),
      expiry_date: new FormControl(null, [Validators.required]),
      minimum_stay: new FormControl(null, [Validators.required]),
    });

    this.formCheck();

    if (this.disableTypesList) {
      this.addUserAccessConfigurationForm.get('user_type_id').disable();
    }
  }

  searchForProject(searchKey: any) {
    this.showSpinnerForProjects = true;
    this.projectNameSearchKey = searchKey;
    this.getProjects();
  }

  getProjects() {
    this.rest
      .projectListWithSearch(this.projectNameSearchKey)
      .subscribe((res: any) => {
        this.projectsArray = res;
        this.showSpinnerForProjects = false;
      });
  }

  selectProject(project: any) {
    this.villages_arr = project?.villages;
    this.addUserAccessConfigurationForm.get('villages').enable();
  }

  getUserTypes() {
    this.unitService.userTypes().subscribe((res: any) => {
      this.showSpinner = false;
      this.userTypesArray = res?.children;
    });
  }

  selectUserType(userType: any) {
    this.types_arr = userType?.children;
    this.addUserAccessConfigurationForm.get('user_type_id').enable();
  }

  getAccessTypes() {
    this.unitService.cardType().subscribe((res: any) => {
      this.accessTypes = res?.children;
    });
  }

  onInsuredChange(event: any): void {
    this.insured = event?.value;
    if (this.insured) {
      this.addUserAccessConfigurationForm.get('insurance_fees').enable();
    } else {
      this.addUserAccessConfigurationForm.get('insurance_fees').disable();
    }
  }

  addUserAccessConfiguration() {
    const {
      user_type_id,
      villages,
      access_type_id,
      maximum_extra_cards,
      first_issue_fees,
      reissuance_fees,
      insured,
      insurance_fees,
      cards_per_bedroom,
      expiry_date,
      minimum_stay,
      maximum_first_cards,
      minimum_stay_price,
      extra_cards_fees,
    } = this.addUserAccessConfigurationForm.value;

    const addUserConfigInputs: any = {
      user_type_id,
      villages,
      access_type_id,
      maximum_extra_cards,
      first_issue_fees,
      reissuance_fees,
      insured,
      insurance_fees,
      cards_per_bedroom,
      maximum_first_cards,
      minimum_stay_price,
      extra_cards_fees,
      expiry_date: this.pipe.transform(expiry_date, 'YYYY-MM-dd'),
      minimum_stay,
    };

    if (!this.insured) {
      addUserConfigInputs.insurance_fees = 0;
    }

    addUserConfigInputs.villages = addUserConfigInputs.villages.filter(
      (item: any) => item !== 'all'
    );

    this.dialogRef.close(addUserConfigInputs);
  }

  //Form Validator
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addUserAccessConfigurationForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
  //Form Validator

  formCheck() {
    this.addUserAccessConfigurationForm
      .get('villages')
      .valueChanges.subscribe((values: any) => {
        if (this.isAllSelected()) {
          if (!values?.includes(this.selectAllValue)) {
            this.addUserAccessConfigurationForm
              .get('villages')
              .setValue(
                [
                  this.selectAllValue,
                  ...this.villages_arr.map((v: any) => v.id),
                ],
                { emitEvent: false }
              );
          }
        } else {
          const newValues = values?.filter(
            (v: any) => v !== this.selectAllValue
          );
          if (
            values?.includes(this.selectAllValue) &&
            !this.areEqual(
              newValues,
              this.villages_arr.map((v: any) => v.id)
            )
          ) {
            this.addUserAccessConfigurationForm
              .get('villages')
              .setValue(newValues, { emitEvent: false });
          } else if (newValues?.length === this.villages_arr?.length) {
            this.addUserAccessConfigurationForm
              .get('villages')
              .setValue(
                [
                  this.selectAllValue,
                  ...this.villages_arr.map((v: any) => v.id),
                ],
                { emitEvent: false }
              );
          }
        }
      });
  }

  // Select All Scenario
  toggleSelectAll() {
    const control = this.addUserAccessConfigurationForm.get('villages');
    if (this.isAllSelected()) {
      control.setValue([]);
    } else {
      control.setValue([
        this.selectAllValue,
        ...this.villages_arr.map((v: any) => v.id),
      ]);
    }
  }

  onSelectionChange(event: any) {
    const control = this.addUserAccessConfigurationForm.get('villages');
    const selectedValues = control.value;
    if (
      selectedValues?.includes(this.selectAllValue) &&
      !this.isAllSelected()
    ) {
      control.setValue([
        this.selectAllValue,
        ...this.villages_arr.map((v: any) => v.id),
      ]);
    } else if (
      !selectedValues?.includes(this.selectAllValue) &&
      this.isAllSelected()
    ) {
      control.setValue(
        control.value?.filter((value: any) => value !== this.selectAllValue)
      );
    }
  }

  isAllSelected() {
    const control = this.addUserAccessConfigurationForm.get('villages');
    const selectedValues = control.value;
    return this.villages_arr?.length === selectedValues?.length - 1;
  }

  areEqual(array1: any[], array2: any[]) {
    return (
      array1?.length === array2?.length &&
      array1.every((value) => array2?.includes(value))
    );
  }
}
