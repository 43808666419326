<section class="main">
  <div
    class="mb-3 d-flex justify-content-between align-items-center flex-wrap supmain"
  >
    <h3>Edit Unit Details</h3>

    <div class="actions d-flex mt-2">
      <button
        [disabled]="
          editUnitDetailsForm.invalid ||
          unitImageToShow.length == 0 ||
          googleMapErrorMessage ||
          !vrImageEdit
        "
        class="btn me-2 w-100"
        (click)="saveUnitDetails()"
      >
        Save
      </button>
    </div>
  </div>

  <mat-spinner diameter="50" *ngIf="showSpinner" class="mt-4"></mat-spinner>

  <section
    *ngIf="!showSpinner"
    class="tableUnit table-responsive completeUnitDetailsBody"
    [formGroup]="editUnitDetailsForm"
  >
    <div class="mb-4">
      <strong>Garden status:</strong>

      <br />

      <div class="row">
        <div class="col-md-3">
          <mat-slide-toggle
            class="example-margin mt-2 me-2"
            [color]="color"
            [checked]="checked"
            (change)="statusChange($event)"
            formControlName="gardened"
          >
            {{ gardenStatus }}
          </mat-slide-toggle>
        </div>
        <div class="col-md-3">
          <mat-slide-toggle
            class="example-margin mt-2"
            [color]="color"
            [checked]="checked"
            (change)="deliveryChange($event)"
            formControlName="delivered"
          >
            {{ deliveryStatus }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="outline" title="Unit name" class="w-100">
          <mat-label>Unit name</mat-label>
          <input matInput type="text" formControlName="unitName" />

          <mat-error *ngIf="isControlHasError('unitName', 'required')">
            Unit name is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="outline" title="Extension ID" class="w-100">
          <mat-label>Extension ID</mat-label>
          <input matInput type="text" formControlName="extensionId" />
          <mat-error *ngIf="isControlHasError('extensionId', 'required')">
            Extension ID is required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Unit type</mat-label>
          <mat-select
            fullWidth
            placeholder="Select village"
            class="d-felx justify-content-center align-items-center"
            formControlName="unitType"
          >
            <mat-option
              *ngFor="let item of typeArray"
              [value]="item?.id"
              (click)="chooseType(item)"
            >
              <strong>
                {{ item?.name | titlecase }}
              </strong>
            </mat-option>
          </mat-select>

          <mat-error *ngIf="isControlHasError('unitType', 'required')">
            Unit type is required
          </mat-error>
        </mat-form-field>
      </div> -->
    </div>

    <div class="row">
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          title="Unit floor no."
          class="w-100"
        >
          <mat-label>Unit floor no.</mat-label>
          <input matInput type="text" formControlName="unitFloor" />

          <mat-error *ngIf="isControlHasError('unitFloor', 'required')">
            Unit floor number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="outline" title="Bedrooms no." class="w-100">
          <mat-label>Rooms no.</mat-label>
          <input matInput type="text" formControlName="roomsNumber" />
          <mat-error *ngIf="isControlHasError('roomsNumber', 'required')">
            Rooms number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="outline" title="Bedrooms no." class="w-100">
          <mat-label>Beds no.</mat-label>
          <input matInput type="text" formControlName="bedsNumber" />
          <mat-error *ngIf="isControlHasError('bedsNumber', 'required')">
            Beds number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          title="Bathrooms no."
          class="w-100"
        >
          <mat-label>Bathrooms no.</mat-label>
          <input matInput type="text" formControlName="bathroomNumber" />
          <mat-error *ngIf="isControlHasError('bathroomNumber', 'required')">
            Bathrooms number is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3 d-flex justify-content-between">
        <mat-form-field appearance="outline" class="me-2" title="Area">
          <mat-label>Unit area</mat-label>
          <input matInput type="text" formControlName="area" />
          <mat-error *ngIf="isControlHasError('area', 'required')">
            Unit area is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="me-2" title="Garden area">
          <mat-label>Garden area</mat-label>
          <input matInput type="text" formControlName="gardenArea" />
          <mat-error *ngIf="isControlHasError('gardenArea', 'required')">
            Garden area is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3 d-flex justify-content-between">
        <mat-form-field
          appearance="outline"
          title="Garden area measuring unit"
          class="w-100"
        >
          <mat-label>Measuring unit</mat-label>
          <input matInput type="text" formControlName="measuringUnit" />
          <mat-error *ngIf="isControlHasError('measuringUnit', 'required')">
            Measuring unit is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          title="Minimum person capacity"
          class="w-100"
        >
          <mat-label>Minimum person capacity</mat-label>
          <input matInput type="text" formControlName="minimumPersonCapacity" />
          <mat-error
            *ngIf="isControlHasError('minimumPersonCapacity', 'required')"
          >
            Minimum person capacity is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          title="Maximum person capacity"
          class="w-100"
        >
          <mat-label>Maximum person capacity</mat-label>
          <input matInput type="text" formControlName="maximumPersonCapacity" />
          <mat-error
            *ngIf="isControlHasError('maximumPersonCapacity', 'required')"
          >
            Maximum person capacity is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div>
      <strong>Unit Location:</strong>

      <div class="row mt-3">
        <div class="col-md-3">
          <mat-form-field appearance="outline" title="Country" class="w-100">
            <mat-label>Country</mat-label>
            <input matInput type="text" formControlName="country" />
            <mat-error *ngIf="isControlHasError('country', 'required')">
              Country is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" title="City" class="w-100">
            <mat-label>City</mat-label>
            <input matInput type="text" formControlName="city" />
            <mat-error *ngIf="isControlHasError('city', 'required')">
              City is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field
            appearance="outline"
            title="Governorate"
            class="w-100"
          >
            <mat-label>Governorate</mat-label>
            <input matInput type="text" formControlName="governorate" />
            <mat-error *ngIf="isControlHasError('governorate', 'required')">
              Governorate is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="outline" title="Street" class="w-100">
            <mat-label>Street</mat-label>
            <input matInput type="text" formControlName="street" />
            <mat-error *ngIf="isControlHasError('street', 'required')">
              Street is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <strong>Select your location on Google Maps:</strong>

      <div class="mt-3">
        <input type="button" id="map" hidden (click)="sendDataToMap()" />

        <label for="map" class="googleMapButton">
          <img
            class="googleMapLogo"
            src="../../../../assets/images/googleMaps.png"
            alt=""
          />
        </label>
        <mat-error *ngIf="googleMapErrorMessage">
          You should select your location on Map
        </mat-error>

        <mat-hint class="success-hint" *ngIf="googleMapSuccessMessage">
          You location is selected successfully
        </mat-hint>
      </div>
    </div>

    <div class="mb-4">
      <strong>Add unit pictures:</strong>
      <div class="row mt-3 d-flex">
        <div
          *ngFor="let image of unitImageToShow"
          class="image-container"
          (mouseenter)="showButton(image)"
          (mouseleave)="hideButton(image)"
        >
          <img [src]="image?.url" class="image" />
          <button class="remove-button" (click)="removeImage(image)">
            Remove
          </button>
        </div>

        <input
          type="file"
          id="file"
          hidden
          (change)="uploadDocumentClient($event)"
          #fileInput
          multiple
        />

        <label for="file" class="addNewImages" title="Upload unit photos"
          >+</label
        >
      </div>

      <div *ngIf="errorUnitPhotos" class="alertError">
        <span>Please upload unit photos</span>
      </div>
    </div>

    <div>
      <strong>Add VR pictures:</strong>
      <div class="row mt-3 d-flex">
        <div *ngIf="vrImageEdit" class="image-container">
          <img [src]="url" class="image" />
          <button class="remove-button" (click)="removeUserImage()">
            Remove
          </button>
        </div>

        <input
          type="file"
          id="VrImage"
          hidden
          (change)="uploadVRImage($event)"
          #fileInput2
        />

        <label
          for="VrImage"
          class="uploadVRImage"
          title="Upload unit photos"
          *ngIf="!vrImageEdit"
        >
          <div>
            <img
              class="icon"
              src="../../../../assets/images/upload.png"
              alt=""
            />
            <br />
            <strong>Upload Image</strong>
            <p>JPG up to 5MB</p>
          </div></label
        >
      </div>
    </div>
  </section>
</section>

<ngx-google-map
  *ngIf="showGoogleMapsComponent"
  [data]="locationData"
  [show]="showGoogleMapsComponent"
  (hideModal)="showGoogleMapsComponent = $event"
  (mapData)="receiveData($event)"
></ngx-google-map>
