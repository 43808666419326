import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPromoCodeComponent } from './add-promo-code/add-promo-code.component';
import { PromoCodeListComponent } from './promo-code-list/promo-code-list.component';
import { EditPromoCodeComponent } from './edit-promo-code/edit-promo-code.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
  ],
  declarations: [
    PromoCodeListComponent,
    AddPromoCodeComponent,
    EditPromoCodeComponent,
  ],
})
export class PromoCodesModule {}
