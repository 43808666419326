<section class="main">
  <h2>Add User Config</h2>
  <hr />

  <mat-spinner diameter="20" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <form *ngIf="!showSpinner" [formGroup]="Village_form">
    <mat-form-field appearance="outline">
      <mat-label>Choose Village</mat-label>
      <mat-select multiple formControlName="villages">
        <mat-option *ngFor="let item of village_list" [value]="item.id">
          {{ item.name | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Choose User Type</mat-label>
      <mat-select formControlName="userTypes">
        <mat-option
          *ngFor="let userType of userTypesArray"
          [value]="userType.id"
          (click)="selectUserType(userType)"
        >
          {{ userType.name | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Choose Type</mat-label>
      <mat-select formControlName="user_type">
        <mat-option *ngFor="let item of types_arr" [value]="item.id">
          {{ item.name | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <mat-slide-toggle formControlName="anti_passback" [color]="color">
        Anti passback
      </mat-slide-toggle>
    </div>

    <div class="mt-3">
      <mat-slide-toggle formControlName="vip_access" [color]="color">
        Vip access
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="applying_quota" [color]="color">
        Applying quota
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="owner_existence" [color]="color">
        Owner existence
      </mat-slide-toggle>
    </div>
  </form>

  <div *ngIf="!showSpinner" class="footer mt-4">
    <button class="btn addGate" (click)="add_user_config()">
      Add User Config
    </button>
    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
