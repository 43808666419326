<section class="main">
  <h1 *ngIf="!statusOfEdit">Add Utility</h1>
  <h1 *ngIf="statusOfEdit">Edit Utility</h1>
  <div *ngIf="!statusOfImage">
    <form>
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Name</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="utilityName"
            name="utilityName"
          />
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="!statusOfEdit" appearance="outline">
        <mat-label>Projects</mat-label>
        <mat-select [(ngModel)]="project_id" name="project_id">
          <mat-option value="{{ item.id }}" *ngFor="let item of all_projects">
            {{ item?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Unit measure</mat-label>
          <input matInput type="text" [(ngModel)]="measure" name="measure" />
        </mat-form-field>
      </div>

      <div class="mt-3">
        <mat-slide-toggle
          [(ngModel)]="disabledApp"
          name="disabledApp"
          [color]="color"
        >
          Disabled in Mobile APP
        </mat-slide-toggle>
      </div>

      <div class="btnSubmit">
        <button *ngIf="!statusOfEdit" class="btn" (click)="addUtility()">
          Next
        </button>
        <button *ngIf="statusOfEdit" class="btn" (click)="editUtility()">
          Edit Utility
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="statusOfImage">
    <div class="mt-4">
      <p>Utility image</p>
      <label for="images" class="drop-container">
        <input
          (change)="uploadUtilityImage($event)"
          type="file"
          id="images"
          accept="image/*"
          required
        />
      </label>
    </div>
    <div class="btnSubmit">
      <button class="btn" (click)="addUtilityImage()">Add Utility</button>
    </div>
  </div>
</section>
