import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperatorsMainComponent } from '../opreators-main/opreators-main.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VillageService } from 'src/app/services/village.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-edit-opreators',
  templateUrl: './edit-opreators.component.html',
  styleUrls: ['./edit-opreators.component.css'],
})
export class EditOperatorsComponent implements OnInit {
  OperatorForm: FormGroup | any;
  roles_list: any;
  cardPermission = false;
  villages_list: any;
  villages_arr: any;
  rols_list: any;
  roles_arr: any;
  list_projects: any;
  project_list: any;
  savedRoles_array: any = [];
  constructor(
    public dialogRef: MatDialogRef<OperatorsMainComponent>,
    private rest: VillageService,
    private rolesService: RolesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initForm();
    this.getVillages();
    this.getRoles();
    this.getProjects();
  }

  initForm() {
    this.OperatorForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
    });

    this.OperatorForm.patchValue(this.data);
    this.roles_list = this.data.role;
  }

  getVillages() {
    this.rest.villageList().subscribe((res: any) => {
      this.villages_arr = res?.results;
    });
  }

  getRoles() {
    this.rolesService.retrieveOperators().subscribe((res: any) => {
      this.roles_arr = res;
    });
  }

  getProjects() {
    this.rest.projectList().subscribe((res: any) => {
      this.project_list = res;
    });
  }

  saveCard() {
    if (this.status_radio == true) {
      this.villages_list.forEach((element: any) => {
        this.savedRoles_array.push({
          roles: this.rols_list,
          village: element.name,
          villageId: element.id,
          name: 'village',
        });
      });
    } else {
      this.list_projects.forEach((element: any) => {
        this.savedRoles_array.push({
          roles: this.rols_list,
          village: element.name,
          villageId: element.id,
          name: 'project',
        });
      });
    }
    this.cardPermission = false;
    // this.cardPermission = false
    // this.roleStatus = true
  }

  status_radio = true;

  changeRadio(e: any) {
    this.status_radio = !this.status_radio;
  }

  addPermission() {
    this.cardPermission = true;
  }

  addOperators() {
    let data: any = { data: this.OperatorForm.value };
    data.data.user_id = this.data.id;
    data.rolesArr = this.savedRoles_array;
    this.dialogRef.close(data);
  }

  removeRole(item: any) {
    // let obj = {
    //   user_id: this.data?.id,
    //   village_id: item.id,
    //   role_id: item.id,
    // };
    for (let i = 0; i < this.roles_list.length; i++) {
      if (this.roles_list[i].name == item.name) {
        this.roles_list.splice(i, 1);
        // this.rolesService.deleteRole().subscribe((res: any) => {
        //   this.roles_arr = res;
        // });
      }
    }
  }
}
