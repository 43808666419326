import { Component, OnInit } from '@angular/core';
import { VillageService } from 'src/app/services/village.service';
import { ThemePalette } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainGatesComponent } from '../main-gates/main-gates.component';
import { MatDialogRef } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-add-user-config',
  templateUrl: './add-user-config.component.html',
  styleUrls: ['./add-user-config.component.css'],
})
export class AddUserConfigComponent implements OnInit {
  showSpinner: boolean = true;
  village_list: any;
  color: ThemePalette = 'primary';
  checked = false;
  Village_form: FormGroup | any;
  types_arr: any;
  userTypesArray: any;
  disableTypesList: boolean = true;

  constructor(
    private villageSer: VillageService,
    private unitService: UnitsService,
    public dialogRef: MatDialogRef<MainGatesComponent>
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.allVillage();
    this.initForm();
    this.users_type();
    this.getUserTypes();
  }

  initForm() {
    this.Village_form = new FormGroup({
      villages: new FormControl('', [Validators.required]),
      userTypes: new FormControl(''),
      user_type: new FormControl('', [Validators.required]),
      anti_passback: new FormControl(null),
      vip_access: new FormControl(null),
      applying_quota: new FormControl(null),
      owner_existence: new FormControl(null),
    });

    if (this.disableTypesList) {
      this.Village_form.get('user_type').disable();
    }
  }

  getUserTypes() {
    this.unitService.userTypes().subscribe((res: any) => {
      this.showSpinner = false;
      this.userTypesArray = res?.children;
    });
  }

  selectUserType(userType: any) {
    this.types_arr = userType?.children;
    this.Village_form.get('user_type').enable();
  }

  users_type() {
    this.unitService.unitRelation().subscribe((res: any) => {
      this.types_arr = res.children;
    });
  }

  allVillage() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.showSpinner = false;
      this.village_list = res?.results;
    });
  }

  add_user_config() {
    const {
      villages,
      user_type,
      anti_passback,
      vip_access,
      applying_quota,
      owner_existence,
    } = this.Village_form.value;

    const addUserConfigInputs: any = {
      villages,
      user_type,
      owner_existence: owner_existence === true,
      applying_quota: applying_quota === true,
      vip_access: vip_access === true,
      anti_passback: anti_passback === true,
    };
    this.dialogRef.close(addUserConfigInputs);
  }
}
