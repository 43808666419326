<section class="main">
  <h1>Edit RFID</h1>
  <hr />
  <div class="row">
    <div class="col-12">
      <label>Vehicle Number</label>
      <input [(ngModel)]="VehicleNumber" type="text" class="form-control" />
    </div>
    <div class="col-12 mt-3">
      <label>Front Image</label>
      <br />
      <img class="img-fluid" [src]="frontImage" />
      <input (change)="onFileChange($event, 'front')" type="file" />
      <br />
      <span>New front image will Replace with old front image</span>
    </div>
    <div class="col-12 mt-3">
      <label>Back Image</label>
      <br />
      <img class="img-fluid" [src]="backImage" />
      <input (change)="onFileChange($event, 'back')" type="file" />
      <br />
      <span>New back image will Replace with old back image</span>
    </div>
    <div class="col-12 mt-3">
      <label>Serial number</label>
      <input [(ngModel)]="serialNum" type="text" class="form-control" />
    </div>
    <div class="col-12 mt-3">
      <label>Card ID</label>
      <input [(ngModel)]="Card_ID" type="text" class="form-control" />
    </div>

    <div class="mt-3">
      <button (click)="editRFId()" class="btn edit">Edit</button>
      <button class="btn" mat-dialog-close>Cancel</button>
    </div>
  </div>
</section>
