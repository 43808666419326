import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedInStatus = JSON.parse(
    localStorage.getItem('loggedIn') || 'false'
  );

  constructor(private http: HttpClient) {}

  // auth

  login(data: any) {
    return this.http.post(`${environment.baseUrl}/auth/login`, data);
  }
  getLoginStatus() {
    return JSON.parse(localStorage.getItem('loggedIn') || 'false');
  }

  setLoginStatus(value: any) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', 'true');
  }

  isTokenExpired(token: string): boolean {
    if (!token) return true; // Token not provided
    try {
      const decoded: any = jwtDecode(token);
      const expirationTime = decoded.exp * 1000; // Convert to milliseconds
      const currentTime = new Date().getTime();
      return currentTime > expirationTime;
    } catch (error) {
      return true; // Token decoding failed
    }
  }
}
