<div class="live-gate">
    <div class="mb-4 d-flex justify-content-between align-items-center supmain ">
        <h4>{{gate_type_name}}</h4>
        <!-- <p class="more" (click)="moreGate()">
            More Gate
            <img style="width: 28px" src="assets/images/arrow_rigth.png">
        </p> -->
    </div>
    <!-- <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{selectGate}}
                <img src="assets/images/mangeunit/arrow.png">
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li *ngFor="let item of gats_arr" (click)="chooseLiveGate(item)">
                    <a class="dropdown-item">{{item.name}}</a>
                </li>
            </ul>
        </div> -->
    <!-- <mat-form-field appearance="fill">
        <mat-label>All Gates</mat-label>
        <mat-select multiple [(value)]="selectedGate" >
            <mat-option (onSelectionChange)="gateChange($event)" *ngFor="let item of gats_arr" [value]="item">{{item.name}}</mat-option>
        </mat-select>
    </mat-form-field> -->
    <div class="row align-items-center">
        <div class="col-12" *ngFor="let item of selectedGate">
            <div class="text-center">
                <h2>{{item.name}}</h2>
                <ngx-charts-pie-grid  (select)="getChart($event,item.stasticsLiveGates)" [designatedTotal]='item.show'
                    [view]="view" [scheme]="colorScheme" [results]="item.stasticsLiveGates">
                </ngx-charts-pie-grid>
                <div class="infoCard" *ngFor="let chart of item.stasticsLiveGates">
                    <h4 class="pt-2" *ngIf="chart.status == true">{{item.name}} - {{chart.name}}</h4>
                    <ngx-charts-pie-grid *ngIf="chart.status == true" [view]="viewtow" [scheme]="colorScheme"
                        [results]="chart.option">
                    </ngx-charts-pie-grid>
                </div>

            </div>

        </div>
        <!-- <div class="col-3 offset-3">
                <div class="categories">
                    <p>Other categories</p>
                    <ul>
                        <li class="bg d-flex align-items-center justify-content-between">
                            <span>VIP</span>
                            <span>20</span>
                        </li>
                        <li class=" d-flex align-items-center justify-content-between">
                            <span>Operators</span>
                            <span>15</span>
                        </li>
                        <li class="bg d-flex align-items-center justify-content-between">
                            <span>Admin</span>
                            <span>3</span>
                        </li>
                        <li class=" d-flex align-items-center justify-content-between">
                            <span>Other</span>
                            <span>8</span>
                        </li>
                    </ul>
                </div>
            </div> -->
    </div>
</div>