import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLogsComponent } from './main-logs/main-logs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OperatorsListComponent } from './oprreators-list/oprreators-list.component';
import { AccessListComponent } from './access-list/access-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarModule } from 'ngx-avatar';
import { MatInputModule } from '@angular/material/input';
import { ReplaceUnderscorePipe } from 'src/app/shared/pipes/replaceUnderscore.pipe';

@NgModule({
  declarations: [
    MainLogsComponent,
    OperatorsListComponent,
    AccessListComponent,
    ReplaceUnderscorePipe,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    AvatarModule,
    MatInputModule,
  ],
})
export class AllLogsModule {}
