import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup | any;

  constructor(
    private rest: AuthService,
    private service: MainService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.rest.login(this.loginForm.value).subscribe(
        (res: any) => {
          this.rest.setLoginStatus(true);
          localStorage.setItem('permissions', JSON.stringify(res.permissions));
          localStorage.setItem('acc_token', res?.access_token);
          localStorage.setItem('userName', res?.user?.name);
          this.service.SuccessToast('Login Success');
          this.router.navigate(['pages/Dashboard']);
        },
        (err: any) => {
          this.service.errorToast('Please check Email or Password');
        }
      );
    }
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }
}
