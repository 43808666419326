<section class="main">
  <section class="sup_main">
    <div class="title mb-3">
      <h1>{{ user?.name | titlecase }} | <small>Manage Clients</small></h1>
    </div>
    <div class="row">
      <div class="col-md-4 col-12">
        <div class="info-container">
          <div class="text-center">
            <img class="img-fluid" src="assets/images/bgFamily.png" />
            <img
              *ngIf="user.images.length != 0"
              src="{{ user?.images[0]?.image }}"
              class="img-fluid userRealImage"
            />

            <ngx-avatar
              *ngIf="user?.images?.length == 0"
              class="img-fluid user_image"
              size="110"
              name="{{ user?.name }}"
              initialsSize="2"
              cornerRadius="11"
            ></ngx-avatar>
          </div>
          <p class="text-center mt-2 userName">{{ user?.name | titlecase }}</p>
          <hr />
          <div class="d-flex align-items-center info mb-3">
            <img src="assets/images/Bagde.png" />
            <div>
              <p class="first_title">User name</p>
              <p class="second_title mb-0">{{ user?.name | titlecase }}</p>
            </div>
          </div>
          <div class="d-flex align-items-center info mb-3">
            <img src="assets/images/Bagde-1.png" />
            <div>
              <p class="first_title">Units</p>
              <p class="second_title mb-0" *ngIf="user?.unit?.length != 0">
                {{ user.unit[0].name | titlecase }}
              </p>
              <p class="second_title mb-0" *ngIf="user?.unit?.length == 0">
                None
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center info mb-3">
            <img src="assets/images/Bagde-2.png" />
            <div>
              <p class="first_title">E-mail</p>
              <p class="second_title mb-0" *ngIf="user?.email">
                {{ user?.email }}
              </p>
              <p class="second_title mb-0" *ngIf="!user?.email">None</p>
            </div>
          </div>
          <div class="d-flex align-items-center info">
            <img src="assets/images/Bagde-3.png" />
            <div>
              <p class="first_title">Phone Number</p>
              <p class="second_title mb-0" *ngIf="user?.phone_number">
                {{ user?.phone_number }}
              </p>
              <p class="second_title mb-0" *ngIf="!user?.phone_number">None</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-12 otherDocument">
        <div class="documents">
          <h1>Other documents</h1>
        </div>
      </div>
    </div>
    <div class="title mt-2 footer">
      <button class="btn" (click)="reject()">Reject</button>
      <button class="btn" (click)="activeClient()">Accept</button>
    </div>
  </section>
</section>
