import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditClientComponent } from '../edit-client/edit-client.component';

@Component({
  selector: 'app-delet-rfid',
  templateUrl: './delet-rfid.component.html',
  styleUrls: ['./delet-rfid.component.css'],
})
export class DeletRfidComponent implements OnInit {
  VehicleNumber: any;
  constructor(
    public dialogRef: MatDialogRef<EditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.VehicleNumber = this.data.item.license_plate;
  }

  delete() {
    this.dialogRef.close('true');
  }
}
