<section class="main">
  <h1>Edit Operator</h1>
  <form [formGroup]="OperatorForm">
    <div>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>UserName</mat-label>
        <input matInput formControlName="username" type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input matInput formControlName="phone_number" type="text" />
      </mat-form-field>
    </div>
  </form>

  <div *ngIf="roles_list.length != 0">
    <h1 class="mt-2 mb-2">Roles</h1>
    <div class="perChoise mb-1">
      <span *ngFor="let item of roles_list" class="me-3">
        <span style="padding: 2px 10px; margin: 0">
          {{ item.name | titlecase }}
        </span>
        <span class="deletePermission" (click)="removeRole(item)">X</span>
      </span>
    </div>
  </div>

  <!--  -->

  <div *ngIf="savedRoles_array.length != 0">
    <div class="permission mt-2" *ngFor="let item of savedRoles_array">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Village / Projects</h4>
        <img style="margin-top: -13px" src="assets/images/edit.png" />
      </div>
      <p class="subTitle">{{ item.village }}</p>

      <div class="roles">
        <p>Roles</p>
        <div>
          <span class="hint" *ngFor="let role of item.roles">
            {{ role.name }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="permission mt-2 p-4" *ngIf="cardPermission">
    <div class="d-flex align-items-center justify-content-around">
      <div>
        <input
          class="me-1"
          [checked]="status_radio"
          (change)="changeRadio($event)"
          type="radio"
          id="1"
          name="age"
          value="1"
        />
        <label for="1">Villages</label>
      </div>
      <div>
        <input
          (change)="changeRadio($event)"
          class="me-1"
          type="radio"
          id="2"
          name="age"
          value="2"
        />
        <label for="2">Project</label>
      </div>
    </div>

    <div *ngIf="status_radio">
      <div class="mt-2">
        <mat-form-field appearance="outline">
          <mat-label>Villages name</mat-label>
          <mat-select multiple name="name" [(ngModel)]="villages_list">
            <mat-option *ngFor="let item of villages_arr" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select roles</mat-label>
          <mat-select multiple name="name" [(ngModel)]="rols_list">
            <mat-option *ngFor="let item of roles_arr" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!status_radio">
      <div class="mt-2">
        <mat-form-field appearance="outline">
          <mat-label>Projects name</mat-label>
          <mat-select multiple name="name" [(ngModel)]="list_projects">
            <mat-option *ngFor="let item of project_list" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select roles</mat-label>
          <mat-select multiple name="name" [(ngModel)]="rols_list">
            <mat-option *ngFor="let item of roles_arr" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-4 cardFooter">
      <span style="cursor: pointer" (click)="saveCard()">Save</span>
      <span style="cursor: pointer" (click)="cardPermission = !cardPermission"
        >cancel</span
      >
    </div>
  </div>

  <button class="btn addPermission" (click)="addPermission()">
    Add new role
  </button>

  <div class="f-b mt-4">
    <button class="btn cancel" mat-dialog-close>Cancel</button>
    <button class="btn add" (click)="addOperators()">Edit operator</button>
  </div>
</section>
