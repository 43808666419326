import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { OperatorsService } from 'src/app/services/opreators.service';
import { PageEvent } from '@angular/material/paginator';
import { MainService } from 'src/app/services/main.service';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-oprreators-list',
  templateUrl: './oprreators-list.component.html',
  styleUrls: ['./oprreators-list.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class OperatorsListComponent implements OnInit {
  validFromControlName: string = 'valid_from';
  validToControlName: string = 'valid_to';

  showSpinner: boolean = true;
  filter_form: FormGroup | any;
  all_gates: any;
  all_types: any;
  current_page = 1;
  pagesLength = 1;
  pageNumber = 0;
  pageLimit = 100;
  pipe = new DatePipe('en-US');
  operators_logs: any = [];

  tableHeaderArray: string[] = [];
  excludedKeys: Set<string> = new Set(['type', 'id', '_id']); // Add the keys you want to exclude here

  constructor(
    private rest: OperatorsService,
    private formBuilder: FormBuilder,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.initFormFilet();
    this.retrieveOperatorsLog();
  }

  initFormFilet() {
    this.filter_form = this.formBuilder.group({
      email: [''],
      operator_name: [''],
      valid_to: [''],
      valid_from: [''],
    });
  }
  retrieveOperatorsLog(value?: any) {
    let data: any;
    if (value) {
      Object.keys(value).forEach((key) => {
        if (value[key] === '' || value[key] === null) {
          delete value[key];
        }
      });
      value.page = this.current_page;
      value.page_limit = this.pageLimit;
      data = value;
    } else {
      data = {
        page: this.current_page,
        page_limit: this.pageLimit,
      };
    }

    this.rest.retrieveLogs(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.operators_logs = res?.operator_logs;
      this.collectUniqueKeys();
      this.pagesLength = res?.total_number;
    });
  }

  collectUniqueKeys() {
    const uniqueKeysSet: Set<string> = new Set();
    this.operators_logs.forEach((obj: any) => {
      Object.keys(obj).forEach((key) => {
        if (!this.excludedKeys.has(key)) {
          uniqueKeysSet.add(key);
        }
      });
    });
    this.tableHeaderArray = Array.from(uniqueKeysSet);
  }

  objectEntries(obj: any): [string, any][] {
    return Object.entries(obj);
  }

  filterData() {
    this.showSpinner = true;
    let data = this.filter_form.value;
    data.valid_from = this.pipe.transform(data.valid_from, 'yyyy-MM-dd');
    data.valid_to = this.pipe.transform(data.valid_to, 'yyyy-MM-dd');
    this.retrieveOperatorsLog(data);
  }

  exportData() {
    let data = this.filter_form.value;
    data.valid_from = this.pipe.transform(data.valid_from, 'yyyy-MM-dd');
    data.valid_to = this.pipe.transform(data.valid_to, 'yyyy-MM-dd');

    Object.keys(data).forEach((key) => {
      if (data[key] === '' || data[key] === null) {
        delete data[key];
      }
    });
    data.page = this.current_page;
    data.page_limit = this.pagesLength;
    this.rest.exportLogs(data).subscribe((res: any) => {
      if (res) {
        window.open(`res`, '_blank');
      } else {
        this.mainService.errorToast('No data Found');
      }
    });
  }

  resetForm() {
    this.filter_form.reset();
    this.retrieveOperatorsLog();
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.pageNumber = e.pageIndex;
    this.current_page = e.pageIndex + 1;
    this.pageLimit = e.pageSize;
    this.retrieveOperatorsLog();
  }
}
