<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center">
    <h3>User card configuration</h3>
  </div>

  <div class="mb-4 d-flex justify-content-between align-items-center flex-wrap">
    <div class="filters d-flex justify-content-between align-items-center">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose project</mat-label>
        <mat-select>
          <mat-option value="" (click)="selectAllProject()">
            <strong>All</strong>
          </mat-option>

          <mat-option
            value="{{ item.name }}"
            *ngFor="let item of projectsArray"
            (click)="selectProject(item)"
          >
            <strong>{{ item.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inputButton">
      <button class="btn addNewImage me-2" (click)="addNewImage()">
        Add new image
      </button>
    </div>
  </div>

  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinner" class="tableUnit table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Project name</th>
          <th scope="col">User type</th>
          <th scope="col">Front Image</th>
          <th scope="col">Back Image</th>
          <th scope="col" class="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="configurationRow"
          *ngFor="let item of allConfiguration; let i = index"
        >
          <td>
            <div class="d-flex align-items-center">
              <strong class="projectOrType" (click)="openConfiguration(item)">{{
                item.project | titlecase
              }}</strong>
            </div>
          </td>
          <td>
            <strong class="projectOrType" (click)="openConfiguration(item)">{{
              item.type | titlecase
            }}</strong>
          </td>
          <td>
            <img
              *ngIf="item?.frontPhoto"
              [src]="item?.frontPhoto"
              class="configurationImage"
              (click)="openPhoto(photoDialog, item?.frontPhoto)"
            />
          </td>
          <td>
            <img
              *ngIf="item?.backPhoto"
              [src]="item?.backPhoto"
              class="configurationImage"
              (click)="openPhoto(photoDialog, item?.backPhoto)"
            />
          </td>
          <td class="actionColumn">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="moreDetailsButton"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editConfiguration(item)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <button mat-menu-item (click)="openDeleteConfiguration(item)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <!-- <button mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button> -->
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 *ngIf="allConfiguration == null">No Data Found</h3>
  </section>

  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>

<ng-template #photoDialog let-data let-ref="dialogRef">
  <section class="photoHolder">
    <img [src]="photo" class="imageDivInHolder" />
  </section>
</ng-template>

<!-- deleted model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete configuration</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete the configuration?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteConfiguration()"
          data-bs-dismiss="modal"
        >
          Delete configuration
        </button>
      </div>
    </div>
  </div>
</div>
