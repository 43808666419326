import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveGatsComponent } from './live-gats/live-gats.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DashboardComponent } from './dashbaord/dashbaord.component';
import { LiveGateTypeComponent } from './live-gate-type/live-gate-type.component';
import { SystemAnalyticsComponent } from './system-analytics/system-analytics.component';
import { GateMonitorComponent } from './gate-monitor/gate-monitor.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { GateMonitorInputFilterComponent } from './gate-monitor-input-filter/gate-monitor-input-filter.component';
import { AvatarModule } from 'ngx-avatar';

@NgModule({
  declarations: [
    LiveGatsComponent,
    DashboardComponent,
    LiveGateTypeComponent,
    SystemAnalyticsComponent,
    GateMonitorComponent,
    GateMonitorInputFilterComponent,
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    AvatarModule,
  ],
})
export class MainDashboardModule {}
