<section class="main">
  <h1>Invitations</h1>
  <hr />
  <form [formGroup]="invite">
    <div class="row">
      <div class="col-md-6 col-12">
        <!-- <div>
          <label>Client</label>
          <input
            disabled
            value="{{ clientName }}"
            class="form-control"
            type="text"
          />
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Client</mat-label>
          <input matInput type="text" value="{{ clientName }}" disabled />
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <!-- <div>
          <label>Unit</label>
          <input
            disabled
            value="{{ unitName }}"
            class="form-control"
            type="text"
          />
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Unit</mat-label>
          <input
            matInput
            disabled
            value="{{ unitName }}"
            class="form-control"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Date from</mat-label>
          <input
            placeholder="dd/mm/yyyy"
            [matDatepicker]="pickerFrom"
            matInput
            type="text"
            formControlName="valid_from"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>

          <mat-error *ngIf="isControlHasError('valid_from', 'required')">
            Date from is required
          </mat-error>
        </mat-form-field>

        <!-- <label>From</label>
        <div class="mb-3 d-flex align-items-center datePicker">
          <input
            formControlName="valid_from"
            class="form-control"
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerFrom"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </div> -->
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Time from</mat-label>
          <input
            matInput
            class="time-picker"
            type="time"
            id="appt"
            name="appt"
            formControlName="time_from"
          />


          <mat-error *ngIf="isControlHasError('time_from', 'required')">
            Time from is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Date to</mat-label>
          <input
            placeholder="dd/mm/yyyy"
            [matDatepicker]="pickerTo"
            matInput
            type="text"
            formControlName="valid_to"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>

          <mat-error *ngIf="isControlHasError('valid_to', 'required')">
            Date to is required
          </mat-error>
        </mat-form-field>

        <!-- <label>To</label>
        <div class="mb-3 d-flex align-items-center datePicker">
          <input
            formControlName="valid_to"
            class="form-control"
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerTo"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </div> -->
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Time to</mat-label>
          <input
            matInput
            class="time-picker"
            type="time"
            id="appt"
            name="appt"
            formControlName="time_to"
          />

          <mat-error *ngIf="isControlHasError('time_to', 'required')">
            Time to is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <!-- <div>
          <label>QR Type</label>
          <div class="dropdown mb-0">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ Choose_QR_Type }}
              <img src="assets/images/mangeunit/arrow.png" />
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li *ngFor="let item of QrTypes_array">
                <a (click)="getType(item)" class="dropdown-item">
                  {{ item.name }}</a
                >
              </li>
            </ul>
          </div>
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>QR Type</mat-label>
          <mat-select
            fullWidth
            placeholder="Select QR Type"
            class="d-felx justify-content-center align-items-center"
          >
            <mat-option
              (click)="getType(item)"
              *ngFor="let item of QrTypes_array"
              [value]="item?.id"
            >
              <strong>
                {{ item.name | titlecase }}
              </strong>
            </mat-option>

            <mat-option [disabled]="true" *ngIf="!QrTypes_array?.length">
              <strong>No Qr types found</strong>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="typeID == undefined || !typeID">
            Qr type is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <!-- <div>
          <label>Customer Name </label>
          <input
            formControlName="customer_name"
            class="form-control"
            type="text"
          />
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer Name</mat-label>
          <input matInput type="text" formControlName="customer_name" />
          <mat-error *ngIf="isControlHasError('customer_name', 'required')">
            Customer name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <!-- <div>
          <label>Customer Phone</label>
          <input
            formControlName="phone_number"
            class="form-control"
            type="text"
          />
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer Phone</mat-label>
          <input matInput type="text" formControlName="phone_number" />
          <mat-error *ngIf="isControlHasError('phone_number', 'required')">
            Phone is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <!-- <div>
          <label>Customer Email</label>
          <input formControlName="email" class="form-control" type="text" />
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer Email</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            Email is required
          </mat-error>

          <mat-error *ngIf="isControlHasError('email', 'email')">
            Email is invalid
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- <div>
          <label>People count</label>
          <input formControlName="count" class="form-control" type="number" />
        </div> -->

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>People count</mat-label>
          <input matInput type="number" formControlName="count" />
          <mat-error *ngIf="isControlHasError('count', 'required')">
            People count is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-4 text-center">
      <button
        [disabled]="invite.invalid || typeID == undefined || !typeID"
        (click)="generateInvitation('normal')"
        class="btn generate"
      >
        Generate
      </button>
      <button
        [disabled]="invite.invalid || typeID == undefined || !typeID"
        (click)="generateInvitation('sms')"
        class="btn generate ms-2"
      >
        SMS
      </button>
    </div>
  </form>
</section>
