<section class="main">
    <section class="sup_main">
        <h1>Activity Log</h1>
        <hr>
        <div class="header">
            <div>
                <p>Estimate #01092</p>
            </div>
            <div>
                <button class="btn">Convert to invoice</button>
                <button class="btn">Print Estimate</button>
            </div>
        </div>
        <p class="actionTitle mt-3 mb-4">All Actions</p>
        <button class="btn btnDay mb-3">Sunday</button>
        <div class="row">
            <div class="col-6">
                <div class="mb-4" *ngFor="let item of [1,2,3] ; let i = index">
                    <div class="cards d-flex">
                        <div class="step">
                            <img [ngClass]="{'active': i == 0 }" src="assets/images/liner.png">
                            <div *ngIf="i != 2" class="line"></div>
                        </div>
                        <div class="sup_card">
                            <p>September 12, 2023 | 08:00</p>
                            <p>You updated the estimate # 01092 for Arabtec Construction L.L.C (#00002) totalling
                                3,682.20</p>
                            <div class="d-flex align-items-center">
                                <span>
                                    <img src="assets/images/fUser.png">
                                </span>
                                <span>Ahmed Elshaari</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</section>