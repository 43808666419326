<section class="main">
  <h1 *ngIf="!status_edit">Add Section</h1>
  <h1 *ngIf="status_edit">Edit Section</h1>
  <hr />
  <div>
    <form [formGroup]="addForm">
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" type="text" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Start</mat-label>
          <input matInput formControlName="start" type="number" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>End</mat-label>
          <input matInput formControlName="end" type="number" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Price</mat-label>
          <input matInput formControlName="price" type="number" />
        </mat-form-field>
      </div>

      <button *ngIf="!status_edit" class="mt-3 btn" (click)="addSection()">
        Add Section
      </button>
      <button *ngIf="status_edit" class="mt-3 btn" (click)="editSection()">
        Edit Section
      </button>
    </form>
  </div>
</section>
