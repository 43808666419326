import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PayInvoiceComponent } from '../pay-invoice/pay-invoice.component';
import { UnitsService } from 'src/app/services/units.service';
import { MainService } from 'src/app/services/main.service';
import { RegisterAccessComponent } from '../register-access/register-access.component';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
  selector: 'app-unpaid-invoices',
  templateUrl: './unpaid-invoices.component.html',
  styleUrls: ['./unpaid-invoices.component.css'],
})
export class UnpaidInvoicesComponent implements OnInit {
  //   unPaidInvoices: any;
  //   permissions: any[] = [];

  //   constructor(
  //     public dialogRef: MatDialogRef<UnitDetailsComponent>,
  //     @Inject(MAT_DIALOG_DATA) public data: any,
  //     private unitService: UnitsService,
  //     private main: MainService,
  //     public dialog: MatDialog
  //   ) {}

  //   ngOnInit() {
  //     this.unPaidInvoices = this.data?.upPaidInvoices;
  //     this.logPermissions();
  //   }

  //   payInvoice(invoiceId: any) {
  //     const dialogRef = this.dialog.open(PayInvoiceComponent, {
  //       width: '90%',
  //       maxWidth: '450px',
  //       data: {
  //         invoiceId: invoiceId,
  //       },
  //     });

  //     dialogRef.afterClosed().subscribe((result: any) => {
  //       if (result) {
  //         this.unitService.payInvoice(result).subscribe((res: any) => {
  //           if (res.status) {
  //             this.main.SuccessToast(res?.message);
  //             this.dialog.closeAll();
  //           } else {
  //             this.main.errorToast(res?.message);
  //           }
  //         });
  //       }
  //     });
  //   }

  //   checkPermissions() {
  //     const permissions = localStorage.getItem('permissions');
  //     return permissions ? JSON.parse(permissions) : [];
  //   }

  //   logPermissions(): void {
  //     this.permissions = this.checkPermissions();
  //   }

  //   checkForPayInvoicePermission() {
  //     return this.permissions.some(
  //       (obj: any) => obj.name.toLowerCase() === 'pay invoice'
  //     );
  //   }
  // }

  upPaidInvoice: any;
  promoCode: any;
  permissions: any[] = [];
  promoCodeVerifiedSuccessfully: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private unitService: UnitsService,
    private main: MainService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RegisterAccessComponent>,
    private readonly promoCodeService: PromoCodeService
  ) {}

  ngOnInit() {
    this.upPaidInvoice = this.data.result;
    console.log(this.upPaidInvoice);

    this.logPermissions();
  }

  payInvoice(invoiceId: any) {
    const data: any = { invoiceId: invoiceId };

    if (this.promoCode) {
      data.promoCode = this.promoCode;
    }

    const dialogRef = this.dialog.open(PayInvoiceComponent, {
      width: '90%',
      maxWidth: '450px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.unitService.payInvoice(result).subscribe((res: any) => {
          if (res.status) {
            this.main.SuccessToast(res?.message);
            this.dialogRef.close(res);
          } else {
            this.main.errorToast(res?.message);
          }
          this.dialogRef.close(res);
        });
      }
    });
  }

  checkPermissions() {
    const permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  logPermissions(): void {
    this.permissions = this.checkPermissions();
  }

  checkForPayInvoicePermission() {
    return this.permissions.some(
      (obj: any) => obj.name.toLowerCase() === 'pay invoice'
    );
  }

  verifyPromoCode() {
    this.promoCodeService
      .verifyPromoCodeService(this.promoCode, this.upPaidInvoice?.ammount)
      .subscribe((res: any) => {
        if (res.status == false) {
          this.main.errorToast(res.message);
        } else {
          this.main.SuccessToast('Promo code verified');
          this.promoCodeVerifiedSuccessfully = true;
          this.upPaidInvoice.ammount = res?.total_ammount;
        }
      });
  }
}
