import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  constructor(private http: HttpClient) {}

  allUnits(name: string, type: string) {
    return this.http.get(
      `${environment.baseUrl}/units/retrieve_unit_types/?unit_type=${type}&unit_name=${name}`
    );
  }
  allUnitsPagination(page: number, limit: number, unitName: any) {
    return this.http.get(
      `${environment.baseUrl}/units/?page=${page}&limit=${limit}&unit_name=${unitName}`
    );
  }

  searchUnit(unitName: string) {
    return this.http.get(`${environment.baseUrl}/units/?unit_name=${unitName}`);
  }

  unitDetails(unitName: string) {
    return this.http.get(
      `${environment.baseUrl}/units/getUnitData/?unit_name=${unitName}`
    );
  }

  unitDetailsDescription(unitId: string) {
    return this.http.get(
      `${environment.baseUrl}/units/description?unit_id=${unitId}`
    );
  }

  addUnit(unitData: any) {
    return this.http.post(`${environment.baseUrl}/units/`, unitData);
  }

  editUnitDetails(unitDetails: any) {
    return this.http.put(
      `${environment.baseUrl}/units/description`,
      unitDetails
    );
  }

  completeUnitDetails(unitDetails: any) {
    return this.http.post(
      `${environment.baseUrl}/units/description`,
      unitDetails
    );
  }

  userTypes() {
    return this.http.get(`${environment.baseUrl}/types/?name=User Types`);
  }

  unitRelation() {
    return this.http.get(`${environment.baseUrl}/types/?name=unit relations`);
  }

  unitRelations() {
    return this.http.get(`${environment.baseUrl}/types/?name=relations`);
  }

  blockUnitForGate(data: any) {
    return this.http.post(`${environment.baseUrl}/gates/blacklist_gate`, data);
  }

  whiteListGateUnit(data: any) {
    return this.http.post(`${environment.baseUrl}/gates/whitelist_unit`, data);
  }

  blackListForClient(data: any) {
    return this.http.post(
      `${environment.baseUrl}/gates/blacklist_clients`,
      data
    );
  }

  whiteListForClient(data: any) {
    return this.http.post(
      `${environment.baseUrl}/gates/whitelist_clients`,
      data
    );
  }

  retrieveUnitBlackList(id: any) {
    return this.http.get(
      `${environment.baseUrl}/gates/blacklist_gate?unit_id=${id}`
    );
  }

  retrieveClientBlackList(id: any) {
    return this.http.get(
      `${environment.baseUrl}/gates/client_gates?client_id=${id}`
    );
  }

  removeBlackListGateClient(data: any) {
    var myHeaders: any = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: data,
      redirect: 'follow',
    };
    return this.http.delete(
      `${environment.baseUrl}/gates/blacklist_clients`,
      requestOptions
    );
  }
  removeWhiteListGateClient(data: any) {
    var myHeaders: any = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: data,
      redirect: 'follow',
    };
    return this.http.delete(
      `${environment.baseUrl}/gates/whitelist_clients`,
      requestOptions
    );
  }

  // unit types

  getTypes(typeName?: string) {
    if (typeName) {
      return this.http.get(`${environment.baseUrl}/types/?name=${typeName}`);
    } else {
      return this.http.get(`${environment.baseUrl}/types/?name=Unit Type`);
    }
  }

  transferUnit(data: any) {
    return this.http.put(`${environment.baseUrl}/client/transfer`, data);
  }

  // addClient

  addClient(client: any) {
    return this.http.post(`${environment.baseUrl}/client/`, client);
  }
  addClientImage(id: any, file: any) {
    let auth_token = localStorage.getItem('acc_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth_token}`,
    });

    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var formdata = new FormData();
    file.forEach((element: any) => {
      formdata.append(element.type, element.path, element.path.name);
    });

    formdata.append('client_id', id);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return fetch(`${environment.baseUrl}/images/`, requestOptions);
  }

  deleteClientImage(id: any) {
    return this.http.delete(`${environment.baseUrl}/images/?id=${id}`);
  }

  mergeClient(oldId: any, newId: any, replace: any) {
    return this.http.put(
      `${environment.baseUrl}/client/merge/?old_client_id=${oldId}&new_client_id=${newId}&replcae=${replace}`,
      ''
    );
  }

  deleteClient(id: any) {
    return this.http.delete(`${environment.baseUrl}/client/?client_id=${id}`);
  }

  // quota

  addQuota(data: any) {
    return this.http.post(`${environment.baseUrl}/qu/invitations`, data);
  }

  getQuota(unit_id: any, date: any) {
    return this.http.get(
      `${environment.baseUrl}/qu/invitations?unit_id=${unit_id}&visit_date=${date}`
    );
  }

  // notes

  addNoteForUnit(data: any) {
    return this.http.post(`${environment.baseUrl}/notes/unittnotes/`, data);
  }

  getNoteForUnit(unitId: any) {
    return this.http.get(
      `${environment.baseUrl}/notes/unittnotes/?unit_id=${unitId}`
    );
  }

  addNoteForClient(data: any) {
    return this.http.post(`${environment.baseUrl}/notes/clientnotes/`, data);
  }

  getNoteForClient(unitId: any) {
    return this.http.get(
      `${environment.baseUrl}/notes/clientnotes/?client_id=${unitId}`
    );
  }

  deleteNote(id: any) {
    return this.http.delete(
      `${environment.baseUrl}/notes/delete/?note_id=${id}`
    );
  }

  // import client

  importClient(data: any) {
    return this.http.post(`${environment.baseUrl}/client/shift`, data);
  }

  // qrTypes

  QrTypes() {
    return this.http.get(`${environment.baseUrl}/types/?name=Qr Access`);
  }

  addNewType(data: any) {
    return this.http.post(`${environment.baseUrl}/types/`, data);
  }

  // add invitation

  clientInvitation(data: any) {
    return this.http.post(`${environment.baseUrl}/qr/invitation`, data);
  }
  clientSmsInvite(data: any) {
    return this.http.post(`${environment.baseUrl}/qr/sms_request`, data);
  }

  retrieveInvitation(id: any) {
    return this.http.get(
      `${environment.baseUrl}/qr/invitation?visit_date=2023-06-4&unit_id=${id}`
    );
  }

  getUnitInvitations(unitId: any, visitDate: any) {
    return this.http.get(
      `${environment.baseUrl}/qr/invitation?unit_id=${unitId}&visit_date=${visitDate}`
    );
  }

  cancelQRInvitation(qr_code: any) {
    return this.http.put(
      `${environment.baseUrl}/qr/cancel?qr_code=${qr_code}`,
      ''
    );
  }

  // card types

  cardType() {
    return this.http.get(`${environment.baseUrl}/types/?name=access`);
  }

  addCard(data: any) {
    return this.http.post(`${environment.baseUrl}/cards/`, data);
  }
  cardsList(id: any) {
    return this.http.get(`${environment.baseUrl}/cards/?client_id=${id}`);
  }
  deleteCard(id: any) {
    return this.http.delete(`${environment.baseUrl}/cards/?id=${id}`);
  }

  // qr types

  QRType() {
    return this.http.get(`${environment.baseUrl}/types/?name=Qr`);
  }

  addRFId(data: any) {
    var formdata = new FormData();
    formdata.append('front', data.front);
    formdata.append('back', data.back);
    formdata.append('card_id', data.card_id);
    formdata.append('client_id', data.client_id);
    formdata.append('license_plate', data.license_plate);
    formdata.append('serial_number', data.serial_number);
    formdata.append('type_id', data.type_id);
    if (data.unit_id) {
      formdata.append('unit_id', data.unit_id);
    }
    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    return fetch(`${environment.baseUrl}/cards/rfid/`, requestOptions);
  }

  retrieveRFId(id: any) {
    return this.http.get(`${environment.baseUrl}/cards/rfids/?id=${id}`);
  }

  editRFId(data: any) {
    var formdata = new FormData();

    if (data.front) {
      formdata.append('front', data.front);
    }
    if (data.back) {
      formdata.append('back', data.back);
    }
    formdata.append('card_id', data.card_id);
    formdata.append('client_id', data.client_id);
    formdata.append('license_plate', data.license_plate);
    formdata.append('serial_number', data.serial_number);
    formdata.append('type_id', data.type_id);
    formdata.append('unit_id', data.unit_id);
    formdata.append('id', data.id);
    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var requestOptions: any = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    return fetch(`${environment.baseUrl}/cards/rfid/`, requestOptions);
  }

  deleteRFId(id: any) {
    return this.http.delete(`${environment.baseUrl}/cards/rfid/?id=${id}`);
  }

  unit_invoice(id: any) {
    return this.http.get(`${environment.baseUrl}/invoices/unit/?unit_id=${id}`);
  }

  // payment types

  getPaymentTypesService() {
    return this.http.get(`${environment.baseUrl}/types/?name=Payments`);
  }

  payInvoice(data: any) {
    return this.http.put(`${environment.baseUrl}/invoices/`, data);
  }

  // Register Access
  registerAccessService(data: any) {
    return this.http.post(`${environment.baseUrl}/cards/register_access`, data);
  }

  // Client Invoices
  getClientInvoices(page: number, limit: number, clientId: any) {
    return this.http.get(
      `${environment.baseUrl}/invoices/client_invoices/?page=${page}&limit=${limit}&client_id=${clientId}`
    );
  }

  getUnitRentals(
    page: number,
    limit: number,
    unitId: any,
    startDate?: any,
    endDate?: any
  ) {
    let url = `${environment.baseUrl}/units/rental/bookings?unit_id=${unitId}&page=${page}&limit=${limit}`;

    if (
      startDate !== undefined &&
      startDate !== null &&
      endDate !== undefined &&
      endDate !== null
    ) {
      url += `&start_date=${startDate}&end_date=${endDate}`;
    }

    // if (endDate !== undefined && endDate !== null) {
    //   url += `&end_date=${endDate}`;
    // }

    return this.http.get(url);
  }
}
