import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { io } from 'socket.io-client';
import { GatesService } from 'src/app/services/gates.service';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environments';
import { GateMonitorInputFilterComponent } from '../gate-monitor-input-filter/gate-monitor-input-filter.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'app-gate-monitor',
  templateUrl: './gate-monitor.component.html',
  styleUrls: ['./gate-monitor.component.css'],
  animations: [fadeInOutAnimation],
})
export class GateMonitorComponent implements OnInit, OnDestroy {
  baseUrlForImages = environment.baseUrl;
  formFilterBoolean: boolean = false;
  dataComesFromFilter: any;
  copyDataComesFromSocket: any[] = [];
  notificationSound = new Audio('/assets/sound/notification.mp3');
  photo: string = '';
  hideFilters: boolean = false;
  gatesArray: any;
  gateTypesArr: any;
  socketConfig: any;
  dataComesFromSocket: any[] = [];
  pipe = new DatePipe('en-US');

  constructor(
    private gatesService: GatesService,
    private mainService: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.retrieveGates();
    this.gatesType();
    this.openSocket();
  }

  openSocket() {
    this.socketConfig = io(`${environment.baseSocket}`);
    this.socketConfig.connect();
    this.socketConfig.on('connect', () => {
      // console.log('connected to socket server');
      // console.log(this.socketConfig.id);
    });

    this.socketConfig.on('gate_live_monitor', (data: any) => {
      this.dataComesFromSocket.unshift(data);
      this.copyDataComesFromSocket.unshift(data);
      this.playNotificationSound();
      if (this.dataComesFromFilter) {
        this.copyDataComesFromSocket = this.filterData(
          this.copyDataComesFromSocket,
          this.dataComesFromFilter
        );
      }
    });

    this.socketConfig.on('gate_type_live_monitor', (data: any) => {
      this.dataComesFromSocket.unshift(data);
      this.copyDataComesFromSocket.unshift(data);
      this.playNotificationSound();
      if (this.dataComesFromFilter) {
        this.copyDataComesFromSocket = this.filterData(
          this.copyDataComesFromSocket,
          this.dataComesFromFilter
        );
      }
    });
  }

  selectGate(gate: any) {
    this.socketConfig.emit('gate_monitor', gate?.id);
  }

  selectGateType(gateType: any) {
    this.socketConfig.emit('gate_type_monitor', gateType?.id);
  }

  retrieveGates() {
    this.gatesService.retrieveGates().subscribe((res: any) => {
      this.gatesArray = res;
    });
  }

  gatesType() {
    this.mainService.gateByType().subscribe((res: any) => {
      this.gateTypesArr = res?.children;
    });
  }

  inputFilter() {
    const dialogRef = this.dialog.open(GateMonitorInputFilterComponent, {
      width: '90%',
      maxWidth: '450px',
      height: '900px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const isEmpty = Object.values(result).every((i) => !i);
      this.formFilterBoolean = !isEmpty;

      if (result) {
        this.dataComesFromFilter = result;
        this.dataComesFromFilter.from = this.pipe.transform(
          this.dataComesFromFilter.from,
          'yyyy-MM-dd hh:mm:ss'
        );
        this.dataComesFromFilter.to = this.pipe.transform(
          this.dataComesFromFilter.to,
          'yyyy-MM-dd hh:mm:ss'
        );

        this.copyDataComesFromSocket = [...this.dataComesFromSocket];

        this.copyDataComesFromSocket = this.filterData(
          this.copyDataComesFromSocket,
          this.dataComesFromFilter
        );
      }
    });
  }

  filterData(dataComesFromSocket: any, filterObject: any) {
    return dataComesFromSocket.filter((item: any) => {
      let result = false;

      const isEmpty = Object.values(filterObject).every((i) => !i);

      if (isEmpty) {
        return true;
      }

      for (const key in filterObject) {
        const itemValue = item[key];
        const filterValue = filterObject[key];

        if (!filterValue) {
          continue;
        }

        if (['name', 'message', 'unit_name', 'registered_to'].includes(key)) {
          result ||= itemValue
            ?.toLowerCase()
            ?.split('')
            ?.join('')
            ?.includes(filterValue?.toLowerCase()?.split('')?.join(''));
        }

        if (
          [
            'vehiclePlateNumbers',
            'vehiclePlateLetter1',
            'vehiclePlateLetter2',
            'vehiclePlateLetter3',
          ].includes(key)
        ) {
          result ||= [
            filterObject.vehiclePlateNumbers,
            filterObject.vehiclePlateLetter1,
            filterObject.vehiclePlateLetter2,
            filterObject.vehiclePlateLetter3,
          ]
            .filter((i) => !!i)
            .join('')
            .includes(item?.plate_number?.split('').join(''));
        }

        if (key == 'gate') {
          result ||= filterValue.includes(item.controller_id);
        }

        // if (key == 'gateType') {
        //   result ||=  filterValue.includes(item.controller_id);
        // }

        if (key == 'direction') {
          result ||=
            filterValue.toLowerCase() === item?.direction?.toLowerCase();
        }

        if (
          ((key == 'to' && filterObject.from) ||
            (key == 'from' && filterObject.to)) &&
          item.timestamp
        ) {
          result ||= moment(item.timestamp).isBetween(
            moment(filterObject.from).startOf('day'),
            moment(filterObject.to).startOf('day')
          );
        }

        if (key == 'to' && !filterObject.from && item.timestamp) {
          result ||= moment(item.timestamp).isSameOrBefore(
            moment(filterObject.to).startOf('day')
          );
        }

        if (key == 'from' && !filterObject.to && item.timestamp) {
          result ||= moment(item.timestamp).isSameOrAfter(
            moment(filterObject.from).startOf('day')
          );
        }
      }
      return result;
    });
  }

  openPhoto(dialog: TemplateRef<any>, photo: any) {
    this.photo = photo;
    this.dialog.open(dialog);
  }

  playNotificationSound() {
    this.notificationSound.play();
  }

  fullScreen() {
    this.mainService.booleanValue = !this.mainService.booleanValue;
    this.hideFilters = !this.hideFilters;
  }

  resetInputFilter() {
    this.dataComesFromFilter = Object.keys(this.dataComesFromFilter).reduce(
      (acc: any, key) => {
        acc[key] = null;
        return acc;
      },
      {}
    );

    this.formFilterBoolean = false;
    this.copyDataComesFromSocket = [...this.dataComesFromSocket];
  }

  ngOnDestroy(): void {
    this.socketConfig.close();
  }
}
