import { Component } from '@angular/core';
import { VillageService } from 'src/app/services/village.service';
import { ThemePalette } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainGatesComponent } from '../main-gates/main-gates.component';
import { MatDialogRef } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-add-qr-config',
  templateUrl: './add-qr-config.component.html',
  styleUrls: ['./add-qr-config.component.css'],
})
export class AddQrConfigComponent {
  showSpinner: boolean = true;
  qrConfigArray = environment.qrConfigArray;

  village_list: any;
  color: ThemePalette = 'primary';
  checked = false;
  Village_form: FormGroup | any;
  types_arr: any;

  constructor(
    private villageSer: VillageService,
    private unitService: UnitsService,
    public dialogRef: MatDialogRef<MainGatesComponent>
  ) {}

  ngOnInit() {
    this.allVillage();
    this.initForm();
    this.qr_type();
  }
  // initForm() {
  //   this.Village_form = new FormGroup({
  //     villages: new FormControl('', [Validators.required]),
  //     qr_type_id: new FormControl('', [Validators.required]),
  //     available_days: new FormControl(''),
  //     one_time_use: new FormControl(null),
  //     started: new FormControl(null),
  //     ended: new FormControl(null),
  //     full_day_access: new FormControl(null),
  //     thirty_seconds: new FormControl(null),
  //     start_time: new FormControl(null),
  //     count: new FormControl(null),
  //   });
  // }

  initForm() {
    this.Village_form = new FormGroup({
      villages: new FormControl('', [Validators.required]),
      qr_type_id: new FormControl('', [Validators.required]),
    });
    const formControls = this.qrConfigArray;
    formControls.forEach((control: any) => {
      // const validators =
      //   control.type === 'number'
      //     ? [Validators.required, Validators.pattern('^[0-9]*$')]
      //     : [Validators.required];

      // const formControl = new FormControl('', validators);
      const formControl = new FormControl(null);

      this.Village_form.addControl(
        control.name.toLowerCase().replace(/\s+/g, '_'),
        formControl
      );
    });
  }

  qr_type() {
    this.unitService.QrTypes().subscribe((res: any) => {
      this.types_arr = res.children;
      this.showSpinner = false;
    });
  }

  allVillage() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.showSpinner = false;
      this.village_list = res?.results;
    });
  }

  // add_qr_config() {
  //   const {
  //     villages,
  //     qr_type_id,
  //     one_time_use,
  //     thirty_seconds,
  //     started,
  //     ended,
  //     full_day_access,
  //     start_time,
  //     count,
  //     available_days,
  //   } = this.Village_form.value;

  //   const addUQrConfigInputs: any = {
  //     villages,
  //     qr_type_id,
  //     available_days,
  //     '30_seconds': thirty_seconds == null ? false : true,
  //     one_time_use: one_time_use == null ? false : true,
  //     started: started == null ? false : true,
  //     ended: ended == null ? false : true,
  //     full_day_access: full_day_access == null ? false : true,
  //     start_time: start_time == null ? false : true,
  //     count: count == null ? false : true,
  //   };

  //   // this.dialogRef.close(addUQrConfigInputs);
  // }

  add_qr_config() {
    const formValues = this.Village_form.value;
    const dynamicConfig: any = {};

    for (const controlName in formValues) {
      if (formValues.hasOwnProperty(controlName)) {
        const controlValue = formValues[controlName];

        const mappedValue = controlValue !== null ? controlValue : false;
        const controlObject = this.qrConfigArray.find(
          (obj: any) =>
            obj.name.toLowerCase().replace(/\s+/g, '_') === controlName
        );

        if (controlObject && controlObject.type === null) {
          dynamicConfig[controlName] = mappedValue;
        } else {
          const snakeCaseName = controlName.toLowerCase();
          dynamicConfig[snakeCaseName] = mappedValue;
        }
      }
    }
    this.dialogRef.close(dynamicConfig);
  }
}
