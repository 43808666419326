import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnitDetailsComponent } from '../unit-details/unit-details.component';
import { UnitsService } from 'src/app/services/units.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css'],
})
export class AddNoteComponent implements OnInit {
  action = '';
  notes = '';
  note_array: any = [];
  constructor(
    public dialogRef: MatDialogRef<UnitDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mainService: MainService,
    private rest: UnitsService
  ) {}

  ngOnInit() {
    if (this.data.name == 'unit') {
      this.getNoteForUnit();
    } else {
      this.getNoteForClient();
    }
  }

  addNote() {
    if (this.action != '' && this.notes != '') {
      let data = {
        note: this.notes,
        action: this.action,
      };
      this.dialogRef.close(data);
    }
  }

  getNoteForUnit() {
    this.rest.getNoteForUnit(this.data.id).subscribe((res: any) => {
      this.note_array = res;
    });
  }

  getNoteForClient() {
    this.rest.getNoteForClient(this.data.id).subscribe((res: any) => {
      this.note_array = res;
    });
  }

  deleteNote(item: any) {
    this.rest.deleteNote(item.id).subscribe((res: any) => {
      this.mainService.SuccessToast('Note deleted');
      if (this.data.name == 'unit') {
        this.getNoteForUnit();
      } else {
        this.getNoteForClient();
      }
    });
  }
}
