import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllServicesComponent } from '../all-services/all-services.component';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css'],
})
export class EditServiceComponent implements OnInit {
  serviceName = '';
  serviceImage = '';
  serviceId = '';
  imageUploaded = '';
  servicCost = '';
  constructor(
    public dialogRef: MatDialogRef<AllServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.serviceName = this.data.value.name;
    this.serviceId = this.data.value.id;
    this.serviceImage = this.data.value.image;
    if (this.data.value.cost) {
      this.servicCost = this.data.value.cost;
    }
  }

  uploadServiceImage(e: any) {
    this.imageUploaded = e.target.files[0];
  }

  addService() {
    let data: any;
    if (this.imageUploaded != '') {
      data = {
        name: this.serviceName,
        image: this.imageUploaded,
        service_id: this.serviceId,
      };
    } else {
      data = {
        name: this.serviceName,
        service_id: this.serviceId,
      };
    }

    if (this.data.value.cost != '') {
      data.cost = this.servicCost;
    }

    this.dialogRef.close(data);
  }

  deleteImage() {
    this.serviceImage = '';
  }
}
