import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitsService } from 'src/app/services/units.service';
import { DatePipe, Location } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { InvtaionComponent } from '../invtaion/invtaion.component';
import { MainService } from 'src/app/services/main.service';
import { QrInvtationComponent } from '../qr-invtation/qr-invtation.component';
import { UnitBlockComponent } from '../unit-block/unit-block.component';
import { AddCardComponent } from '../add-card/add-card.component';
import { ClientsService } from 'src/app/services/clients.service';
import { AddNoteComponent } from '../add-note/add-note.component';
import { RegisterAccessComponent } from '../register-access/register-access.component';
import { UnpaidInvoicesComponent } from '../unpaid-invoices/unpaid-invoices.component';
import { environment } from 'src/environments/environments';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { PrintConfirmationModalComponent } from '../print-confirmation-modal/print-confirmation-modal.component';
import { tap } from 'rxjs';
import { UnitUnpaidInvoicesComponent } from '../unit-unpaid-invoices/unit-unpaid-invoices.component';
import { VillageService } from 'src/app/services/village.service';
import { DomSanitizer } from '@angular/platform-browser';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-unit-rentals',
  templateUrl: './unit-rentals.component.html',
  styleUrls: ['./unit-rentals.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class UnitRentalsComponent implements OnInit {
  showSpinner: boolean = true;
  unit_Id: any;
  unit_name: any;
  page = 1;
  limit = 15;
  totalCount = 0;
  startDate: any;
  endDate: any;
  panelOpenState = false;
  mergeButtonStatus = false;
  pipe = new DatePipe('en-US');
  unitRentals: any;
  unitClientRentals: any;
  blockClientTitle = 'Block';
  clientIdDeleted: any;
  dataPrint: any = [];
  clientIdForInvoices: any;
  clientInvoices: any;
  clientIdArrayForPrinting: any;

  cardObject = {
    name: '',
    unitName: '',
    validFrom: '',
    validTo: '',
    type: '',
    image: '',
    image1: 'assets/images/teamCard.jpg',
    frontImage: '',
    villageName: '',
    status: false,
    orientationPrinting: '',
  };
  allConfiguration: any;
  frontPhoto: any;
  backPhoto: any;
  orientationPrinting: any;
  printingCardOrientation: any = environment.printingCardOrientation;
  showResetButton: boolean = false;
  @ViewChild('printModal') printModal!: PrintConfirmationModalComponent;
  villageId: any;
  todayDate: any;

  constructor(
    private route: Router,
    public dialog: MatDialog,
    private location: Location,
    private mainService: MainService,
    private unitService: UnitsService,
    private activeRouter: ActivatedRoute,
    private clientService: ClientsService,
    private villageService: VillageService,
    private readonly configuration: ConfigurationsService
  ) {}

  ngOnInit() {
    this.unit_Id = this.activeRouter.snapshot.paramMap.get('id');
    this.unit_name = this.activeRouter.snapshot.paramMap.get('name');
    this.villageId = this.activeRouter.snapshot.paramMap.get('villageId');

    this.getAllRentals();
    this.getAllConfigurations();
    this.village_List(this.villageId);

    if (localStorage.getItem('clientIdArrayForPrinting')) {
      this.clientIdArrayForPrinting = localStorage.getItem(
        'clientIdArrayForPrinting'
      );
      this.clientIdArrayForPrinting = JSON.parse(this.clientIdArrayForPrinting);

      setTimeout(() => {
        this.printModal.show();
      }, 1000);
    }

    this.checkTodayDate();
  }

  checkTodayDate() {
    const currentDate = new Date();
    this.todayDate = this.pipe.transform(currentDate, 'yyyy-MM-dd');
  }

  village_List(id?: any) {
    this.villageService.villageList().subscribe((res: any) => {
      res.results.forEach((element: any) => {
        if (element.id == id) {
          this.cardObject.villageName = element.name;
        }
      });
    });
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const inputElement = event.targetElement as HTMLInputElement;
    const name = inputElement.name;
    if (name == 'Date from') {
      this.startDate = event?.value;
      this.startDate = this.pipe.transform(this.startDate, 'yyyy-MM-dd');
      if (this.startDate && this.endDate) {
        this.showResetButton = true;
        this.showSpinner = true;
        this.getAllRentals();
      }
    } else if (name == 'Date to') {
      this.endDate = event?.value;
      this.endDate = this.pipe.transform(this.endDate, 'yyyy-MM-dd');
      if (this.startDate && this.endDate) {
        this.showResetButton = true;
        this.showSpinner = true;
        this.getAllRentals();
      }
    }
  }

  getAllRentals() {
    this.unitService
      .getUnitRentals(
        this.page,
        this.limit,
        this.unit_Id,
        this.startDate,
        this.endDate
      )
      .subscribe((res: any) => {
        this.unitRentals = res?.results;
        this.unitRentals.forEach((element: any) => {
          element?.rentals?.forEach((rental: any) => {
            if (rental.images.length != 0) {
              rental.images.forEach((item: any) => {
                if (item.image_type == 'personal_photo') {
                  rental.photo = item.image;
                }
              });
            } else {
              rental.images.push({
                image: 'assets/images/avatar.png',
              });
            }
          });
        });
        this.unitClientRentals = res?.results?.rentals;
        this.totalCount = res?.total_count;
        this.showSpinner = false;
      });
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.page = e?.pageIndex + 1;
    this.limit = e?.pageSize;
    this.getAllRentals();
  }

  goBack(): void {
    this.location.back();
  }

  resetFilter() {
    this.showSpinner = true;
    this.startDate = null;
    this.endDate = null;
    this.showResetButton = !this.showResetButton;
    this.getAllRentals();
  }

  viewClient(value: any) {
    this.route.navigate(['pages/view_client', this.unit_Id, value]);
  }

  editClient(value: any) {
    this.route.navigate(['pages/edit_client', this.unit_Id, value]);
  }

  openBlockClient(title: any, item: any) {
    if (title == 'block') {
      this.blockClientTitle = 'Block';
    } else {
      this.blockClientTitle = 'Active';
    }
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn4');
    button.click();
  }

  openDeleteClient(item: any) {
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  generateInvitation(value: any) {
    const dialogRef = this.dialog.open(InvtaionComponent, {
      width: '450px',
      data: {
        unitName: this.unit_name,
        clientName: value.name,
        unitId: this.unit_Id,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.data.unit_id = this.unit_Id;
        result.data.client_id = value.id;
        result.data.valid_from = this.pipe.transform(
          result.data.valid_from,
          'yyyy-MM-dd hh:mm:ss'
        );
        result.data.valid_to = this.pipe.transform(
          result.data.valid_to,
          'yyyy-MM-dd hh:mm:ss'
        );

        result.data.valid_from = result.data.valid_from.replace(
          /\d{2}:\d{2}(:\d{2})?/,
          result.data.time_from + ':00'
        );

        result.data.valid_to = result.data.valid_to.replace(
          /\d{2}:\d{2}(:\d{2})?/,
          result.data.time_to + ':00'
        );

        delete result.data.time_from;
        delete result.data.time_to;

        if (result.value == 'normal') {
          this.unitService.clientInvitation(result.data).subscribe(
            (res: any) => {
              if (res.status == false) {
                this.mainService.errorToast(res.message);
              } else {
                let data: any = result;
                data.qrName = res.qr_code;
                data.unitName = this.unit_name;
                (data.inviter = value.name), this.openQr(data);
              }
            },
            (err: any) => {
              this.mainService.errorToast('Something went error');
            }
          );
        } else {
          this.unitService.clientSmsInvite(result.data).subscribe(
            (res: any) => {},
            (err: any) => {
              this.mainService.errorToast('Something went error');
            }
          );
        }
      }
    });
  }

  openQr(value: any) {
    const dialogRef = this.dialog.open(QrInvtationComponent, {
      width: '320px',
      data: value,
    });
  }

  blockUnitGates(id: any) {
    let unitOrClientID: any;
    let unitOrClientName: any;
    if (id != null) {
      unitOrClientID = id;
      unitOrClientName = 'client';
    } else {
      unitOrClientID = this.unit_Id;
      unitOrClientName = 'unit';
    }
    const dialogRef = this.dialog.open(UnitBlockComponent, {
      width: '400px',
      data: { id: unitOrClientID, name: unitOrClientName },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (id != null) {
          let data = {
            gates: result,
            clients: [id],
          };
          if (result.balckList.length != 0) {
            data.gates = result.balckList;
            this.unitService.blackListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in blackList Gates');
            });
          } else {
            data.gates = result.WhiteList;
            this.unitService.whiteListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in whiteList Gates');
            });
          }
        } else {
          let data = {
            gates: result,
            units: [this.unit_Id],
          };
          if (result.balckList.length != 0) {
            data.gates = result.balckList;
            this.unitService.blockUnitForGate(data).subscribe((res: any) => {
              this.mainService.SuccessToast('unit add in blackList Gates');
            });
          } else {
            data.gates = result.WhiteList;
            this.unitService.whiteListGateUnit(data).subscribe((res: any) => {
              this.mainService.SuccessToast('unit add in whiteList Gates');
            });
          }
        }
      }
    });
  }

  addCard(value: any) {
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: '450px',
      data: {
        id: value.id,
        unitId: this.unit_Id,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const invoicesIdsToBeDelivered = result.invoicesIdsToBeDelivered;
      if (result.data) {
        if (result.data.license_plate) {
          result.data.unit_id = this.unit_Id;
          result.data.client_id = value.id;
          this.unitService
            .addRFId(result.data)
            .then((response) => response.text())
            .then((result) => {
              let res = JSON.parse(result);
              if (res.status == true) {
                invoicesIdsToBeDelivered.forEach((id: any) => {
                  this.invoiceDelivery(id);
                });
                this.mainService.SuccessToast('RFID Added');
              } else {
                this.mainService.errorToast('Opss something wrong happen!');
              }
            })
            .catch((error) => {
              this.mainService.errorToast('Opss something wrong happen!');
            });
        }
      } else {
        result.client_id = value.id;
        this.unitService.addCard(result).subscribe((res: any) => {
          this.activeClient(value.id);
          this.mainService.SuccessToast('Card Added');
        });
      }
    });
  }

  invoiceDelivery(invoice: any) {
    this.clientService.deliverInvoice(invoice).subscribe({
      next: (response: any) => {
        this.mainService.SuccessToast('Invoice delivered');
      },
      error: (err) => {
        this.mainService.errorToast(err?.message);
      },
    });
  }

  activeClient(value: any) {
    let data = {
      id: value + '',
      activated: true,
    };
    this.clientService.editClient(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Client Activated');
      this.getAllRentals();
    });
  }

  addNote(value: any, id: any) {
    let idOfClientOrUnit;
    if (id) {
      idOfClientOrUnit = id;
    } else {
      idOfClientOrUnit = this.unit_Id;
    }
    const dialogRef = this.dialog.open(AddNoteComponent, {
      width: '450px',
      data: { id: idOfClientOrUnit, name: value },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (value == 'unit') {
          result.unit_id = this.unit_Id;
          (result.expiry_date = this.pipe.transform(new Date(), 'yyyy-MM-dd')),
            this.unitService.addNoteForUnit(result).subscribe((res: any) => {
              this.mainService.SuccessToast('Note Added for the Unit');
            });
        } else {
          result.client_id = id;
          (result.expiry_date = this.pipe.transform(new Date(), 'yyyy-MM-dd')),
            this.unitService.addNoteForClient(result).subscribe((res: any) => {
              this.getAllRentals();
              this.mainService.SuccessToast('Note Added for the client');
            });
        }
      }
    });
  }

  allOtherInvoices: any;
  unPaidOtherInvoices: any[] = [];
  userNotPrintedIds: any[] = [];
  clientId: any;
  clientsArrayWithCommonInvoiceForPrinting: any[] = [];
  usersToBePrinted: any[] = [];
  invoicesIdsToBeDelivered: any[] = [];
  invoicesIdsToBeDeliveredForPrinting: any[] = [];

  getAllInvoices(data: any) {
    console.log(data);

    return this.unitService.unit_invoice(this.unit_Id).pipe(
      tap((res: any) => {
        this.allOtherInvoices = res?.others;
        // this.unPaidOtherInvoices = this.allOtherInvoices.filter(
        //   (item: any) =>
        //     item?.purchase_name?.includes('Access') &&
        //     item?.paid === false &&
        //     item?.created_at?.includes(this.todayDate)
        // );

        const hasDetailsInvoices = this.allOtherInvoices.some(
          (invoice: any) => 'details' in invoice
        );
        if (hasDetailsInvoices) {
          this.unPaidOtherInvoices = this.allOtherInvoices.filter(
            (item: any) => {
              const details = item?.details || '';
              const numbers = details.match(/\d+/g)?.map(Number) || [];

              const setOfClientIds = new Set(this.userNotPrintedIds);
              const setOfNumbers = new Set(numbers);

              const commonIds = Array.from(setOfClientIds).filter((element) =>
                setOfNumbers.has(element)
              );

              if (
                // details.includes(this.accessType) &&
                (numbers.includes(this.clientId) || commonIds.length > 0) &&
                item?.updated_on?.includes(this.todayDate)
              ) {
                this.clientsArrayWithCommonInvoiceForPrinting =
                  this.rentalClients.filter((obj: any) =>
                    numbers.includes(obj.id)
                  );

                console.log(
                  'filtered',
                  this.clientsArrayWithCommonInvoiceForPrinting
                );

                return true;
              }
              return false;
            }
          );
        } else {
          console.log('mn badry');

          this.userNotPrintedIds = this.rentalClients
            .filter((item: any) => item?.printed === false)
            .map((item: any) => item.id);
          if (data == 'all') {
            this.openRegisterAccessDialog(data, this.userNotPrintedIds);
          } else {
            this.openRegisterAccessDialog(data);
          }
        }
      })
    );
  }

  rentalClients: any[] = [];
  print_card_check_invoices(data: any, arrayOfRentalClients: any) {
    this.rentalClients = arrayOfRentalClients;

    if (data == 'all') {
      // Getting the Ids of unprinted clients
      this.userNotPrintedIds = this.rentalClients
        .filter((item: any) => item?.printed === false)
        .map((item: any) => item.id);

      // Getting the Ids of unprinted clients
    } else {
      this.clientId = data.id;
    }

    // this.rentalClients = arrayOfRentalClients;
    localStorage.setItem('rentalClients', JSON.stringify(this.rentalClients));
    // this.getAllInvoices().subscribe({
    //   next: (response: any) => {
    //     if (this.unPaidOtherInvoices?.length) {
    //       const dialogRef = this.dialog.open(UnitUnpaidInvoicesComponent, {
    //         width: '700px',
    //         maxHeight: '700px',
    //         data: {
    //           unPaidOtherInvoices: this.unPaidOtherInvoices,
    //           unitId: this.unit_Id,
    //         },
    //       });

    //       dialogRef.afterClosed().subscribe((resultComeFromPayment: any) => {
    //         if (resultComeFromPayment?.status) {
    //           if (data == 'all') {
    //             let userNotPrinted = arrayOfRentalClients.filter(
    //               (item: any) => item?.printed === false
    //             );
    //             this.newPrintingScenario(userNotPrinted);
    //           } else {
    //             this.newPrintingScenario([data]);
    //           }
    //         } else {
    //           localStorage.removeItem('rentalClients');
    //         }
    //       });
    //     } else {
    //       if (data == 'all') {
    //         let userNotPrinted = arrayOfRentalClients.filter(
    //           (item: any) => item?.printed === false
    //         );
    //         this.newPrintingScenario(userNotPrinted);
    //       } else {
    //         this.newPrintingScenario([data]);
    //       }
    //     }
    //   },
    //   error: (err) => {
    //     if (err?.status == 406) {
    //       if (data == 'all') {
    //         let userNotPrinted = arrayOfRentalClients.filter(
    //           (item: any) => item?.printed === false
    //         );
    //         this.newPrintingScenario(userNotPrinted);
    //       } else {
    //         this.newPrintingScenario([data]);
    //       }
    //     }
    //   },
    // });

    this.getAllInvoices(data).subscribe({
      next: (response: any) => {
        if (this.unPaidOtherInvoices.length > 0) {
          console.log(this.unPaidOtherInvoices);

          // Filter out the invoices that are paid
          const unPaidOtherInvoices = this.unPaidOtherInvoices.filter(
            (invoice) => !invoice.paid
          );

          const paidOtherInvoices = this.unPaidOtherInvoices.filter(
            (invoice) => invoice.paid
          );

          if (this.unPaidOtherInvoices.some((invoice) => !invoice?.paid)) {
            console.log('false Only');

            // this.showSpinnerForRFID = true;

            const dialogRef = this.dialog.open(UnitUnpaidInvoicesComponent, {
              width: '700px',
              maxHeight: '700px',
              data: {
                unPaidOtherInvoices: unPaidOtherInvoices,
                unitId: this.unit_Id,
              },
            });

            dialogRef.afterClosed().subscribe((resultComeFromPayment: any) => {
              if (resultComeFromPayment?.status) {
                console.log('false Only w dafa3');

                // this.showSpinnerForRFID = false;

                if (data == 'all') {
                  let userNotPrinted = this.rentalClients.filter(
                    (item: any) => item?.printed === false
                  );

                  this.newPrintingScenario(userNotPrinted);
                } else {
                  this.newPrintingScenario(
                    this.clientsArrayWithCommonInvoiceForPrinting
                  );
                }
              } else {
                console.log('false Only w madfa3sh');

                this.mainService.errorToast('Invoice must be paid.');
                // this.invoicePaymentHint = 'Invoice must be paid.';
                // this.showInvoicePaymentHint = true;
              }
            });
          } else if (
            paidOtherInvoices.some(
              (invoice) => invoice?.paid && !invoice?.delivered
            )
          ) {
            this.invoicesIdsToBeDelivered = paidOtherInvoices.map(
              (invoice: any) => invoice.id
            );

            localStorage.setItem(
              'invoicesIds',
              JSON.stringify(this.invoicesIdsToBeDelivered)
            );

            console.log('TRUE FALSE');

            if (data == 'all') {
              console.log('TRUE FALSE print all');

              let userNotPrinted = this.rentalClients.filter(
                (item: any) => item?.printed === false
              );

              this.newPrintingScenario(userNotPrinted);
            } else {
              console.log('TRUE FALSE print client');
              this.newPrintingScenario(
                this.clientsArrayWithCommonInvoiceForPrinting
              );
            }
          } else if (
            paidOtherInvoices.some(
              (invoice) => invoice?.paid && invoice?.delivered
            )
          ) {
            console.log('TRUE TRUE');

            if (data == 'all') {
              console.log('TRUE TRUE register all');

              this.openRegisterAccessDialog(data, this.userNotPrintedIds);
              console.log(this.userNotPrintedIds);
            } else {
              console.log('TRUE TRUE register client');
              this.openRegisterAccessDialog(data);
            }
          }
        }
        if (this.unPaidOtherInvoices.length == 0) {
          console.log('mafiesh');

          if (data == 'all') {
            console.log('mafiesh register all');

            this.openRegisterAccessDialog(data, this.userNotPrintedIds);
            console.log(this.userNotPrintedIds);
          } else {
            console.log('mafiesh register client');

            this.openRegisterAccessDialog(data);
          }
        }
      },
      error: (err) => {
        if (err?.status == 406) {
          console.log('Mafiesh fawteeer khales');

          if (data == 'all') {
            console.log('Mafiesh fawteeer  register access all');

            this.openRegisterAccessDialog(data, this.userNotPrintedIds);
            console.log(this.userNotPrintedIds);
          } else {
            console.log('Mafiesh fawteeer  register access client');

            this.openRegisterAccessDialog(data);
          }
        }
      },
    });
  }

  print_card_again(data: any, arrayOfRentalClients: any) {
    this.rentalClients = arrayOfRentalClients;
    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitId: this.unit_Id,
        clientId: data?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.unitService.registerAccessService(result).subscribe((res: any) => {
          if (res.status == false) {
            this.mainService.errorToast(res?.message);
          } else {
            if (res.paid) {
              if (result.clients) {
                // let usersToBePrinted = this.filterArrayByIds(
                //   this.rentalClients,
                //   result.clients
                // );
                this.newPrintingScenario([data]);
              } else if (!result.clients) {
                this.showSpinner = true;
                this.getAllRentals();
              }
            } else {
              const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                width: '450px',
                data: {
                  result: res,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    if (result.clients) {
                      // let usersToBePrinted = this.filterArrayByIds(
                      //   this.rentalClients,
                      //   result.clients
                      // );

                      this.newPrintingScenario([data]);
                    } else if (!result.clients) {
                      this.showSpinner = true;
                      this.getAllRentals();
                    }
                  } else {
                    this.showSpinner = true;
                    this.getAllRentals();
                  }
                });
            }
          }
        });
      }
    });
  }

  filterArrayByIds(array: any[], ids: number[]): any[] {
    return array.filter((item) => ids.includes(item.id));
  }

  printContent(contentId: string) {
    const printContent = document.getElementById(contentId);
    if (printContent) {
      const originalContents = document.body.innerHTML;
      const printContents = printContent.innerHTML;

      document.body.innerHTML = `
      <html>
        <head>
          <title>Print</title>
          <style>
            .clientImage {
              width: 150px !important;
              height: 150px !important;
              object-fit: contain;
              margin-bottom: 30px;
              margin-top: 20px;
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `;

      // Wait for images to load before printing
      const images = Array.from(document.images);
      const loadedImages = images.map((img) => {
        if (img.complete) {
          return Promise.resolve(img);
        } else {
          return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
          });
        }
      });

      Promise.all(loadedImages)
        .then(() => {
          window.onafterprint = () => {
            document.body.innerHTML = originalContents;
            window.location.reload();
          };
          window.print();
        })
        .catch(() => {
          console.error('Failed to load images for printing');
          document.body.innerHTML = originalContents;
          window.location.reload();
        });
    }
  }

  checkUserType(type: any) {
    for (const obj of this.printingCardOrientation) {
      if (obj.userType === type) {
        this.orientationPrinting = obj.orientation;
        break;
      }
    }
  }

  findPhotosByType(configurationArray: any, userType: any) {
    this.frontPhoto = null;
    this.backPhoto = null;

    for (const obj of configurationArray) {
      if (obj.type === userType) {
        this.frontPhoto = obj.frontPhoto;
        this.backPhoto = obj.backPhoto;
        break;
      }
    }
  }

  getAllConfigurations() {
    this.configuration
      .retrieveConfigurations(this.page, this.limit)
      .subscribe((data: any) => {
        this.allConfiguration = data.results;
        this.allConfiguration.forEach((element: any) => {
          if (element.images?.length != 0) {
            element.images?.forEach((item: any) => {
              if (item.image_type == 'front') {
                element.frontPhoto = item.image;
              } else if (item.image_type == 'back') {
                element.backPhoto = item.image;
              }
            });
          }
        });
      });
  }

  blockClient() {
    let data = {
      id: this.clientIdDeleted,
      cancelled: true,
    };
    if (this.blockClientTitle == 'Block') {
      data.cancelled = true;
    } else {
      data.cancelled = false;
    }
    this.clientService.editClient(data).subscribe((res: any) => {
      if (this.blockClientTitle == 'Block') {
        this.mainService.SuccessToast('Client Blocked');
      } else {
        this.mainService.SuccessToast('Client Active');
      }
      this.getAllRentals();
    });
  }

  deleteClient() {
    this.unitService
      .deleteClient(this.clientIdDeleted)
      .subscribe((res: any) => {
        this.mainService.SuccessToast('Client deleted');
        this.showSpinner = true;
        this.getAllRentals();
      });
  }

  logInvoicesId(): void {
    this.invoicesIdsToBeDeliveredForPrinting = this.checkInvoicesIds();
  }

  checkInvoicesIds() {
    const invoicesIds = localStorage.getItem('invoicesIds');
    return invoicesIds ? JSON.parse(invoicesIds) : [];
  }

  onModalOk() {
    this.logInvoicesId();
    const storedRentalClients = JSON.parse(
      localStorage.getItem('rentalClients') || '[]'
    );

    if (this.clientIdArrayForPrinting) {
      this.clientIdArrayForPrinting.forEach((element: any) => {
        let userNotPrinted = storedRentalClients.filter(
          (item: any) => item?.id == element
        );

        if (userNotPrinted?.length) {
          this.clientService
            .printCardForClient(element, true)
            .subscribe((res: any) => {
              if (res.status) {
                this.mainService.SuccessToast('Printed success');
              } else {
                this.mainService.errorToast(res?.message);
              }
            });
        }
      });

      setTimeout(() => {
        this.invoicesIdsToBeDeliveredForPrinting.forEach((id: any) => {
          this.invoiceDelivery(id);
        });

        this.getAllRentals();
      }, 200);
      localStorage.removeItem('clientIdArrayForPrinting');
      localStorage.removeItem('rentalClients');
    }
  }

  onModalCancel() {
    const storedRentalClients = JSON.parse(
      localStorage.getItem('rentalClients') || '[]'
    );

    if (this.clientIdArrayForPrinting) {
      this.clientIdArrayForPrinting.forEach((element: any) => {
        let userNotPrinted = storedRentalClients.filter(
          (item: any) => item?.id == element
        );

        if (userNotPrinted?.length) {
          this.clientService
            .printCardForClient(element, false)
            .subscribe((res: any) => {
              if (res.status) {
                this.mainService.infoToast('You did not print the card');
              } else {
                this.mainService.errorToast(res?.message);
              }
            });
        }
      });

      setTimeout(() => {
        this.getAllRentals();
      }, 200);
      localStorage.removeItem('clientIdArrayForPrinting');
      localStorage.removeItem('rentalClients');
    }
  }

  onModalBackground() {
    if (this.clientIdArrayForPrinting) {
      localStorage.removeItem('clientIdArrayForPrinting');
      localStorage.removeItem('rentalClients');
    }
  }

  newPrintingScenario(data: any) {
    data.forEach((element: any) => {
      // Pushing the ids of printing client in array in the LocalStorage
      let storedArray = localStorage.getItem('clientIdArrayForPrinting');
      let array: number[] = [];
      if (storedArray) {
        array = JSON.parse(storedArray);
      }
      array.push(element.id);
      localStorage.setItem('clientIdArrayForPrinting', JSON.stringify(array));
      // Pushing the ids of printing client in array in the LocalStorage

      let object_data: any = {
        name: element.name,
        unitName: this.unit_name,
        villageName: this.cardObject.villageName,
        image1: this.cardObject.image1,
        frontImage: '',
        userType: element.type.name,
        validFrom: this.pipe.transform(element.valid_from, 'dd-MM-yyyy'),
        validTo: this.pipe.transform(element.valid_to, 'dd-MM-yyyy'),
      };
      if (element.type.name == 'Main Owner' || element.type.name == 'Owner') {
        object_data.type = 'owner' + "'s" + ' ' + 'Card';
        object_data.status = true;
      } else {
        object_data.type = element.type.name + "'s" + ' ' + 'Card';
        object_data.status = false;
      }

      if (element.photo) {
        object_data.image = element.photo;
      } else {
        object_data.image = 'assets/images/avatar.png';
      }

      this.checkUserType(element?.type?.name);
      this.findPhotosByType(this.allConfiguration, element?.type?.name);
      object_data.image1 = this.backPhoto;
      object_data.frontImage = this.frontPhoto;
      object_data.orientationPrinting = this.orientationPrinting;

      this.dataPrint.push(object_data);
      // this.clientService
      //   .printCardForClient(element.id)
      //   .subscribe((res: any) => {});
    });
    setTimeout(() => {
      this.printContent('cardPrint');
    }, 200);
  }

  openRegisterAccessDialog(data: any, userNotPrintedIds?: any) {
    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitId: this.unit_Id,
        clientId: this.clientId,
        userNotPrintedIds,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.unitService.registerAccessService(result).subscribe((res: any) => {
          if (res.status == false) {
            this.mainService.errorToast(res?.message);
          } else {
            if (res.paid) {
              if (result.clients) {
                this.usersToBePrinted = this.filterArrayByIds(
                  this.rentalClients,
                  result.clients
                );
                this.newPrintingScenario([data]);
              } else if (!result.clients) {
                this.showSpinner = true;
                this.getAllRentals();
              }
            } else {
              const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                width: '450px',
                data: {
                  result: res,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    if (result.clients) {
                      this.usersToBePrinted = this.filterArrayByIds(
                        this.rentalClients,
                        result.clients
                      );

                      this.newPrintingScenario([data]);
                    } else if (!result.clients) {
                      this.showSpinner = true;
                      this.getAllRentals();
                    }
                  } else {
                    this.showSpinner = true;
                    this.getAllRentals();
                  }
                });
            }
          }
        });
      }
    });
  }
}
