import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class GatesService {
  constructor(private http: HttpClient) {}

  retrieveGates() {
    return this.http.get(`${environment.baseUrl}/gates/`);
  }

  addGate(data: any) {
    return this.http.post(`${environment.baseUrl}/gates/`, data);
  }

  addUserConfig(data: any) {
    return this.http.post(`${environment.baseUrl}/gates/user_config`, data);
  }

  retrieve_user_config(type: any, villageId: any) {
    return this.http.get(
      `${environment.baseUrl}/gates/user_config?user_type_id=${type}&village_id=${villageId}`
    );
  }

  // qr

  addQrConfig(data: any) {
    return this.http.post(
      `${environment.baseUrl}/gates/qr_village_config`,
      data
    );
  }
}
