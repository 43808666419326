import { Component, OnInit, Inject } from '@angular/core';
import { VillageService } from 'src/app/services/village.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUtilitiesComponent } from '../all-utiltes/all-utiltes.component';
import { ServiceMangeService } from 'src/app/services/service-mange.service';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-add-utilty',
  templateUrl: './add-utilty.component.html',
  styleUrls: ['./add-utilty.component.css'],
})
export class AddUtilityComponent implements OnInit {
  all_projects: any;

  utilityName: any;
  project_id: any;
  measure: any;
  statusOfEdit = false;
  statusOfImage = false;
  utility_id: any;
  utilityImage: any;
  color: ThemePalette = 'primary';
  disabledApp: any = false;
  constructor(
    private rest: VillageService,
    public dialogRef: MatDialogRef<AddUtilitiesComponent>,
    private servMange: ServiceMangeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    if (this.data) {
      this.statusOfEdit = true;
      this.utilityName = this.data.name;
      this.measure = this.data.unit_measure;
      this.utility_id = this.data.id;
    }
    this.rest.projectList().subscribe((res: any) => {
      this.all_projects = res;
    });
  }

  addUtility() {
    let data = {
      name: this.utilityName,
      project_id: this.project_id,
      unit_measure: this.measure,
    };

    this.statusOfImage = true;
    this.servMange.addUtility(data).subscribe((res: any) => {
      this.utility_id = res.utility_id;
    });
  }

  editUtility() {
    let data = {
      utility_id: this.data.id,
      name: this.utilityName,
      unit_measure: this.measure,
      status: this.disabledApp,
    };
    this.dialogRef.close(data);
  }

  uploadUtilityImage(e: any) {
    this.utilityImage = e.target.files[0];
  }

  addUtilityImage() {
    let data = {
      utility_id: this.utility_id,
      image: this.utilityImage,
      status: this.disabledApp,
    };
    this.dialogRef.close(data);
    this.getData();

  }
}
