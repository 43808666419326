import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-print-confirmation-modal',
  templateUrl: './print-confirmation-modal.component.html',
  styleUrls: ['./print-confirmation-modal.component.css'],
})
export class PrintConfirmationModalComponent {
  showModal = false;

  @Output() okClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();
  @Output() backgroundClick = new EventEmitter<void>();

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  onOkClick() {
    this.okClick.emit();
    this.hide();
  }

  onCancelClick() {
    this.cancelClick.emit();
    this.hide();
  }

  onBackgroundClick(event: MouseEvent) {
    this.backgroundClick.emit();
    if (event.target === event.currentTarget) {
      this.hide();
    }
  }
}
