import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorsMainComponent } from './opreators-main/opreators-main.component';
import { MatMenuModule } from '@angular/material/menu';
import { AddOperatorComponent } from './add-operator/add-operator.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { EditOperatorsComponent } from './edit-opreators/edit-opreators.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AvatarModule } from 'ngx-avatar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    OperatorsMainComponent,
    AddOperatorComponent,
    EditOperatorsComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    AvatarModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
})
export class OperatorsModule {}
