import { Component, Inject, OnInit } from '@angular/core';
import { PayInvoiceComponent } from '../pay-invoice/pay-invoice.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { UnitDetailsComponent } from '../unit-details/unit-details.component';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-unit-unpaid-invoices',
  templateUrl: './unit-unpaid-invoices.component.html',
  styleUrls: ['./unit-unpaid-invoices.component.css'],
})
export class UnitUnpaidInvoicesComponent implements OnInit {
  permissions: any[] = [];
  unPaidOtherInvoices: any;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private main: MainService,
    public dialogRef: MatDialogRef<UnitDetailsComponent>,
    private unitService: UnitsService
  ) {}

  ngOnInit() {
    this.unPaidOtherInvoices = this.data.unPaidOtherInvoices;
    this.logPermissions();
  }

  logPermissions(): void {
    this.permissions = this.checkPermissions();
  }

  checkPermissions() {
    const permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  checkForPayInvoicePermission() {
    return this.permissions.some(
      (obj: any) => obj.name.toLowerCase() === 'pay invoice'
    );
  }

  payInvoice(invoice: any) {
    const dialogRef = this.dialog.open(PayInvoiceComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        invoiceId: invoice.id,
        invoiceAmount: invoice.ammount,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.unitService.payInvoice(result).subscribe((res: any) => {
          if (res.status) {
            this.main.SuccessToast(res?.message);
            this.dialogRef.close(res);
          } else {
            this.main.errorToast(res?.message);
          }
          this.dialogRef.close(res);
        });
      }
    });
  }
}
