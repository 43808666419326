import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllServicesComponent } from '../all-services/all-services.component';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent {

  serviceName = ""
  serviceImage = ""
  constructor(public dialogRef: MatDialogRef<AllServicesComponent>,){}

  ngOnInit(){
  }


  uploadServiceImage(e:any){
    this.serviceImage = e.target.files[0]
  }

  addService(){
    let data = {
      name : this.serviceName,
      image : this.serviceImage
    }
    this.dialogRef.close(data)
  }
}
