import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { MainGatesComponent } from '../main-gates/main-gates.component';

@Component({
  selector: 'app-add-user-type',
  templateUrl: './add-user-type.component.html',
  styleUrls: ['./add-user-type.component.css'],
})
export class AddUserTypeComponent implements OnInit {
  showSpinner: boolean = true;
  typeForm: FormGroup | any;
  QrParentId: any;

  constructor(
    private unitService: UnitsService,
    public dialogRef: MatDialogRef<MainGatesComponent>
  ) {}

  ngOnInit() {
    this.getUserTypes();
    this.initForm();
  }

  getUserTypes() {
    this.unitService.unitRelation().subscribe((res: any) => {
      this.QrParentId = res?.id;
      this.showSpinner = false;
    });
  }

  initForm() {
    this.typeForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  add_qr_type() {
    let data = this.typeForm.value;
    data.parent_id = this.QrParentId;
    this.dialogRef.close(data);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.typeForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
}
