<section class="main">
  <h3>
    <span>
      <img
        style="cursor: pointer"
        (click)="back()"
        src="assets/images/mangeunit/back.png"
      />
    </span>
    Edit client
  </h3>
  <mat-tab-group>
    <mat-tab label="Images">
      <section class="images_main mt-3">
        <div class="row">
          <div class="col-md-3" *ngFor="let item of clients_image">
            <div class="card_image mt-2">
              <img class="img-fluid imageSize" [src]="item.image" />
              <p class="mt-2">{{ item.image_type }}</p>
              <div class="deleteImageSize">
                <img
                  (click)="deleteImage(item.id)"
                  src="assets/images/delete.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="addImage">
          <div class="mt-3">
            <div class="row align-items-center" *ngIf="showInputFile">
              <div class="col-3">
                <mat-form-field>
                  <mat-label>Image Type</mat-label>
                  <mat-select
                    [(ngModel)]="typeDocName"
                    (selectionChange)="typeChoose($event.value)"
                  >
                    <!-- personal_photo -->
                    <mat-option value="personal_photo">
                      Personal photo
                    </mat-option>
                    <mat-option value="Passport-ID"> ID/Passport </mat-option>
                    <mat-option value="Drivers License">
                      Drivers License
                    </mat-option>
                    <mat-option value="Birth Certificate">
                      Birth Certificate
                    </mat-option>
                    <mat-option value="Other"> Other </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-4">
                <input
                  type="file"
                  multiple
                  (change)="uploadDocumentClient($event)"
                />
              </div>
            </div>
            <div *ngIf="status_input_type_name">
              <div class="row">
                <div class="col-6">
                  <label>Image Type Name</label>
                  <input
                    (input)="inputChange($event)"
                    [(ngModel)]="typeInputName"
                    type="text"
                    class="form-control mb-2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="userDocEditPhoto" class="d-flex mt-3">
            <div
              class="imageUserUpload documentsImages ms-3"
              *ngFor="let item of documentImage"
            >
              <span (click)="removeDocImage(item)">X</span>
              <img *ngIf="item.file == false" [src]="item.url" />
              <p *ngIf="item.file == true">{{ item.url }}</p>
              <h5>{{ item.name }}</h5>
            </div>
          </div>

          <button *ngIf="!showInputFile" class="btn" (click)="addImage()">
            Add Image
          </button>
          <button *ngIf="showInputFile" class="btn" (click)="createImage()">
            Save
          </button>
        </div>
      </section>
    </mat-tab>
    <mat-tab label="Information">
      <section class="supmain mt-3" *ngIf="validForm">
        <p>Units</p>
        <div class="row">
          <div class="col-md-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Unit name</mat-label>
              <mat-select>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search for unit name"
                    noEntriesFoundLabel="'No data found'"
                    (keyup)="searchForUnit(unit?.value)"
                    #unit
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let unit of unitSearch_arr"
                  [value]="unit?.id"
                  (click)="selectUnit(unit)"
                >
                  <span class="d-flex justify-content-between">
                    <strong>
                      {{ unit?.name }}
                    </strong>
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" class="w-100">
              <mat-label>Unit name</mat-label>
              <input
                matInput
                type="text"
                (input)="searchUnit($event)"
                placeholder="Find Unit"
                [(ngModel)]="unitNameSearch"
              />
            </mat-form-field> -->

            <!-- <label>Unit name</label>
            <input
              [(ngModel)]="unitNameSearch"
              (input)="searchUnit($event)"
              placeholder="Find Unit"
              type="text"
              class="form-control"
            /> -->
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Relation</mat-label>
              <mat-select
                fullWidth
                class="d-felx justify-content-center align-items-center"
              >
                <mat-option
                  *ngFor="let item of relationArray"
                  [value]="item?.id"
                  (click)="relationTypeValue(item?.name)"
                >
                  <strong>
                    {{ item?.name | titlecase }}
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <label>Relation</label>
            <input
              [(ngModel)]="unitRelation"
              type="text"
              class="form-control"
            /> -->
          </div>
          <div class="col-md-2">
            <button class="btn addUnit" (click)="addUnit()">Add Unit</button>
          </div>
        </div>
        <!-- <div class="listSearch" *ngIf="unitSearch_arr.length != 0">
          <ul>
            <li (click)="getUnit(item)" *ngFor="let item of unitSearch_arr">
              {{ item.name }}
            </li>
          </ul>
        </div> -->
        <div class="mt-2">
          <div class="row">
            <div class="col-12 col-md-6">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Unit name</th>
                    <th scope="col">Unit Relation</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of array_unit; let i = index"
                    [class.newly-added]="isNewlyAdded(i)"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.relation }}</td>
                    <td>
                      <img
                        class="deleteImage"
                        (click)="deleteUnit(i)"
                        src="assets/images/delete.png"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <small *ngIf="showConfirmEditMessageAfterAddingUnit"
                >You should save your edits after selecting new Unit from
                <strong>"Edit Client"</strong> Button.</small
              >
            </div>
          </div>
        </div>
        <form [formGroup]="clientForm">
          <!-- persona info -->
          <div class="mt-4 mb-4">
            <p>Personal information</p>
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <!-- <div class="form-group">
                  <label>Client name (EN)</label>
                  <input
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.name.errors
                    }"
                    type="text"
                    class="form-control"
                  />
                </div> -->

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Client name (EN)</mat-label>
                  <input
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.name.errors
                    }"
                    matInput
                    type="text"
                  />
                </mat-form-field>
              </div>
              <div class="col-12 col-md-3">
                <!-- <div class="form-group">
                  <label>Client name (AR)</label>
                  <input
                    formControlName="arabic_name"
                    type="text"
                    class="form-control"
                  />
                </div> -->

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Client name (AR)</mat-label>
                  <input formControlName="arabic_name" matInput type="text" />
                </mat-form-field>
              </div>
            </div>
            <!-- <div class="row mb-2">
                              <div class="col-3">
                                  <div class="form-group">
                                      <label>National ID number</label>
                                      <input type="number" class="form-control">
                                  </div>
                              </div>
                          </div> -->

            <div class="row">
              <div class="col-12 col-md-3">
                <div class="d-flex align-items-center datePicker">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Birth date</mat-label>
                    <input
                      [formControlName]="birthDateControlName"
                      [ngClass]="{
                        'is-invalid': submitted && ControlForm.birth_date.errors
                      }"
                      matInput
                      [matDatepicker]="picker"
                    />

                    <mat-error
                      *ngIf="isControlHasError('birth_date', 'required')"
                    >
                      Birth date is required
                    </mat-error>

                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Extension ID</mat-label>
                  <input formControlName="ext_id" matInput type="text" />
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-md-3">
                <p>Gender</p>
                <input
                  formControlName="gender"
                  type="radio"
                  id="male"
                  value="Male"
                />
                <label class="ms-2" for="male">Male</label><br />
                <input
                  formControlName="gender"
                  type="radio"
                  id="female"
                  value="Female"
                />
                <label class="ms-2" for="female">Female</label>
                <div
                  class="invalid-feedback"
                  *ngIf="submitted && ControlForm.gender.errors"
                >
                  Gender is Required
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-md-3">
                <p>Status</p>
                <input
                  formControlName="verified"
                  type="checkbox"
                  id="Verified"
                  value="true"
                />
                <label class="ms-2" for="Verified">Verified</label><br />
                <input
                  formControlName="activated"
                  type="checkbox"
                  id="Activated"
                  value="true"
                />
                <label class="ms-2" for="Activated">Activated</label>
              </div>
            </div>

            <!-- <div class="row mb-3">
              <label>Relation type</label>
              <div class="dropdown mb-0 ">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ typeName }}
                  <img src="assets/images/mangeunit/arrow.png" />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li
                    *ngFor="let item of types_arr"
                    (click)="getRelationTypeId(item)"
                  >
                    <a class="dropdown-item">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="invalid-feedback" *ngIf="submitted && typeId == ''">
                Relation type is Required
              </div>
            </div> -->
          </div>

          <!-- Contact information -->
          <div>
            <p>Contact information</p>
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    type="email"
                    formControlName="email"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.email.errors
                    }"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Egypt"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [numberFormat]="PhoneNumberFormat.International"
                  formControlName="phone_number"
                  [separateDialCode]="separateDialCode"
                >
                </ngx-intl-tel-input>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select your identity type</mat-label>
                  <mat-select
                    fullWidth
                    [(value)]="identitySelected"
                    class="d-felx justify-content-center align-items-center"
                  >
                    <mat-option
                      *ngFor="let type of identityTypes"
                      [value]="type.key"
                      (click)="selectIdentityType(type.key)"
                    >
                      <strong>{{ type.name | titlecase }}</strong>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <mat-form-field
                  appearance="outline"
                  class="w-100"
                  *ngIf="showIdentityTypeInput"
                >
                  <mat-label>{{ identityType }}</mat-label>
                  <input
                    formControlName="national_id"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.national_id.errors
                    }"
                    type="text"
                    matInput
                  />

                  <mat-error
                    *ngIf="isControlHasError('national_id', 'required')"
                  >
                    {{ identityType }} is required
                  </mat-error>

                  <mat-error
                    *ngIf="
                      isControlHasError('national_id', 'invalidNationalId') &&
                      identityType == 'National Id'
                    "
                  >
                    Invalid Egyptian National ID [ Numbers ]
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- <div class="row mb-2">
                              <div class="col-3">
                                  <div class="form-group">
                                      <label>Address</label>
                                      <input type="text" class="form-control">
                                  </div>
                              </div>
                          </div>
                          <div class="row ">
                              <div class="col-3">
                                  <div class="form-group">
                                      <label>Social link 1</label>
                                      <input type="text" class="form-control">
                                  </div>
                              </div>
                              <div class="col-3">
                                  <div class="form-group">
                                      <label>Social link 2</label>
                                      <input type="text" class="form-control">
                                  </div>
                              </div>
                          </div> -->
          </div>

          <!-- Other information -->

          <div class="mt-4">
            <p>Other information</p>

            <!-- <div class="row mb-2">
                              <div class="col-3">
                                  <div class="form-group">
                                      <label>Extension ID</label>
                                      <input type="text" class="form-control">
                                  </div>
                              </div>
                          </div> -->
            <div class="row mb-3">
              <!-- <div class="col-3">
                                  <div class="form-group">
                                      <label>Relation type</label>
                                      <input type="text" class="form-control">
                                  </div>
                              </div> -->
              <!-- <label>Relation type</label>
                              <div class="dropdown mb-0 ">
                                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      {{typeName}}
                                      <img src="assets/images/mangeunit/arrow.png">
                                  </button>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                      <li *ngFor="let item of types_arr" (click)="getRelationTypeId(item)">
                                          <a class="dropdown-item">{{item.name}}</a>
                                      </li>
                                  </ul>
                              </div>
                              <div class="invalid-feedback" *ngIf="submitted && (typeId == '')">Relation type is Required</div> -->
            </div>
            <!-- <div class="row mb-2">
                              <div class="col-3">
                                  <input type="checkbox">
                                  <label class="ms-1">This client is temporary</label>
                              </div>
                          </div> -->

            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Relation type</mat-label>
                  <mat-select
                    formControlName="type"
                    fullWidth
                    class="d-felx justify-content-center align-items-center"
                  >
                    <mat-option
                      *ngFor="let item of types_arr"
                      [value]="item?.id"
                      (click)="relationTypeValue(item?.name)"
                    >
                      <strong>
                        {{ item?.name | titlecase }}
                      </strong>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-3">
                <div class="d-flex align-items-center datePicker">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Valid from</mat-label>
                    <input
                      formControlName="valid_from"
                      [ngClass]="{
                        'is-invalid': submitted && ControlForm.valid_from.errors
                      }"
                      matInput
                      placeholder="Valid from"
                      [matDatepicker]="pickerFrom"
                    />

                    <mat-error
                      *ngIf="isControlHasError('valid_from', 'required')"
                    >
                      Valid from date is required
                    </mat-error>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="pickerFrom"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="d-flex align-items-center datePicker">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Valid to</mat-label>
                    <input
                      formControlName="valid_to"
                      [ngClass]="{
                        'is-invalid': submitted && ControlForm.valid_to.errors
                      }"
                      matInput
                      placeholder="Valid to"
                      [matDatepicker]="pickerTo"
                    />

                    <mat-error
                      *ngIf="isControlHasError('valid_to', 'required')"
                    >
                      Valid to date is required
                    </mat-error>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="pickerTo"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-5 mb-2">
            <div>
              <button class="btn me-2" (click)="back()">Cancel</button>
              <button
                class="btn next"
                [disabled]="clientForm.invalid"
                (click)="editClient()"
              >
                Edit Client
              </button>
            </div>
          </div>
        </form>
      </section>
    </mat-tab>
    <mat-tab label="RFID">
      <mat-tab-group>
        <mat-tab label="RFID List">
          <section class="tableUnit table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Card id</th>
                  <th scope="col">License plate</th>
                  <th scope="col" class="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of rfid_list; let i = index">
                  <td class="">
                    {{ item.id }}
                  </td>
                  <td>
                    <span>{{ item.card_id }}</span>
                  </td>
                  <td>
                    {{ item.license_plate }}
                  </td>
                  <td class="text-end">
                    <div class="icons_actions">
                      <img
                        mat-raised-button
                        matTooltip="View"
                        matTooltipClass="example-tooltip-uppercase"
                        [matTooltipPosition]="'above'"
                        (click)="openViewRFID(item)"
                        src="assets/images/view.png"
                      />

                      <img
                        mat-raised-button
                        matTooltip="Edit"
                        matTooltipClass="example-tooltip-uppercase"
                        [matTooltipPosition]="'above'"
                        (click)="editRFID(item)"
                        class="ms-2"
                        src="assets/images/edits.png"
                      />

                      <img
                        mat-raised-button
                        matTooltip="Delete"
                        matTooltipClass="example-tooltip-uppercase"
                        [matTooltipPosition]="'above'"
                        (click)="deleteRFId(item)"
                        class="ms-2"
                        src="assets/images/delete.png"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 *ngIf="rfid_list.length == 0">No Data Found</h3>
          </section>
        </mat-tab>

        <mat-tab label="Add RFID">
          <section class="mt-3">
            <div class="row">
              <div class="col-md-6">
                <form [formGroup]="RfidForm">
                  <div class="col-md-12">
                    <label>Vehicle Number</label>
                    <div class="row">
                      <div class="col-md-6">
                        <input
                          formControlName="number"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-2">
                        <input
                          formControlName="charOne"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-2">
                        <input
                          formControlName="charTow"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-2">
                        <input
                          formControlName="charThree"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>Driving License Front Face</label>
                    <input
                      (change)="onFileChange($event, 'front')"
                      formControlName="front"
                      type="file"
                    />
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>Driving License Back Face</label>
                    <input
                      (change)="onFileChange($event, 'back')"
                      formControlName="back"
                      type="file"
                    />
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>Car Tag </label>
                    <input
                      formControlName="card_id"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-12 mt-3">
                    <label>Serial Number </label>
                    <input
                      formControlName="serial_number"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </form>
                <button class="btn next mt-4" (click)="addRFId()">Save</button>
              </div>
            </div>
          </section>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>
  </mat-tab-group>
</section>
