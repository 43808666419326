<section class="main">
  <h3>
    <span>
      <img
        style="cursor: pointer"
        (click)="back()"
        src="assets/images/mangeunit/back.png"
      />
    </span>
    Add new client
  </h3>

  <section class="supmain" *ngIf="validForm">
    <form [formGroup]="clientForm">
      <!-- persona info -->
      <div class="mt-4 mb-4">
        <p>Personal information</p>
        <div class="row mb-2">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Client name (EN)</mat-label>
              <input
                formControlName="name"
                [ngClass]="{
                  'is-invalid': submitted && ControlForm.name.errors
                }"
                matInput
                type="text"
              />

              <mat-error *ngIf="isControlHasError('name', 'required')">
                Client name in English is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Client name (AR)</mat-label>
              <input formControlName="arabic_name" matInput type="text" />

              <mat-error *ngIf="isControlHasError('arabic_name', 'required')">
                Client name in Arabic is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-3">
            <div class="d-flex align-items-center datePicker">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Birth date</mat-label>
                <input
                  [formControlName]="birthDateControlName"
                  [ngClass]="{
                    'is-invalid': submitted && ControlForm.birth_date.errors
                  }"
                  matInput
                  [matDatepicker]="picker"
                />

                <mat-error *ngIf="isControlHasError('birth_date', 'required')">
                  Birth date is required
                </mat-error>

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Extension ID</mat-label>
              <input formControlName="ext_id" matInput type="text" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-md-3">
            <p>Gender</p>
            <input
              formControlName="gender"
              type="radio"
              id="male"
              value="Male"
            />
            <label class="ms-2" for="male">Male</label><br />
            <input
              formControlName="gender"
              type="radio"
              id="female"
              value="Female"
            />
            <label class="ms-2" for="female">Female</label>
            <div
              class="invalid-feedback"
              *ngIf="submitted && ControlForm.gender.errors"
            >
              Gender is Required
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-md-3">
            <p>Status</p>
            <input
              formControlName="verified"
              type="checkbox"
              id="Verified"
              value="true"
            />
            <label class="ms-2" for="Verified">Verified</label><br />
            <input
              formControlName="activated"
              type="checkbox"
              id="Activated"
              value="true"
            />
            <label class="ms-2" for="Activated">Activated</label>
          </div>
        </div>
      </div>

      <!-- Contact information -->
      <div>
        <p>Contact information</p>
        <div class="row mb-2">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input
                formControlName="email"
                [ngClass]="{
                  'is-invalid': submitted && ControlForm.email.errors
                }"
                type="email"
                matInput
              />

              <mat-error *ngIf="isControlHasError('email', 'email')">
                Email is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12 col-md-3">
            <ngx-intl-tel-input
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Egypt"
              [maxLength]="15"
              [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.International"
              formControlName="phone_number"
              [separateDialCode]="separateDialCode"
            >
            </ngx-intl-tel-input>
          </div>
        </div>

        <div class="row mb-2 mt-2">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select your identity type</mat-label>
              <mat-select
                fullWidth
                class="d-felx justify-content-center align-items-center"
              >
                <mat-option
                  *ngFor="let type of identityTypes"
                  [value]="type.key"
                  (click)="selectIdentityType(type.key)"
                >
                  <strong>{{ type.name | titlecase }}</strong>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12 col-md-3">
            <mat-form-field
              appearance="outline"
              class="w-100"
              *ngIf="showIdentityTypeInput"
            >
              <mat-label>{{ identityType }}</mat-label>
              <input
                formControlName="national_id"
                [ngClass]="{
                  'is-invalid': submitted && ControlForm.national_id.errors
                }"
                type="text"
                matInput
              />

              <mat-error *ngIf="isControlHasError('national_id', 'required')">
                {{ identityType }} is required
              </mat-error>

              <mat-error
                *ngIf="
                  isControlHasError('national_id', 'invalidNationalId') &&
                  identityType == 'National Id'
                "
              >
                Invalid Egyptian National ID [ Numbers ]
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Other information -->

      <div class="mt-4">
        <p>Other information</p>
        <div class="row mb-3">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Relation type</mat-label>
              <mat-select
                fullWidth
                class="d-felx justify-content-center align-items-center"
              >
                <mat-option
                  *ngFor="let item of types_arr"
                  [value]="item?.id"
                  (click)="getRelationTypeId(item)"
                >
                  <strong>
                    {{ item?.name | titlecase }}
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="invalid-feedback" *ngIf="submitted && typeId == ''">
              Relation type is Required
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Relation</mat-label>
              <mat-select
                fullWidth
                class="d-felx justify-content-center align-items-center"
                [(value)]="relationNameSelected"
              >
                <mat-option
                  *ngFor="let item of relationArray"
                  [value]="item?.name"
                  (click)="getRelationName(item?.name)"
                >
                  <strong>
                    {{ item?.name | titlecase }}
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div
              class="invalid-feedback"
              *ngIf="submitted && relationNameSelected == ''"
            >
              Relation is Required
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-3">
            <div class="d-flex align-items-center datePicker">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Valid from</mat-label>
                <input
                  formControlName="valid_from"
                  [ngClass]="{
                    'is-invalid': submitted && ControlForm.valid_from.errors
                  }"
                  matInput
                  placeholder="Valid from"
                  [matDatepicker]="pickerFrom"
                />

                <mat-error *ngIf="isControlHasError('valid_from', 'required')">
                  Valid from date is required
                </mat-error>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="pickerFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-md-3">
            <div class="d-flex align-items-center datePicker">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Valid to</mat-label>
                <input
                  formControlName="valid_to"
                  [ngClass]="{
                    'is-invalid': submitted && ControlForm.valid_to.errors
                  }"
                  matInput
                  placeholder="Valid to"
                  [matDatepicker]="pickerTo"
                />

                <mat-error *ngIf="isControlHasError('valid_to', 'required')">
                  Valid to date is required
                </mat-error>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="pickerTo"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-5 mb-2">
        <div>
          <button class="btn me-2">Cancel</button>
          <button
            class="btn next"
            [disabled]="clientForm?.invalid"
            (click)="nextDataInForm()"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  </section>

  <!-- documents -->
  <section class="documents" *ngIf="!validForm">
    <div class="userPhoto mt-4 mb-4">
      <p>Upload profile picture</p>
      <div *ngIf="!userEditPhoto" class="mt-3">
        <label for="user">
          <img src="assets/images/mangeunit/imageDefault.png" /> </label
        ><br />
        <label for="user" class="upload">Upload photo</label>
        <input (change)="uploadUserPhoto($event)" id="user" type="file" />
      </div>
      <div *ngIf="userEditPhoto" class="imageUserUpload mt-3">
        <span (click)="removeUserImage()">X</span>
        <img [src]="url" />
      </div>

      <div *ngIf="errorUserPhoto" class="alertError">
        <span>Please upload Profile image</span>
      </div>
    </div>
    <div class="uploadDocs">
      <p class="mb-1">Upload documents</p>
      <span>Upload user ID, contract, or any other important document</span>

      <div class="mt-3">
        <div class="row align-items-center">
          <div class="col-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Image Type</mat-label>
              <mat-select
                [(ngModel)]="typeDocName"
                (selectionChange)="typeChoose($event.value)"
              >
                <mat-option value="ID_Passport"> ID/Passport </mat-option>
                <mat-option value="Drivers_License">
                  Drivers License
                </mat-option>
                <mat-option value="Birth_Certificate">
                  Birth Certificate
                </mat-option>
                <mat-option value="Other"> Other </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <input
              type="file"
              multiple
              (change)="uploadDocumentClient($event)"
            />
          </div>
        </div>
        <div *ngIf="status_input_type_name">
          <div class="row">
            <div class="col-6">
              <label>Image Type Name</label>
              <input
                [(ngModel)]="typeInputName"
                (input)="inputChange($event)"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="userDocEditPhoto" class="d-flex mt-3">
        <div
          class="imageUserUpload documentsImages ms-3"
          *ngFor="let item of documentImage"
        >
          <span (click)="removeDocImage(item)">X</span>
          <img *ngIf="item.file == false" [src]="item.url" />
          <p *ngIf="item.file == true">{{ item.url }}</p>
          <h5>{{ item.name }}</h5>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="errorDocumentPhoto" class="alertError">
      <span>Please upload Document</span>
    </div> -->

    <div class="d-flex justify-content-end mt-5 mb-2">
      <div>
        <!-- <button class="btn me-2">Back</button> -->
        <button class="btn me-2" (click)="skip()">Skip</button>
        <button (click)="createClient()" class="btn next">
          Create profile
        </button>
      </div>
    </div>
  </section>
</section>
