import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environments';
@Injectable({
  providedIn: 'root',
})
export class GAccessService {
  constructor(private http: HttpClient) {}

  retrieveStaff() {
    return this.http.get(`${environment.baseUrl}/client/staff`);
  }

  retrieveVip() {
    return this.http.get(`${environment.baseUrl}/client/vip`);
  }

  getTypes() {
    return this.http.get(`${environment.baseUrl}/types/?name=user types`);
  }
  addCategory(data: any) {
    return this.http.post(`${environment.baseUrl}/types/`, data);
  }

  getStaffTypes(value: any) {
    return this.http.get(`${environment.baseUrl}/types/?name=${value}`);
  }

  add_member(data: any) {
    return this.http.put(`${environment.baseUrl}/client/addclient/`, data);
  }

  retrieveLogs(data: any) {
    return this.http.post(`${environment.baseUrl}/cards/access/`, data);
  }

  retrieveStaffOrVIP(page: number, limit: number, name?: any, typeId?: any) {
    let url = `${environment.baseUrl}/client/management/?page=${page}&limit=${limit}`;

    if (name !== undefined && name !== null) {
      url += `&name=${name}`;
    }

    if (typeId !== undefined && typeId !== null) {
      url += `&type_id=${typeId}`;
    }

    return this.http.get(url);
  }

  exportLogos(data: any) {
    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify(data);
    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    return fetch(
      `${environment.baseUrl}/cards/access_exports/`,
      requestOptions
    );
    //  return this.http.post(`${environment.baseUrl}/cards/access_exports/`,data)
  }
}
