import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUtilityComponent } from './add-utilty/add-utilty.component';
import { AddUtilitiesComponent } from './all-utiltes/all-utiltes.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddSectionComponent } from './add-section/add-section.component';
import { AddInoviceComponent } from './add-inovice/add-inovice.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarModule } from 'ngx-avatar';

@NgModule({
  declarations: [
    AddUtilityComponent,
    AddUtilitiesComponent,
    AddSectionComponent,
    AddInoviceComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    AvatarModule,
  ],
})
export class UtiltyModule {}
