<section class="main">
  <div class="mb-3 d-flex justify-content-between align-items-center">
    <h3>Gate configuration</h3>
    <div class="addGate">
      <button class="btn" (click)="addGate()">Add Gate</button>
    </div>
  </div>

  <mat-spinner *ngIf="showSpinnerForGates" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinnerForGates" class="sup_main">
    <table class="mt-3 table table-striped sticky-header">
      <thead>
        <tr>
          <th>ID</th>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Cctv IP</th>
          <th scope="col">Anpr IP</th>
          <th scope="col">Face recognition IP</th>
          <th scope="col">hikVision IP</th>
          <th scope="col">IP</th>
          <th scope="col">Direction</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of gates_arr">
          <td>{{ item?.id }}</td>
          <td>{{ item?.name }}</td>
          <td>{{ item?.type }}</td>
          <td>{{ item?.cctv_ip }}</td>
          <td>{{ item?.anpr_ip }}</td>
          <td>{{ item?.face_rec_ip }}</td>
          <td>{{ item?.hikvision_ip }}</td>
          <td>{{ item?.ip }}</td>
          <td>{{ item?.direction }}</td>
        </tr>
      </tbody>
    </table>
  </section>

  <!--
  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div> -->
</section>
