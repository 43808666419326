import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { PromoCodeListComponent } from '../promo-code-list/promo-code-list.component';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-edit-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrls: ['./edit-promo-code.component.css'],
})
export class EditPromoCodeComponent implements OnInit {
  editNewPromoCodeForm: FormGroup | any;

  constructor(
    private main: MainService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly promoCodeService: PromoCodeService,
    public dialogRef: MatDialogRef<PromoCodeListComponent>
  ) {}

  ngOnInit() {
    this.getEditPromoCodeForm();
  }

  getEditPromoCodeForm() {
    this.editNewPromoCodeForm = new FormGroup({
      promo_code: new FormControl(null, [Validators.required]),
      percentage: new FormControl(null, [Validators.required]),
      limit: new FormControl(null, [Validators.required]),
    });

    this.editNewPromoCodeForm.patchValue({
      promo_code: this.data?.promoCodeDetails?.promo_code,
      percentage: this.data?.promoCodeDetails?.percentage,
      limit: this.data?.promoCodeDetails?.limit,
    });
  }

  editPromoCode() {
    if (this.editNewPromoCodeForm.invalid) {
      this.editNewPromoCodeForm.markAllAsTouched();
      return;
    }

    const { promo_code, percentage, limit } = this.editNewPromoCodeForm.value;
    const promoCode = {
      id: this.data?.promoCodeDetails?.id,
      promo_code,
      percentage,
      limit,
    };

    this.promoCodeService.editPromoCode(promoCode).subscribe(
      (res: any) => {
        this.main.SuccessToast('Promo code Updated successfully');
        this.dialogRef.close(res);
      },
      (err: any) => {
        this.main.errorToast('Something wrong');
      }
    );
  }
}
