<section class="main">
  <h3>Gate Access</h3>

  <mat-tab-group>
    <mat-tab label="Black List">
      <!-- <div class="mb-3 mt-2">
        <label>Black List Gates </label>
        <div class="dropdown mb-0">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Gate name
            <img src="assets/images/mangeunit/arrow.png" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <li (click)="chooseGate(item)" *ngFor="let item of allGate_array">
              <a class="dropdown-item">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
        <span class="errorMessage" *ngIf="errorGate"> Gates is required</span>
      </div> -->

      <div class="mb-3 mt-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Black List Gates</mat-label>
          <mat-select
            fullWidth
            placeholder="Select gate name"
            class="d-felx justify-content-center align-items-center"
          >
            <mat-option
              (click)="chooseGate(item)"
              *ngFor="let item of allGate_array"
            >
              <strong>
                {{ item?.name | titlecase }}
              </strong>
            </mat-option>
          </mat-select>
          <span class="errorMessage" *ngIf="errorGate"> Gates is required</span>
        </mat-form-field>
      </div>

      <section *ngIf="gateChoice.length != 0">
        <h3>Blocked gates</h3>

        <div class="gateBlocked">
          <p *ngFor="let item of gateChoice; let i = index">
            {{ item.name }}
            <span (click)="removeGate(item.id, i)">
              <img src="assets/images/mangeunit/close.png" />
            </span>
          </p>
        </div>
      </section>

      <div class="addUnits d-flex justify-content-end mt-5 mb-2">
        <div>
          <button mat-dialog-close class="btn me-2">Cancel</button>
          <button (click)="saveChange()" class="btn addUnit">
            Save change
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="White List">
      <!-- <div class="mb-3 mt-2">
        <label>White List Gates </label>
        <div class="dropdown mb-0">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Gate name
            <img src="assets/images/mangeunit/arrow.png" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <li
              (click)="chooseGateWhite(item)"
              *ngFor="let item of allWHitGates"
            >
              <a class="dropdown-item">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
        <span class="errorMessage" *ngIf="errorGateWhite">
          Gates is required</span
        >
      </div> -->

      <div class="mb-3 mt-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>White List Gates</mat-label>
          <mat-select
            fullWidth
            placeholder="Select gate name"
            class="d-felx justify-content-center align-items-center"
          >
            <mat-option
              (click)="chooseGateWhite(item)"
              *ngFor="let item of allWHitGates"
            >
              <strong>
                {{ item?.name | titlecase }}
              </strong>
            </mat-option>
          </mat-select>
          <span class="errorMessage" *ngIf="errorGateWhite"
            >Gates is required</span
          >
        </mat-form-field>
      </div>

      <section *ngIf="gateWhitChoices.length != 0">
        <h3>White gates</h3>

        <div class="gateBlocked">
          <p *ngFor="let item of gateWhitChoices; let i = index">
            {{ item.name }}
            <span (click)="removeGateWhite(item.id, i)">
              <img src="assets/images/mangeunit/close.png" />
            </span>
          </p>
        </div>
      </section>

      <div class="addUnits d-flex justify-content-end mt-5 mb-2">
        <div>
          <button mat-dialog-close class="btn me-2">Cancel</button>
          <button (click)="saveWhiteChange()" class="btn addUnit">
            Save change
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
