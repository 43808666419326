<section class="main">
  <h1>Rentals</h1>
  <section class="sup_main">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h2>
        <span>
          <img
            src="assets/images/mangeunit/back.png"
            class="backButton"
            (click)="goBack()"
          />
        </span>
        Unit Name : {{ unit_name | titlecase }}
      </h2>

      <app-print-confirmation-modal
        #printModal
        (okClick)="onModalOk()"
        (cancelClick)="onModalCancel()"
        (backgroundClick)="onModalBackground()"
      ></app-print-confirmation-modal>

      <div class="d-flex justify-content-between align-items-center filters">
        <mat-form-field appearance="outline" class="w-50 me-2">
          <mat-label>Date from</mat-label>
          <input
            matInput
            name="Date from"
            placeholder="Date from"
            [(ngModel)]="startDate"
            [matDatepicker]="pickerFrom"
            (dateChange)="onDateChange($event)"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-50">
          <mat-label>Date to</mat-label>
          <input
            matInput
            name="Date to"
            placeholder="Date to"
            [(ngModel)]="endDate"
            [matDatepicker]="pickerTo"
            (dateChange)="onDateChange($event)"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <button
          (click)="resetFilter()"
          *ngIf="showResetButton"
          class="btn ms-3 w-25 filterButton"
        >
          Reset filter
        </button>
      </div>
    </div>

    <hr />

    <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

    <div class="page-container">
      <section *ngIf="!showSpinner" class="mt-3 content">
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let unitRental of unitRentals"
            class="m-1"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ unitRental?.checkin_date }}
              </mat-panel-title>
              <mat-panel-title>
                {{ unitRental?.checkout_date }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="d-flex justify-content-end mb-3">
              <div
                *ngIf="unitRental?.rentals?.length"
                class="btn filterButton"
                (click)="print_card_check_invoices('all', unitRental?.rentals)"
              >
                Print All
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-4 col-12"
                *ngFor="let rentals of unitRental?.rentals"
              >
                <div
                  class="clientsCard"
                  [ngClass]="{
                    cardWhenMerge: mergeButtonStatus === true,
                    clientsCardWhenBlock: rentals.cancelled === true
                  }"
                >
                  <div class="row info">
                    <div class="col-4">
                      <img
                        *ngIf="rentals.photo"
                        class="user img-fluid"
                        [src]="rentals.photo"
                      />

                      <ngx-avatar
                        *ngIf="!rentals.photo"
                        size="80"
                        name="{{ rentals?.name }}"
                        cornerRadius="5"
                        [round]="false"
                      ></ngx-avatar>
                    </div>
                    <div class="col-7 p-0 ownerInfo">
                      <p
                        class="mb-2"
                        style="cursor: pointer"
                        (click)="viewClient(rentals.id)"
                      >
                        <img
                          *ngIf="rentals.verified == true"
                          src="assets/images/mangeunit/circle_check.png"
                        />
                        {{ rentals.name | titlecase }}
                      </p>
                      <div>
                        <span *ngIf="rentals.relation" class="hint">{{
                          rentals.relation
                        }}</span>
                        <span *ngIf="rentals.verified == true" class="hint"
                          >Verified</span
                        >
                        <span *ngIf="rentals.activated == true" class="hint"
                          >Activated</span
                        >
                        <span
                          *ngIf="rentals.multiple_units == true"
                          class="hint"
                          >Multiple units</span
                        >
                        <span *ngIf="rentals.phone_number" class="hint">{{
                          rentals.phone_number
                        }}</span>
                        <span *ngIf="rentals.email" class="hint">{{
                          rentals.email
                        }}</span>
                      </div>
                    </div>
                    <div class="col-1 dots">
                      <mat-menu #menu="matMenu">
                        <p
                          (click)="editClient(rentals.id)"
                          class="menuItem"
                          mat-menu-item
                        >
                          Edit
                        </p>
                        <!-- <p class="menuItem" mat-menu-item>Merge</p> -->
                        <p
                          *ngIf="rentals.cancelled === false"
                          (click)="openBlockClient('block', rentals)"
                          class="menuItem"
                          mat-menu-item
                        >
                          Block
                        </p>
                        <p
                          *ngIf="rentals.cancelled === true"
                          (click)="openBlockClient('unblock', rentals)"
                          class="menuItem"
                          mat-menu-item
                        >
                          Active
                        </p>
                        <p
                          (click)="openDeleteClient(rentals)"
                          class="menuItem"
                          mat-menu-item
                        >
                          Delete
                        </p>
                      </mat-menu>
                      <img
                        [matMenuTriggerFor]="menu"
                        src="assets/images/mangeunit/dots.png"
                      />
                    </div>
                  </div>

                  <div
                    class="mt-4 icons d-flex align-items-center justify-content-between"
                  >
                    <img
                      (click)="generateInvitation(rentals)"
                      mat-raised-button
                      matTooltip="Invitations "
                      matTooltipClass="example-tooltip-uppercase"
                      [matTooltipPosition]="'above'"
                      src="assets/images/mangeunit/Group 162.png"
                    />

                    <img
                      (click)="blockUnitGates(rentals.id)"
                      class="icon2"
                      mat-raised-button
                      matTooltip="Gate access"
                      matTooltipClass="example-tooltip-uppercase"
                      [matTooltipPosition]="'above'"
                      src="assets/images/mangeunit/Group 136.png"
                    />

                    <img
                      (click)="addCard(rentals)"
                      mat-raised-button
                      matTooltip="Activate"
                      matTooltipClass="example-tooltip-uppercase"
                      [matTooltipPosition]="'above'"
                      class="icon3"
                      src="assets/images/mangeunit/Group 160.png"
                    />

                    <div class="notes">
                      <p *ngIf="rentals.notes.length != 0">
                        <span>{{ rentals.notes.length }}</span>
                      </p>
                      <img
                        (click)="addNote('client', rentals.id)"
                        mat-raised-button
                        matTooltip="Add/Show Note"
                        matTooltipClass="example-tooltip-uppercase"
                        [matTooltipPosition]="'above'"
                        src="assets/images/mangeunit/Group 170.png"
                      />
                    </div>

                    <img
                      *ngIf="!rentals.printed"
                      (click)="
                        print_card_check_invoices(rentals, unitRental?.rentals)
                      "
                      mat-raised-button
                      matTooltip="Print"
                      matTooltipClass="example-tooltip-uppercase"
                      [matTooltipPosition]="'above'"
                      src="assets/images/mangeunit/5.png"
                    />

                    <img
                      *ngIf="rentals.printed"
                      (click)="print_card_check_invoices(rentals, unitRental?.rentals)"
                      mat-raised-button
                      matTooltip="Print Again"
                      matTooltipClass="example-tooltip-uppercase"
                      [matTooltipPosition]="'above'"
                      src="assets/images/mangeunit/greenPrint.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </section>
    </div>
  </section>
  <div class="paginator">
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</section>

<!-- blocked -->

<button
  id="btn4"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal4"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal4"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">
          {{ blockClientTitle }} client
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to {{ blockClientTitle }} the client?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="blockClient()"
          data-bs-dismiss="modal"
        >
          {{ blockClientTitle }} Client
        </button>
      </div>
    </div>
  </div>
</div>

<!-- delete model -->

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete client</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">Are you sure you want to delete the client?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteClient()"
          data-bs-dismiss="modal"
        >
          Delete client
        </button>
      </div>
    </div>
  </div>
</div>

<div id="cardPrint">
  <div class="cards print" *ngFor="let item of dataPrint">
    <div class="front">
      <div class="background-image">
        <img [src]="item?.frontImage" />
      </div>

      <ul class="clientData">
        <li>
          <p style="margin: 0; padding: 0" class="clientName">
            {{ item?.name | uppercase }}
          </p>
        </li>
        <li>
          <p style="margin: 0; padding: 0" *ngIf="item.unitName != ''">
            <strong>Unit:</strong> {{ item?.unitName }}
          </p>
        </li>
        <li>
          <p style="margin: 0; padding: 0" *ngIf="item.villageName != ''">
            <strong>Zone:</strong> {{ item?.villageName }}
          </p>
        </li>
        <li *ngIf="item.userType == 'Tenant' || item.userType == 'tenant'">
          <p style="margin: 0; padding: 0">
            <strong>[ {{ item?.validFrom }} ] : [ {{ item?.validTo }} ]</strong>
          </p>
        </li>
      </ul>

      <div class="centered-image">
        <img class="clientImage" [src]="item?.image" alt="Avatar" />
      </div>
    </div>
    <div class="back">
      <div>
        <img [src]="item?.image1" />
      </div>
    </div>
  </div>
</div>
