import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent implements OnInit {
  menu = [
    {
      name: 'Dashboard',
      // route: '/pages/Dashboard',
      status: true,
      showSubMenu: false,
      children: [
        {
          name: 'Gate Dashboard',
          route: '/pages/Dashboard',
          subStatus: false,
        },

        {
          name: 'Gate Monitor',
          route: '/pages/gate-monitor',
          subStatus: false,
        },
      ],
    },
    {
      name: 'System Analytics',
      route: '/pages/system_analytics',
      status: false,
    },
    {
      name: 'Manage units',
      status: false,
      route: '/pages/mange_units',
    },
    {
      name: 'Manage Clients',
      status: false,
      route: '/pages/all_clients',
    },
    {
      name: 'Family Requests',
      status: false,
      route: '/pages/family_requests',
    },
    {
      name: 'Manage Villages',
      status: false,
      route: '/pages/mange_village',
    },
    {
      name: 'Mange services',
      status: false,
      route: '/pages/all_services',
    },
    {
      name: 'Services request',
      status: false,
      route: '/pages/service_request',
    },
    {
      name: 'General access',
      status: false,
      route: '/pages/general_access',
    },
    {
      name: 'Utility',
      status: false,
      route: '/pages/Utility',
    },

    // {
    //   name: "Access Logs",
    //   status: false,
    //   route: "/pages/accsess_logs"
    // },
    {
      name: 'Operators',
      status: false,
      route: '/pages/Operators',
    },

    {
      name: ' Logs',
      status: false,
      // route: '/pages/all_logs',

      showSubMenu: false,
      children: [
        {
          name: 'Access logs',
          route: '/pages/access_logs',
          subStatus: false,
        },

        {
          name: 'Operators logs',
          route: '/pages/operators_logs',
          subStatus: false,
        },
      ],
    },

    {
      name: 'Roles and permissions',
      status: false,
      route: '/pages/roles',
    },

    {
      name: 'Manage gates',
      status: false,
      route: '/pages/mange_gate',
    },

    {
      name: 'Promo codes',
      status: false,
      route: '/pages/promo-codes',
    },
    {
      name: 'Configuration',
      status: false,

      showSubMenu: false,
      children: [
        {
          name: 'User card configuration',
          route: '/pages/user-card-configuration',
          subStatus: false,
        },

        {
          name: 'User access configuration',
          route: '/pages/user-access-configuration',
          subStatus: false,
        },

        // {
        //   name: 'Gate configuration',
        //   route: '/pages/gate-configuration',
        //   subStatus: false,
        // },
        // {
        //   name: 'Village configuration',
        //   route: '/pages/village-configuration',
        //   subStatus: false,
        // },
        // {
        //   name: 'Users configuration',
        //   route: '/pages/users-configuration',
        //   subStatus: false,
        // },
        // {
        //   name: 'QR configuration',
        //   route: '/pages/qr-configuration',
        //   subStatus: false,
        // },
      ],
    },
  ];
  constructor(private route: Router) {}

  // toggleSubMenu(item: any) {
  //   item.status = true;
  //   item.showSubMenu = !item.showSubMenu;

  //   this.menu.forEach((element: any) => {
  //     if (item.route == element.route) {
  //       element.status = true;
  //     } else {
  //       element.status = false;

  //       if (element.children) {
  //         element.children.forEach((element: any) => {
  //           if (item.route == element.route) {
  //             element.subStatus = true;
  //           } else {
  //             element.subStatus = false;
  //           }
  //         });
  //       } else {
  //         element.status = false;
  //       }
  //     }
  //   });
  // }

  toggleSubMenu(item: any) {
    this.menu.forEach((element: any) => {
      if (item === element) {
        element.status = !element.status;
        element.showSubMenu = !element.showSubMenu;
      } else {
        element.status = false;
      }
    });
  }

  handleClick(item: any) {
    if (item.children) {
      this.toggleSubMenu(item);
    } else {
      this.setRoute(item);
    }
  }

  ngOnInit() {
    let router = localStorage.getItem('route');
    if (router && router == this.route.url) {
      this.menu.forEach((element: any) => {
        if (router == element.route) {
          element.status = true;
        } else {
          if (element.children) {
            element.children.forEach((element: any) => {
              element.subStatus = false;
            });
          } else {
            element.subStatus = false;
          }
        }
      });
    }
  }

  setRoute(item: any) {
    if (item.route) {
      this.menu.forEach((element: any) => {
        if (item.route == element.route) {
          element.status = true;
        } else {
          element.status = false;

          if (element.children) {
            element.children.forEach((element: any) => {
              if (item.route == element.route) {
                element.subStatus = true;
              } else {
                element.subStatus = false;
              }
            });
          } else {
            element.status = false;
          }
        }
      });

      this.route.navigateByUrl(`${item.route}`);
      localStorage.setItem('route', item.route);
    }
  }
}
