import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MangeUnitsComponent } from '../mange-units/mange-units.component';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-unit-block',
  templateUrl: './unit-block.component.html',
  styleUrls: ['./unit-block.component.css'],
})
export class UnitBlockComponent implements OnInit {
  allGate_array: any;
  allWHitGates: any;
  gateChoice: any = [];
  gateWhitChoices: any = [];
  errorGate = false;
  errorGateWhite = false;
  blackList_arr: any = [];
  WhiteList_arr: any = [];

  constructor(
    private rest: MainService,
    private unitservice: UnitsService,
    public dialogRef: MatDialogRef<MangeUnitsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getData();
    if (this.data.name == 'client') {
      this.retriveClientBlackList();
    }
  }

  getData() {
    this.rest.allGats().subscribe((res: any) => {
      this.allGate_array = res;
      this.allWHitGates = res;
    });
  }

  chooseGate(value: any) {
    this.errorGate = false;
    if (this.gateChoice.includes(value)) {
      return;
    } else {
      this.gateChoice.push(value);
    }
  }

  chooseGateWhite(value: any) {
    this.errorGateWhite = false;
    if (this.gateWhitChoices.includes(value)) {
      return;
    } else {
      this.gateWhitChoices.push(value);
    }
  }

  removeGate(id: any, value: any) {
    this.gateChoice.splice(value, 1);

    if (this.data.name == 'client') {
      let clients = [this.data.id];
      let gates = [id];
      let data = {
        gates: gates,
        clients: clients,
      };
      this.unitservice.removeBlackListGateClient(data).subscribe((res: any) => {
      });
    }
  }
  removeGateWhite(id: any, value: any) {
    this.gateWhitChoices.splice(value, 1);

    if (this.data.name == 'client') {
      let clients = [this.data.id];
      let gates = [id];
      let data = {
        gates: gates,
        clients: clients,
      };
      this.unitservice.removeWhiteListGateClient(data).subscribe((res: any) => {
      });
    }
  }

  saveChange() {
    if (this.gateChoice.length != 0) {
      this.errorGate = false;
      let gateId: any = [];
      this.gateChoice.forEach((element: any) => {
        this.blackList_arr.push(element.id);
      });
      let data = {
        name: 'black',
        balckList: this.blackList_arr,
        WhiteList: this.WhiteList_arr,
      };
      this.dialogRef.close(data);
    } else {
      this.errorGate = true;
    }
  }
  saveWhiteChange() {
    if (this.gateWhitChoices.length != 0) {
      this.errorGateWhite = false;
      let gateId: any = [];
      this.gateWhitChoices.forEach((element: any) => {
        this.WhiteList_arr.push(element.id);
      });
      let data = {
        name: 'white',
        balckList: this.blackList_arr,
        WhiteList: this.WhiteList_arr,
      };
      this.dialogRef.close(data);
    } else {
      this.errorGateWhite = true;
    }
  }

  retriveUnitBlackList() {
    this.unitservice
      .retrieveUnitBlackList(this.data.id)
      .subscribe((res: any) => {
      });
  }

  retriveClientBlackList() {
    this.unitservice
      .retrieveClientBlackList(this.data.id)
      .subscribe((res: any) => {
        this.gateChoice = res.blacklisted_gates;
        this.gateWhitChoices = res.whitelisted_gates;
      });
  }
}
