import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GatesService } from 'src/app/services/gates.service';
import { VillageService } from 'src/app/services/village.service';
import { AddUserTypeComponent } from '../../gates/add-user-type/add-user-type.component';
import { UnitsService } from 'src/app/services/units.service';
import { MainService } from 'src/app/services/main.service';
import { AddUserConfigComponent } from '../../gates/add-user-config/add-user-config.component';

@Component({
  selector: 'app-users-configuration',
  templateUrl: './users-configuration.component.html',
  styleUrls: ['./users-configuration.component.css'],
})
export class UsersConfigurationComponent implements OnInit {
  users_list_arr: any = [];
  selected_user_village: any = 'all';
  village_list: any;
  types_arr: any;
  showSpinnerForUsers: boolean = true;
  selected_type: any = 'all';

  constructor(
    private mainService: MainService,
    private villageSer: VillageService,
    private unitService: UnitsService,
    private rest: GatesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  retrieve_all_villages() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.village_list = res?.results;
      // this.selected_qr_village = res?.results[0];
      // res?.results.forEach((element: any) => {
      //   this.retrieveVillagesConfig(element);
      // });
      // this.users_type();
      // this.qr_type();
    });
  }

  changeType(e: any) {
    this.users_list_arr = [];
    if (e.value == 'all') {
      if (this.selected_user_village == 'all') {
        for (let i = 0; i < this.village_list.length; i++) {
          for (let j = 0; j < this.types_arr.length; j++) {
            this.retrieve_user_config(this.types_arr[j], this.village_list[i]);
          }
        }
      } else {
        for (let i = 0; i < this.types_arr.length; i++) {
          this.retrieve_user_config(
            this.types_arr[i],
            this.selected_user_village
          );
        }
      }
    } else {
      if (this.selected_user_village == 'all') {
        for (let i = 0; i < this.village_list.length; i++) {
          this.retrieve_user_config(e.value, this.village_list[i]);
        }
      } else {
        this.retrieve_user_config(e.value, this.selected_user_village);
      }
    }
  }

  village_user_Change(e: any) {
    this.users_list_arr = [];
    if (e.value == 'all') {
      if (this.selected_type == 'all') {
        for (let i = 0; i < this.village_list.length; i++) {
          for (let j = 0; j < this.types_arr.length; j++) {
            this.retrieve_user_config(this.types_arr[j], this.village_list[i]);
          }
        }
      } else {
        for (let i = 0; i < this.village_list.length; i++) {
          this.retrieve_user_config(this.selected_type, this.village_list[i]);
        }
      }
    } else {
      if (this.selected_type == 'all') {
        for (let i = 0; i < this.types_arr.length; i++) {
          this.retrieve_user_config(this.types_arr[i], e.value);
        }
      } else {
        this.retrieve_user_config(this.selected_type, e.value);
      }
    }
  }

  retrieve_user_config(type: any, village: any) {
    this.rest
      .retrieve_user_config(type.id, village.id)
      .subscribe((res: any) => {
        this.showSpinnerForUsers = false;
        res.name = village.name;
        res.type = type.name;
        this.users_list_arr.push(res);
      });
  }

  //Add User type

  addUserType() {
    const dialogRef = this.dialog.open(AddUserTypeComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.unitService.addNewType(res).subscribe((res: any) => {
          this.mainService.SuccessToast('User type added successfully');
        });
      }
    });
  }

  add_user_config() {
    const dialogRef = this.dialog.open(AddUserConfigComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.rest.addUserConfig(res).subscribe((res: any) => {
          this.showSpinnerForUsers = true;
          this.retrieve_all_villages();
          this.users_list_arr = [];
          this.mainService.SuccessToast('User Config added successfully');
        });
      }
    });
  }
}
