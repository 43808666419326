<section class="main">
  <!-- <button *ngIf="add_serviceStatus" class="btn mb-2" (click)="addService()">
    Add Service
  </button>
  <button *ngIf="!add_serviceStatus" class="btn mb-2" (click)="back()">
    Back
  </button> -->

  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <button *ngIf="!add_serviceStatus" class="btn mb-2 me-2" (click)="back()">
        Back
      </button>
      <h3>Services</h3>
    </div>
    <button
      *ngIf="add_serviceStatus"
      class="btn mb-2"
      (click)="addService(); showServiceMobileView()"
    >
      Add Service
    </button>
  </div>

  <div class="row">
    <div class="col-md-4" *ngIf="showServiceList">
      <div class="d-flex justify-content-between servMange">
        <p>Services Management</p>
        <div class="boxes">
          <img
            (click)="editMicroService(); showServiceMobileView()"
            class="edit me-2"
            src="assets/images/edit-64.png"
          />
          <img
            (click)="deleteService()"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            class="edit"
            src="assets/images/delete.png"
          />
        </div>
      </div>
      <hr class="headeLine" />

      <mat-spinner
        class="mt-4"
        *ngIf="showSpinnerForMainServices"
        diameter="40"
      ></mat-spinner>

      <section class="menuItems" *ngIf="!showSpinnerForMainServices">
        <div
          [ngClass]="{ active: item.status == true }"
          class="box"
          (click)="serviceDetails(item); showServiceMobileView()"
          *ngFor="let item of list_services"
        >
          <img src="{{ item.image }}" style="width: 60px; height: 60px" />
          <p>{{ item.name | titlecase }}</p>
        </div>
      </section>
    </div>
    <div [ngClass]="showServiceList ? 'col-md-8' : 'col-md-12'">
      <section
        *ngIf="add_serviceStatus && edit_serviceStatus == false"
        class="menuItems"
      >
        <div
          class="headMicro d-flex justify-content-between align-items-center"
        >
          <mat-spinner
            class="m-0"
            *ngIf="showSpinnerForSubServices"
            diameter="30"
          ></mat-spinner>

          <div class="d-flex align-items-center">
            <span class="d-md-none">
              <img
                src="assets/images/mangeunit/back.png"
                class="backButton"
                (click)="backToServices()"
              />
            </span>

            <h1 *ngIf="!showSpinnerForSubServices">
              {{ ServiceName | titlecase }}
            </h1>
          </div>
          <button class="btn" (click)="addMicroService()">
            Add Micro Service
          </button>
        </div>
        <hr />
        <div class="row">
          <div class="row">
            <!-- <div
              *ngFor="let item of microServiceList"
              class="col-3 itemContent"
            >
              <img
                (click)="editMicroService(item)"
                src="assets/images/edit-64.png"
                class="editMicroIcon"
              />
              <img
                src="{{ item.image }}"
                style="width: 132px; height: 124px"
                class="img-fluid"
              />
              <p class="mb-1 text-truncate">{{ item.name }}</p>
              <p class="mt-1">{{ item.cost }} LE</p>
            </div> -->

            <div class="table">
              <mat-spinner
                *ngIf="showSpinnerForSubServices"
                diameter="50"
              ></mat-spinner>

              <table
                *ngIf="!showSpinnerForSubServices"
                mat-table
                [dataSource]="microServiceList"
                matSort
                matSortDirection="desc"
                matSortDisableClear
              >
                <ng-container matColumnDef="serviceImage">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Service Image</strong>
                  </th>

                  <td mat-cell *matCellDef="let row">
                    <img
                      *ngIf="row?.image"
                      [src]="row?.image"
                      class="serviceImage"
                    />

                    <div class="ngxAvatar" *ngIf="!row?.image">
                      <ngx-avatar
                        size="52"
                        name="{{ row?.name }}"
                        cornerRadius="10"
                        [round]="false"
                      ></ngx-avatar>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="serviceName">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear
                  >
                    <strong>Service Name</strong>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.name | titlecase }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="serviceCost">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear
                  >
                    <strong>Service Cost</strong>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row?.cost }} <strong>LE</strong>
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    disableClear
                  >
                    <strong>Action</strong>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <img
                      title="Edit service"
                      (click)="editMicroService(row)"
                      src="assets/images/edit-64.png"
                      class="newEditMicroIcon"
                    />
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
            <div *ngIf="microServiceList?.length === 0">
              <p>Sorry, no services to display!</p>
            </div>
            <mat-paginator
              [pageSize]="7"
              [length]="totalCount"
              [pageIndex]="page - 1"
              (page)="changePage($event)"
            ></mat-paginator>
          </div>
        </div>
        <!-- <div *ngIf="microServiceList.length == 0">
          <p>No Data Found</p>
        </div> -->
      </section>
      <section
        *ngIf="!add_serviceStatus && edit_serviceStatus == false"
        class="menuItems"
      >
        <section *ngIf="!schedules_status">
          <h3>Add Service</h3>
          <hr />
          <div class="row">
            <div class="col-12">
              <!-- <div class="form-group">
                <label>Name</label>
                <input
                  [(ngModel)]="serviceName"
                  type="text"
                  class="form-control"
                />
              </div> -->

              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Name</mat-label>
                  <input matInput type="text" [(ngModel)]="serviceName" />
                </mat-form-field>
              </div>

              <div class="mt-3">
                <mat-slide-toggle
                  [(ngModel)]="disabledApp"
                  name="disabledApp"
                  [color]="color"
                >
                  Disabled in Mobile APP
                </mat-slide-toggle>
              </div>
              <div class="mt-4">
                <p>Service image</p>
                <label for="images" class="drop-container">
                  <input
                    (change)="uploadServiceImage($event)"
                    type="file"
                    id="images"
                    accept="image/*"
                    required
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="mt-4" style="text-align: right">
            <button class="btn add" (click)="next()">Next</button>
          </div>
        </section>
        <section *ngIf="schedules_status">
          <h3>Add Service</h3>
          <hr />
          <strong>Date</strong>
          <form [formGroup]="scheduleForm" class="mt-3">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>From</label>
                  <input
                    formControlName="valid_from"
                    type="datetime-local"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>To</label>
                  <input
                    formControlName="valid_to"
                    type="datetime-local"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="row mt-3">
              <div class="col">
                <div class="form-group">
                  <label>Duration</label>
                  <input
                    formControlName="duration"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Capacity</label>
                  <input
                    formControlName="capacity"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
            </div> -->
            <div class="row mt-3">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Duration</mat-label>
                  <input matInput type="number" formControlName="duration" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Capacity</mat-label>
                  <input matInput type="number" formControlName="capacity" />
                </mat-form-field>
              </div>
            </div>
          </form>
          <div class="mt-4" style="text-align: right">
            <button (click)="addServices()" class="btn add">Add Service</button>
          </div>
        </section>
      </section>
      <section *ngIf="edit_serviceStatus" class="menuItems">
        <h3>Edit Service</h3>
        <hr />
        <div *ngIf="!scheduleEdit">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Name</label>
                <input
                  [(ngModel)]="serviceNameEdit"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group mt-3" *ngIf="serviceCost != ''">
                <label>Cost</label>
                <input
                  [(ngModel)]="serviceCost"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="mt-3" *ngIf="serviceCost == ''">
                <mat-slide-toggle
                  [(ngModel)]="disabledApp"
                  name="disabledApp"
                  [color]="color"
                >
                  Disabled in Mobile APP
                </mat-slide-toggle>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <p>Service image</p>
            <div *ngIf="serviceImageEdit != ''" class="imageIcon">
              <span (click)="deleteImage()">
                <img src="assets/images/delete.png" />
              </span>
              <img src="{{ serviceImageEdit }}" style="width: 100px" />
            </div>
            <label
              *ngIf="serviceImageEdit == ''"
              for="images"
              class="drop-container"
            >
              <input
                (change)="uploadServiceImageEdit($event)"
                type="file"
                id="images"
                accept="image/*"
                required
              />
            </label>
          </div>
          <div class="mt-4" style="text-align: right">
            <button
              *ngIf="serviceCost == ''"
              (click)="EditService()"
              class="btn add"
            >
              Next
            </button>
            <button
              *ngIf="serviceCost != ''"
              (click)="EditService()"
              class="btn add"
            >
              Save
            </button>
          </div>
        </div>
        <div *ngIf="scheduleEdit">
          <p>Date</p>
          <form [formGroup]="scheduleForm">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>From</label>
                  <input
                    formControlName="valid_from"
                    type="datetime-local"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>To</label>
                  <input
                    formControlName="valid_to"
                    type="datetime-local"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-group">
                  <label>Duration</label>
                  <input
                    formControlName="duration"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Capacity</label>
                  <input
                    formControlName="capacity"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </form>
          <div class="mt-4" style="text-align: right">
            <button (click)="addServices()" class="btn add">
              Edit Service
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<!-- Button trigger modal -->

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <p>Are you sure to delete this service</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Yes
        </button>
        <button
          type="button"
          style="background: none; color: #000"
          class="btn"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
