import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-qr-invtation',
  templateUrl: './qr-invtation.component.html',
  styleUrls: ['./qr-invtation.component.css'],
})
export class QrInvtationComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrCodeCardConfiguration = environment?.qrCodeCard;
  projectLogo = environment?.projectLogo;

  value = '';
  clientName = '';
  clientInviter = '';
  dateFrom = '';
  dateTo = '';
  unitName = '';
  phone_number = '';
  email = '';
  peopleCount = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.clientName =
      this.data?.data?.customer_name || this.data?.customer_name;
    this.dateFrom = this.data?.data?.valid_from || this.data?.valid_from;
    this.dateTo = this.data?.data?.valid_to || this.data?.valid_to;
    this.clientInviter = this.data?.inviter || this.data?.client_name;
    this.unitName = this.data?.unitName;
    this.value = this.data?.qrName || this.data?.qr_code;
    this.phone_number =
      this.data?.data?.phone_number || this.data?.phone_number;
    this.email = this.data?.data?.email;
    this.peopleCount = this.data?.data?.count || this.data?.count;
  }

  @ViewChild('screen') screen: ElementRef | any;
  @ViewChild('canvas') canvas: ElementRef | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;
  download() {
    html2canvas(this.screen.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'invitation.png';
      this.downloadLink.nativeElement.click();
    });

    // const el: any = document.querySelector('#capture');
    // html2canvas(el).then((canvas: HTMLCanvasElement) => {
    //   document.body.appendChild(canvas);
    // });
  }

  sendWhatsApp() {
    html2canvas(this.screen.nativeElement).then((canvas) => {
      const imageDataUrl = canvas.toDataURL('image/png');
      const whatsappShareUrl = `https://wa.me/${
        this.phone_number
      }?%20${encodeURIComponent(imageDataUrl)}`;
      window.open(whatsappShareUrl, '_blank');
    });
  }
}
