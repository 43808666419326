import { Component } from '@angular/core';

@Component({
  selector: 'app-clients-payment',
  templateUrl: './clients-payment.component.html',
  styleUrls: ['./clients-payment.component.css']
})
export class ClientsPaymentComponent {

}
