import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VillageService } from 'src/app/services/village.service';
import { AddNewImageComponent } from '../add-new-image/add-new-image.component';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { PageEvent } from '@angular/material/paginator';
import { ConfigurationDetailsComponent } from '../configuration-details/configuration-details.component';
import { EditConfigurationComponent } from '../edit-configuration/edit-configuration.component';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-user-card-configuration',
  templateUrl: './user-card-configuration.component.html',
  styleUrls: ['./user-card-configuration.component.css'],
})
export class UserCardConfigurationComponent implements OnInit {
  showSpinner: boolean = true;
  projectsArray: any;
  projectNameSearchKey: any = '';
  photo: string = '';
  configurationId: any;
  page = 1;
  limit = 10;
  totalCount = 0;
  projectId: any;
  allConfiguration: any;

  constructor(
    private apis: VillageService,
    public dialog: MatDialog,
    private readonly configuration: ConfigurationsService,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.getProjects();
    this.getAllConfigurations();
  }

  getProjects() {
    this.apis
      .projectListWithSearch(this.projectNameSearchKey)
      .subscribe((res: any) => {
        this.projectsArray = res;
      });
  }

  getAllConfigurations() {
    this.configuration
      .retrieveConfigurations(this.page, this.limit, this.projectId)
      .subscribe((data: any) => {
        this.showSpinner = false;
        this.allConfiguration = data.results;
        this.totalCount = data?.total_count;

        this.allConfiguration.forEach((element: any) => {
          if (element.images?.length != 0) {
            element.images?.forEach((item: any) => {
              if (item.image_type == 'front') {
                element.frontPhoto = item.image;
              } else if (item.image_type == 'back') {
                element.backPhoto = item.image;
              }
            });
          }
        });
      });
  }

  selectProject(project: any) {
    this.showSpinner = true;
    this.projectId = project?.id;
    this.getAllConfigurations();
  }

  selectAllProject() {
    this.projectId = null;
    this.showSpinner = true;
    this.getAllConfigurations();
  }

  addNewImage() {
    const dialogRef = this.dialog.open(AddNewImageComponent, {
      width: '90%',
      maxWidth: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.status) {
        this.showSpinner = true;
        this.getAllConfigurations();
      }
    });
  }

  openConfiguration(configuration: any) {
    const dialogRef = this.dialog.open(ConfigurationDetailsComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        configuration: configuration,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.status) {
        this.showSpinner = true;
        this.getAllConfigurations();
      }
    });
  }

  openPhoto(dialog: TemplateRef<any>, photo: any) {
    this.photo = photo;
    this.dialog.open(dialog);
  }

  editConfiguration(item: any) {
    const dialogRef = this.dialog.open(EditConfigurationComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        configuration: item,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.status) {
        this.showSpinner = true;
        this.getAllConfigurations();
      }
    });
  }

  openDeleteConfiguration(item: any) {
    this.configurationId = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  deleteConfiguration() {
    this.configuration
      .deleteConfiguration(this.configurationId)
      .subscribe((res: any) => {
        if (res.status) {
          this.mainService.SuccessToast(res.message);
          this.showSpinner = true;
          this.getAllConfigurations();
        } else {
          this.mainService.errorToast(res.message);
        }
      });
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.page = e?.pageIndex + 1;
    this.limit = e?.pageSize;
    this.getAllConfigurations();
  }
}
