import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitsService } from 'src/app/services/units.service';
import { UnitInvoiceComponent } from '../unit-inovice/unit-inovice.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.css'],
})
export class PayInvoiceComponent implements OnInit {
  showSpinner: boolean = true;
  payInvoiceForm: FormGroup | any;
  paymentTypes: any;
  invoiceId: any;

  //Promocode from all Other Invoice
  promoCodeVerifiedSuccessfully: boolean = false;
  promoCode: any;
  invoiceAmount: any;
  showPromoCodeInput: boolean = false;
  //Promocode from all Other Invoice

  constructor(
    private readonly promoCodeService: PromoCodeService,
    private main: MainService,
    private readonly unitsService: UnitsService,
    public dialogRef: MatDialogRef<UnitInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.invoiceId = this.data?.invoiceId;
    this.getPaymentTypes();
    this.getPayInvoiceForm();

    //Promocode from all Other Invoice
    if (this.data.invoiceAmount) {
      this.showPromoCodeInput = true;
      this.invoiceAmount = this.data.invoiceAmount;
      console.log(this.invoiceAmount);
    }
    //Promocode from all Other Invoice
  }

  getPayInvoiceForm() {
    this.payInvoiceForm = new FormGroup({
      paymentType: new FormControl(null, [Validators.required]),
    });
  }

  getPaymentTypes() {
    this.unitsService.getPaymentTypesService().subscribe((res: any) => {
      this.paymentTypes = res?.children;
      this.showSpinner = false;
    });
  }

  //Promocode from all Other Invoice
  verifyPromoCode() {
    this.promoCodeService
      .verifyPromoCodeService(this.promoCode, this.invoiceAmount)
      .subscribe((res: any) => {
        console.log(res);

        if (res.status == false) {
          this.main.errorToast(res.message);
        } else {
          this.main.SuccessToast('Promo code verified');
          this.promoCodeVerifiedSuccessfully = true;
          this.invoiceAmount = res?.total_ammount;
        }
      });
  }
  //Promocode from all Other Invoice

  pay() {
    const { paymentType } = this.payInvoiceForm.value;
    const data = {
      id: this.invoiceId,
      payment_type_id: paymentType,
      promo_code: this.data?.promoCode ? this.data?.promoCode : this.promoCode,
    };

    if (!data.promo_code) {
      delete data.promo_code;
    }

    this.dialogRef.close(data);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.payInvoiceForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
}
