<div
  class="modal"
  [ngClass]="{ show: showModal }"
  (click)="onBackgroundClick($event)"
>
  <div class="modal-content">
    <h3>Are you sure, Did you print the card ?</h3>
    <div class="icon d-flex justify-content-center">
      <button class="btn me-2" (click)="onOkClick()">Yes</button>
      <button class="btn" (click)="onCancelClick()">No</button>
    </div>
  </div>
</div>
