import { Component, OnInit } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UnitDetailsComponent } from '../unit-details/unit-details.component';

@Component({
  selector: 'app-import-client',
  templateUrl: './import-client.component.html',
  styleUrls: ['./import-client.component.css'],
})
export class ImportClientComponent implements OnInit {
  unitName = '';
  units_arr: any = [];
  unit_clients: any = [];
  selectedClients: any;
  constructor(
    private rest: UnitsService,
    public dialogRef: MatDialogRef<UnitDetailsComponent>
  ) {}

  ngOnInit() {}

  searchUnit() {
    this.rest.searchUnit(this.unitName).subscribe((res: any) => {
      this.units_arr = res?.results;
    });
  }

  getUnitItem(value: any) {
    this.units_arr = [];
    this.unitName = value.name;
    this.unit_data(this.unitName);
  }

  unit_data(value: any) {
    this.rest.unitDetails(value).subscribe((res: any) => {
      if (res) {
        if (res?.clients?.Owner) {
          this.unit_clients.push(...res.clients.Owner);
        }
        if (res?.clients?.Guest) {
          this.unit_clients.push(...res.clients.Guest);
        }
        if (res?.clients?.Tenant) {
          this.unit_clients.push(...res.clients.Tenant);
        }
        if (res?.clients?.Household) {
          this.unit_clients.push(...res.clients.Household);
        }

        // this.unit_clients = [
        //   ...res?.clients?.Owner,
        //   ...res?.clients?.Guest,
        //   ...res?.clients?.Tenant,
        //   ...res?.clients?.Household,
        // ];

        console.log(this.unit_clients);
      } else {
        this.unit_clients = [];
      }
      // this.unit_clients = res.clients.Guest
    });
  }

  ClientsChange(event: any) {}

  import() {
    if (this.selectedClients) {
      let data: any = [];
      this.selectedClients.forEach((element: any) => {
        data.push({
          id: element.id,
          relation: element.unit_relation_type,
        });
      });
      this.dialogRef.close(data);
    }
  }
}
