import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap } from 'rxjs';
import { MainService } from 'src/app/services/main.service';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-edit-unit-details',
  templateUrl: './edit-unit-details.component.html',
  styleUrls: ['./edit-unit-details.component.css'],
})
export class EditUnitDetailsComponent implements OnInit {
  @Output() sendMapData = new EventEmitter<{}>();

  showSpinner: boolean = true;

  unitId: any;
  color: ThemePalette = 'primary';
  checked = false;
  gardenStatus: string = 'Have a garden / No garden';
  deliveryStatus: string = 'Delivered / Not Delivered';
  typeName: any;
  typeArray: any;
  selectedTypeName: any;
  typeId: any;
  unitImages: (string | ArrayBuffer | null | any)[] = [];
  unitImageToShow: (string | ArrayBuffer | null | any)[] = [];
  imageKey: any = 'image';
  errorUnitPhotos = false;
  showGoogleMapsComponent = false;
  latComingFromTheMap: number = 0;
  longComingFromTheMap: number = 0;
  googleMapErrorMessage: boolean | any = false;
  googleMapSuccessMessage: boolean | any = false;
  editUnitDetailsForm: FormGroup | any;
  vrImageEdit = false;
  vrImagePath: any = '';
  url: any;
  errorVRImage = false;
  submitLoader: boolean = false;

  locationData: any;

  constructor(
    private activeRouter: ActivatedRoute,
    private rest: UnitsService,
    private mainService: MainService,
    private route: Router
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.unitId = this.activeRouter.snapshot.params['id'];
    this.getUnitDetails(this.unitId);
    this.getTypes(this.typeName);
    this.getAddUnitDetailsForm();
  }

  //Getting the unit details form
  getAddUnitDetailsForm() {
    this.editUnitDetailsForm = new FormGroup({
      unitName: new FormControl(null, [Validators.required]),
      extensionId: new FormControl(null, [Validators.required]),
      // unitType: new FormControl(null, [Validators.required]),
      unitFloor: new FormControl(null, [Validators.required]),
      roomsNumber: new FormControl(null, [Validators.required]),
      bedsNumber: new FormControl(null, [Validators.required]),
      bathroomNumber: new FormControl(null, [Validators.required]),
      area: new FormControl(null, [Validators.required]),
      measuringUnit: new FormControl(null, [Validators.required]),
      gardenArea: new FormControl(null, [Validators.required]),
      minimumPersonCapacity: new FormControl(null, [Validators.required]),
      maximumPersonCapacity: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      governorate: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      gardened: new FormControl(null),
      delivered: new FormControl(null),
    });
  }
  //Getting the unit details form

  getUnitDetails(unitId: string) {
    this.rest
      .unitDetailsDescription(unitId)
      .pipe(
        take(1),
        tap((data: any) => {
          if (data.status == false) {
            this.mainService.errorToast(data?.message);
          }
          this.showSpinner = false;
          this.locationData = data?.location?.location;
          this.checkGardenAndDeliveredStatus(data);
          this.gettingTheLocationDataForUpdate();
          this.filterImages(data?.images);
          this.editUnitDetailsForm.patchValue({
            id: data?.id,
            unitName: data?.unit_name,
            extensionId: localStorage.getItem('extId'),
            unitFloor: data?.floor,
            roomsNumber: data?.rooms,
            bedsNumber: data?.beds,
            bathroomNumber: data?.bath,
            area: data?.area,
            measuringUnit: data?.unit_measure,
            gardenArea: data?.garden_area,
            maximumPersonCapacity: data?.max_capacity,
            minimumPersonCapacity: data?.min_capacity,
            gardened: data?.gardened,
            delivered: data?.delivered,
            city: data?.location?.city,
            country: data?.location?.country,
            governorate: data?.location?.governorate,
            street: data?.location?.street,
          });
        })
      )
      .subscribe();
  }

  checkGardenAndDeliveredStatus(data: any) {
    if (data.gardened) {
      this.gardenStatus = 'Have a garden';
    } else {
      this.gardenStatus = 'No garden';
    }

    if (data.delivered) {
      this.deliveryStatus = 'Delivered';
    } else {
      this.deliveryStatus = 'Not Delivered';
    }
  }

  //Submitting the unit details
  saveUnitDetails() {
    this.submitLoader = true;

    const {
      unitName,
      unitFloor,
      roomsNumber,
      bedsNumber,
      bathroomNumber,
      area,
      measuringUnit,
      gardenArea,
      minimumPersonCapacity,
      maximumPersonCapacity,
      country,
      city,
      governorate,
      street,
      gardened,
      delivered,
      extensionId,
      lat = this.latComingFromTheMap,
      long = this.longComingFromTheMap,
    } = this.editUnitDetailsForm.value;

    var formData = new FormData();
    this.unitImages.forEach((element: any) => {
      formData.append(element.type, element.path, element.path.name);
    });

    const editUnitDetailsInputs: any = {
      name: unitName,
      unit_id: this.unitId,
      ext_id: extensionId,
      country,
      governorate,
      city,
      street,
      floor: unitFloor,
      bath: bathroomNumber,
      beds: bedsNumber,
      rooms: roomsNumber,
      area,
      unit_measure: measuringUnit,
      gardened: gardened,
      delivered: delivered,
      garden_area: gardenArea,
      min_capacity: minimumPersonCapacity,
      max_capacity: maximumPersonCapacity,
      // location: `POINT(${lat} ${long})`,
    };

    for (const key in editUnitDetailsInputs) {
      const formattedKey = key.includes('_')
        ? key
        : key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
      formData.append(formattedKey, editUnitDetailsInputs[key]);
    }

    if (this.vrImagePath) {
      formData.append('vrImage', this.vrImagePath);
    }

    this.rest.editUnitDetails(formData).subscribe({
      next: (response: any) => {
        if (response.status) {
          this.editUnitDetailsForm.reset();
          this.gardenStatus = 'Have a garden / No garden';
          this.deliveryStatus = 'Delivered / Not Delivered';
          this.unitImageToShow = [];
          this.url = null;
          this.vrImageEdit = false;
          this.googleMapErrorMessage = null;
          this.googleMapSuccessMessage = null;
          this.submitLoader = false;
          this.mainService.SuccessToast('Unit details updated successfully');
          this.route.navigateByUrl(`/pages/mange_units`);
        } else {
          this.mainService.errorToast(response?.message);
        }
      },
      error: (err) => {
        this.mainService.errorToast(err?.error?.message);
      },
    });
  }
  //Submitting the unit details

  //Toggle for the garden status
  statusChange(event: any) {
    if (event?.checked) {
      this.gardenStatus = 'Have a garden';
    } else [(this.gardenStatus = 'No garden')];
  }
  //Toggle for the garden status

  //Toggle for the garden status
  deliveryChange(event: any) {
    if (event?.checked) {
      this.deliveryStatus = 'Delivered';
    } else [(this.deliveryStatus = 'Not Delivered')];
  }
  //Toggle for the garden status

  //Get unit types
  getTypes(typeName: string) {
    this.rest.getTypes(typeName).subscribe((res: any) => {
      this.typeArray = res.children;
    });
  }
  //Get unit types

  //Selecting unit types from the dropdown list
  chooseType(value: any) {
    this.selectedTypeName = value.name;
    this.typeId = value.id;
  }
  //Selecting unit types from the dropdown list

  // Uploading unit images
  uploadDocumentClient(event: any): void {
    const fileInput = event.target;
    const files = fileInput.files;

    if (files) {
      this.errorUnitPhotos = false;
      const startingIndex = this.unitImages.length;

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.unitImages.push({
            type: this.imageKey + (startingIndex + i),
            path: files[i],
          });
        };
        this.convertFile(files[i]);
        reader.readAsDataURL(files[i]);
      }
    }

    // if (files) {
    //   this.errorUnitPhotos = false;
    //   for (let i = 0; i < files.length; i++) {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.unitImages.push({
    //         type: this.imageKey + i,
    //         path: files[i],
    //       });
    //     };
    //     this.convertFile(files[i]);
    //     reader.readAsDataURL(files[i]);

    //   }
    // }
  }

  convertFile(file: any) {
    if (file.type == 'application/pdf') {
      this.unitImageToShow.push({
        url: file.name,
        file: true,
        fileName: file.name,
        name: this.imageKey,
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.unitImageToShow.push({
          url: reader.result,
          file: false,
          fileName: file.name,
          name: this.imageKey,
        });
      };
    }

    // this.errorDocumentPhoto = false;
  }
  // Uploading unit images

  // Function to show the remove button on hover [Removing Unit Image]
  showButton(image: any): void {
    image.isHovered = true;
  }

  // Function to hide the remove button when not hovering [Removing Unit Image]
  hideButton(image: any): void {
    image.isHovered = false;
  }

  // Function to remove the image [Removing Unit Image]
  removeImage(item: any) {
    for (let i = 0; i < this.unitImageToShow.length; i++) {
      if (this.unitImageToShow[i].fileName == item.fileName) {
        this.unitImageToShow.splice(i, 1);
      }
    }
    for (let i = 0; i < this.unitImages.length; i++) {
      if (this.unitImages[i].path.name == item.fileName) {
        this.unitImages.splice(i, 1);
      }
    }
    if (this.unitImages.length == 0) {
      this.errorUnitPhotos = true;
    }
  }

  //Upload VR Image
  uploadVRImage(event: any) {
    this.vrImageEdit = true;
    this.vrImagePath = event.target.files[0];
    const reader = new FileReader();
    let imagePath = this.vrImagePath;
    reader.readAsDataURL(imagePath);
    reader.onload = (_event) => {
      this.url = reader.result;
      this.errorVRImage = false;
    };
  }
  //Upload VR Image

  //Remove VR Image
  removeUserImage() {
    this.vrImagePath = '';
    this.vrImageEdit = false;
    this.errorVRImage = true;
  }
  //Remove VR Image

  //Open and receive lat and long from Google Maps Module
  receiveData(event: any) {
    if (event == undefined || !event) {
      this.googleMapErrorMessage = true;
      this.googleMapSuccessMessage = false;
    } else {
      this.googleMapErrorMessage = false;
      this.googleMapSuccessMessage = true;
      this.latComingFromTheMap = event?.lat;
      this.longComingFromTheMap = event?.long;
    }
  }

  skipToAllUnits() {
    this.route.navigateByUrl(`/pages/mange_units`);
  }
  //Open and receive lat and long from Google Maps Module

  //Form Validator
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editUnitDetailsForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
  //Form Validator

  sendDataToMap() {
    this.showGoogleMapsComponent = true;
    this.sendMapData.emit(this.locationData);
  }

  gettingTheLocationDataForUpdate() {
    // Define your string
    const pointString = this.locationData;

    // Define a regular expression pattern to match the numbers
    const regexPattern = /POINT \(([-+]?\d*\.?\d+)\s+([-+]?\d*\.?\d+)\)/;

    // Execute the regular expression to extract the latitude and longitude
    const match = pointString?.match(regexPattern);

    // Check if a match is found
    if (match) {
      // The latitude will be the first captured group
      const latitude = parseFloat(match[1]);
      this.latComingFromTheMap = latitude;
      // The longitude will be the second captured group
      const longitude = parseFloat(match[2]);
      this.longComingFromTheMap = longitude;
    } else {
    }
  }

  filterImages(images: any) {
    images?.forEach((image: any) => {
      if (image.image_type === 'vrImage') {
        this.url = image?.image;
        // this.vrImagePath = image?.image
        this.vrImageEdit = true;
      } else {
        this.unitImageToShow.push({
          url: image?.image,
          file: false,
          // fileName: file.name,
          name: this.imageKey,
        });
      }
    });
  }
}
