<section class="main">
  <h3>Manage clients</h3>
  <section class="supmain">
    <h2>Mohammed’s payments</h2>
    <div class="paymentAlert">
      <p>
        some payments are due for this client, send them a reminder to take
        action now! Send reminder View due payments
      </p>
    </div>
    <div class="paymentFees mt-3">
      <img src="assets/images/payment.png" />
      <div>
        <p>Available Balance</p>
        <h4>500 EGP</h4>
      </div>
    </div>

    <p class="addCredit">Add credit</p>

    <!-- tables -->

    <div class="row">
      <div class="col">
        <section class="mt-5 tableUnit table-responsive">
          <h2>Due/upcoming payments</h2>

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <div class="col">
        <section class="mt-5 tableUnit table-responsive">
          <h2>Transaction history</h2>

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
              <tr>
                <td class="">Utilities (Gas)</td>
                <td>
                  <span>99,9999 EGP</span>
                </td>
                <td>
                  <span>11/01/2023</span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </section>
</section>
