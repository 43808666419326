import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { AddNewImageComponent } from './add-new-image/add-new-image.component';
import { UserCardConfigurationComponent } from './user-card-configuration/user-card-configuration.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ConfigurationDetailsComponent } from './configuration-details/configuration-details.component';
import { EditConfigurationComponent } from './edit-configuration/edit-configuration.component';
import { GateConfigurationComponent } from './gate-configuration/gate-configuration.component';
import { UsersConfigurationComponent } from './users-configuration/users-configuration.component';
import { VillageConfigurationComponent } from './village-configuration/village-configuration.component';
import { QrConfigurationComponent } from './qr-configuration/qr-configuration.component';
import { UserAccessConfigurationComponent } from './user-access-configuration/user-access-configuration.component';
import { AddUserAccessConfigurationComponent } from './add-user-access-configuration/add-user-access-configuration.component';
import { EditUserAccessConfigurationComponent } from './edit-user-access-configuration/edit-user-access-configuration.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    UserCardConfigurationComponent,
    AddNewImageComponent,
    ConfigurationDetailsComponent,
    EditConfigurationComponent,
    GateConfigurationComponent,
    UsersConfigurationComponent,
    VillageConfigurationComponent,
    QrConfigurationComponent,
    UserAccessConfigurationComponent,
    AddUserAccessConfigurationComponent,
    EditUserAccessConfigurationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
  ],
})
export class ConfigurationsModule {}
