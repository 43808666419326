import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainSerReqComponent } from './main-ser-req/main-ser-req.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ServiceDetailsComponent } from './serv-details/serv-details.component';

@NgModule({
  declarations: [MainSerReqComponent, ServiceDetailsComponent],
  imports: [CommonModule, MatTabsModule],
})
export class ServiceRequestModule {}
