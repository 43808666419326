import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
// import { NotificationService } from "../../../../@core/utils/notification.service";

@Component({
  selector: 'ngx-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @Input() show: boolean = false;
  @Output() hideModal = new EventEmitter<boolean>();
  @Output() mapData = new EventEmitter<{}>();

  @Input() data: any = false;

  latitude: number = 30.09755667885341;
  longitude: number = 31.23632352727045;
  zoom: number = 12;
  country: string = '';
  city: string = '';
  governorate: string = '';
  street: string = '';
  private geoCoder: any;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private mainService: MainService // private notificationsService: NotificationService
  ) {}

  ngOnInit() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      // this.geoCoder = new google.maps.Geocoder();
    });
    this.gettingTheLocationDataForUpdate();
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // this.latitude = position.coords.latitude;
          // this.longitude = position.coords.longitude;
          this.zoom = 8;
          // this.getAddress(this.latitude, this.longitude);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.mainService.errorToast(
                'Please turn on location in your device'
              );

              break;
            case error.POSITION_UNAVAILABLE:
              this.mainService.errorToast(
                'Location information is unavailable'
              );
              break;
            case error.TIMEOUT:
              this.mainService.errorToast(
                'The request to get user location timed out'
              );
              break;
          }
        },
        { maximumAge: 60000, timeout: 5000 }
      );
    }
  }

  setLocation($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
  }

  close() {
    if (this.data) {
      const lat = this.latitude;
      const long = this.longitude;
      const data = { lat, long };

      this.hideModal.emit(false);
      this.show = false;
      this.mapData.emit(data);
    } else {
      this.hideModal.emit(false);
      this.show = false;
      this.mapData.emit();
    }
  }

  save() {
    const lat = this.latitude;
    const long = this.longitude;
    const data = { lat, long };
    this.mapData.emit(data);
    this.hideModal.emit(false);
    this.show = false;
  }

  gettingTheLocationDataForUpdate() {
    // Define your string
    const pointString = this.data;

    // Define a regular expression pattern to match the numbers
    const regexPattern = /POINT \(([-+]?\d*\.?\d+)\s+([-+]?\d*\.?\d+)\)/;

    // Execute the regular expression to extract the latitude and longitude
    const match = pointString.match(regexPattern);

    // Check if a match is found
    if (match) {
      // The latitude will be the first captured group
      const latitude = parseFloat(match[1]);
      this.latitude = latitude;
      // The longitude will be the second captured group
      const longitude = parseFloat(match[2]);
      this.longitude = longitude;
    } else {
    }
  }
}
