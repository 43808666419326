import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VillageService } from 'src/app/services/village.service';
import { AddVillageComponent } from '../add-village/add-village.component';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-mange-village',
  templateUrl: './mange-village.component.html',
  styleUrls: ['./mange-village.component.css'],
})
export class MangeVillageComponent {
  showSpinner: boolean = true;
  typesArr: any = [];
  villages_arr: any = [];
  villageName = '';
  page: number = 1;
  limit: number = 9;
  totalCount: number = 10;

  constructor(
    private route: Router,
    private villageService: VillageService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.village_List();
  }

  // villages

  village_List() {
    this.villageService
      .villageList(this.page, this.limit)
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.villages_arr = res?.results;
        this.totalCount = res?.total_count;
      });
  }

  add_Village() {
    const dialogRef = this.dialog.open(AddVillageComponent, {
      width: '90%',
      maxWidth: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.villageService.addVillage(result).subscribe((res: any) => {
          this.village_List();
        });
      }
    });
  }

  VillageDetails(id: string) {
    this.route.navigate(['pages/village_details', id]);
  }

  searchVillage() {
    this.villageService.searchVillage(this.villageName).subscribe(
      (res: any) => {
        this.villages_arr = res?.results;
        if (res) {
          this.villages_arr = res?.results;
        }
      },
      (err: any) => {
        this.villages_arr = [];
      }
    );
  }

  changePage(e: PageEvent) {
    this.showSpinner = true;
    this.page = e.pageIndex + 1;
    this.village_List();
  }
}
