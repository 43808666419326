<section class="main">
  <section class="sup_main">
    <h1>Service request</h1>
    <hr />
    <div class="row">
      <div class="col-12">
        <mat-tab-group>
          <mat-tab label="Pending">
            <div class="row mt-3 containerItems">
              <div class="col-6 mb-2" *ngFor="let item of pending_arr">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3 img-fluid"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusPending d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="pending_arr.length == 0">No data Found</h1>
            </div>
          </mat-tab>
          <mat-tab label="Active">
            <div class="row mt-3 containerItems">
              <div class="col-6" *ngFor="let item of active_arr">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusActive d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="active_arr.length == 0">No data Found</h1>
            </div>
          </mat-tab>
          <mat-tab label="Closed">
            <div class="row mt-3 containerItems">
              <div class="col-6" *ngFor="let item of closed_arr">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusClosed d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="closed_arr.length == 0">No data Found</h1>
            </div>
          </mat-tab>
          <mat-tab label="Complete">
            <div class="row mt-3 containerItems">
              <div class="col-6" *ngFor="let item of complete_array">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusComplet d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="complete_array.length == 0">No data Found</h1>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </section>
</section>
