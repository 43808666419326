<section class="main">
  <h2>Edit User Access Config</h2>
  <hr />

  <mat-spinner diameter="20" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <form *ngIf="!showSpinner" [formGroup]="editUserAccessConfigurationForm">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Maximum first cards</mat-label>
      <input matInput type="number" formControlName="maximum_first_cards" />

      <mat-error *ngIf="isControlHasError('maximum_first_cards', 'required')">
        Maximum first cards is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Maximum extra cards</mat-label>
      <input matInput type="number" formControlName="maximum_extra_cards" />

      <mat-error *ngIf="isControlHasError('maximum_extra_cards', 'required')">
        Maximum extra cards is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Insured</mat-label>
      <mat-select
        formControlName="insured"
        (selectionChange)="onInsuredChange($event)"
      >
        <mat-option [value]="true">True</mat-option>
        <mat-option [value]="false">False</mat-option>
      </mat-select>

      <mat-error *ngIf="isControlHasError('insured', 'required')">
        Insured is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Insurance fees</mat-label>
      <input matInput type="number" formControlName="insurance_fees" />

      <mat-error *ngIf="isControlHasError('insurance_fees', 'required')">
        Insurance fees is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Minimum stay price</mat-label>
      <input matInput type="number" formControlName="minimum_stay_price" />

      <mat-error *ngIf="isControlHasError('minimum_stay_price', 'required')">
        minimum_stay_price is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>First issue fees</mat-label>
      <input matInput type="number" formControlName="first_issue_fees" />

      <mat-error *ngIf="isControlHasError('first_issue_fees', 'required')">
        First issue fees is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Extra cards fees</mat-label>
      <input matInput type="number" formControlName="extra_cards_fees" />

      <mat-error *ngIf="isControlHasError('extra_cards_fees', 'required')">
        Extra cards fees is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Reissuance fees</mat-label>
      <input matInput type="number" formControlName="reissuance_fees" />

      <mat-error *ngIf="isControlHasError('reissuance_fees', 'required')">
        Reissuance fees is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Cards per bed room</mat-label>
      <input matInput type="number" formControlName="cards_per_bedroom" />

      <mat-error *ngIf="isControlHasError('cards_per_bedroom', 'required')">
        Cards per bed room is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Expiry date</mat-label>
      <input
        formControlName="expiry_date"
        matInput
        placeholder="Expiry date"
        [matDatepicker]="picker"
      />

      <mat-error *ngIf="isControlHasError('expiry_date', 'required')">
        Expiry date is required
      </mat-error>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Minimum stay</mat-label>
      <input matInput type="number" formControlName="minimum_stay" />
    </mat-form-field>
  </form>

  <div *ngIf="!showSpinner" class="footer mt-4">
    <button
      class="btn addGate"
      [disabled]="editUserAccessConfigurationForm.invalid"
      (click)="editUserAccessConfiguration()"
    >
      Save
    </button>
    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
