<section class="main">
    <form [formGroup]="loginForm">
        <h1 class="text-center">Sign in</h1>
        <!-- Email input -->
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example1">Email address</label>
            <input formControlName="email" type="email" id="form2Example1" class="form-control" />
        </div>
        <div *ngIf="loginFormControl['email'].touched && loginFormControl['email'].invalid" class="alert alert-danger">
            <div *ngIf="loginFormControl['email'].errors && loginFormControl['email'].errors['required']">Email is
                required.</div>
            <div *ngIf="loginFormControl['email'].errors && loginFormControl['email'].errors['email']">Please, Enter
                valid email address.</div>
        </div>

        <!-- Password input -->
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example2">Password</label>
            <input formControlName="password" type="password" id="form2Example2" class="form-control" />
        </div>
        <div *ngIf="loginFormControl['password'].touched && loginFormControl['password'].invalid" class="alert alert-danger">
            <div *ngIf="loginFormControl['password'].errors && loginFormControl['password'].errors['required']">password is
                required.</div>
        </div>
        <!-- 2 column grid layout for inline styling -->
        <div class="row mb-4">

            <div class="col">
                <a>Forgot password?</a>
            </div>
        </div>

        <!-- Submit button -->
        <button type="button" (click)="login()" class="btn btn-primary btn-block mb-4">Sign in</button>
    </form>
</section>