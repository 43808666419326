<section class="main">
  <div class="heading d-flex justify-content-between align-items-center">
    <h1>Operators</h1>
    <div>
      <button class="btn add" (click)="addOperator()">Add new operator</button>
    </div>
  </div>
  <div class="edit_view mb-4 mt-2">
    <button class="btn">View/Edit</button>
    <!-- <button class="btn">Operators log</button> -->
  </div>

  <!-- cards -->

  <mat-spinner *ngIf="showSpinner" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinner" class="cards mt-5">
    <div class="row">
      <div class="col-md-4 col-12" *ngFor="let item of allOperators">
        <div class="clientsCard">
          <div class="row info">
            <div class="col-4">
              <ngx-avatar
                *ngIf="!item.photo"
                size="80"
                name="{{ item?.name }}"
                cornerRadius="5"
                [round]="false"
              ></ngx-avatar>

              <!-- <img class="user img-fluid" src="assets/images/avatar.png" /> -->
            </div>
            <div class="col-7 p-0 ownerInfo">
              <p class="mb-2 p1">
                {{ item.name | titlecase }}
              </p>
              <p class="p2">{{ item.email }}</p>
              <p class="p2">{{ item.phone_number }}</p>
            </div>
            <div class="col-1 dots">
              <mat-menu #menu="matMenu">
                <p (click)="editOperators(item)" class="menuItem" mat-menu-item>
                  Edit
                </p>
                <p
                  id="btn3"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  class="menuItem"
                  mat-menu-item
                >
                  Delete
                </p>
                <!-- <p (click)="change_password(item)" class="menuItem" mat-menu-item>Change password</p> -->
              </mat-menu>
              <img
                [matMenuTriggerFor]="menu"
                src="assets/images/mangeunit/dots.png"
              />
            </div>
          </div>
          <p class="se_date">
            Last session<span>: {{ item.joined_date | date : "medium" }}</span>
          </p>
          <div class="rols" *ngIf="item.role.length != 0">
            <p>Roles</p>
            <div>
              <span class="hint" *ngFor="let role of item.role">
                {{ role.name }}
              </span>
            </div>
          </div>
          <!-- <div class="mt-3">
                        <a href="#" >
                            View more
                        </a>
                    </div> -->
        </div>
      </div>
    </div>
  </section>
</section>

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Delete Operator</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete the Operator?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="DeleteOperator()"
          data-bs-dismiss="modal"
        >
          Delete Operator
        </button>
      </div>
    </div>
  </div>
</div>
