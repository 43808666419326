<section class="main">
    <section class="sup_main">
        <h3 class="mb-4">System Analytics</h3>
        <hr>
        <div class="row">
            <div class="col-4">
                <div class="cards">
                    <div class="d-flex align-items-center mb-3">
                        <img src="assets/images/systemDB/profile-2user.png">
                        <p class="mb-0">Client number</p>
                    </div>
                    <p class="count">40,000 K</p>
                </div>
            </div>
            <div class="col-4">
                <div class="cards">
                    <div class="d-flex align-items-center mb-3">
                        <img src="assets/images/systemDB/profile.png">
                        <p class="mb-0">Client Cards Style</p>
                    </div>
                    <p class="count">40,000 K</p>
                </div>
            </div>
            <div class="col-4">
                <div class="cards">
                    <div class="d-flex align-items-center mb-3">
                        <img src="assets/images/systemDB/scan-barcode.png">
                        <p class="mb-0">QR codes</p>
                    </div>
                    <p class="count">55,000 K</p>
                </div>
            </div>
            <div class="col-4 mt-3">
                <div class="cards">
                    <div class="d-flex align-items-center mb-3">
                        <img src="assets/images/systemDB/building-3.png">
                        <p class="mb-0">Units / Villa</p>
                    </div>
                    <p class="count">1,432</p>
                </div>
            </div>
            <div class="col-4 mt-3">
                <div class="cards">
                    <div class="d-flex align-items-center mb-3">
                        <img src="assets/images/systemDB/building-3.png">
                        <p class="mb-0">Units / 1 Room</p>
                    </div>
                    <p class="count">1,432</p>
                </div>
            </div>
            <div class="col-4 mt-3">
                <div class="cards">
                    <div class="d-flex align-items-center mb-3">
                        <img src="assets/images/systemDB/building-3.png">
                        <p class="mb-0">Units / 2 Room</p>
                    </div>
                    <p class="count">1,432</p>
                </div>
            </div>
        </div>
    </section>
</section>
