// shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintConfirmationModalComponent } from '../pages/units/print-confirmation-modal/print-confirmation-modal.component';

@NgModule({
  declarations: [
    PrintConfirmationModalComponent, // Declare the component here
  ],
  imports: [CommonModule],
  exports: [
    PrintConfirmationModalComponent, // Export the component so other modules can use it
  ],
})
export class SharedModule {}
