import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './main-component/login/login.component';
import { PagesComponent } from './pages/pages.component';
import { AuthGuard } from './auth/auth.guard';
import { DeActiveGuard } from './auth/de-active.guard';
import { PageNotFoundComponent } from './main-component/page-not-found/page-not-found.component';

const routes: Routes = [

  {
    path: "login",
    component: LoginComponent,
    canActivate: [DeActiveGuard],
  },
  {
    path: "",
    redirectTo : "login",
    pathMatch : "full",
  },
  {
    path: "pages",
    redirectTo : "pages/Dashboard",
    pathMatch : "full",
  },
  {
    path: "pages",
    component: PagesComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
