import { Component, OnInit } from '@angular/core';
import { RolesService } from 'src/app/services/roles.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RolesMainComponent } from '../roles-main/roles-main.component';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css'],
})
export class AddRoleComponent implements OnInit {
  RoleName: string = '';
  submitted = false;
  PermissionsName = 'Choose Permission';
  all_permissions: any;
  permissions_choice: any = [];
  permissions_arr: any = [];
  constructor(
    private rest: RolesService,
    public dialogRef: MatDialogRef<RolesMainComponent>
  ) {}

  ngOnInit() {
    this.getPermissions();
  }

  getPermissions() {
    this.rest.retrievePermission().subscribe((res: any) => {
      this.all_permissions = res;
    });
  }

  getPerItem(item: any) {
    this.permissions_choice.push(item);
  }

  addRole() {
    this.submitted = true;
    if (this.RoleName != '' && this.permissions_arr.length != 0) {
      let data = {
        name: this.RoleName,
        permiosn: this.permissions_arr,
      };
      this.dialogRef.close(data);
    }
  }

  getInput(event: any) {
    this.RoleName = event.target.value;
  }
}
