<section class="main">
  <h2>Add Qr Config</h2>
  <hr />

  <mat-spinner *ngIf="showSpinner" diameter="20"></mat-spinner>

  <form [formGroup]="Village_form" *ngIf="!showSpinner">
    <mat-form-field appearance="outline">
      <mat-label>Choose Village</mat-label>
      <mat-select multiple formControlName="villages">
        <mat-option *ngFor="let item of village_list" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Choose Type</mat-label>
      <mat-select formControlName="qr_type_id">
        <mat-option *ngFor="let item of types_arr" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div class="mb-3">
      <input
        class="form-control"
        formControlName="available_days"
        type="number"
        placeholder="available days"
      />
    </div> -->

    <!-- <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Available days</mat-label>
        <input matInput type="number" formControlName="available_days" />
      </mat-form-field>
    </div> -->

    <div class="mt-3" *ngFor="let config of qrConfigArray">
      <mat-form-field appearance="outline" class="w-100" *ngIf="config.type">
        <mat-label>{{ config.name | titlecase }}</mat-label>
        <input matInput [type]="config.type" formControlName="available_days" />
      </mat-form-field>

      <mat-slide-toggle
        [formControlName]="config.name | spaceToUnderscore"
        [color]="color"
        *ngIf="!config.type"
      >
        {{ config?.name | titlecase }}
      </mat-slide-toggle>
    </div>

    <!--
    <div>
      <mat-slide-toggle formControlName="one_time_use" [color]="color">
        one time use
      </mat-slide-toggle>
    </div>

    <div class="mt-3">
      <mat-slide-toggle formControlName="started" [color]="color">
        started
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="ended" [color]="color">
        ended
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="start_time" [color]="color">
        start time
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="thirtySeconds" [color]="color">
        30 seconds
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="full_day_access" [color]="color">
        full day access
      </mat-slide-toggle>
    </div>
    <div class="mt-3">
      <mat-slide-toggle formControlName="count" [color]="color">
        count
      </mat-slide-toggle>
    </div> -->
  </form>

  <div class="footer mt-4">
    <button
      class="btn addGate"
      [disabled]="showSpinner"
      (click)="add_qr_config()"
    >
      Add Qr Config
    </button>
    <button class="btn" mat-dialog-close>Cancel</button>
  </div>
</section>
