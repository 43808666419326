<section class="main">
  <h1>Add Invoice</h1>
  <hr />
  <form class="example-form">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Search Unit</mat-label>
      <input
        type="text"
        (input)="getData($event)"
        placeholder="Unit Name"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="getOption($event.option.value)"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Consumed</mat-label>
        <input
          matInput
          [(ngModel)]="consumed"
          [ngModelOptions]="{ standalone: true }"
          type="number"
          class="form-control"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          [(ngModel)]="description"
          [ngModelOptions]="{ standalone: true }"
          type="text"
          class="form-control"
          rows="5"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="submitBtn">
    <button class="btn" (click)="addInvoice()">Add Invoice</button>
  </div>
</section>
