import { Component } from '@angular/core';

@Component({
  selector: 'app-main-logs',
  templateUrl: './main-logs.component.html',
  styleUrls: ['./main-logs.component.css']
})
export class MainLogsComponent {

}
