import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { PayInvoiceComponent } from '../pay-invoice/pay-invoice.component';
import { UnitsService } from 'src/app/services/units.service';

@Component({
  selector: 'app-unpaid-invoice-print-all',
  templateUrl: './unpaid-invoice-print-all.component.html',
  styleUrls: ['./unpaid-invoice-print-all.component.css'],
})
export class UnpaidInvoicePrintAllComponent implements OnInit {
  upPaidInvoice: any

  permissions: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private unitService: UnitsService,
    private main: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.upPaidInvoice = this.data.result;
    this.logPermissions();
  }

  payInvoice(invoiceId: any) {
    const dialogRef = this.dialog.open(PayInvoiceComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        invoiceId: invoiceId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.unitService.payInvoice(result).subscribe((res: any) => {
          if (res.status) {
            this.main.SuccessToast(res?.message);
            this.dialog.closeAll();
          } else {
            this.main.errorToast(res?.message);
          }
        });
      }
    });
  }

  checkPermissions() {
    const permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  logPermissions(): void {
    this.permissions = this.checkPermissions();
  }

  checkForPayInvoicePermission() {
    return this.permissions.some(
      (obj: any) => obj.name.toLowerCase() === 'pay invoice'
    );
  }
}
