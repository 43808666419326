import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnitsService } from 'src/app/services/units.service';
import { PayInvoiceComponent } from '../pay-invoice/pay-invoice.component';
import { MatDialog } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-unit-inovice',
  templateUrl: './unit-inovice.component.html',
  styleUrls: ['./unit-inovice.component.css'],
})
export class UnitInvoiceComponent implements OnInit, OnDestroy {
  unit_Id: any;
  unit_name: any;
  utility_array: any = [];
  service_arr: any = [];
  other: any = [];
  permissions: any[] = [];
  projectLogo = `../../../../${environment?.projectLogo}`;
  showLogoForPrinting: boolean = false;
  owners: any;
  invoiceDataForPrinting: any;
  mainOwner: any = null;

  constructor(
    private activeRouter: ActivatedRoute,
    private unitService: UnitsService,
    private main: MainService,
    private location: Location,
    public dialog: MatDialog
  ) {
    this.unit_Id = this.activeRouter.snapshot.paramMap.get('id');
    this.unit_name = this.activeRouter.snapshot.paramMap.get('name');
  }

  ngOnInit() {
    if (environment?.projectLogo) {
      this.showLogoForPrinting = true;
    }
    this.getAllInvoices();
    this.logPermissions();
    this.filterFirstMainOwner();
  }

  getAllInvoices() {
    this.unitService.unit_invoice(this.unit_Id).subscribe((res: any) => {
      this.utility_array = res?.utilities;
      this.service_arr = res?.services;
      this.other = res?.others;
    });
  }

  logPermissions(): void {
    this.permissions = this.checkPermissions();
  }

  checkPermissions() {
    const permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  checkForPayInvoicePermission() {
    return this.permissions.some(
      (obj: any) => obj.name.toLowerCase() === 'pay invoice'
    );
  }

  payInvoice(invoice: any) {
    const dialogRef = this.dialog.open(PayInvoiceComponent, {
      width: '90%',
      maxWidth: '450px',
      data: {
        invoiceId: invoice.id,
        invoiceAmount: invoice.ammount,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.unitService.payInvoice(result).subscribe(
          (res: any) => {
            this.main.SuccessToast(res?.message);
            this.getAllInvoices();
          },
          (err: any) => {
            this.main.errorToast(err?.message);
          }
        );
      }
    });
  }

  filterFirstMainOwner() {
    this.owners = JSON.parse(localStorage.getItem('unitClients') || '[]');
    for (let owner of this.owners) {
      if (owner.type.name === 'Main Owner') {
        this.mainOwner = owner;
        break;
      }
    }
  }

  printInvoice(item: any) {
    this.invoiceDataForPrinting = item;

    setTimeout(() => {
      const printContent: any = document.getElementById('invoice');
      const printContents = printContent.innerHTML;
      const printWindow: any = window.open();
      printWindow.document.write(`
        <html>
          <head>
            <style>
              @media print {
                .invoice-container {
                  width: 100%;
                  margin: 0;
                  font-size: 12pt;
                  font-family: Arial, sans-serif;
                }

                .invoice-header, .invoice-footer {
                  margin-bottom: 50px;
                }

                .invoice-header h1 {
                  text-align: center;
                }

                .d-flex {
                  display: flex;
                }

                .justify-content-between {
                  justify-content: space-between;
                }

                .align-items-center {
                  align-items: center;
                }

                .invoice-table table {
                  width: 100%;
                  border-collapse: collapse;
                }

                .invoice-table th, .invoice-table td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }

                .invoice-table th {
                  background-color: #f2f2f2;
                  font-weight: bold;
                }

                .invoice-table tfoot td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                  font-weight: bold;
                }

                .text-right {
                  text-align: right;
                }

                .clientImage {
                  width :150px;
                }
              }
            </style>
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.focus();

      // Wait for images to load before printing
      const images = Array.from(printWindow.document.images);
      const loadedImages = images.map((img: any) => {
        if (img.complete) {
          return Promise.resolve(img);
        } else {
          return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
          });
        }
      });

      Promise.all(loadedImages)
        .then(() => {
          printWindow.print();
          printWindow.close();
        })
        .catch(() => {
          console.error('Failed to load images for printing');
          printWindow.close();
        });
    }, 200);
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    localStorage.removeItem('unitClients');
  }
}
