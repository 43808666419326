import { Component } from '@angular/core';

@Component({
  selector: 'app-logs-client',
  templateUrl: './logs-client.component.html',
  styleUrls: ['./logs-client.component.css']
})
export class LogsClientComponent {

}
