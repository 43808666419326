import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitDetailsComponent } from '../unit-details/unit-details.component';
import { UnitsService } from 'src/app/services/units.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-invtaion',
  templateUrl: './invtaion.component.html',
  styleUrls: ['./invtaion.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class InvtaionComponent implements OnInit {
  invite: FormGroup | any;
  QrTypes_array: any = [];
  unitName: any = '';
  clientName: any = '';
  typeID: any;
  Choose_QR_Type = 'Choose QR Type';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UnitDetailsComponent>,
    private rest: UnitsService
  ) {}

  ngOnInit() {
    this.unitName = this.data.unitName;
    this.clientName = this.data.clientName;
    this.retiveInvtaion();
    this.getQrType();
    this.initForm();
  }

  initForm() {
    this.invite = new FormGroup({
      customer_name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      valid_from: new FormControl('', [Validators.required]),
      time_from: new FormControl('', [Validators.required]),
      valid_to: new FormControl('', [Validators.required]),
      time_to: new FormControl('', [Validators.required]),
      count: new FormControl('', [Validators.required]),
    });
  }

  getQrType() {
    this.rest.QrTypes().subscribe((res: any) => {
      this.QrTypes_array = res.children;
    });
  }

  getType(value: any) {
    this.Choose_QR_Type = value.name;
    this.typeID = value.id;
  }

  generateInvitation(value: any) {
    let data = this.invite.value;

    if (!this.invite.get('phone_number').value.includes('+2')) {
      data.phone_number = '+2' + this.invite.get('phone_number').value;
    }

    data.type_id = this.typeID;
    this.dialogRef.close({ data, value: value });
  }

  retiveInvtaion() {
    this.rest.retrieveInvitation(this.data.unitId).subscribe((res: any) => {});
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.invite.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
}
