import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

import { UnitDetailsComponent } from '../unit-details/unit-details.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-addquota',
  templateUrl: './addquota.component.html',
  styleUrls: ['./addquota.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class AddquotaComponent implements OnInit {
  pipe = new DatePipe('en-US');
  errorScheduleMassage = false;
  weeksArr = [
    {
      name: 'Saturday',
      value: 0,
      title: 'saturday',
    },
    {
      name: 'Sunday',
      value: 0,
      title: 'sunday',
    },
    {
      name: 'Monday',
      value: 0,
      title: 'monday',
    },
    {
      name: 'Tuesday',
      value: 0,
      title: 'tuesday',
    },
    {
      name: 'Wednesday',
      value: 0,
      title: 'wednesday',
    },
    {
      name: 'Thursday',
      value: 0,
      title: 'thursday',
    },
    {
      name: 'Friday',
      value: 0,
      title: 'friday',
    },
  ];

  showQuotaStatus = true;
  weekDays = new FormGroup({});
  prioritySchudle: any = 0;
  valid_ForSchedule: any = '';
  valid_toSchedule: any = '';
  Choose_Priority_schedule = 'Choose Priority';

  //

  quotaData: any;
  Choose_Priority = 'Choose Priority';
  priorityNumber = 0;
  quotaQuantity = 0;
  constructor(public dialogRef: MatDialogRef<UnitDetailsComponent>) {}
  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.weeksArr.forEach((element: any) => {
      this.weekDays.addControl(
        element.title,
        new FormControl('', [Validators.required])
      );
    });
  }

  changeQuota(event: any) {
    this.showQuotaStatus = !this.showQuotaStatus;
    this.errorScheduleMassage = false;
  }

  addQuotaByDay() {
    if (
      this.quotaData != '' &&
      this.quotaQuantity != 0 &&
      this.priorityNumber != 0
    ) {
      let data = {
        visit_date: this.pipe.transform(this.quotaData, 'yyyy-MM-dd'),
        quota: this.quotaQuantity,
        priority: this.priorityNumber,
      };
      this.errorScheduleMassage = false;
      this.dialogRef.close(data);
    } else {
      this.errorScheduleMassage = true;
    }
  }

  getPriority(name: string, number: number) {
    this.Choose_Priority = name;
    this.priorityNumber = number;
  }

  // schucle

  getPrioritySchedule(name: string, number: number) {
    this.Choose_Priority_schedule = name;
    this.prioritySchudle = number;
  }

  addQuotaSchedule() {
    if (
      this.weekDays.valid &&
      this.valid_ForSchedule != '' &&
      this.valid_toSchedule != '' &&
      this.prioritySchudle != 0
    ) {
      let data = {
        valid_from: this.pipe.transform(this.valid_ForSchedule, 'yyyy-MM-dd'),
        valid_to: this.pipe.transform(this.valid_toSchedule, 'yyyy-MM-dd'),
        week_days: this.weekDays.value,
        priority: this.prioritySchudle,
      };
      this.errorScheduleMassage = false;
      this.dialogRef.close(data);
    } else {
      this.errorScheduleMassage = true;
    }
  }
}
