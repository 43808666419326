import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { MainService } from 'src/app/services/main.service';
import { UnitsService } from 'src/app/services/units.service';
import { VillageService } from 'src/app/services/village.service';
import { UserCardConfigurationComponent } from '../user-card-configuration/user-card-configuration.component';

@Component({
  selector: 'app-add-new-image',
  templateUrl: './add-new-image.component.html',
  styleUrls: ['./add-new-image.component.css'],
})
export class AddNewImageComponent implements OnInit {
  showSpinner: boolean = true;
  addNewImageForm: FormGroup | any;
  projectsArray: any;
  projectNameSearchKey: any = '';
  userTypesArray: any;
  types_arr: any;

  //Front image
  frontImageEdit = false;
  frontImagePath: any = '';
  frontImageUrl: any;
  errorFrontImage = false;
  //Front image

  //Back image
  backImageEdit = false;
  backImagePath: any = '';
  backImageUrl: any;
  errorBackImage = false;
  //Back image

  disableTypesList: boolean = true;

  constructor(
    private apis: VillageService,
    private mainService: MainService,
    private unitService: UnitsService,
    private readonly configurations: ConfigurationsService,
    public dialogRef: MatDialogRef<UserCardConfigurationComponent>
  ) {}

  ngOnInit() {
    this.getProjects();
    this.getUserTypes();
    this.getAddNewImageForm();
  }

  getAddNewImageForm() {
    this.addNewImageForm = new FormGroup({
      project_id: new FormControl(null),
      userTypes: new FormControl(null),
      type_id: new FormControl(null),
    });

    if (this.disableTypesList) {
      this.addNewImageForm.get('type_id').disable();
    }
  }

  getProjects() {
    this.apis
      .projectListWithSearch(this.projectNameSearchKey)
      .subscribe((res: any) => {
        this.projectsArray = res;
      });
  }

  getUserTypes() {
    this.unitService.userTypes().subscribe((res: any) => {
      this.showSpinner = false;
      this.userTypesArray = res?.children;
    });
  }

  selectUserType(userType: any) {
    this.types_arr = userType?.children;
    this.addNewImageForm.get('type_id').enable();
  }

  //Upload front Image
  uploadFrontImage(event: any) {
    this.frontImageEdit = true;
    this.frontImagePath = event.target.files[0];
    const reader = new FileReader();
    let imagePath = this.frontImagePath;
    reader.readAsDataURL(imagePath);
    reader.onload = (_event) => {
      this.frontImageUrl = reader.result;
      this.errorFrontImage = false;
    };
  }
  //Upload front Image

  //Remove front Image
  removeFrontImage() {
    this.frontImagePath = '';
    this.frontImageEdit = false;
    this.errorFrontImage = true;
  }
  //Remove front Image

  //Upload back Image
  uploadBackImage(event: any) {
    this.backImageEdit = true;
    this.backImagePath = event.target.files[0];
    const reader = new FileReader();
    let imagePath = this.backImagePath;
    reader.readAsDataURL(imagePath);
    reader.onload = (_event) => {
      this.backImageUrl = reader.result;
      this.errorBackImage = false;
    };
  }
  //Upload back Image

  //Remove back Image
  removeBackImage() {
    this.backImagePath = '';
    this.backImageEdit = false;
    this.errorBackImage = true;
  }
  //Remove back Image

  addNewConfigurationImage() {
    this.showSpinner = true;
    const { project_id, type_id } = this.addNewImageForm.value;
    var formData = new FormData();

    const configurations: any = {
      project_id,
      type_id,
    };

    for (const key in configurations) {
      const formattedKey = key.includes('_')
        ? key
        : key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
      formData.append(formattedKey, configurations[key]);
    }

    formData.append('front', this.frontImagePath);
    formData.append('back', this.backImagePath);

    this.configurations.addConfiguration(formData).subscribe({
      next: (response: any) => {
        if (response.status) {
          this.showSpinner = false;
          this.mainService.SuccessToast('Configuration Added Successfully');
          this.dialogRef.close(response);
        } else {
          this.showSpinner = false;
          this.mainService.errorToast(response?.message);
        }
      },
      error: (err) => {
        this.showSpinner = false;
        this.mainService.errorToast(err?.error?.message);
      },
    });
  }
}
