import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  booleanValue: boolean = true;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  // all gats
  allGats() {
    return this.http.get(`${environment.baseUrl}/gates/`);
  }

  gateByType() {
    return this.http.get(`${environment.baseUrl}/types/?name=Gate`);
  }

  gatesByGateType(id: any) {
    return this.http.get(
      `${environment.baseUrl}/gates/gate_types?gate_type=${id}`
    );
  }

  // toastr

  SuccessToast(massage: string) {
    this.toastr.success(massage, 'Great', {
      timeOut: 3000,
    });
  }

  infoToast(massage: string) {
    this.toastr.info(massage, 'Info', {
      timeOut: 3000,
    });
  }

  errorToast(massage: string) {
    this.toastr.error(massage, 'Ops !!', {
      timeOut: 3000,
    });
  }
}
