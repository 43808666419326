<section class="main">
  <h1>QR Codes</h1>

  <section class="sup_main">
    <div class="d-flex justify-content-between align-items-center">
      <h2>
        <span>
          <img
            src="assets/images/mangeunit/back.png"
            class="backButton"
            (click)="goBack()"
          />
        </span>

        Unit Name : {{ unitName | titlecase }}
      </h2>

      <div class="col-md-6 col-6 d-flex justify-content-end">
        <mat-form-field appearance="outline" class="w-50">
          <mat-label>Select date</mat-label>
          <input
            #dateInput
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerFrom"
            matInput
            type="text"
            (dateChange)="getValue($event)"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <hr />

    <mat-spinner diameter="50" *ngIf="showSpinner" class="mt-4"></mat-spinner>

    <div *ngIf="!showSpinner" class="d-flex align-items-center flex-wrap">
      <div class="qrMainCard" *ngFor="let qr of qrCodesArray">
        <table (click)="openQRCodeCard(qr)">
          <tr>
            <th>Inviter:</th>
            <td>
              {{ qr?.client_name | titlecase }}
            </td>
          </tr>
          <tr>
            <th>Visitor:</th>
            <td>
              {{ qr?.customer_name | titlecase }}
            </td>
          </tr>
          <tr>
            <th>Visitor Phone:</th>
            <td>
              {{ qr?.phone_number }}
            </td>
          </tr>
          <tr>
            <th>Invitation type:</th>
            <td>
              {{ qr?.type }}
            </td>
          </tr>

          <tr>
            <th>People count:</th>
            <td>
              {{ qr?.count }}
            </td>
          </tr>

          <tr>
            <th>Used:</th>
            <td>
              {{ qr?.used }}
            </td>
          </tr>

          <tr>
            <th>Card status:</th>
            <td>
              {{ qr?.cancelled ? "Cancelled" : "Not cancelled" }}
            </td>
          </tr>

          <tr>
            <th>Visit form:</th>
            <td>
              {{ qr?.valid_from }}
            </td>
          </tr>

          <tr>
            <th>Visit to:</th>
            <td>
              {{ qr?.valid_to }}
            </td>
          </tr>
        </table>
        <ngx-qrcode
          (click)="openQRCodeCard(qr)"
          [elementType]="elementType"
          [errorCorrectionLevel]="correctionLevel"
          [value]="qr?.qr_code"
          cssClass="bshadow"
          class="qrCode"
          [width]="260"
        ></ngx-qrcode>
        <button
          *ngIf="!qr?.cancelled"
          class="btn d-flex m-auto"
          (click)="openCancelQR(qr?.qr_code)"
        >
          Cancel
        </button>
      </div>
    </div>

    <strong *ngIf="!showSpinner && !qrCodesArray"
      >There is no QR codes cards for this unit today</strong
    >
  </section>
</section>

<button
  id="btn3"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal3"
>
  Launch demo modal
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Cancel QR Card</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Are you sure you want to cancel this QR code card?
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="cancelQR()"
          data-bs-dismiss="modal"
        >
          Cancel QR Card
        </button>
      </div>
    </div>
  </div>
</div>
