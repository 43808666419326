import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { MatMenuModule } from '@angular/material/menu';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { AvatarModule } from 'ngx-avatar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [MainComponent, AddCategoryComponent, AddMemberComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgxPrintModule,
    MatProgressSpinnerModule,
    AvatarModule,
    MatInputModule,
    MatPaginatorModule,
    MatDialogModule
  ],
})
export class GenralAccessModule {}
