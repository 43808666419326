<section class="main">
  <h1>Invoices</h1>
  <section class="sup_main">
    <h2>
      <span>
        <img
          src="assets/images/mangeunit/back.png"
          class="backButton"
          (click)="goBack()"
        />
      </span>
      Unit Name : {{ unit_name | titlecase }}
    </h2>
    <hr />
    <section>
      <mat-tab-group>
        <mat-tab label="Utilities">
          <section class="tableUnit table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Section</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Consumed</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Updated on</th>
                  <th scope="col">Payment status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of utility_array; let i = index">
                  <td>
                    {{ item?.purchase_name }}
                  </td>
                  <td>
                    {{ item?.section }}
                  </td>
                  <td>{{ item?.ammount }} <strong>LE</strong></td>
                  <td>
                    {{ item?.consumed }}
                  </td>
                  <td>
                    {{ item?.created_at }}
                  </td>
                  <td>
                    {{ item?.updated_on }}
                  </td>
                  <td>
                    <button
                      [disabled]="!checkForPayInvoicePermission()"
                      *ngIf="!item?.paid"
                      class="btn"
                      (click)="payInvoice(item?.id)"
                    >
                      Pay Invoice
                    </button>

                    <span *ngIf="item?.paid">The invoice is already paid</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 *ngIf="utility_array?.length == 0">No Data Found</h3>
          </section>
        </mat-tab>

        <mat-tab label="Services">
          <section class="tableUnit table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Updated on</th>
                  <th scope="col">Payment status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of service_arr; let i = index">
                  <td>
                    {{ item?.purchase_name }}
                  </td>
                  <td>{{ item?.ammount }} <strong>LE</strong></td>
                  <td>
                    {{ item?.created_at }}
                  </td>
                  <td>
                    {{ item?.updated_on }}
                  </td>

                  <td>
                    <button
                      [disabled]="!checkForPayInvoicePermission()"
                      *ngIf="!item?.paid"
                      class="btn"
                      (click)="payInvoice(item?.id)"
                    >
                      Pay Invoice
                    </button>

                    <span *ngIf="item?.paid">The invoice is already paid</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 *ngIf="service_arr?.length == 0">No Data Found</h3>
          </section>
        </mat-tab>

        <mat-tab label="Others">
          <section class="tableUnit table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Updated on</th>
                  <th scope="col">Payment status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of other; let i = index">
                  <td>
                    {{ item?.purchase_name }}
                  </td>
                  <td>{{ item?.ammount }} <strong>LE</strong></td>
                  <td>
                    {{ item?.created_at }}
                  </td>
                  <td>
                    {{ item?.updated_on }}
                  </td>

                  <td>
                    <button
                      [disabled]="!checkForPayInvoicePermission()"
                      *ngIf="!item?.paid"
                      class="btn"
                      (click)="payInvoice(item)"
                    >
                      Pay Invoice
                    </button>

                    <span *ngIf="item?.paid">The invoice is already paid</span>
                  </td>

                  <td>
                    <button class="btn" (click)="printInvoice(item)">
                      Print Invoice
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 *ngIf="other?.length == 0">No Data Found</h3>
          </section>
        </mat-tab>
      </mat-tab-group>
    </section>
  </section>
</section>

<div class="invoice-container" id="invoice">
  <div class="invoice-header">
    <div class="d-flex align-items-center justify-content-between mb-5">
      <img
        *ngIf="showLogoForPrinting"
        class="clientImage"
        [src]="projectLogo"
      />

      <h1>Invoice</h1>
      <h1></h1>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <p><strong>Invoice No. :</strong> {{ invoiceDataForPrinting?.id }}</p>
      <p>
        <strong>Date :</strong> {{ invoiceDataForPrinting?.created_at | date }}
      </p>
    </div>
    <p><strong>Unit No. :</strong> {{ unit_name }}</p>
    <p><strong>Owner Name :</strong> {{ mainOwner?.name }}</p>
    <p><strong>Remarks:</strong> ...................................</p>
  </div>

  <div class="invoice-table">
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Description</th>
          <th>Count</th>
          <th>Amount per unit</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of invoiceDataForPrinting?.items; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item?.name }}</td>
          <td>{{ item?.amount }}</td>
          <td>{{ item?.price }} LE</td>
          <td>{{ item?.total_price }} LE</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4" class="text-right"><strong>Total Amount:</strong></td>
          <td>{{ invoiceDataForPrinting?.ammount }} LE</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
