import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OperatorsMainComponent } from '../opreators-main/opreators-main.component';
import { VillageService } from 'src/app/services/village.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-add-operator',
  templateUrl: './add-operator.component.html',
  styleUrls: ['./add-operator.component.css'],
})
export class AddOperatorComponent implements OnInit {
  registerForm: FormGroup | any;
  villageName = 'Village Name';
  roleName = 'Role Name';
  villageID: any;
  roleId: any;
  villages_arr: any;
  roles_arr: any;
  roleStatus = false;
  cardPermission = false;
  rolesChoise_arr: any = [];
  savedRoles_array: any = [];
  savedCardPermison = false;
  submitted = false;
  project_list: any;
  villages_list: any;
  rols_list: any;
  list_projects: any;
  constructor(
    public dialogRef: MatDialogRef<OperatorsMainComponent>,
    private rest: VillageService,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getVillages();
    this.getRoles();
    this.getProjects();
  }

  initForm() {
    this.registerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      web_login: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
    });
  }

  get ControlForm() {
    return this.registerForm.controls;
  }

  addOperators() {
    this.submitted = true;
    if (this.registerForm.valid && this.savedRoles_array.length != 0) {
      let data: any = { data: this.registerForm.value };
      data.rolesArr = this.savedRoles_array;
      this.dialogRef.close(data);
    }
  }

  getProjects() {
    this.rest.projectList().subscribe((res: any) => {
      this.project_list = res;
    });
  }

  getVillages() {
    this.rest.villageList().subscribe((res: any) => {
      this.villages_arr = res?.results;
    });
  }

  getRoles() {
    this.rolesService.retrieveOperators().subscribe((res: any) => {
      this.roles_arr = res;
    });
  }

  getVillageInfo(value: any) {
    this.villageName = value.name;
    this.villageID = value.id;
  }
  getRoleInfo(value: any) {
    this.roleName = value.name;
    this.roleId = value.id;
    this.rolesChoise_arr.push(value);
    this.roleStatus = true;
  }

  addPermission() {
    this.villageName = 'Village Name';
    this.roleName = 'Role Name';
    this.rolesChoise_arr = [];
    this.cardPermission = true;
  }

  saveCard() {
    if (this.status_radio == true) {
      this.villages_list.forEach((element: any) => {
        this.savedRoles_array.push({
          roles: this.rols_list,
          village: element.name,
          villageId: element.id,
          name: 'village',
        });
      });
    } else {
      this.list_projects.forEach((element: any) => {
        this.savedRoles_array.push({
          roles: this.rols_list,
          village: element.name,
          villageId: element.id,
          name: 'project',
        });
      });
    }
    this.cardPermission = false;
    // this.cardPermission = false
    // this.roleStatus = true
  }

  status_radio = true;
  changeRadio(e: any) {
    this.status_radio = !this.status_radio;
  }
}
