import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { io } from 'socket.io-client';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environments';

@Component({
  selector: 'app-dashbaord',
  templateUrl: './dashbaord.component.html',
  styleUrls: ['./dashbaord.component.css'],
})
export class DashboardComponent implements OnInit {
  gateId: any;
  toppings = new FormControl('');
  toppingList: string[] = [
    'Extra cheese',
    'Mushroom',
    'Onion',
    'Pepperoni',
    'Sausage',
    'Tomato',
  ];
  view: any = [500, 200];
  stasticsLiveGates: any = [
    {
      name: 'Owner',
      value: 1,
    },
    {
      name: 'Guest',
      value: 0,
    },
    {
      name: 'Staff',
      value: 0,
    },
  ];
  colorScheme: any = {
    domain: ['#3734E5', '#82BD69', '#F9BD3B'],
  };

  gats_arr: any;
  totalOwners = 0;
  selectGate: string = 'Maingate2';
  selectGateReport: string = 'Choose Gate';
  constructor(
    private rest: MainService,
    private activeRouter: ActivatedRoute
  ) {}
  ngOnInit() {
    this.gateId = this.activeRouter.snapshot.paramMap.get('id');
    // this.result()
    // this.allGats()
    // this.connectWithSocket()
  }

  allGats() {
    this.rest.allGats().subscribe((res: any) => {
      this.gats_arr = res;
    });
  }

  // socket io
  connectWithSocket() {
    let socket = io(`${environment.baseSocket}`);
    socket.on('connect', () => {
      console.log('connected to socket server');
      // socket.emit("gate_state", id);
    });
    // socket.on('gate_access', (data) => {
    //   this.stasticsLiveGates = []
    //   this.liveData(data)
    // })
  }

  liveData(data: any) {
    let unitRelation = data['Unit Relations'];
    let staffRelation = data.Staff;
    unitRelation.forEach((el: any) => {
      if (el.Guest) {
        this.guestCalc(el.Guest);
      } else if (el.Owner) {
        this.ownersCalc(el.Owner);
      } else if (el['Main Owner']) {
        this.MainOwnerCalc(el['Main Owner']);
      }
    });
    this.staffCalc(staffRelation);
  }

  total_main_owner = 0;
  MainOwnerCalc(data: any) {
    let result: any = [];
    let totalOwners: any = 0;
    data.map((el: any) => {
      let element;
      element = { name: Object.keys(el)[0], value: Object.values(el)[0] };
      totalOwners = totalOwners + Object.values(el)[0];
      result.push(element);
    });
    this.total_main_owner = totalOwners;
    // element.stasticsLiveGates.unshift({
    //   name: 'Main Owner',
    //   value: totalOwners,
    //   option: result,
    //   status: false
    // });
  }

  // owners statstics
  ownersCalc(data: any) {
    let result: any = [];
    let totalOwners: any = 0;
    data.map((el: any) => {
      let element;
      element = { key: Object.keys(el)[0], value: Object.values(el)[0] };
      totalOwners = totalOwners + Object.values(el)[0];
      result.push(element);
    });
    this.stasticsLiveGates.unshift({
      name: 'Owner',
      value: totalOwners + this.total_main_owner,
    });
  }

  // guest statsitcs

  guestCalc(data: any) {
    let result: any = [];
    let totalGuest: any = 0;
    data.map((el: any) => {
      let element;
      element = { key: Object.keys(el)[0], value: Object.values(el)[0] };
      totalGuest = totalGuest + Object.values(el)[0];
      result.push(element);
    });

    this.stasticsLiveGates.push({
      name: 'Guest',
      value: totalGuest,
    });
  }

  // staff statsitcs
  staffCalc(data: any) {
    let result: any = [];
    let totalStaff: any = 0;

    data.forEach((element: any) => {
      element[Object.keys(element)[0]].map((el: any) => {
        let element;
        element = { key: Object.keys(el)[0], value: Object.values(el)[0] };
        totalStaff = totalStaff + Object.values(el)[0];
        result.push(element);
      });
    });

    this.stasticsLiveGates.push({
      name: 'staff',
      value: totalStaff,
    });
  }

  result() {
    let arr = [
      { 'Face Recognition': 0 },
      { QR: 0 },
      { NFC: 0 },
      { Bluetooth: 0 },
      { RFID: 4 },
      { 'Mifare Bracelet': 0 },
      { 'Mifare Card': 0 },
      { Mifare: 0 },
    ];
  }

  chooseLiveGate(data: any) {
    this.selectGate = data.name;
    // this.connectWithSocket(data.id)
  }
  chooseLiveGateReport(data: any) {
    this.selectGateReport = data.name;
  }

  colorSchemetow: any = {
    domain: ['#3734E5', '#82BD69', '#F9BD3B'],
  };
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  // xAxisLabel: string = 'Year';
  // yAxisLabel: string = 'Population';
  timeline: boolean = false;
  viewtow: any = [700, 300];
  multi: any = [
    {
      name: 'Owner',
      series: [
        {
          name: '00:00',
          value: 10,
        },
        {
          name: '02:00',
          value: 20,
        },
        {
          name: '06:00',
          value: 30,
        },
        {
          name: '08:00',
          value: 40,
        },
        {
          name: '10:00',
          value: 60,
        },
        {
          name: '12:00',
          value: 19,
        },
        {
          name: '14:00',
          value: 65,
        },
        {
          name: '16:00',
          value: 2,
        },
        {
          name: '18:00',
          value: 44,
        },
        {
          name: '20:00',
          value: 22,
        },
        {
          name: '22:00',
          value: 11,
        },
        {
          name: '24:00',
          value: 77,
        },
      ],
    },
    {
      name: 'Guest',
      series: [
        {
          name: '00:00',
          value: 20,
        },
        {
          name: '02:00',
          value: 10,
        },
        {
          name: '06:00',
          value: 50,
        },
        {
          name: '08:00',
          value: 27,
        },
        {
          name: '10:00',
          value: 30,
        },
        {
          name: '12:00',
          value: 30,
        },
        {
          name: '14:00',
          value: 11,
        },
        {
          name: '16:00',
          value: 22,
        },
        {
          name: '18:00',
          value: 17,
        },
        {
          name: '20:00',
          value: 23,
        },
        {
          name: '22:00',
          value: 8,
        },
        {
          name: '24:00',
          value: 16,
        },
      ],
    },
    {
      name: 'Stauf',
      series: [
        {
          name: '00:00',
          value: 30,
        },
        {
          name: '02:00',
          value: 40,
        },
        {
          name: '06:00',
          value: 20,
        },
        {
          name: '08:00',
          value: 60,
        },
        {
          name: '10:00',
          value: 70,
        },
        {
          name: '12:00',
          value: 80,
        },
        {
          name: '14:00',
          value: 91,
        },
        {
          name: '16:00',
          value: 100,
        },
        {
          name: '18:00',
          value: 4,
        },
        {
          name: '20:00',
          value: 9,
        },
        {
          name: '22:00',
          value: 0,
        },
        {
          name: '24:00',
          value: 110,
        },
      ],
    },
  ];
}
