<section class="main">
    <h3 class="mb-4">My Dashboard</h3>
    <section class="sup_main">
        <app-live-gats [gateId]="gateId"></app-live-gats>

        <!-- gate report -->
        <!-- <div class="gate-report mt-5">
            <h4 class="mb-4">Gate report</h4>
            <div class="d-flex align-items-center mb-5">

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="gatreport"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {{selectGateReport}}
                        <img src="assets/images/mangeunit/arrow.png">
                    </button>
                    <ul style="width: 100%" class="dropdown-menu" aria-labelledby="gatreport">
                        <li *ngFor="let item of gats_arr" (click)="chooseLiveGateReport(item)">
                            <a class="dropdown-item">{{item.name}}</a>
                        </li>
                    </ul>
                </div>

                <div class="d-flex align-items-center datePicker">
                    <input class="form-control" placeholder="Form" [matDatepicker]="picker">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>

                <div class="d-flex align-items-center datePicker">
                    <input class="form-control" placeholder="To" [matDatepicker]="pickerone">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerone"></mat-datepicker-toggle>
                    <mat-datepicker #pickerone></mat-datepicker>
                </div>
            </div>

            <ngx-charts-line-chart [view]="viewtow" [scheme]="colorSchemetow" [legend]="legend"
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
                [timeline]="timeline" [results]="multi">
            </ngx-charts-line-chart>
        </div> -->
    </section>
</section>