<section class="main mb-1">
  <h3 style="text-align: center" class="mb-4">Add Unit</h3>
  <div class="row" [formGroup]="addUnitForm">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Projects</mat-label>
        <mat-select
          fullWidth
          placeholder="Select project"
          class="d-felx justify-content-center align-items-center"
          formControlName="projectName"
        >
          <mat-option class="custom-search-input">
            <ngx-mat-select-search
              #project
              placeholderLabel="Search for project"
              noEntriesFoundLabel="'No data found'"
              (keyup)="searchForProject(project?.value)"
            >
            </ngx-mat-select-search>
          </mat-option>

          <mat-option *ngIf="showSpinnerForProjects">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-option>

          <mat-option
            *ngFor="let item of projectsArray"
            [value]="item"
            (click)="selectProject(item)"
          >
            <strong *ngIf="!showSpinnerForProjects">
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>

        <mat-error *ngIf="isControlHasError('projectName', 'required')">
          Project is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Villages / Phase</mat-label>
        <mat-select
          fullWidth
          placeholder="Select village"
          formControlName="villageName"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            (click)="selectVillage(item)"
            *ngFor="let item of villagesArray"
            [value]="item?.id"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>

          <mat-option [disabled]="true" *ngIf="!villagesArray?.length">
            <strong>No villages found in this project</strong>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="isControlHasError('villageName', 'required')">
          Village is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Building / Zone</mat-label>
        <mat-select
          fullWidth
          placeholder="Select Building / Zone"
          formControlName="buildingZone"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option *ngFor="let item of buildingZoneArray" [value]="item?.id">
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>

          <mat-option [disabled]="true" *ngIf="!buildingZoneArray?.length">
            <strong>No building / zone found at this village</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Unit name</mat-label>
        <input matInput type="text" formControlName="unitName" />
        <mat-error *ngIf="isControlHasError('unitName', 'required')">
          Unit name is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Extension ID</mat-label>
        <input matInput type="text" formControlName="extensionID" />
        <mat-error *ngIf="isControlHasError('extensionID', 'required')">
          Extension ID is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Unit type</mat-label>
        <mat-select
          fullWidth
          placeholder="Select unit type"
          class="d-felx justify-content-center align-items-center"
          formControlName="unitType"
        >
          <mat-option
            *ngFor="let item of unitTypeArray"
            [value]="item?.id"
            (click)="chooseType(item)"
          >
            <strong>
              {{ item?.name | titlecase }}
            </strong>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="isControlHasError('unitType', 'required')">
          Unit type is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="addUnits d-flex justify-content-center">
    <div>
      <button
        class="btn addUnit"
        (click)="addUnit()"
        [disabled]="addUnitForm.invalid"
      >
        Proceed to unit details
      </button>
    </div>
  </div>
</section>
