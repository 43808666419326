import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VillageService } from 'src/app/services/village.service';

@Component({
  selector: 'app-village-details',
  templateUrl: './village-details.component.html',
  styleUrls: ['./village-details.component.css'],
})
export class VillageDetailsComponent implements OnInit {
  showSpinner: boolean = true;
  villageId: any;
  unitsArray: any;
  villageName: any = '';
  constructor(
    private activeRouter: ActivatedRoute,
    private route: Router,
    private rest: VillageService
  ) {}

  ngOnInit() {
    this.villageId = this.activeRouter.snapshot.paramMap.get('id');
    this.getData();
  }

  getData() {
    this.rest.villageDetails(this.villageId).subscribe((res: any) => {
      this.villageName = res.name;
      this.unitsArray = res.units;
      this.showSpinner = false;
    });
  }

  unitDetails(name: string) {
    this.route.navigate(['pages/unit_details', name]);
  }
}
