import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GatesService } from 'src/app/services/gates.service';
import { AddGateComponent } from '../add-gate/add-gate.component';
import { MainService } from 'src/app/services/main.service';
import { VillageService } from 'src/app/services/village.service';
import { AddVillageConfigComponent } from '../add-village-config/add-village-config.component';
import { AddUserConfigComponent } from '../add-user-config/add-user-config.component';
import { UnitsService } from 'src/app/services/units.service';
import { AddQrConfigComponent } from '../add-qr-config/add-qr-config.component';
import { AddQrTypeComponent } from '../add-qr-type/add-qr-type.component';
import { AddUserTypeComponent } from '../add-user-type/add-user-type.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { environment } from 'src/environments/environments';
@Component({
  selector: 'app-main-gates',
  templateUrl: './main-gates.component.html',
  styleUrls: ['./main-gates.component.css'],
})
export class MainGatesComponent {
  showSpinnerForGates: boolean = true;
  showSpinnerForVillages: boolean = true;
  showSpinnerForUsers: boolean = true;
  showSpinnerForQr: boolean = true;
  gates_arr: any = [];

  qrConfigArray = environment.qrConfigArray;

  village_list: any;

  userObj = {
    name: '',
    type: '',
    anti_passback: false,
    applying_quota: false,
    owner_existence: false,
    vip_access: false,
  };

  villageObj = {
    name: '',
    kids: false,
    rented: false,
  };

  qrObject: any = {
    name: '',
    type: '',
  };

  types_arr: any;
  selectedVillage: any = 'all';
  selected_user_village: any = 'all';
  selected_type: any = 'all';
  selectedQr_type: any;
  selected_qr_village: any;
  types_qr_arr: any;
  constructor(
    public dialog: MatDialog,
    private mainService: MainService,
    private villageSer: VillageService,
    private unitService: UnitsService,
    private rest: GatesService
  ) {}

  ngOnInit() {
    this.retrieveGates();
    // this.retrieve_all_villages();
    this.pushingTheQrConfigArrayInQrObject();
  }

  pushingTheQrConfigArrayInQrObject() {
    this.qrConfigArray.forEach((item) => {
      this.qrObject[item.name.replace(/\s+/g, '_')] = '';
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.tab.textLabel === 'Village') {
      this.retrieve_all_villages();
    } else if (event.tab.textLabel === 'Users') {
      this.retrieve_all_villages();
    } else if (event.tab.textLabel === 'Qr') {
      this.retrieve_all_villages();
    }
  }

  // gates
  retrieveGates() {
    this.rest.retrieveGates().subscribe((res: any) => {
      this.showSpinnerForGates = false;
      this.gates_arr = res;
    });
  }
  addGate() {
    const dialogRef = this.dialog.open(AddGateComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.rest.addGate(res).subscribe((res: any) => {
          this.mainService.SuccessToast('Gate added successfully');
          this.retrieveGates();
        });
      }
    });
  }
  // end gates

  // village config

  retrieve_all_villages() {
    this.villageSer.villageList().subscribe((res: any) => {
      this.village_list = res?.results;
      // this.selectedVillage = res[0];
      // this.selected_user_village = res[0];
      this.selected_qr_village = res?.results[0];
      res?.results.forEach((element: any) => {
        this.retrieveVillagesConfig(element);
      });
      this.users_type();
      this.qr_type();
    });
  }

  villages_arr_table: any = [];
  retrieveVillagesConfig(item: any) {
    this.villageSer.retrieveVillageConfig(item.id).subscribe((res: any) => {
      this.showSpinnerForVillages = false;
      res.name = item.name;
      this.villages_arr_table.push(res);
    });
  }

  addVillageConfig() {
    const dialogRef = this.dialog.open(AddVillageConfigComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.excluded == '') {
        delete res.excluded;
      }
      if (res) {
        this.villageSer.addVillageConfig(res).subscribe((res: any) => {
          this.mainService.SuccessToast('Config added successfully');
        });
      }
    });
  }

  villageChange(e: any) {
    this.villages_arr_table = [];
    if (e.value == 'all') {
      this.village_list.forEach((element: any) => {
        this.retrieveVillagesConfig(element);
      });
    } else {
      this.retrieveVillagesConfig(e.value);
    }
  }

  // end village

  // users config

  users_list_arr: any = [];
  users_type() {
    this.unitService.unitRelation().subscribe((res: any) => {
      this.types_arr = res.children;
      // this.selected_type = this.types_arr[0]
      for (let i = 0; i < this.village_list.length; i++) {
        for (let j = 0; j < this.types_arr.length; j++) {
          this.retrieve_user_config(this.types_arr[j], this.village_list[i]);
        }
      }
    });
  }

  changeType(e: any) {
    this.users_list_arr = [];
    if (e.value == 'all') {
      if (this.selected_user_village == 'all') {
        for (let i = 0; i < this.village_list.length; i++) {
          for (let j = 0; j < this.types_arr.length; j++) {
            this.retrieve_user_config(this.types_arr[j], this.village_list[i]);
          }
        }
      } else {
        for (let i = 0; i < this.types_arr.length; i++) {
          this.retrieve_user_config(
            this.types_arr[i],
            this.selected_user_village
          );
        }
      }
    } else {
      if (this.selected_user_village == 'all') {
        for (let i = 0; i < this.village_list.length; i++) {
          this.retrieve_user_config(e.value, this.village_list[i]);
        }
      } else {
        this.retrieve_user_config(e.value, this.selected_user_village);
      }
    }
  }

  village_user_Change(e: any) {
    this.users_list_arr = [];
    if (e.value == 'all') {
      if (this.selected_type == 'all') {
        for (let i = 0; i < this.village_list.length; i++) {
          for (let j = 0; j < this.types_arr.length; j++) {
            this.retrieve_user_config(this.types_arr[j], this.village_list[i]);
          }
        }
      } else {
        for (let i = 0; i < this.village_list.length; i++) {
          this.retrieve_user_config(this.selected_type, this.village_list[i]);
        }
      }
    } else {
      if (this.selected_type == 'all') {
        for (let i = 0; i < this.types_arr.length; i++) {
          this.retrieve_user_config(this.types_arr[i], e.value);
        }
      } else {
        this.retrieve_user_config(this.selected_type, e.value);
      }
    }
  }

  retrieve_user_config(type: any, village: any) {
    this.rest
      .retrieve_user_config(type.id, village.id)
      .subscribe((res: any) => {
        this.showSpinnerForUsers = false;
        res.name = village.name;
        res.type = type.name;
        this.users_list_arr.push(res);
      });
  }

  add_user_config() {
    const dialogRef = this.dialog.open(AddUserConfigComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.rest.addUserConfig(res).subscribe((res: any) => {
          this.showSpinnerForUsers = true;
          this.retrieve_all_villages();
          this.users_list_arr = [];
          this.mainService.SuccessToast('User Config added successfully');
        });
      }
    });
  }

  //Add QR type

  addQrType() {
    const dialogRef = this.dialog.open(AddQrTypeComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.unitService.addNewType(res).subscribe((res: any) => {
          this.mainService.SuccessToast('Qr type added successfully');
        });
      }
    });
  }

  //Add User type

  addUserType() {
    const dialogRef = this.dialog.open(AddUserTypeComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.unitService.addNewType(res).subscribe((res: any) => {
          this.mainService.SuccessToast('User type added successfully');
        });
      }
    });
  }

  // qr config

  add_qr_config() {
    const dialogRef = this.dialog.open(AddQrConfigComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.rest.addQrConfig(res).subscribe((res: any) => {
          this.showSpinnerForQr = true;
          this.retrieve_all_villages();
          this.mainService.SuccessToast('Qr Config added successfully');
        });
      }
    });
  }

  qr_type() {
    this.unitService.QrTypes().subscribe((res: any) => {
      this.types_qr_arr = res?.children;
      this.selectedQr_type = this.types_qr_arr[0];
      this.retrieve_qr_config(
        this.selectedQr_type?.id,
        this.selected_qr_village?.id
      );
    });
  }

  changeQrType(e: any) {
    this.retrieve_qr_config(e?.value?.id, this.selected_qr_village?.id);
  }

  village_qr_change(e: any) {
    this.retrieve_qr_config(this.selectedQr_type?.id, e?.value?.id);
  }
  retrieve_qr_config(type: any, village: any) {
    this.qrObject.name = this.selected_qr_village?.name;
    this.qrObject.type = this.selectedQr_type?.name;
    this.rest.retrieve_user_config(type, village).subscribe((res: any) => {
      this.showSpinnerForQr = false;

      for (let key in this.qrObject) {
        if (res.hasOwnProperty(key)) {
          this.qrObject[key] = res[key];
        }
      }

      this.objectEntries(this.qrObject);

      // this.qrObject.one_time_use = res?.one_time_use;
      // this.qrObject.started = res?.started;
      // this.qrObject.ended = res?.ended;
      // this.qrObject.available_days = res?.available_days;
      // this.qrObject.start_time = res?.start_time;
      // this.qrObject.thirty_seconds = res?.['30_seconds'];
      // this.qrObject.full_day_access = res?.full_day_access;
      // this.qrObject.count = res?.count;
    });
  }

  objectEntries(obj: any): Array<[string, any]> {
    return Object.entries(obj).filter(
      ([key]) => key !== 'name' && key !== 'type'
    );
  }
}

// retrieve_all_villages
