import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddOperatorComponent } from '../add-operator/add-operator.component';
import { OperatorsService } from 'src/app/services/opreators.service';
import { RolesService } from 'src/app/services/roles.service';
import { MainService } from 'src/app/services/main.service';
import { EditOperatorsComponent } from '../edit-opreators/edit-opreators.component';

@Component({
  selector: 'app-opreators-main',
  templateUrl: './opreators-main.component.html',
  styleUrls: ['./opreators-main.component.css'],
})
export class OperatorsMainComponent implements OnInit {
  showSpinner: boolean = true;
  allOperators: any;
  constructor(
    public dialog: MatDialog,
    private rest: OperatorsService,
    private mainService: MainService,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    this.get_opreators();
    this.getRoles();
  }

  get_opreators() {
    this.rest.retrieveOperators().subscribe((res: any) => {
      this.showSpinner = false;
      this.allOperators = res;
    });
  }

  getRoles() {
    this.rolesService.retrieveOperators().subscribe((res: any) => {});
    this.rolesService.retrievePermission().subscribe((res: any) => {});
  }

  addOperator() {
    const dialogRef = this.dialog.open(AddOperatorComponent, {
      width: '450px',
      height: '68%',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.data.web_login == 'true') {
          result.data.web_login = true;
        } else {
          result.data.web_login = false;
        }
        this.rest.addOperators(result.data).subscribe(
          (res: any) => {
            for (let i = 0; i < result.rolesArr.length; i++) {
              for (let j = 0; j < result.rolesArr[i].roles.length; j++) {
                if (result.rolesArr[i].name == 'village') {
                  let data = {
                    user_id: res.user.id,
                    village_id: result.rolesArr[i].villageId,
                    role_id: result.rolesArr[i].roles[j].id,
                  };

                  this.rest.addRoleForUser(data).subscribe(
                    (res: any) => {
                      this.mainService.SuccessToast(res.message);
                    },

                    (err: any) => {
                      this.mainService.errorToast(err.error.message);
                    }
                  );
                } else {
                  let data = {
                    user_id: res.user.id,
                    project_id: result.rolesArr[i].villageId,
                    role_id: result.rolesArr[i].roles[j].id,
                  };
                  this.rest.addRoleForUserAndProject(data).subscribe(
                    (res: any) => {
                      this.mainService.SuccessToast(res.message);
                    },

                    (err: any) => {
                      this.mainService.errorToast(err.error.message);
                    }
                  );
                }
              }
            }
            this.get_opreators();
          },

          (err: any) => {
            this.mainService.errorToast(err.error.message);
          }
        );
      }
    });
  }

  editOperators(item: any) {
    const dialogRef = this.dialog.open(EditOperatorsComponent, {
      width: '450px',
      height: '68%',
      data: item,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        for (let i = 0; i < result.rolesArr.length; i++) {
          for (let j = 0; j < result.rolesArr[i].roles.length; j++) {
            if (result.rolesArr[i].name == 'village') {
              let data = {
                user_id: result.data.user_id,
                village_id: result.rolesArr[i].villageId,
                role_id: result.rolesArr[i].roles[j].id,
              };
              this.rest.addRoleForUser(data).subscribe((res: any) => {
                this.mainService.SuccessToast('Operators Edit successfully');
              });
            } else {
              let data = {
                user_id: result.data.user_id,
                project_id: result.rolesArr[i].villageId,
                role_id: result.rolesArr[i].roles[j].id,
              };
              this.rest.addRoleForUserAndProject(data).subscribe((res: any) => {
                this.mainService.SuccessToast('Operators Edit successfully');
              });
            }
          }
        }
        this.rest.editOperators(result.data).subscribe((res: any) => {
          this.mainService.SuccessToast('Operators edit successfully');
          this.get_opreators();
        });
      }
    });
  }

  DeleteOperator() {}
}
