import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { MainService } from 'src/app/services/main.service';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ClientsService } from 'src/app/services/clients.service';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class AddClientComponent implements OnInit {
  birthDateControlName: string = 'birth_date';
  defaultDate = moment();

  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.Egypt];
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode = true;

  identityType: string = '';
  validForm = true;
  unitId: any;
  unitName: any;
  clientForm: FormGroup | any;
  types_arr: any;
  relationArray: any;
  typeName: any = 'Relation type';
  typeId: any = '';
  submitted = false;
  clientId: any;
  userPhotoPath: any = '';
  pipe = new DatePipe('en-US');
  userEditPhoto = false;
  url: any;
  documentImage: any = [];
  userDocEditPhoto = false;
  documentsFiles: any = [];
  Relation = '';

  showIdentityTypeInput: boolean = false;
  identityTypes: any[] = [
    {
      name: 'National Id',
      key: 'nationalId',
    },
    {
      name: 'Passport',
      key: 'passport',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    private mainService: MainService,
    private location: Location,
    private rest: UnitsService,
    private route: Router,
    private clientService: ClientsService
  ) {}

  ngOnInit() {
    this.unitId = this.activeRouter.snapshot.paramMap.get('id');

    this.unitName = localStorage.getItem('unitName');
    this.initForm();
    this.unitRelationType();
    this.getUnitRelation();
  }

  initForm() {
    this.clientForm = this.formBuilder.group({
      name: [null, Validators.required],
      valid_from: [null, Validators.required],
      national_id: new FormControl(null, [
        Validators.required,
        this.conditionalValidator(),
      ]),
      arabic_name: [null, Validators.required],
      valid_to: [null, Validators.required],
      email: [null, Validators.email],
      birth_date: [this.defaultDate, Validators.required],
      ext_id: [null],
      gender: [null, Validators.required],
      phone_number: [null, Validators.required],
      verified: [false],
      activated: [false],
    });
  }

  get ControlForm() {
    return this.clientForm.controls;
  }

  nextDataInForm() {
    // this.validForm = false
    this.submitted = true;
    if (this.clientForm.valid && this.typeId != '') {
      let formValue = this.clientForm.value;
      formValue.birth_date = this.pipe.transform(
        formValue.birth_date,
        'yyyy-MM-dd'
      );
      formValue.valid_from = this.pipe.transform(
        formValue.valid_from,
        'YYYY/MM/dd'
      );
      formValue.valid_to = this.pipe.transform(
        formValue.valid_to,
        'YYYY/MM/dd'
      );

      let data_step_one = formValue;
      data_step_one.unit_id = this.unitId;
      data_step_one.unit_relation_id = this.typeId;
      data_step_one.relation = this.relationNameSelected;
      data_step_one.phone_number =
        this.clientForm.get('phone_number').value.e164Number;

      // if (this.typeName != 'Owner') {
      //   data_step_one.relation = this.typeName;
      // } else {
      //   data_step_one.relation = this.Relation;
      // }

      this.rest.addClient(data_step_one).subscribe((res: any) => {
        this.clientId = res;

        if (formValue.verified) {
          this.verifyClient(this.clientId);
        }

        if (formValue.activated) {
          this.activateClient(this.clientId);
        }

        this.validForm = false;
      });
    }
  }

  verifyClient(clientId: any) {
    this.clientService.verifyClient(clientId).subscribe((res: any) => {
      this.mainService.SuccessToast('Client Verified');
    });
  }

  activateClient(clientId: any) {
    this.clientService.activateClient(clientId).subscribe((res: any) => {
      this.mainService.SuccessToast('Client Activated');
    });
  }

  unitRelationType() {
    this.rest.unitRelation().subscribe((res: any) => {
      this.types_arr = res.children;
    });
  }

  getUnitRelation() {
    this.rest.unitRelations().subscribe((res: any) => {
      this.relationArray = res.children;
    });
  }

  relationNameSelected: any;
  getRelationTypeId(value: any) {
    this.typeName = value.name;
    this.typeId = value.id;
    this.relationArray.unshift(value);

    this.relationNameSelected = this.relationArray[0].name;
  }

  getRelationName(value: any) {
    this.relationNameSelected = value;
  }

  selectIdentityType(value: any) {
    this.showIdentityTypeInput = true;

    if (value == 'nationalId') {
      this.identityType = 'National Id';
    } else if (value == 'passport') {
      this.identityType = 'Passport';
    }
  }

  uploadDocumentClient(event: any) {
    this.userDocEditPhoto = true;
    let files: any = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.documentsFiles.push({
        type: this.typeDocName + i,
        path: files[i],
      });
      this.convertFile(files[i]);
    }
  }

  convertFile(file: any) {
    if (file.type == 'application/pdf') {
      if (this.typeDocName == 'Other') {
        this.typeDocName = this.typeInputName;
      }
      this.documentImage.push({
        url: file.name,
        file: true,
        fileName: file.name,
        name: this.typeDocName,
      });
    } else {
      if (this.typeDocName == 'Other') {
        this.typeDocName = this.typeInputName;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.documentImage.push({
          url: reader.result,
          file: false,
          fileName: file.name,
          name: this.typeDocName,
        });
      };
    }
    // this.errorDocumentPhoto = false;
  }

  removeDocImage(item: any) {
    for (let i = 0; i < this.documentImage.length; i++) {
      if (this.documentImage[i].fileName == item.fileName) {
        this.documentImage.splice(i, 1);
      }
    }
    for (let i = 0; i < this.documentsFiles.length; i++) {
      if (this.documentsFiles[i].path.name == item.fileName) {
        this.documentsFiles.splice(i, 1);
      }
    }
    if (this.documentsFiles.length == 0) {
      this.userDocEditPhoto = false;
      // this.errorDocumentPhoto = true;
    }
  }

  typeDocName: any = 'ID_Passport';
  typeInputName: any = '';
  status_input_type_name = false;
  typeChoose(e: any) {
    if (e == 'Other') {
      this.status_input_type_name = true;
    } else {
      this.status_input_type_name = false;
      this.typeDocName = e;
    }
  }

  // end documents

  // user photo

  uploadUserPhoto(event: any) {
    this.userEditPhoto = true;
    this.userPhotoPath = event.target.files[0];
    const reader = new FileReader();
    let imagePath = this.userPhotoPath;
    reader.readAsDataURL(imagePath);
    reader.onload = (_event) => {
      this.url = reader.result;
      this.errorUserPhoto = false;
    };
  }
  removeUserImage() {
    this.userPhotoPath = '';
    this.userEditPhoto = false;
    this.errorUserPhoto = true;
  }

  // end user photo

  errorUserPhoto = false;
  errorDocumentPhoto = false;
  createClient() {
    if (this.userPhotoPath == '') {
      this.errorUserPhoto = true;
    }
    // if (this.documentsFiles.length == 0) {
    //   this.errorDocumentPhoto = true;
    // }

    // if (this.userPhotoPath == '' || this.documentsFiles.length == 0) {

    if (this.userPhotoPath == '') {
      return;
    } else {
      this.documentsFiles.push({
        type: 'personal_photo',
        path: this.userPhotoPath,
      });

      this.rest
        .addClientImage(this.clientId, this.documentsFiles)
        .then((response: any) => response.text())
        .then((result: any) => {
          this.mainService.SuccessToast('Client Created');
          this.back();
        })
        .catch((error: any) => {});
    }
  }

  inputChange(e: any) {
    this.typeDocName = e.target.value;
  }

  back() {
    this.location.back();
  }

  skip() {
    this.route.navigate(['pages/unit_details', this.unitName]);
    localStorage.removeItem('unitName');
  }

  conditionalValidator() {
    return (control: FormControl) => {
      if (this.identityType == 'National Id') {
        return this.egyptianNationalIdValidator(control);
      }
      return null;
    };
  }

  egyptianNationalIdValidator(
    control: FormControl
  ): { [key: string]: any } | null {
    const nationalId = control.value;
    const regex = /^[0-9]{14}$/;

    if (nationalId && !regex.test(nationalId)) {
      return { invalidNationalId: true };
    }
    return null;
  }

  //Form Validator
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.clientForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
  //Form Validator
}
