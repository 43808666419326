<section class="main">
  <h3 class="d-flex justify-content-center">Add unit quota</h3>

  <div class="mb-3 radioInput">
    <div class="form-group">
      <input
        [checked]="showQuotaStatus"
        (change)="changeQuota($event)"
        name="name"
        type="radio"
      />
      <label class="me-2 ms-2">One day event</label>
    </div>
    <div class="form-group">
      <input (change)="changeQuota($event)" name="name" type="radio" />
      <label class="me-2 ms-2">Weekly schedule</label>
    </div>
  </div>

  <section *ngIf="showQuotaStatus">
    <!-- <div>
      <label>Date</label>
      <div class="mb-3 d-flex align-items-center datePicker">
        <input
          [(ngModel)]="quotaData"
          class="form-control"
          placeholder="yyyy/mm/dd"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div> -->

    <div class="datePicker">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Date</mat-label>
        <input matInput [(ngModel)]="quotaData" [matDatepicker]="picker" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Priority</mat-label>
        <mat-select
          fullWidth
          placeholder="Select priority"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            (click)="getPriority('Override ALL', 1)"
            value="Override ALL"
          >
            <strong> Override ALL</strong>
          </mat-option>
          <mat-option
            (click)="getPriority('High Priority', 2)"
            value="High Priority"
          >
            <strong>High Priority</strong>
          </mat-option>
          <mat-option (click)="getPriority('Standard', 3)" value="Standard">
            <strong>Standard</strong>
          </mat-option>
          <mat-option
            (click)="getPriority('Low Priority', 4)"
            value="Low Priority"
          >
            <strong>Low Priority</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div class="mb-3">
      <label>Priority</label>
      <div class="dropdown mb-0">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ Choose_Priority }}
          <img src="assets/images/mangeunit/arrow.png" />
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
          <li (click)="getPriority('Override ALL', 1)">
            <a class="dropdown-item">Override ALL</a>
          </li>
          <li (click)="getPriority('High Priority', 2)">
            <a class="dropdown-item">High Priority</a>
          </li>
          <li (click)="getPriority('Standard', 3)">
            <a class="dropdown-item">Standard</a>
          </li>
          <li (click)="getPriority('Low Priority', 4)">
            <a class="dropdown-item">Low Priority</a>
          </li>
        </ul>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Quantity</label>
          <input
            type="number"
            [(ngModel)]="quotaQuantity"
            class="form-control"
          />
        </div>
      </div>
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" [(ngModel)]="quotaQuantity" />
      </mat-form-field>
    </div>

    <div *ngIf="errorScheduleMassage" class="alert alert-danger" role="alert">
      please fill all Data
    </div>
    <div class="addUnits d-flex justify-content-end mt-5 mb-2">
      <div>
        <button class="btn me-2">Cancel</button>
        <button (click)="addQuotaByDay()" class="btn addUnit">Add quota</button>
      </div>
    </div>
  </section>

  <section *ngIf="!showQuotaStatus">
    <div class="row">
      <!-- <div class="col">
        <label>From</label>
        <div class="mb-3 d-flex align-items-center datePicker">
          <input
            [(ngModel)]="valid_ForSchedule"
            class="form-control"
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerFrom"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </div>
      </div> -->

      <div class="col-md-6 datePicker">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>From</mat-label>
          <input
            matInput
            [(ngModel)]="valid_ForSchedule"
            [matDatepicker]="pickerFrom"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-6 datePicker">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>To</mat-label>
          <input
            matInput
            [(ngModel)]="valid_toSchedule"
            [matDatepicker]="pickerTo"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- <div class="col">
        <label>To</label>
        <div class="mb-3 d-flex align-items-center datePicker">
          <input
            [(ngModel)]="valid_toSchedule"
            class="form-control"
            placeholder="yyyy/mm/dd"
            [matDatepicker]="pickerTo"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </div>
      </div> -->
    </div>

    <!-- <div class="mb-3">
      <label>Priority</label>
      <div class="dropdown mb-0">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ Choose_Priority_schedule }}
          <img src="assets/images/mangeunit/arrow.png" />
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
          <li (click)="getPrioritySchedule('Override ALL', 1)">
            <a class="dropdown-item">Override ALL</a>
          </li>
          <li (click)="getPrioritySchedule('High Priority', 2)">
            <a class="dropdown-item">High Priority</a>
          </li>
          <li (click)="getPrioritySchedule('Standard', 3)">
            <a class="dropdown-item">Standard</a>
          </li>
          <li (click)="getPrioritySchedule('Low Priority', 4)">
            <a class="dropdown-item">Low Priority</a>
          </li>
        </ul>
      </div>
    </div> -->

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Priority</mat-label>
        <mat-select
          fullWidth
          placeholder="Select priority"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option
            (click)="getPrioritySchedule('Override ALL', 1)"
            value="Override ALL"
          >
            <strong> Override ALL</strong>
          </mat-option>
          <mat-option
            (click)="getPrioritySchedule('High Priority', 2)"
            value="High Priority"
          >
            <strong>High Priority</strong>
          </mat-option>
          <mat-option
            (click)="getPrioritySchedule('Standard', 3)"
            value="Standard"
          >
            <strong>Standard</strong>
          </mat-option>
          <mat-option
            (click)="getPrioritySchedule('Low Priority', 4)"
            value="Low Priority"
          >
            <strong>Low Priority</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <form [formGroup]="weekDays">
        <strong>Quantity:</strong>
        <div class="row mt-2">
          <div class="col-4 mb-2" *ngFor="let item of weeksArr">
            <label>{{ item.name }}</label>
            <input
              [formControlName]="item.title"
              value="{{ item.value }}"
              type="number"
              class="form-control"
            />
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="errorScheduleMassage" class="alert alert-danger" role="alert">
      please fill all Data
    </div>

    <div class="addUnits d-flex justify-content-end mt-5 mb-2">
      <div>
        <button class="btn me-2">Cancel</button>
        <button (click)="addQuotaSchedule()" class="btn addUnit">
          Add quota
        </button>
      </div>
    </div>
  </section>
</section>
