<section class="main">
  <div class="d-flex align-items-center justify-content-between">
    <h3>
      <span>
        <img
          style="cursor: pointer"
          (click)="back()"
          src="assets/images/mangeunit/back.png"
        />
      </span>
      View client
    </h3>
    <button (click)="EditClient()" class="btn editBtn">Edit Client</button>
  </div>

  <mat-tab-group>
    <mat-tab label="Information">
      <section class="supmain mt-3" *ngIf="validForm">
        <p>Units</p>
        <div class="mt-2">
          <div class="row">
            <div class="col-6">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Unit name</th>
                    <th scope="col">Unit Relation</th>
                    <!-- <th scope="col">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of array_unit; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.relation }}</td>
                    <!-- <td>
                            <img
                              class="deleteImage"
                              (click)="deleteUnit(i)"
                              src="assets/images/delete.png"
                            />
                          </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <form [formGroup]="clientForm">
          <!-- persona info -->
          <div class="mt-4 mb-4">
            <p>Personal information</p>
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>Client name (EN)</label>
                  <input
                    formControlName="name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>Client name (AR)</label>
                  <input
                    formControlName="arabic_name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <label>Birth date</label>
                <div class="d-flex align-items-center datePicker">
                  <input
                    formControlName="birth_date"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.birth_date.errors
                    }"
                    class="form-control"
                    placeholder="Form"
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 col-md-3">
                <p>Gender</p>
                <input
                  formControlName="gender"
                  type="radio"
                  id="male"
                  value="Male"
                />
                <label class="ms-2" for="male">Male</label><br />
                <input
                  formControlName="gender"
                  type="radio"
                  id="female"
                  value="Female"
                />
                <label class="ms-2" for="female">Female</label>
                <div
                  class="invalid-feedback"
                  *ngIf="submitted && ControlForm.gender.errors"
                >
                  Gender is Required
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-md-3">
                <p>Status</p>
                <input
                  formControlName="verified"
                  type="checkbox"
                  id="Verified"
                  value="true"
                />
                <label class="ms-2" for="Verified">Verified</label><br />
                <input
                  formControlName="activated"
                  type="checkbox"
                  id="Activated"
                  value="true"
                />
                <label class="ms-2" for="Activated">Activated</label>
              </div>
            </div>
          </div>

          <!-- Contact information -->
          <div>
            <p>Contact information</p>
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.email.errors
                    }"
                    formControlName="email"
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>Phone number</label>
                  <input
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.phone_number.errors
                    }"
                    formControlName="phone_number"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>National id</label>
                  <input
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.national_id.errors
                    }"
                    formControlName="national_id"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Other information -->

          <div class="mt-4">
            <p>Other information</p>
            <div class="row">
              <div class="col-12 col-md-3">
                <label>From</label>
                <div class="d-flex align-items-center datePicker">
                  <input
                    formControlName="valid_from"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.valid_from.errors
                    }"
                    class="form-control"
                    placeholder="Valid Form"
                    [matDatepicker]="pickerFrom"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="pickerFrom"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom></mat-datepicker>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label>To</label>
                <div class="d-flex align-items-center datePicker">
                  <input
                    formControlName="valid_to"
                    [ngClass]="{
                      'is-invalid': submitted && ControlForm.valid_to.errors
                    }"
                    class="form-control"
                    placeholder="Valid To"
                    [matDatepicker]="pickerTo"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="pickerTo"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo></mat-datepicker>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </mat-tab>
    <mat-tab label="RFID">
      <section class="tableUnit table-responsive mt-3">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Card id</th>
              <th scope="col">License plate</th>
              <!-- <th scope="col" class="text-end">Actions</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              (click)="openViewRFID(item)"
              style="cursor: pointer"
              *ngFor="let item of rfid_list; let i = index"
            >
              <td class="">
                {{ item.id }}
              </td>
              <td>
                <span>{{ item.card_id }}</span>
              </td>
              <td>
                {{ item.license_plate }}
              </td>
            </tr>
          </tbody>
        </table>
        <h3 *ngIf="rfid_list.length == 0">No Data Found</h3>
      </section>
    </mat-tab>
    <mat-tab label="Images">
      <section class="images_main">
        <div class="row">
          <div class="col-md-3" *ngFor="let item of clients_image">
            <div class="card_image mt-2">
              <img class="img-fluid" [src]="item.image" />
              <p class="mt-2">{{ item.image_type }}</p>
            </div>
          </div>
        </div>
      </section>
    </mat-tab>
  </mat-tab-group>
</section>
