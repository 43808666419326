<section class="main">
  <h1>Utilities</h1>
  <section>
    <button class="btn mb-2" (click)="addUtility()">Add Utility</button>
    <div class="row">
      <mat-spinner diameter="50" *ngIf="showSpinner" class="mb-4"></mat-spinner>
      <div class="col-md-4" *ngIf="!showSpinner">
        <section class="menuItems">
          <div
            class="box"
            (click)="utilityDetailsFunction(item)"
            [ngClass]="{
              active: item.status == true,
              'justify-content-between': item.status == true
            }"
            *ngFor="let item of allUtility"
          >
            <img
              *ngIf="item.image"
              src="{{ item.image }}"
              style="width: 60px; height: 60px"
            />

            <ngx-avatar
              *ngIf="!item.image"
              size="60"
              name="{{ item?.name }}"
              cornerRadius="5"
              [round]="false"
            ></ngx-avatar>

            <p>{{ item.name }}</p>
            <img
              *ngIf="item.status == true"
              (click)="addUtility(item)"
              class="edit me-2"
              src="assets/images/edit-64.png"
            />
          </div>
        </section>
      </div>
      <div class="col-md-8">
        <section class="menuItems">
          <div
            class="headMicro d-flex justify-content-between align-items-center"
          >
            <h1>Unit measure : {{ utilityDetails.unit_measure }}</h1>
            <div>
              <button
                class="btn mb-2"
                (click)="addSection(utilityDetails, 'add')"
              >
                Add Section
              </button>
              <button class="btn mb-2 ms-2" (click)="addInvoice()">
                Add Invoice
              </button>
            </div>
          </div>
          <hr />

          <table class="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Created at</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of utilityDetails.sections">
                <td>{{ item.name }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.start }}</td>
                <td>{{ item.end }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <img
                    style="width: 20px; cursor: pointer"
                    (click)="addSection(item, 'edit')"
                    class="edit me-2"
                    src="assets/images/edit-64.png"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </section>
</section>
