<section>
  <ul class="mainUl">
    <ng-container *ngFor="let item of menu">
      <li
        class="mainli mb-3 d-flex justify-content-between align-items-center"
        (click)="handleClick(item)"
        [ngClass]="{ active: item.status }"
      >
        <a>{{ item.name }}</a>

        <i *ngIf="item.children" class="fa-solid fa-angle-down me-2"></i>
      </li>

      <ul *ngIf="item.showSubMenu" class="subUl">
        <li
          class="subMenu"
          *ngFor="let child of item.children"
          (click)="setRoute(child)"
          [ngClass]="{ active: child.subStatus }"
        >
          <a>{{ child.name }}</a>
        </li>
      </ul>
    </ng-container>
  </ul>
</section>

<div>
  <nav class="navbar navbar-dark">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <img style="width: 40px" src="assets/images/icon-menu.png" />
        </span>
      </button>
    </div>
  </nav>

  <div class="collapse" id="navbarToggleExternalContent">
    <div class="p-4">
      <!-- <li class="mainli" class="mb-4" *ngFor="let item of menu" (click)="setRoute(item)"
              [ngClass]="{'active': item.status}">
              <a>
                  {{item.name}}
              </a>
          </li> -->

      <ng-container *ngFor="let item of menu">
        <li
          class="mainli mb-3 d-flex justify-content-between align-items-center"
          (click)="toggleSubMenu(item)"
          (click)="setRoute(item)"
          [ngClass]="{ active: item.status }"
        >
          <a>{{ item.name }}</a>

          <i *ngIf="item.children" class="fa-solid fa-angle-down me-2"></i>
        </li>

        <ul *ngIf="item.showSubMenu" class="subUl">
          <li
            class="subMenu"
            *ngFor="let child of item.children"
            (click)="setRoute(child)"
            [ngClass]="{ active: child.subStatus }"
          >
            <a>{{ child.name }}</a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
