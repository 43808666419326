<section class="main">
  <h3>{{ serviceName }} Micro Services</h3>
  <div class="row">
    <!-- <div class="col-12">
      <div class="form-group">
        <label>Name</label>
        <input
          [(ngModel)]="microServiceName"
          type="text"
          class="form-control"
        />
      </div>
    </div> -->

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <input matInput type="text" [(ngModel)]="microServiceName" />
      </mat-form-field>
    </div>

    <!-- <div class="col-12 mt-2">
      <div class="form-group">
        <label>Cost</label>
        <input
          [(ngModel)]="microServiceCost"
          type="number"
          class="form-control"
        />
      </div>
    </div>
 -->

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Cost</mat-label>
        <input matInput type="number" [(ngModel)]="microServiceCost" />
      </mat-form-field>
    </div>
  </div>
  <div>
    <p>Service image</p>
    <label for="images" class="drop-container">
      <input
        (change)="uploadServiceImage($event)"
        type="file"
        id="images"
        accept="image/*"
        required
      />
    </label>
  </div>

  <div class="mt-4">
    <button class="btn add" (click)="addService()">Add Service</button>
    <button class="btn">Cancel</button>
  </div>
</section>
