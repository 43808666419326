<section class="main">
  <mat-tab-group>
    <mat-tab label="Add Card">
      <div class="row mt-4">
        <div class="col-md-4 mb-2" *ngFor="let item of cardType_array">
          <input
            (change)="radioChange($event)"
            type="radio"
            id="{{ item.id }}"
            name="name"
            value="{{ item.id }}"
          />
          <label class="ms-1" for="{{ item.id }}">{{ item.name }}</label>
        </div>
        <div class="col-md-4">
          <input
            (change)="radioChange($event)"
            type="radio"
            id="RFID"
            name="name"
            value="RFID"
          />
          <label class="ms-1" for="RFID">RFID</label>
        </div>
      </div>

      <div class="mt-4" *ngIf="cardType == 'RFID'">
        <mat-spinner
          *ngIf="showSpinnerForRFID && !showInvoicePaymentHint"
          diameter="50"
        ></mat-spinner>

        <strong class="invoicePaymentHint" *ngIf="showInvoicePaymentHint">{{
          invoicePaymentHint
        }}</strong>

        <div
          *ngIf="!showSpinnerForRFID && !showInvoicePaymentHint"
          class="row"
          [formGroup]="RfidForm"
        >
          <div class="col-md-6 col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Vehicle plate numbers</mat-label>
              <input matInput type="text" formControlName="number" />
            </mat-form-field>
          </div>

          <div class="col-md-2 col-4">
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                type="text"
                formControlName="charOne"
                maxlength="1"
              />
            </mat-form-field>
          </div>
          <div class="col-md-2 col-4">
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                type="text"
                formControlName="charTow"
                maxlength="1"
              />
            </mat-form-field>
          </div>
          <div class="col-md-2 col-4">
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                type="text"
                formControlName="charThree"
                maxlength="1"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div
        class="mt-2"
        *ngIf="
          cardType == 'RFID' && !showSpinnerForRFID && !showInvoicePaymentHint
        "
      >
        <form [formGroup]="RfidForm">
          <!-- <div class="col-md-12">
            <label>Vehicle Number</label>
            <div class="row">
              <div class="col-md-6">
                <input
                  formControlName="number"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-md-2">
                <input
                  formControlName="charOne"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-md-2">
                <input
                  formControlName="charTow"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-md-2">
                <input
                  formControlName="charThree"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div> -->
          <div class="col-md-12 mt-3">
            <label><strong>Driving License Front Face</strong></label>
            <input
              (change)="onFileChange($event, 'front')"
              formControlName="front"
              type="file"
            />
          </div>
          <div class="col-md-12 mt-3 mb-3">
            <label><strong>Driving License Back Face</strong></label>
            <input
              (change)="onFileChange($event, 'back')"
              formControlName="back"
              type="file"
            />
          </div>
          <!-- <div class="col-md-12 mt-3">
            <label>Car Tag </label>
            <input formControlName="card_id" type="text" class="form-control" />
          </div> -->

          <div class="col-md-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Car Tag</mat-label>
              <input matInput type="text" formControlName="card_id" />
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Serial Number</mat-label>
              <input matInput type="text" formControlName="serial_number" />
            </mat-form-field>
          </div>

          <!-- <div class="col-md-12 mt-3">
            <label>Serial Number </label>
            <input
              formControlName="serial_number"
              type="text"
              class="form-control"
            />
          </div> -->
        </form>
      </div>

      <!-- <div class="mt-3" *ngIf="cardType != 'RFID'">
        <label> Card ID </label>
        <input type="text" [(ngModel)]="cardId" class="form-control" />
      </div> -->

      <div class="mt-3" *ngIf="cardType != 'RFID'">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Card ID</mat-label>
          <input
            [disabled]="disableCardId"
            matInput
            type="text"
            [(ngModel)]="cardId"
          />
        </mat-form-field>
      </div>

      <div class="mt-3">
        <button *ngIf="!showInvoicePaymentHint" class="btn" (click)="addCard()">
          Create
        </button>
      </div>
    </mat-tab>

    <mat-tab label="Cards List">
      <div class="mt-4 cardContainer">
        <div class="mb-3 card" *ngFor="let item of cards_arr">
          <div>
            <h4>{{ item.card_id }}</h4>
            <p>{{ item.type_name }}</p>
          </div>
          <img (click)="deleteCard(item)" src="assets/images/delete.png" />
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
