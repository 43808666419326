<section class="main">
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <h3>Users configuration</h3>
    <div class="mt-5 addGate">
      <div class="me-2">
        <mat-form-field appearance="outline">
          <mat-label>Choose user type</mat-label>
          <mat-select
            (selectionChange)="changeType($event)"
            [(value)]="selected_type"
          >
            <mat-option value="all">All</mat-option>
            <mat-option *ngFor="let item of types_arr" [value]="item">
              {{ item?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="me-2">
        <mat-form-field appearance="outline">
          <mat-label>Choose Village</mat-label>
          <mat-select
            (selectionChange)="village_user_Change($event)"
            [(value)]="selected_user_village"
          >
            <mat-option value="all"> All </mat-option>
            <mat-option *ngFor="let item of village_list" [value]="item">
              {{ item?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <button class="btn me-2" (click)="addUserType()">Add user type</button>
        <button class="btn" (click)="add_user_config()">Add User config</button>
      </div>
    </div>
  </div>

  <mat-spinner *ngIf="showSpinnerForUsers" diameter="50"></mat-spinner>

  <section *ngIf="!showSpinnerForUsers" class="sup_main">
    <table class="mt-3 table table-striped sticky-header">
      <thead>
        <tr>
          <th scope="col">Village Name</th>
          <th scope="col">User Type</th>
          <th scope="col">Anti Passback</th>
          <th scope="col">Applying quota</th>
          <th scope="col">Owner existence</th>
          <th scope="col">Vip access</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of users_list_arr">
          <td>{{ item?.name }}</td>
          <td>{{ item?.type }}</td>
          <td>{{ item?.anti_passback }}</td>
          <td>{{ item?.applying_quota }}</td>
          <td>{{ item?.owner_existence }}</td>
          <td>{{ item?.vip_access }}</td>
        </tr>
      </tbody>
    </table>
  </section>

  <!--
  <div>
    <mat-paginator
      (page)="handlePageEvent($event)"
      [pageIndex]="page - 1"
      [length]="totalCount"
      [pageSize]="limit"
      [pageSizeOptions]="[10, 25, 100, 150, 200]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div> -->
</section>
