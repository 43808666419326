import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService {
  constructor(private http: HttpClient) {}

  getAllPromoCodeService(page: number, limit: number) {
    return this.http.get(
      `${environment.baseUrl}/promos/?page=${page}&limit=${limit}`
    );
  }

  addPromoCodeService(promoCode: any) {
    return this.http.post(`${environment.baseUrl}/promos/`, promoCode);
  }

  editPromoCode(promoCode: any) {
    return this.http.put(`${environment.baseUrl}/promos/`, promoCode);
  }

  deletePromoCode(promoCodeId: any) {
    return this.http.delete(`${environment.baseUrl}/promos/?id=${promoCodeId}`);
  }

  verifyPromoCodeService(promoCode: any, amount: any) {
    return this.http.get(
      `${environment.baseUrl}/promos/deduct?promocode=${promoCode}&ammount=${amount}`
    );
  }
}
