import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllServicesComponent } from '../all-services/all-services.component';

@Component({
  selector: 'app-add-micro-service',
  templateUrl: './add-micro-service.component.html',
  styleUrls: ['./add-micro-service.component.css'],
})
export class AddMicroServiceComponent implements OnInit {
  serviceName = '';
  microServiceName = '';
  microServiceCost = '';
  serviceImage = '';
  constructor(
    public dialogRef: MatDialogRef<AllServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.serviceName = this.data.name;
  }

  uploadServiceImage(e: any) {
    this.serviceImage = e.target.files[0];
  }

  addService() {
    let data = {
      name: this.microServiceName,
      cost: this.microServiceCost,
      image: this.serviceImage,
    };
    this.dialogRef.close(data);
  }
}
