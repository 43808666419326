import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitDetailsComponent } from '../unit-details/unit-details.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { ThemePalette } from '@angular/material/core';

import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-register-access',
  templateUrl: './register-access.component.html',
  styleUrls: ['./register-access.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class RegisterAccessComponent implements OnInit {
  registerAccessForm: FormGroup | any;
  accessTypes: any;
  userTypes: any;
  unitUsers: any;
  unitId: any;
  color: ThemePalette = 'primary';
  checked = true;
  showUnitUsersList: boolean = false;
  showRegisterClientsAndSelectUser: boolean = true;
  showDateRange: boolean = false;
  userTypeSelected: any;

  //Printing Again
  showAccessTypeOnly: boolean = false;
  userDetails: any;
  enableRegisterButton: boolean = false;
  singleClientId: any;

  //Printing All
  clientIds: any;

  // RFID SELECTION
  RFIDSelection: boolean = false;
  selectedAccessTypeName: string | null = null;

  // RFID from Add card
  RFIDFromAddCard: boolean = false;
  accessTypeId: any;
  clientId: any;

  constructor(
    public dialogRef: MatDialogRef<UnitDetailsComponent>,
    private unitService: UnitsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.clientId || this.data.userNotPrintedIds) {
      this.singleClientId = this.data.clientId;
      this.clientIds = this.data.userNotPrintedIds;
      this.showAccessTypeOnly = true;
    }

    if (this.data.RFID_data) {
      this.RFIDFromAddCard = this.data?.RFID_data?.RFIDFromAddCard;
      this.accessTypeId = this.data?.RFID_data?.accessTypeId;
      this.clientId = this.data?.RFID_data?.clientId;
    }

    this.unitUsers = this.data?.unitUsers;
    this.unitId = this.data?.unitId;
    this.initForm();
    this.getAccessTypes();
    this.getUserTypes();
  }

  getAccessTypes() {
    this.unitService.cardType().subscribe((res: any) => {
      this.accessTypes = res?.children;
    });
  }

  getUserTypes() {
    this.unitService.unitRelation().subscribe((res: any) => {
      this.userTypes = res?.children;
    });
  }

  registerClientsChange(event?: any) {
    if (!event) {
      this.showUnitUsersList = true;
    } else {
      this.showUnitUsersList = false;
    }
  }

  selectAccessType() {
    if (this.selectedAccessTypeName == 'RFID') {
      this.checked = false;
      this.RFIDSelection = true;
      this.registerClientsChange(this.checked);
    } else {
      this.checked = true;
      this.RFIDSelection = false;
      this.registerClientsChange(this.checked);
    }
  }

  selectUserType(userType: any) {
    this.userTypeSelected = userType;
    if (userType == 'Tenant') {
      this.showRegisterClientsAndSelectUser = false;
      this.showUnitUsersList = false;
      this.showDateRange = true;
      this.registerAccessForm?.valid;
    } else {
      this.showRegisterClientsAndSelectUser = true;
      this.showUnitUsersList = false;
      this.showDateRange = false;
      this.registerAccessForm?.invalid;
    }
  }

  initForm() {
    this.registerAccessForm = new FormGroup({
      access_type_id: new FormControl(null, [Validators.required]),
      user_type_id: new FormControl(null),
      clients: new FormControl(null),
      amount: new FormControl(null),
      register_clients: new FormControl(null),
      re_issue: new FormControl(null),
      valid_from: new FormControl(null),
      valid_to: new FormControl(null),
      time_from: new FormControl('12:00'), // set the default value here
      time_to: new FormControl('19:00'),
    });

    this.registerAccessForm
      .get('access_type_id')
      ?.valueChanges.subscribe((selectedValue: any) => {
        const selectedAccessType = this.accessTypes.find(
          (item: any) => item.id === selectedValue
        );
        this.selectedAccessTypeName = selectedAccessType
          ? selectedAccessType.name
          : null;
      });
  }

  registerAccess() {
    const {
      access_type_id,
      user_type_id,
      clients,
      amount,
      register_clients,
      re_issue,
      valid_from,
      valid_to,
      time_from,
      time_to,
    } = this.registerAccessForm.value;

    const registerInputs: any = {
      access_type_id,
      user_type_id,
      clients,
      amount,
      register_clients:
        this.userTypeSelected == 'Tenant' ? true : register_clients,
      re_issue: !re_issue ? false : true,
      unit_id: this.unitId,
      valid_from,
      valid_to,
      time_from,
      time_to,
    };

    if (this.userTypeSelected != 'Tenant') {
      delete registerInputs.valid_from;
      delete registerInputs.valid_to;
      delete registerInputs.time_from;
      delete registerInputs.time_to;
    }

    // if (registerInputs.register_clients) {
    //   delete registerInputs.clients;
    //   delete registerInputs.reprint;
    // } else {
    //   if (this.selectedAccessTypeName == 'RFID') {
    //     delete registerInputs.register_clients;
    //     delete registerInputs.user_type_id;
    //     delete registerInputs.amount;
    //   } else {
    //     delete registerInputs.register_clients;
    //     delete registerInputs.user_type_id;
    //     delete registerInputs.amount;
    //     delete registerInputs.reprint;
    //   }
    // }

    if (
      registerInputs.register_clients &&
      this.selectedAccessTypeName == 'RFID'
    ) {
      delete registerInputs.clients;
    } else if (registerInputs.register_clients) {
      delete registerInputs.clients;
      delete registerInputs.re_issue;
    } else if (this.selectedAccessTypeName == 'RFID') {
      delete registerInputs.register_clients;
      delete registerInputs.user_type_id;
      delete registerInputs.amount;
    } else {
      delete registerInputs.register_clients;
      delete registerInputs.user_type_id;
      delete registerInputs.amount;
      delete registerInputs.re_issue;
    }

    this.dialogRef.close(registerInputs);
  }

  registerAccessForPrintingAgin() {
    const { access_type_id } = this.registerAccessForm.value;
    const registerInputs: any = {
      access_type_id,
      clients: this.singleClientId ? [this.singleClientId] : this.clientIds,
      unit_id: this.unitId,
    };
    this.dialogRef.close(registerInputs);
  }

  registerAccessForRIFDFromAddCard() {
    const { re_issue } = this.registerAccessForm.value;

    const registerInputs: any = {
      access_type_id: this.accessTypeId,
      clients: [this.clientId],
      re_issue: !re_issue ? false : true,
      unit_id: this.unitId,
    };

    this.dialogRef.close(registerInputs);
  }
}
