import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddRoleComponent } from '../add-role/add-role.component';
import { RolesService } from 'src/app/services/roles.service';
import { EditRoleComponent } from '../edit-role/edit-role.component';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-roles-main',
  templateUrl: './roles-main.component.html',
  styleUrls: ['./roles-main.component.css'],
})
export class RolesMainComponent implements OnInit {
  showSpinner: boolean = true;
  role_arr: any = [];
  constructor(
    public dialog: MatDialog,
    private mainService: MainService,
    private rest: RolesService
  ) {}

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.rest.retrieveRoles().subscribe((res: any) => {
      this.showSpinner = false;
      this.role_arr = res;
    });
  }
  addRole() {
    const dialogRef = this.dialog.open(AddRoleComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let permissionId: any = [];
        result.permiosn.forEach((element: any) => {
          permissionId.push(element.id);
        });
        let data = {
          name: result.name,
        };
        this.rest.addRole(data).subscribe((res: any) => {
          let data = {
            role_id: res,
            permissions: permissionId,
          };
          this.rest.addPermissionForRole(data).subscribe((res: any) => {
            this.mainService.SuccessToast('Role added successfully');
            this.getRoles();
          });
        });
      }
    });
  }

  editRole(item: any) {
    const dialogRef = this.dialog.open(EditRoleComponent, {
      width: '450px',
      data: item,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let data = {
          id: result.roleId,
          name: result.name,
        };
        this.rest.editRole(data).subscribe((res: any) => {
          let permissionId: any = [];
          result.permiosn.forEach((element: any) => {
            permissionId.push(element.id);
          });
          let data = {
            role_id: result.roleId,
            permissions: permissionId,
          };
          this.rest.addPermissionForRole(data).subscribe((res: any) => {
            this.mainService.SuccessToast('Role updated successfully');
            this.getRoles();
          });
        });
      }
    });
  }
}
