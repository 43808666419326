import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MangeVillageComponent } from './mange-village/mange-village.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VillageDetailsComponent } from './village-details/village-details.component';
import { AddVillageComponent } from './add-village/add-village.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    MangeVillageComponent,
    VillageDetailsComponent,
    AddVillageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule
  ],
})
export class VillagesModule {}
