<section class="main">
  <h3>Add Service</h3>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label>Name</label>
        <input [(ngModel)]="serviceName" type="text" class="form-control" />
      </div>
    </div>
  </div>
  <div class="mt-4">
    <p>Service image</p>
    <label for="images" class="drop-container">
      <input
        (change)="uploadServiceImage($event)"
        type="file"
        id="images"
        accept="image/*"
        required
      />
    </label>
  </div>

  <div class="mt-4">
    <button class="btn add" (click)="addService()">Add Service</button>
    <button class="btn">Cancel</button>
  </div>
</section>
