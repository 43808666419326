import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UnitsService } from 'src/app/services/units.service';
import { UserAccessConfigurationComponent } from '../user-access-configuration/user-access-configuration.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-edit-user-access-configuration',
  templateUrl: './edit-user-access-configuration.component.html',
  styleUrls: ['./edit-user-access-configuration.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class EditUserAccessConfigurationComponent implements OnInit {
  types_arr: any;
  accessTypes: any;
  userTypesArray: any;
  userAccessDetails: any;
  showSpinner: boolean = true;
  pipe = new DatePipe('en-US');
  insured: boolean | null = null;
  disableTypesList: boolean = true;
  editUserAccessConfigurationForm: FormGroup | any;

  constructor(
    public dialogRef: MatDialogRef<UserAccessConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.userAccessDetails = this.data?.userAccessConfiguration;
    this.initForm();
    this.patchingValues();
  }

  patchingValues() {
    this.editUserAccessConfigurationForm.patchValue({
      id: this.userAccessDetails.id,
      maximum_extra_cards: this.userAccessDetails?.maximum_extra_cards,
      reissuance_fees: this.userAccessDetails?.reissuance_fees,
      insured: this.userAccessDetails?.insured,
      insurance_fees: this.userAccessDetails?.insurance_fees,
      cards_per_bedroom: this.userAccessDetails?.cards_per_bedroom,
      expiry_date: this.userAccessDetails?.expiry_date,
      minimum_stay: this.userAccessDetails?.minimum_stay,
      maximum_first_cards: this.userAccessDetails?.maximum_first_cards,
      minimum_stay_price: this.userAccessDetails?.minimum_stay_price,
      first_issue_fees: this.userAccessDetails?.first_issue_fees,
      extra_cards_fees: this.userAccessDetails?.extra_cards_fees,
    });

    this.insured = this.userAccessDetails?.insured;
    if (this.insured) {
      this.editUserAccessConfigurationForm.get('insurance_fees').enable();
    } else {
      this.editUserAccessConfigurationForm.get('insurance_fees').disable();
    }

    this.showSpinner = false;
  }

  initForm() {
    this.editUserAccessConfigurationForm = new FormGroup({
      maximum_extra_cards: new FormControl(null, [Validators.required]),
      reissuance_fees: new FormControl(null, [Validators.required]),
      insured: new FormControl(null, [Validators.required]),
      insurance_fees: new FormControl(null, [Validators.required]),
      cards_per_bedroom: new FormControl(null, [Validators.required]),
      expiry_date: new FormControl(null, [Validators.required]),
      minimum_stay: new FormControl(null, [Validators.required]),

      maximum_first_cards: new FormControl(null, [Validators.required]),
      minimum_stay_price: new FormControl(null, [Validators.required]),
      first_issue_fees: new FormControl(null, [Validators.required]),
      extra_cards_fees: new FormControl(null, [Validators.required]),
    });
  }

  onInsuredChange(event: any): void {
    this.insured = event?.value;
    if (this.insured) {
      this.editUserAccessConfigurationForm.get('insurance_fees').enable();
    } else {
      this.editUserAccessConfigurationForm.get('insurance_fees').disable();
    }
  }

  editUserAccessConfiguration() {
    const {
      maximum_extra_cards,
      reissuance_fees,
      insured,
      insurance_fees,
      cards_per_bedroom,
      expiry_date,
      minimum_stay,
      maximum_first_cards,
      minimum_stay_price,
      first_issue_fees,
      extra_cards_fees,
    } = this.editUserAccessConfigurationForm.value;

    const editUserConfigInputs: any = {
      id: this.userAccessDetails?.id,
      maximum_extra_cards,
      reissuance_fees,
      insured,
      insurance_fees,
      cards_per_bedroom,
      expiry_date: this.pipe.transform(expiry_date, 'YYYY-MM-dd'),
      minimum_stay,
      maximum_first_cards,
      minimum_stay_price,
      first_issue_fees,
      extra_cards_fees,
    };

    if (!this.insured) {
      editUserConfigInputs.insurance_fees = 0;
    }

    this.dialogRef.close(editUserConfigInputs);
  }

  //Form Validator
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editUserAccessConfigurationForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);

    return result;
  }
  //Form Validator
}
