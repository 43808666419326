import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserCardConfigurationComponent } from '../user-card-configuration/user-card-configuration.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.css'],
})
export class EditConfigurationComponent implements OnInit {
  showSpinner: boolean = true;
  editUserConfiguration: FormGroup | any;
  project: any;
  type: any;
  frontPhoto: any;
  backPhoto: any;

  configurationId: any;

  //Front image
  frontImageEdit = false;
  frontImagePath: any = '';
  frontImageUrl: any;
  errorFrontImage = false;
  //Front image

  //Back image
  backImageEdit = false;
  backImagePath: any = '';
  backImageUrl: any;
  errorBackImage = false;
  //Back image

  constructor(
    private mainService: MainService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly configurations: ConfigurationsService,
    public dialogRef: MatDialogRef<UserCardConfigurationComponent>
  ) {}

  ngOnInit() {
    this.loadMainData();
  }

  loadMainData() {
    this.configurationId = this.data.configuration.id;
    this.project = this.data.configuration.project;
    this.type = this.data.configuration.type;
    this.backPhoto = this.data.configuration.backPhoto;
    this.frontPhoto = this.data.configuration.frontPhoto;
    this.showSpinner = false;

    if (this.backPhoto) {
      this.backImageUrl = this.backPhoto;
      this.backImageEdit = true;
    }

    if (this.frontPhoto) {
      this.frontImageUrl = this.frontPhoto;
      this.frontImageEdit = true;
    }
  }

  //Upload front Image
  uploadFrontImage(event: any) {
    this.frontImageEdit = true;
    this.frontImagePath = event.target.files[0];
    const reader = new FileReader();
    let imagePath = this.frontImagePath;
    reader.readAsDataURL(imagePath);
    reader.onload = (_event) => {
      this.frontImageUrl = reader.result;
      this.errorFrontImage = false;
    };
  }
  //Upload front Image

  //Remove front Image
  removeFrontImage() {
    this.frontImagePath = '';
    this.frontImageEdit = false;
    this.errorFrontImage = true;
  }
  //Remove front Image

  //Upload back Image
  uploadBackImage(event: any) {
    this.backImageEdit = true;
    this.backImagePath = event.target.files[0];
    const reader = new FileReader();
    let imagePath = this.backImagePath;
    reader.readAsDataURL(imagePath);
    reader.onload = (_event) => {
      this.backImageUrl = reader.result;
      this.errorBackImage = false;
    };
  }
  //Upload back Image

  //Remove back Image
  removeBackImage() {
    this.backImagePath = '';
    this.backImageEdit = false;
    this.errorBackImage = true;
  }
  //Remove back Image

  saveEditUserConfiguration() {
    this.showSpinner = true;
    var formData = new FormData();

    const configurations: any = {
      id: this.configurationId,
    };

    for (const key in configurations) {
      const formattedKey = key.includes('_')
        ? key
        : key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
      formData.append(formattedKey, configurations[key]);
    }

    if (this.frontImagePath) {
      formData.append('front', this.frontImagePath);
    }

    if (this.backImagePath) {
      formData.append('back', this.backImagePath);
    }

    this.configurations.editConfiguration(formData).subscribe({
      next: (response: any) => {
        if (response.status) {
          this.showSpinner = false;
          this.mainService.SuccessToast(response.message);
          this.dialogRef.close(response);
        } else {
          this.showSpinner = false;
          this.mainService.errorToast(response?.message);
        }
      },
      error: (err) => {
        this.showSpinner = false;
        this.mainService.errorToast(err?.error?.message);
      },
    });
  }

  close() {
    this.dialogRef.close();
  }
}
