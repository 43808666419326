<section class="main">
  <h1>Gates Access</h1>

  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Gate">
      <div class="m-3 addGate">
        <button class="btn" (click)="addGate()">Add Gate</button>
      </div>

      <mat-spinner *ngIf="showSpinnerForGates" diameter="50"></mat-spinner>

      <section *ngIf="!showSpinnerForGates" class="sup_main">
        <table class="mt-3 table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Cctv IP</th>
              <th scope="col">Anpr IP</th>
              <th scope="col">Face recognition IP</th>
              <th scope="col">hikVision IP</th>
              <th scope="col">IP</th>
              <th scope="col">Direction</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of gates_arr">
              <td>{{ item?.id }}</td>
              <td>{{ item?.name }}</td>
              <td>{{ item?.type }}</td>
              <td>{{ item?.cctv_ip }}</td>
              <td>{{ item?.anpr_ip }}</td>
              <td>{{ item?.face_rec_ip }}</td>
              <td>{{ item?.hikvision_ip }}</td>
              <td>{{ item?.ip }}</td>
              <td>{{ item?.direction }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </mat-tab>

    <mat-tab label="Village">
      <div class="mt-5 addGate">
        <div class="me-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose Village</mat-label>
            <mat-select
              (selectionChange)="villageChange($event)"
              [(value)]="selectedVillage"
            >
              <mat-option value="all"> All </mat-option>
              <mat-option *ngFor="let item of village_list" [value]="item">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button class="btn" (click)="addVillageConfig()">
          Add Village config
        </button>
      </div>

      <mat-spinner *ngIf="showSpinnerForVillages" diameter="50"></mat-spinner>

      <section *ngIf="!showSpinnerForVillages" class="mt-3">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Village Name</th>
              <th scope="col">Kids access</th>
              <th scope="col">Rented unit</th>
              <th scope="col">Excluded</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of villages_arr_table">
              <td>{{ item?.name }}</td>
              <td>{{ item?.kids_access }}</td>
              <td>{{ item?.rented_unit }}</td>
              <td>
                <div class="mainDivExcluded">
                  <div
                    class="excluded"
                    style="margin-bottom: 10px"
                    *ngFor="let excluded of item?.excluded"
                  >
                    {{ excluded }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </mat-tab>

    <mat-tab label="Users">
      <div class="mt-5 addGate">
        <div class="me-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose user type</mat-label>
            <mat-select
              (selectionChange)="changeType($event)"
              [(value)]="selected_type"
            >
              <mat-option value="all">All</mat-option>
              <mat-option *ngFor="let item of types_arr" [value]="item">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="me-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose Village</mat-label>
            <mat-select
              (selectionChange)="village_user_Change($event)"
              [(value)]="selected_user_village"
            >
              <mat-option value="all"> All </mat-option>
              <mat-option *ngFor="let item of village_list" [value]="item">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <button class="btn me-2" (click)="addUserType()">
            Add user type
          </button>
          <button class="btn" (click)="add_user_config()">
            Add User config
          </button>
        </div>
      </div>

      <mat-spinner *ngIf="showSpinnerForUsers" diameter="50"></mat-spinner>

      <section *ngIf="!showSpinnerForUsers" class="mt-3">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Village Name</th>
              <th scope="col">User Type</th>
              <th scope="col">Anti Passback</th>
              <th scope="col">Applying quota</th>
              <th scope="col">Owner existence</th>
              <th scope="col">Vip access</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of users_list_arr">
              <td>{{ item?.name }}</td>
              <td>{{ item?.type }}</td>
              <td>{{ item?.anti_passback }}</td>
              <td>{{ item?.applying_quota }}</td>
              <td>{{ item?.owner_existence }}</td>
              <td>{{ item?.vip_access }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </mat-tab>

    <mat-tab label="Qr">
      <div class="mt-5 addGate">
        <div class="me-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose Qr type</mat-label>
            <mat-select
              (selectionChange)="changeQrType($event)"
              [(value)]="selectedQr_type"
            >
              <mat-option *ngFor="let item of types_qr_arr" [value]="item">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="me-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose Village</mat-label>
            <mat-select
              (selectionChange)="village_qr_change($event)"
              [(value)]="selected_qr_village"
            >
              <mat-option *ngFor="let item of village_list" [value]="item">
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <button class="btn me-2" (click)="addQrType()">Add Qr type</button>
          <button class="btn" (click)="add_qr_config()">Add Qr config</button>
        </div>
      </div>

      <mat-spinner *ngIf="showSpinnerForQr" diameter="50"></mat-spinner>

      <section *ngIf="!showSpinnerForQr" class="mt-3">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Village Name</th>
              <th scope="col">Qr Type</th>

              <th *ngFor="let config of qrConfigArray">
                {{ config?.name | titlecase }}
              </th>

              <!-- <th scope="col">One time use</th>
              <th scope="col">Started</th>
              <th scope="col">Ended</th>
              <th scope="col">Available days</th>
              <th scope="col">Start time</th>
              <th scope="col">30 seconds</th>
              <th scope="col">Full day access</th>
              <th scope="col">Count</th> -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ qrObject.name }}</td>
              <td>{{ qrObject.type }}</td>

              <td *ngFor="let item of objectEntries(qrObject)">
                {{ item[1] }}
              </td>

              <!-- <td>{{ qrObject.one_time_use }}</td>
              <td>{{ qrObject.started }}</td>
              <td>{{ qrObject.ended }}</td>
              <td>{{ qrObject.available_days }}</td>
              <td>{{ qrObject.start_time }}</td>
              <td>{{ qrObject.thirty_seconds }}</td>
              <td>{{ qrObject.full_day_access }}</td>
              <td>{{ qrObject.count }}</td> -->
            </tr>
          </tbody>
        </table>
      </section>
    </mat-tab>
  </mat-tab-group>
</section>
