import { Component, OnInit } from '@angular/core';
import { GAccessService } from 'src/app/services/g-accsess.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MainComponent } from '../main/main.component';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css'],
})
export class AddCategoryComponent implements OnInit {
  typeArray: any;
  typeId: any;
  typename: any = 'Choose Type';
  categoryName: any = '';
  submitted = false;
  constructor(
    private rest: GAccessService,
    public dialogRef: MatDialogRef<MainComponent>
  ) {}

  ngOnInit() {
    this.getTypes();
  }

  getTypes() {
    this.rest.getTypes().subscribe((res: any) => {
      this.typeArray = res.children;
    });
  }

  getTypeInfo(item: any) {
    this.typename = item.name;
    this.typeId = item.id;
  }

  submit() {
    this.submitted = true;
    if (this.categoryName != '') {
      if (this.typename == 'Choose Type') {
        let data = {
          name: this.categoryName,
          parent_id: 1,
        };
        this.dialogRef.close(data);
      } else {
        let data = {
          name: this.categoryName,
          parent_id: this.typeId,
        };
        this.dialogRef.close(data);
      }
    }
  }
}
