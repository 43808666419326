<section class="main m-0 mb-1">
  <h3 style="text-align: center" class="mb-4">Add New Configuration</h3>

  <mat-spinner diameter="20" *ngIf="showSpinner" class="mb-4"></mat-spinner>

  <div *ngIf="!showSpinner" class="row" [formGroup]="addNewImageForm">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Project name</mat-label>
        <mat-select
          fullWidth
          placeholder="Project name"
          formControlName="project_id"
          class="d-felx justify-content-center align-items-center"
        >
          <mat-option [value]="item?.id" *ngFor="let item of projectsArray">
            <strong>{{ item?.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose User Type</mat-label>
        <mat-select formControlName="userTypes">
          <mat-option
            *ngFor="let userType of userTypesArray"
            [value]="userType?.id"
            (click)="selectUserType(userType)"
          >
            <strong>{{ userType.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Choose Type</mat-label>
        <mat-select formControlName="type_id">
          <mat-option *ngFor="let item of types_arr" [value]="item.id">
            <strong>{{ item.name | titlecase }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!showSpinner">
    <strong>Add images:</strong>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="row mt-3 d-flex">
        <div *ngIf="frontImageEdit" class="image-container">
          <img [src]="frontImageUrl" class="image" />
          <button class="remove-button" (click)="removeFrontImage()">
            Remove
          </button>
        </div>

        <input
          type="file"
          id="frontImage"
          hidden
          (change)="uploadFrontImage($event)"
          #fileInput2
        />

        <label
          for="frontImage"
          class="uploadImage"
          title="Upload Front Image"
          *ngIf="!frontImageEdit"
        >
          <div>
            <img
              class="icon"
              src="../../../../assets/images/upload.png"
              alt=""
            />
            <br />
            <strong>Upload Front Image</strong>
          </div></label
        >
      </div>

      <div class="row mt-3 d-flex">
        <div *ngIf="backImageEdit" class="image-container">
          <img [src]="backImageUrl" class="image" />
          <button class="remove-button" (click)="removeBackImage()">
            Remove
          </button>
        </div>

        <input
          type="file"
          id="backImage"
          hidden
          (change)="uploadBackImage($event)"
          #fileInput2
        />

        <label
          for="backImage"
          class="uploadImage"
          title="Upload unit photos"
          *ngIf="!backImageEdit"
        >
          <div>
            <img
              class="icon"
              src="../../../../assets/images/upload.png"
              alt=""
            />
            <br />
            <strong>Upload Back Image</strong>
          </div></label
        >
      </div>
    </div>
  </div>

  <div *ngIf="!showSpinner" class="d-flex justify-content-center mt-3">
    <button class="btn addConfiguration" (click)="addNewConfigurationImage()">
      Add
    </button>
  </div>
</section>
